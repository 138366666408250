import axios from "axios";
import ApiUtils from "./ApiUtils";

export const TaskStatus = [
  { label: "Not Started", color: "#f6a111" },
  { label: "Viewed", color: "#3461eb" },
  { label: "In Process", color: "#12d87c" },
  { label: "On Hold", color: "#929292" },
  { label: "Canceled", color: "#e4572e" },
  { label: "Done", color: "#0a9856" },
];

export const ApproveTaskStatus = [
  { label: "Not Started", color: "#f6a111" },
  { label: "Viewed", color: "#3461eb" },
  { label: "In Process", color: "#12d87c" },
  { label: "On Hold", color: "#929292" },
  { label: "Canceled", color: "#e4572e" },
  { label: "Approved", color: "#0a9856" },
];

export const TaskPriority = ["Low", "Medium", "High"];

export interface TaskInfo {
  id: string;
  name: string;
  description?: string;
  status: string;
  priority: string;
  assignedTo: string;
  createdBy: string;
  created?: string;
  dueDate?: string;
  type?: string;
  tag: string;
  comments?: any[];
  images?: string[];
  addDailyReminder?: boolean;
  hasDailyReminder?: boolean;
}

export interface TemplateTaskInfo {
  name: string;
  description: string;
  priority: string;
  tag: string;
  toMe: boolean;
}

export interface TaskSavedList {
  tasks: TemplateTaskInfo[];
  name: string;
}

export interface TaskListResponse {
  tasks: TaskInfo[];
}

export interface TaskSSavedListResponse {
  list: TaskSavedList[];
}

class TasksApi {
  getProjectTasks = async (
    projectId: string,
    token: string
  ): Promise<TaskListResponse> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/briefs/api/task/project/${projectId}`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return { tasks: [] };
      }
    } catch {
      return { tasks: [] };
    }
  };

  sendReminder = async (taskId: string, token: string): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/briefs/api/task/${taskId}/reminder`,
        {},
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  deleteTask = async (taskId: string, token: string): Promise<any> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/briefs/api/task/${taskId}`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };

  createTask = async (
    task: TaskInfo,
    projectId: string,
    token: string
  ): Promise<any> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/task/project/${projectId}`,
      task,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  markCommentAsRead = async (
    taskId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/task/${taskId}/comments/mark_viewed`,
      {},
      ApiUtils.Put(token)
    );
    return response.data;
  };

  addCommentToTask = async (
    commentId: string,
    comments: any[],
    email: string,
    token: string
  ): Promise<any> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/task/${commentId}`,
      { comments, email },
      ApiUtils.Put(token)
    );
    return response.data;
  };

  updateTask = async (task: TaskInfo, token: string): Promise<any> => {
    const response = await axios.patch(
      `${ApiUtils.serverDomain}/briefs/api/task/${task.id}`,
      task,
      ApiUtils.Patch(token)
    );
    return response.data;
  };

  addNotificationForTask = async (
    taskId: string,
    email: string,
    token: string
  ): Promise<any> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/notifications/${taskId}/approve_task_reminder`,
      email,
      ApiUtils.Post(token)
    );
    return response.data;
  };
}

export const tasksApi = new TasksApi();
