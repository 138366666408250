import { Box } from "@mui/material";
import * as React from "react";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { ShareLinkCopy } from "./ShareLinkCopy";

export interface CMSFieldCopyBtmProps {
  isPara?: boolean;
  value: string;
  invert?: boolean;
  allowCopy?: boolean;
}

export const CMSFieldCopyBtn: React.FC<CMSFieldCopyBtmProps> = ({
  value,
  isPara,
  invert,
  allowCopy,
}) => {
  const blockCopy: boolean = useAppSelector(
    (state: RootState) => state.cmsData.blockCopy
  );
  const cmsState = useAppSelector((state: RootState) => state.cmsData);

  const renderCopy = () => {
    return (!cmsState.sideBarMode && !cmsState.inFormBuildMode) || allowCopy ? (
      <Box
        sx={
          isPara
            ? {
                padding: "5px 8px 0px",
                background: invert ? "#282B28" : "#fff",
                flexGrow: 1,
              }
            : {
                display: "flex",
                alignItems: "center",
                padding: "0px 8px",
                background: invert ? "#282B28" : "#fff",
                cursor: "pointer",
              }
        }
      >
        <ShareLinkCopy linkToShare={value}>
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill={invert ? "#fff" : "#7C48C2"} fillRule="nonzero">
              <path d="M12.955 17.727H4.773a2.045 2.045 0 0 1-2.046-2.045V4.772c0-1.129.916-2.045 2.046-2.045h8.182c1.13 0 2.045.916 2.045 2.046v10.909c0 1.13-.916 2.045-2.045 2.045zM4.773 4.091a.682.682 0 0 0-.682.682v10.909c0 .376.305.682.682.682h8.182a.682.682 0 0 0 .681-.682V4.772a.682.682 0 0 0-.681-.681H4.773z" />
              <path d="M.682 10.91A.682.682 0 0 1 0 10.226V2.045C0 .915.916 0 2.045 0h6.819a.682.682 0 1 1 0 1.364H2.045a.682.682 0 0 0-.681.681v8.182a.682.682 0 0 1-.682.682z" />
            </g>
          </svg>
        </ShareLinkCopy>
      </Box>
    ) : null;
  };

  return !blockCopy ? (
    isPara ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          flexDirection: "column",
        }}
      >
        <Box sx={{ height: "38px" }}></Box>
        {renderCopy()}
      </Box>
    ) : (
      renderCopy()
    )
  ) : null;
};
