import { Tooltip, Typography } from "@mui/material";
import React, { useState, FC } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// @material-ui/core components

export interface ShareLinkCopyProps {
  linkToShare: string;
  children?: React.ReactNode;
}

export const ShareLinkCopy: FC<ShareLinkCopyProps> = ({
  linkToShare,
  children,
}) => {
  const [copiedText, setCopiedText] = useState<boolean>(false);

  return (
    <>
      <CopyToClipboard text={linkToShare} onCopy={() => setCopiedText(true)}>
        <Tooltip
          title={copiedText ? "Copied!" : "Copy To Clipboard"}
          sx={{color:'#fff'}}
          componentsProps={{
            tooltip: {
              sx: {
                textAlign: "center",
                color: "#fff",
                "& .MuiTooltip-arrow": {
                  color: "#fff",
                },
              },
            },
          }}
          placement="top"
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: 300,
              color: "#fff",
              fontSize: "14px",
              cursor: "pointer",
            }}
          > 
            {children}
          </Typography>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};
