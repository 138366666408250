import { Box, Divider, TextField } from "@mui/material";
import React, { FC } from "react";
import { SubscriptionInfo } from "../../../../api/paymentApi";
import { AValiablePackages, PlanCycle, PlanPakageInfo } from "../../../PackagesConfig";
import { DeleteMessageDialog } from "../../../../Massages/DeleteMessageDialog";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export interface SubscriptionViewProps {
  subscription: SubscriptionInfo;
  onDeleteApproved: (reason: string) => void;
  subscriptionData: any;
}

export const SubscriptionView: FC<SubscriptionViewProps> = ({
  subscription,
  onDeleteApproved,
  subscriptionData
}) => {
  const [planCycleInfo, setPlanCycleInfo] = React.useState<PlanCycle>();
  const [reason, setReason] = React.useState<string>("");
  const [showCancelSubscriptionMessage, setShowCancelSubscriptionMessage] =
    React.useState<boolean>(false);
    const [buyerEmail, setBuyerEmail] = React.useState<string>("");
  const navigate = useNavigate();  
  const [planInfo, setPlanInfo] = React.useState<PlanPakageInfo>()
  

  const getPlanCycle = (planId: string) => {
    // const planId = subscription[0].planId;
    const _planInfo = AValiablePackages.find((plan) => {
      return plan.cycles.map((c) => c.planId).includes(planId);
    });
    setPlanInfo(_planInfo)
    return _planInfo?.cycles.find((c) => c.planId === planId);
  };




  React.useEffect(() => {
    if (subscriptionData) {
      const subscriptionDetails = _.get(subscriptionData, 'subscriptionDetails', {})
      const subscriber = _.get(subscriptionDetails, 'subscriber', {})
      const planId = _.get(subscriptionDetails, 'plan_id', '')
      setPlanCycleInfo(getPlanCycle(planId))
      setBuyerEmail(_.get(subscriber, 'email_address', ''))
    }
  }, [subscriptionData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: "#fff",
        padding: "35px",
        gap: "30px",
      }}
    >
      {planCycleInfo && planInfo && (
        <>
          <Box>Plan Details</Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              {planInfo.name} plan -{" "}
              {planCycleInfo.cycle === "month" ? "monthly" : "annual"}
            </Box>
            <Box sx={{ color: "#6c3dab", cursor: "pointer" }} onClick={()=> navigate('/pricing')}>Change plan</Box>
          </Box>
          <Box>
            <Box sx={{ }}>Confirmation email sent to <b>{buyerEmail}</b></Box>
          </Box>
          <Box>
            <Divider sx={{ width: "100%" }} />
          </Box>
          <Box>
            {subscription.status === "Approved" ? (
              <Box
                sx={{ color: "#bf0000", cursor: "pointer" }}
                onClick={() => setShowCancelSubscriptionMessage(true)}
              >
                Cancel subscription
              </Box>
            ): (
              <Box
                sx={{ color: "#bf0000" }}
              >
                Status - {subscription.status}
              </Box>
            )}
          </Box>
        </>
      )}
      {showCancelSubscriptionMessage && (
        <DeleteMessageDialog
          onClose={() => setShowCancelSubscriptionMessage(false)}
          onSecondAction={() => setShowCancelSubscriptionMessage(false)}
          onDelete={() => {
            if (_.isEmpty(reason)) {
              return;
            }
            setShowCancelSubscriptionMessage(false);
            onDeleteApproved(reason);
          }}
          secondActionText="No"
          actionText="Yes"
          title={`Are you sure you want to cancel your subscription?`}
          message={
            <Box>
              By canceling your subscription, you will lose access to all your
              projects and data.
              <Box sx={{marginTop: '20px'}}>Please provide a reason *</Box>
              <TextField
                required
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  background: "#f9f9f9",
                  "& input.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                    WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                  },
                }}
                inputProps={{ className: "dropDownOther" }}
                placeholder={"Enter cancelation reason"}
                multiline={true}
                rows={4}
                maxRows={4}
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};
