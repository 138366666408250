import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Popper,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { get, orderBy } from "lodash";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { clientApi } from "../../api/clientApi";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { CMSFieldCopyBtn } from "../../basic/CMSFieldCopyBtn";
import { ProjectDetalisContext } from "../../projects/ProjectForm/ProjectSettings";
import { COUNTRY_LIST } from "../../utils/Constants";
import {
  CMSComponent,
  CMSState,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";

export interface DropDownCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  padding: number;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export interface DependOnValue {
  value: string;
  other: string;
}

export const DropDownComp: React.FC<DropDownCompProps> = ({
  comp,
  padding,
  location,
  onUpdateComp,
  readonly,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<string[]>([]);
  const [dependOnVal, setDependOnVal] = React.useState<DependOnValue>();
  const loading = open && options.length === 0 && comp.remoteLoad;
  const context = React.useContext(ProjectDetalisContext);
  const cmsState: CMSState = useAppSelector((state: RootState) => state.cmsData);

  useAsyncEffect(async () => {
    if (comp.dependsOn && cmsState.pages.length > 0) {
      const dic = cmsState.cmsItems // TreeUtils.extractDicFromCms(cmsData.pages);
      const cmdDicVal: any = get(dic, comp.dependsOn, { value: "", other: "" });
      if (
        dependOnVal &&
        cmdDicVal.value !== dependOnVal.value &&
        comp.value !== "" &&
        dependOnVal.value !== ""
      ) {
        const updateComp = { ...comp, value: "" };
        onUpdateComp(UpdateCompBuilder(location, updateComp));
      }
      setDependOnVal(cmdDicVal);
    }
  }, [cmsState.cmsItems]);

  const dependOnValExist = () => {
    if (comp.dependsOn) {
      const dic = cmsState.cmsItems // TreeUtils.extractDicFromCms(cmsData.pages);
      const cmdDicVal: any = get(dic, comp.dependsOn, { value: "", other: "" });
      if (
        comp.value !== "" &&
        cmdDicVal.value != ""
      ) {
        setDependOnVal(cmdDicVal);
        return true;
      }
    }
      return false
  }

  useAsyncEffect(async () => {
    if (!comp.remoteLoad && comp.mapKey === "73") {
      setOptions(COUNTRY_LIST);
    } else if (!comp.dependsOn || dependOnValExist() ) {
      getOptions()
    }
  }, []);

  const getValueFromData = () => {
    if (typeof comp.value === "string") {
      return comp.value ? comp.value : "";
    } else {
      if (comp.value && comp.value.value) {
        return comp.value.value;
      } else return "";
    }
  };

  const getOtherValueFromData = () => {
    if (typeof comp.value === "string") {
      return comp.value ? comp.value : "";
    } else {
      if (comp.value && comp.value.other) {
        return comp.value.other;
      } else return "";
    }
  };

  const handleChange = (event: any, newValue: string | null) => {
    const updateComp = {
      ...comp,
      value: {
        value: newValue ? newValue : "",
        other:
          newValue?.toLowerCase() !== "other" ? "" : getOtherValueFromData(),
      },
    };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  const onOtherInputChange = (newValue: string) => {
    const updateComp = {
      ...comp,
      value: { other: newValue, value: getValueFromData() },
    };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  const sleep = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  };

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (!comp.dependsOn) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.
      if (active) {
        getOptions();
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open && comp.remoteLoad && comp.dependsOn) {
      setOptions([]);
    }
  }, [open]);

  const getOptions = async () => {
    if (comp.mapKey === "39") {
      const categories = await clientApi.getBusniessCategories();
      setOptions([
        ...orderBy(categories, ["name"], ["asc"]).map((r) => r.name),
        "Other",
      ]);
    } else if (comp.mapKey === "40" && comp.dependsOn) {
      const categories = await clientApi.getBusniessCategories();
      if (dependOnVal) {
        const dependStr =
          dependOnVal.value.toLowerCase() !== "other"
            ? dependOnVal.value
            : dependOnVal.other;
        const category = categories.find((r) => r.name === dependStr);
        if (category) {
          const subOptions = await clientApi.getSubBusniessCategories(
            category.name
          );
          setOptions([
            ...orderBy(subOptions, ["name"], ["asc"])
              .map((r) => r.name)
              .filter((r) => r.toLowerCase() !== "other"),
            "Other",
          ]);
        } else {
          setOptions(["Other"]);
        }
      }
    } else {
      setOptions(comp.options??[])
    }
  };

  const checkIsDisabled = () => {
    return comp.dependsOn ? dependOnVal && (dependOnVal.value === "" || dependOnVal.value.toLowerCase() === "other"): false;
  };

  return (
    <Grid item xs={padding}>
      <Box sx={{ display: "flex", zIndex:10000 }}>
        <Box sx={{ flexGrow: 1 }}>
          <FormControl fullWidth>
            <Autocomplete             
              id={`compKey-${comp.mapKey}`}
              open={open}
              sx={{
                background: "#f9f9f9",
                "& .MuiAutocomplete-listbox": { border: "1px solid #fff", fontFamily:'Nunito' },
                "& .MuiAutocomplete-groupUl" : {fontFamily:'Nunito',color: 'red' },
              }}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              disablePortal={true}
              autoHighlight
              loading={loading}
              options={options}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  placement="bottom"    
                  disablePortal={false}
                  modifiers={[
                    {
                      name: "flip",
                      enabled: true,
                      options: {
                        altBoundary: true,
                        rootBoundary: "document",
                        padding: 8,
                      },
                    },
                  ]}
                >
                  <Box  className={'lynxiDropDowm'} sx={{ border: "1px solid black", fontFamily:'Nunito'}}>{props.children}</Box>
                </Popper>
              )}
              disabled={readonly || checkIsDisabled()}
              value={getValueFromData()}
              onChange={handleChange}
              renderInput={(params) => (
                <form autoComplete={"new-password"}>
                <TextField
                  {...params}
                  autoComplete={"off"}
                  required={comp.required}
                  label={comp.description}
                  className={getValueFromData() !=='' ? 'inputWithVal': ''}
                  sx={{
                    "& input.Mui-disabled": {
                      color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
                </form>
              )}
            />
            {getValueFromData().toLowerCase() === "other" && (
              <Box sx={{ marginTop: "5px", marginright: "20px", width: "60%" }}>
                <TextField
                  autoFocus
                  sx={{ width: "100%", background: "#f9f9f9" }}
                  variant="outlined"
                  autoComplete={"off"}
                  disabled={readonly}
                  required={comp.required}
                  value={getOtherValueFromData()}
                  placeholder={`Other ${comp.description}`}
                  onChange={(e) => onOtherInputChange(e.target.value)}
                />
              </Box>
            )}
          </FormControl>
          {context && context.errors.indexOf(comp.mapKey) >= 0 && (
            <Box sx={{ color: "red", fontSize: "12px", marginLeft: "3px" }}>
              This field is required
            </Box>
          )}
        </Box>
        <CMSFieldCopyBtn
          value={
            getValueFromData().toLowerCase() === "other"
              ? getOtherValueFromData()
              : getValueFromData()
          }
        />
      </Box>
    </Grid>
  );
};
