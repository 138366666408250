import React, { FC } from "react";
import { SelectPathMessagePopup } from "../../Massages/SelectPathMessagePopup";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export interface UserUpgradeMessageProps {
  onClose: () => void;
}

export const UserUpgradeMessage: FC<UserUpgradeMessageProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();

  return (
    <SelectPathMessagePopup
      open={true}
      title={<Box sx={{ display: "flex", flexDirection: "column"}}>
        <Box>It is time to upgrade.</Box>
      <Box sx={{fontWeight: 300}}> {`Your company's growth is our top priority.`}</Box>
      
      </Box>}
      message={
        <Box sx={{ display: "flex", flexDirection: "column", gap:'20px'}}>
          <Box>
            To start a new project, upgrade your plan. <br />
            Upgrade to Pro grants unlimited projects and a dedicated success
            manager to help you boost your productivity and efficiency!
          </Box>
        </Box>
      }
      onLeftBtn={() => onClose()}
      onRightBtn={() => navigate("/pricing")}
      onClose={() => onClose()}
      leftAction="No, go back"
      rightAction={"Upgrade"}
      disableAutoClose={true}
      extraWidth="600px"
    />
  );
};
