import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { notificationApi } from "../../api/notificationApi";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { ShareMessagePopup } from "../../Massages/ShareMessagePopup";
import { SuccessMessagePopup } from "../../Massages/SuccessMessagePopup";
import { DESIGNER_LANG_TOKEN } from "../../utils/Constants";
import { Project, ProjectState } from "../projectsSlice";
import { ReactComponent as CalendarMeetingSVG } from "../../assets/project/calendarMeeting.svg";
import { ReactComponent as CalandarSVG } from "../../assets/brief/calendar.svg";

import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { HelpPopplerOnHover } from "../../basic/HelpPopplerOnHover";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { getAccessTokenSilence } from "../../auth/auth";
import { TextField } from "@mui/material";
import { useDesginerInfo } from "../../hooks/useDesginerName";

export interface SchedulerMeetingProps {
  project: Project;
}

export const SchedulerMeeting: React.FC<SchedulerMeetingProps> = ({
  project,
}) => {
  const [schedulerlink, setSchedulerlink] = React.useState<string>("");
  const [showShareSchedulerPopup, setShowShareSchedulerPopup] =
    React.useState<boolean>(false);
  const [
    showShareSchedulerSuccessMessage,
    setShowShareSchedulerSuccessMessage,
  ] = React.useState<boolean>(false);

  const msalInstance = useMsal();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const projectData: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const { getDesignerName } = useDesginerInfo();


  useAsyncEffect(async () => {
    if (
      projectData.userInfo &&
      projectData.userInfo.schedulingApp &&
      projectData.userInfo.schedulingApp !== ""
    ) {
      setSchedulerlink(projectData.userInfo.schedulingApp);
    }
  }, [project]);

  const onShareScheduler = async (
    shareEmail: string[],
    description: string,
    subject: string
  ): Promise<boolean> => {
    if (project.cmsDataId) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        return await notificationApi.sendScheduleMetting(
          project.cmsDataId,
          {
            email: shareEmail[0],
            message: description,
            subject: subject,
            link: schedulerlink,
          },
          token.accessToken
        );
      }
    }
    return false;
  };

  const onCloseShareScheduler = (success: boolean) => {
    setShowShareSchedulerSuccessMessage(success);
    setShowShareSchedulerPopup(false);
  };

  return (
    <>
      <Box
        sx={{
          fontFamily: "Nunito",
          fontSize: "16px",
        }}
      >
        <HelpPopplerOnHover
          position="auto"
          message={t("cms.scheduler_button.helper")}
          disabled={schedulerlink === ""}
          icon={<CalandarSVG />}
          useClick={true}
          actionNode={
            <HelpToolTipOnHover
              position="bottom"
              message={"Schedule a client meeting"}
              actionNode={
                <CalendarMeetingSVG
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    schedulerlink !== "" ? setShowShareSchedulerPopup(true) : {}
                  }
                />
              }
            />
          }
        >
          <Box
            onClick={() => navigate(`/profile?param=scheduler`)}
            sx={{ fontWeight: 800, cursor: "pointer" }}
          >
            {t("cms.scheduler_button.redirect")}
          </Box>
        </HelpPopplerOnHover>
      </Box>
      {showShareSchedulerPopup && (
        <ShareMessagePopup
          allowAnonymous={false}
          project={project}
          hideLink={true}
          onClose={onCloseShareScheduler}
          onShare={onShareScheduler}
          singleShare={true}
          title={t("cms.share_scheduler.title")}
          header={t("cms.share_scheduler.header")}
          subject={t("cms.share_scheduler.subject")}
          action={t("cms.share_scheduler.action")}
          message={t("cms.share_scheduler.message").replace(
            DESIGNER_LANG_TOKEN,
            getDesignerName()
          )}
          addedContent={<Box
            sx={{ width: "100%", marginTop: "28px", paddingRight: "40px" }}
          >
            <TextField
              sx={{
                width: "100%",
                background: "#f9f9f9",
                "& input.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                },                
              }}
              disabled
              variant="outlined"
              label={"Service link"}
              className= "inputWithValDisabled"
              inputProps={{ className: "dropDownOther" }}
              value={schedulerlink}
            />
          </Box>}
        />
      )}
      {showShareSchedulerSuccessMessage && (
        <SuccessMessagePopup
          open={showShareSchedulerSuccessMessage}
          title={t("cms.success_meeting.title")}
          message={t("cms.success_meeting.message")}
          onClose={() => setShowShareSchedulerSuccessMessage(false)}
          onContinue={() => setShowShareSchedulerSuccessMessage(false)}
          showAction={false}
        />
      )}
    </>
  );
};
