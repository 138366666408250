import { Box} from "@mui/material";
import React, { FC } from "react";
import { ContextMenuItem } from "../BasicContextMenu";
import "./SideContentMenu.scss";
import { ReactComponent as SmallOpenerSVG } from "../../assets/project/smallOpener.svg";

export interface SideContentMenuProps {
  menuItems: ContextMenuItem[];
}

export const SideContentMenu: FC<SideContentMenuProps> = ({ menuItems }) => {
  const handleMenuItemClick = (e: any, func: any) => {
    e.stopPropagation();
    e.preventDefault();
    func(e);
  };

  return (
    <Box
      className="sideMenu"
      sx={{ display: "flex", justifyContent: "flex-end" }}
    >
      <Box
        sx={{
          width: "18px",
          position: "absolute",
          left: "-18px",
          top: "20px",
          background: "#FFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
          padding: "12px 0px",
        }}
        className="sideButton"
      >
        <SmallOpenerSVG />
      </Box>
      <Box
        sx={{
          display: "none",
          width: "0px",
          position: "absolute",
          left: "-30px",
          top: "20px",
          background: "#fff",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
          flexDirection: "column",
          zIndex: 100,
          opacity: 0,
          transition: "left 3s ease-in-out, opacity 0.3s ease-in-out",
          gap: "5px",
        }}
        className={"sideButtons"}
      >
        {menuItems.map((item, index) => (
          <Box
            key={`contexc-menu-item-${index}`}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "5px",
            }}
            onClick={(e) => handleMenuItemClick(e, item.func)}
          >
            {item.icon}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
