import React from "react";
import { BasicDraggableDialog } from "../../basic/BasicDraggableDialog";
import { Box, Fab, TextField } from "@mui/material";
import { InputEmailField } from "../../basic/InputEmailField";
import {
  ColorButton,
  ColorButtonPurpleOutLined,
  CustomCheckBox,
  PurpleButton,
} from "../../projects/ProjectUIHelper";
import { StaticDropDown } from "../../Intro/StaticDropDown";
import { TaskInfo, TaskPriority } from "../../api/tasksApi";
import { DesignerContext } from "../../routes/AppRouter";
import { AccountMember } from "../../api/backofficeApi";
import { ReactComponent as CheckSvg } from "../../assets/checkbox.svg";
import { ReactComponent as SmallTrashSvg } from "../../assets/project/smallTrash.svg";

import { BasicRichEditor } from "../../basic/BasicRichEditor/BasicRichEditor";
import AddIcon from "@mui/icons-material/Add";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useAccountSettingsData } from "../../hooks/useAccountSettingsData";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import InfoIcon from "@mui/icons-material/Info";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { ProjectState } from "../../projects/projectsSlice";
import _ from "lodash";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DragAndDropInput } from "../../basic/DragAndDropInput";
import { getAccessTokenSilence } from "../../auth/auth";
import { useMsal } from "@azure/msal-react";
import { UserSiteImage } from "../../api/cmsApi";
import TreeUtils from "../../utils/TreeUtils";
import ProjectUtils from "../../projects/ProjectUtils";
import { UploadDocumentResponse } from "../../api/uploadApi";
import FileUploadButton from "../../basic/BasicUploadBtn/BasicUploadBtn";

interface FileObject {
  filename: string;
  base64: string;
  file?: File;
  url?: string;
}

interface FileObjectWithFile {
  filename: string;
  base64: string;
  file: File;
}

interface NewTaskFormProps {
  onClose(): void;
  isClient?: boolean;
  onCreateNewTask(task: TaskInfo): void;
  onUpdateTask(task: TaskInfo, needUpdateNotification?: boolean): void;
  task?: TaskInfo;
  coWorkers?: AccountMember[];
  avaliableTags: string[];
  designerEmail?: string;
  projectId: string;
}

export const NewTaskForm: React.FC<NewTaskFormProps> = ({
  onClose,
  isClient,
  onCreateNewTask,
  onUpdateTask,
  task,
  coWorkers,
  avaliableTags,
  designerEmail,
  projectId,
}) => {
  const [taskName, setTaskName] = React.useState<string>("");
  const [assignedType, setAssignedType] = React.useState<string | null>(
    "Myself"
  );
  const [taskPriority, setTaskPriority] = React.useState<string>("Low");
  const [email, setEmail] = React.useState<string>("");
  const [tag, setTag] = React.useState<string | null>(null);
  const [tagOptions, setTagOptions] = React.useState<string[]>(avaliableTags);
  const designerContext = React.useContext(DesignerContext);
  const [showNewTag, setShowNewTag] = React.useState<boolean>(false);
  const [newTagValue, setNewTagValue] = React.useState<string>("");
  const [approvalTask, setApprovalTask] = React.useState<boolean>(false);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const { getAccountDateFormat } = useAccountSettingsData();
  const [dailyReminder, setDailyReminder] = React.useState<boolean>(false);
  const [useDueDate, setUseDueDate] = React.useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = React.useState<string>("");
  const [uploadedListData, setUploadedListData] = React.useState<FileObject[]>(
    []
  );
  const [taskSubject, setTaskSubject] = React.useState<string>("");
  const [showAttachments, setShowAttachments] = React.useState<boolean>(false);
  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);
  const [initialRemiinderStataus, setInitialRemiinderStataus] =
    React.useState<boolean>(false);
  const msalInstance = useMsal();

  const projectsState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const dateFormat = React.useMemo(
    () => getAccountDateFormat(),
    [getAccountDateFormat]
  );

  React.useEffect(() => {
    if (task) {
      setTaskName(task.name);
      setInitialRemiinderStataus(task.hasDailyReminder ?? false);
      setDailyReminder(task.hasDailyReminder ?? false);
      setTaskSubject(task.description ?? "");
      if (
        task.assignedTo === "" ||
        task.assignedTo.toLowerCase() === designerContext.email.toLowerCase()
      ) {
        setEmail(designerContext.email);
      } else if (
        coWorkers &&
        coWorkers.find(
          (coWorker) =>
            coWorker.email.toLowerCase() === task.assignedTo.toLowerCase()
        )
      ) {
        setEmail(task.assignedTo);
        setAssignedType(isClient ? "Designer" : "Co-worker");
      } else {
        setEmail(task.assignedTo);
        setAssignedType(isClient ? "Designer" : "Client");
      }
      setTaskPriority(task.priority);
      setTag(task.tag);
      if (task.dueDate) {
        setDueDate(dayjs(task.dueDate));
        setUseDueDate(true);
      }
      if (task.type === "Approval") {
        setApprovalTask(true);
      }
      if (task.images && task.images.length > 0) {
        setUploadedListData(
          task.images.map((img) => {
            const imageObj: UserSiteImage = JSON.parse(img);
            return {
              filename: imageObj.description,
              base64: "",
              url: imageObj.url,
            };
          })
        );
        setShowAttachments(true);
      }
    } else {
      setEmail(designerContext.email);
    }
    setEmailFromClient();
  }, [task]);

  const setEmailFromClient = async () => {
    if (!isClient && projectsState) {
      setCustomerEmail(
        _.get(
          JSON.parse(projectsState.activeProject?.additionalData ?? "{}"),
          "8000",
          ""
        )
      );
    }
  };

  const onAssignedTypeChange = (val: string | null) => {
    setAssignedType(val);
    if (val === "Myself") {
      setEmail(designerContext.email);
    } else if (val === "Co-worker") {
      setEmail(coWorkers ? coWorkers[0].email : "");
    } else if (val === "Designer") {
      setEmail(designerEmail ?? "");
    } else {
      setEmail(customerEmail);
    }
  };

  const onSetTag = (val: string | null) => {
    if (val) {
      if (val.indexOf("Add ") === 0) {
        const _val = val.replace(`Add `, "").replace(`"`, "").replace(`"`, "");
        setTag(_val);
        setTagOptions([...tagOptions, _val]);
      } else {
        setTag(val);
      }
    } else {
      setTag(null);
    }
  };

  const onCoWorkerChange = (val: string | null) => {
    if (val && coWorkers) {
      setEmail(val);
    }
  };

  const onButtonClick = async () => {
    const imageList: string[] = [];
    if (uploadedListData.length > 0) {
      (await uploadImagesForTask(uploadedListData)).map((r) => {
        r.url !== "" && imageList.push(JSON.stringify(r));
      });
    }
    if (task) {
      onUpdateTask(
        {
          ...task,
          name: taskName,
          description: taskSubject,
          assignedTo: email,
          priority: taskPriority,
          images: imageList,
          addDailyReminder: dailyReminder,
          tag: tag ?? "",
          dueDate: dueDate
            ? `${dueDate.format("YYYY-MM-DD")}T00:00:00.000Z`
            : undefined,
        },
        dailyReminder !== initialRemiinderStataus
      );
    } else {
      onCreateNewTask({
        name: taskName,
        description: taskSubject,
        assignedTo: email,
        createdBy: designerContext.email,
        id: "",
        images: imageList,
        status: "Not Started",
        tag: tag ?? "",
        priority: taskPriority,
        addDailyReminder: dailyReminder,
        type: approvalTask ? "Approval" : "Regular",
        dueDate: dueDate
          ? `${dueDate.format("YYYY-MM-DD")}T00:00:00.000Z`
          : undefined,
      });
    }
  };

  const deleteFile = (idx: number) => {
    const newList = [...uploadedListData];
    newList.splice(idx, 1);
    setUploadedListData(newList);
  };

  const addNewTag = () => {
    if (newTagValue !== "") {
      setTagOptions([...tagOptions, newTagValue]);
      setTag(newTagValue);
    }
    setShowNewTag(false);
    setNewTagValue("");
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read the file as base64."));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const filesToBase64Array = async (files: File[]): Promise<FileObject[]> => {
    const fileObjects: FileObject[] = [];

    for (const file of files) {
      const base64 = await readFileAsBase64(file);
      fileObjects.push({
        filename: file.name,
        file: file,
        base64,
      });
    }

    return fileObjects;
  };

  const handleUploadClick = async (files: File[]) => {
    filesToBase64Array(files)
      .then((fileObjects) => {
        setUploadedListData([...uploadedListData, ...fileObjects]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const uploadImagesForTask = async (uploadDataList: FileObject[]) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      try {
        const _fileToUpload = uploadDataList.filter(
          (uploadData) => uploadData.file !== undefined
        );
        const _alreadyUploaded = uploadDataList
          .filter((uploadData) => uploadData.file === undefined)
          .map((r) => ({
            description: r.filename,
            url: r.url ?? "",
            imageId: "",
          }));
        if (_fileToUpload.length === 0) {
          return uploadDataList.map((r) => {
            return {
              description: r.filename,
              url: r.url ?? "",
              imageId: "",
            };
          });
        }
        setOnProcessing(true);
        let imageList: UserSiteImage[] = [];
        const allPromises: Promise<UploadDocumentResponse>[] =
          _fileToUpload.map((uploadData: FileObject) => {
            return ProjectUtils.uploadSharedFileToAzureStorage(
              uploadData.file ?? new File([], ""),
              token.accessToken,
              projectId
            );
          });

        const allResponse = await Promise.all(allPromises);
        imageList = allResponse.map((res, idx) => {
          return {
            description: _fileToUpload[idx].file?.name ?? "",
            url: res.url,
            imageId: "",
          };
        });
        setOnProcessing(false);
        return [..._alreadyUploaded, ...imageList];
      } catch (e) {
        setOnProcessing(false);
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <BasicDraggableDialog
      title={task ? "Update task" : "New task"}
      onClose={onClose}
      zIndex={1000}
      extraWidth="900px"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3px",
            position: "relative",
          }}
        >
          <Box sx={{ fontSize: "14px" }}>Task name:</Box>
          <Box>
            <TextField
              sx={{
                width: "100%",
                background: "#f9f9f9",
                "& input.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                },
              }}
              className={taskSubject !== "" ? "inputWithVal" : ""}
              variant="outlined"
              required={true}
              value={taskSubject}
              onChange={(e) => {
                setTaskSubject(e.target.value);
              }}
              inputProps={{ className: "dropDownOther" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              position: "relative",
              paddingTop: "25px",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>Task description:</Box>
            <BasicRichEditor
              value={taskName}
              topToolBar={true}
              onUpdate={(value: string) => setTaskName(value)}
              addTextDecoration={true}
            />
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              color: "rgb(108, 61, 171)",
              cursor: "pointer",
            }}
            onClick={() => setShowAttachments(!showAttachments)}
          >
            <AttachFileIcon fontSize="small" /> Add attachments
          </Box>
          {showAttachments && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                background: "#f9f9f9",

                marginTop: "10px",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              {uploadedListData.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    padding: "20px 20px",
                    flexDirection: "column",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{ position: "absolute", bottom: "0px", right: "0px" }}
                  >
                    <FileUploadButton onFileSelect={handleUploadClick} />
                  </Box>
                  {uploadedListData.map((file, idx) => (
                    <Box
                      key={`file-${idx}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteFile(idx)}
                      >
                        <SmallTrashSvg />
                      </Box>
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "/images/files-type/no-file-type.svg";
                        }}
                        style={{ width: "20px" }}
                        src={`/images/files-type/${file.filename
                          .split(".")
                          .pop()
                          ?.replace(" ", "_")}-file.svg`}
                      />
                      {file.filename}
                    </Box>
                  ))}
                </Box>
              ) : (
                <DragAndDropInput
                  onFileSelect={handleUploadClick}
                  onlyUpload={false}
                  allfiles={true}
                  singleFile={false}
                />
              )}
            </Box>
          )}
        </Box>
        {!isClient && (
          <>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              >
                <CustomCheckBox
                  checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
                  checked={approvalTask}
                  onChange={() => setApprovalTask(!approvalTask)}
                />
                Request approval for this task
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <CustomCheckBox
                  checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
                  checked={dailyReminder}
                  onChange={() => setDailyReminder(!dailyReminder)}
                />
                <Box
                  sx={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  Send a daily reminder
                  <HelpToolTipOnHover
                    position="top-start"
                    size={250}
                    message={
                      "Lynxi will send a daily email reminder with the number of unanswered questions until the form is submitted."
                    }
                    actionNode={<InfoIcon fontSize="small" />}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              width: "60%",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>Assigned to:</Box>
            <StaticDropDown
              handleChange={(val: string | null) => onAssignedTypeChange(val)}
              value={assignedType}
              showError={false}
              options={
                isClient
                  ? ["Myself", "Designer"]
                  : coWorkers && coWorkers.length > 0
                  ? ["Myself", "Co-worker", "Client"]
                  : ["Myself", "Client"]
              }
              placeHolder=""
              background="#f9f9f9"
              disableClear={true}
            />
            {assignedType === "Client" && (
              <InputEmailField
                handleChange={(val) => setEmail(val)}
                defaultValue={email}
                placeholder={"Assigned to- Email"}
                isFullWidth={true}
                required={false}
              />
            )}
            {assignedType === "Co-worker" && (
              <StaticDropDown
                handleChange={(val: string | null) => onCoWorkerChange(val)}
                value={email}
                showError={false}
                options={(coWorkers ?? []).map((coWorker) =>
                  coWorker.email.toLowerCase()
                )}
                placeHolder=""
                background="#f9f9f9"
                disableClear={true}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              width: "35%",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>Priority:</Box>
            <StaticDropDown
              handleChange={(val: string | null) =>
                setTaskPriority(val ?? TaskPriority[0])
              }
              value={taskPriority}
              showError={false}
              options={TaskPriority}
              placeHolder=""
              background="#f9f9f9"
              disableClear={true}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginBottom: "5px",
              flexDirection: "column",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>Due date</Box>
            <Box sx={{}}>
              <DatePicker
                className="datePicker"
                value={dueDate}
                format={dateFormat}
                sx={{ background: "#f9f9f9" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setDueDate(null),
                  },
                }}
                onChange={(newValue: any) => setDueDate(newValue)}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3px",
            marginTop: "10px",
          }}
        >
          <Box sx={{ fontSize: "14px" }}>Select label:</Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginTop: "10px",
            }}
          >
            {tagOptions.map((s: string, idx: number) => (
              <div key={`token-${idx}`} style={{ position: "relative" }}>
                {tag !== s ? (
                  <ColorButtonPurpleOutLined
                    onClick={() => onSetTag(s)}
                    key={`seokey-${idx}`}
                    sx={{ padding: "4px 30px" }}
                  >
                    {s}
                  </ColorButtonPurpleOutLined>
                ) : (
                  <PurpleButton
                    onClick={() => onSetTag("")}
                    key={`seokey-${idx}`}
                    sx={{ padding: "4px 30px" }}
                  >
                    {s}
                  </PurpleButton>
                )}
              </div>
            ))}
            {showNewTag ? (
              <TextField
                onBlur={() => addNewTag()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewTag();
                  }
                }}
                autoFocus
                className={"dropDownOther"}
                sx={{ width: "100%", background: "#f9f9f9" }}
                variant="outlined"
                autoComplete={"off"}
                placeholder={"Add a new label"}
                value={newTagValue}
                onChange={(e) => setNewTagValue(e.target.value)}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                <Fab
                  size="small"
                  sx={{
                    boxShadow: "none",
                    background: "#000",
                    color: "#fff",
                    width: "30px",
                    height: "30px",
                    minHeight: "20px",
                    "&:hover": { color: "#000" },
                  }}
                  onClick={() => setShowNewTag(true)}
                >
                  <AddIcon fontSize="small" sx={{ width: "18px" }} />
                </Fab>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
            Cancel
          </Box>
          <ColorButton
            onClick={() => onButtonClick()}
            disabled={(taskName === "" && taskSubject === "") || email === ""}
          >
            {task ? "Update" : "Create"}
          </ColorButton>
        </Box>
      </Box>
    </BasicDraggableDialog>
  );
};
