import { Box, Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import { DayOpenHoursInfo } from "./OpenHoursComp";
import { IOSSwitch } from "../../../projects/ProjectUIHelper";
import { CMSState } from "../../cmsSlice";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

export interface OpenHoursLineProps {
  dayData: DayOpenHoursInfo
  readonly?: boolean;
  onUpdate(    
    dayData: DayOpenHoursInfo
  ): void;
}

export const OpenHoursLine: React.FC<OpenHoursLineProps> = (
  { dayData, onUpdate, readonly }
) => {

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );


  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    onUpdate({...dayData, closeOnDay: !checked});
  };

  const onChangeHour = (value: string, index: number) => {
    if (index === 1){
      onUpdate({...dayData, openHour: value})
    } else {
      onUpdate({...dayData, closeHour: value})
    }
  };

  const checkIfOpen = () => {
    return !dayData.closeOnDay
  };

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={cmsState.mobileView?6:2} sx={{ alignItems: "center", display: "flex" }}>
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <Typography>{dayData.name}</Typography>
          </Box>  
        </Grid>
        <Grid item xs={cmsState.mobileView?6:2} sx={{ alignItems: "center", display: "flex"}}>
          <Box sx={{ alignItems: "center", display: "flex"}}>
            <IOSSwitch
              checked={checkIfOpen()}
              onChange={onSwitchChange}
              name="checkedB"
              disabled={readonly? true: false}
            />
          </Box>
        </Grid>
        {cmsState.mobileView && <Grid item xs={12} sx={{ alignItems: "center", display: "flex", height:'10px'}}/>}
        {checkIfOpen() ? (
          <>
            <Grid item xs={cmsState.mobileView?6:4}>
              <TextField
                sx={{ background: "#f9f9f9", width: "80%" }}
                type="time"
                placeholder={''}
                variant="outlined"
                onChange={(e) => onChangeHour(e.target.value, 1)}
                value={dayData.openHour}
                disabled={readonly? true: false}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Grid>
            <Grid item xs={cmsState.mobileView?6:4}>
              <TextField
                type="time"
                sx={{ background: "#f9f9f9", width: "80%" }}
                placeholder={''}
                variant="outlined"
                onChange={(e) => onChangeHour(e.target.value, 2)}
                value={dayData.closeHour}
                disabled={readonly? true: false}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={cmsState.mobileView?12:8} sx={{ alignItems: "center", display: "flex" }}>
            <Box>
              <Typography>Closed On {dayData.name}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
