import { Badge, Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BulletsIconSVG } from "../../../assets/project/bullets.svg";
import { ReactComponent as EditDisabledSvg } from "../../../assets/project/edit_disabled.svg";
import { ReactComponent as InvitationIconSVG } from "../../../assets/project/invitation.svg";
import { ReactComponent as CommentSvg } from "../../../assets/project/purpleComment.svg";
import { ReactComponent as ResendDisableSvg } from "../../../assets/project/resend_disabled.svg";
import { ReactComponent as ResendEnableSvg } from "../../../assets/project/resend_enabled.svg";
import { ReactComponent as SmallEditSvg } from "../../../assets/project/smallEdit.svg";
import { ReactComponent as SmallTrashSvg } from "../../../assets/project/smallTrash.svg";
import { ReactComponent as InviteSvg } from "../../../assets/storage/invitationDashboard.svg";
import { ReactComponent as InviteDisableSvg } from "../../../assets/storage/invitationDashboardDes.svg";
import { ReactComponent as NotificationOnSvg } from "../../../assets/brief/notificationOn.svg";
import { ReactComponent as NotificationOffSvg } from "../../../assets/brief/notificationOff.svg";
import { ReactComponent as AddContentSvg } from "../../../assets/brief/addContent.svg";
import { ReactComponent as PreviewFormSvg } from "../../../assets/brief/previewForm.svg";
import { ReactComponent as LockSVG } from "../../../assets/brief/purpulelock.svg";
import { ReactComponent as UnLockSVG } from "../../../assets/brief/purpuleunlock.svg";

import { checkDateOverDue, getPrettyDate } from "../../../utils/DateUtils";
import {
  BriefData,
  InviteInfo,
  Project,
  refreshActiveProject,
} from "../../projectsSlice";

import { useMsal } from "@azure/msal-react";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";
import { QuestionnaireData, projectsApi } from "../../../api/projectsApi";
import { ReactComponent as EditSvg } from "../../../assets/project/editEmail.svg";
import { HelpPopplerOnHover } from "../../../basic/HelpPopplerOnHover";
import { HelpToolTipOnHover } from "../../../basic/HelpToolTipOnHover";
import { WalkThrowPopup } from "../../../PopUpMessage/WalkThrowPopup";
import {
  BriefStatusStages,
  ContentHubFilterType,
} from "../../../utils/Constants";
import { ColorButton, TableCell, TextWithTooltip } from "../../ProjectUIHelper";
import { EditFormCollaboratorsPopup } from "./EditFormCollaboratorsPopup";
import { getAccessTokenSilence } from "../../../auth/auth";
import { useAppDispatch } from "../../../app/hooks";
import { setCMSFilterMode } from "../../../cms/cmsSlice";
import { notificationApi } from "../../../api/notificationApi";
import { useAccountSettingsData } from "../../../hooks/useAccountSettingsData";

export interface BriefLineViewProps {
  project: Project;
  briefData: BriefData;
  selected: boolean;
  onDeleteBrief(brief: BriefData, anchor: HTMLDivElement): void;
  onShowSelectedBrief(brief: BriefData): void;
  showTestPopup: boolean;
  onReopenBriefShare(): void;
  onOpenNewFormForEdit(brief: QuestionnaireData): void;
}

export const BriefLineView: FC<BriefLineViewProps> = ({
  project,
  briefData,
  selected,
  onDeleteBrief,
  onShowSelectedBrief,
  showTestPopup,
  onReopenBriefShare,
  onOpenNewFormForEdit,
}) => {
  const [showTestMessage, setShowTestMessage] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const instance = useMsal();
  const [heplerAnchorEl, setHeplerAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const [showEditCollaboratorDialog, setShowEditCollaboratorDialog] =
    React.useState<boolean>(false);
  const { getAccountDateFormat } = useAccountSettingsData();

  const { t } = useTranslation();

  const navigateToCms = () => {
    dispatch(setCMSFilterMode(ContentHubFilterType.None));
    navigate(`/projects/${project.id}/cms/${project.cmsDataId}/content`);
  };

  const navigateToViewForm = () => {
    navigate(`/projects/${project.id}/questionnaire/client?q=${briefData.id}`);
  };

  const updateNotification = async (isOn: boolean, email: string) => {
    const token = await getAccessTokenSilence(instance);
    if (token) {
      await notificationApi.updateFormReminder(
        briefData.id,
        isOn,
        email,
        token.accessToken
      );
      dispatch(refreshActiveProject(token.accessToken));
    }
  };

  const getInvitationList = () => {
    //
    if (briefData.invitations.length > 0) {
      return briefData.invitations;
    } else if (
      briefData.invitations.length === 0 &&
      briefData.collaborators.length > 0
    ) {
      return briefData.collaborators.map((collaborator) => ({
        created: "NONONO",
        email: collaborator.email,
        isAnonymous: false,
        validForInDays: 0,
      }));
    } else return [];
  };

  const getStatus = () => {
    switch (briefData.status) {
      case BriefStatusStages.InProgress:
      case BriefStatusStages.InProgress2:
        return "Form selected (not shared)";
      case BriefStatusStages.BriefSend:
        return "Shared (not opened yet)";
      case BriefStatusStages.Closed:
        return "Content ready";
      case BriefStatusStages.Opened:
      case BriefStatusStages.Working:
        return (
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {briefData.numberOfQuestions && (
              <Box
                sx={{
                  marginTop: "0px",
                  display: "flex",
                  fontSize: "12px",
                  flexGrow: 1,
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    textAlign: "center",
                    width: "80%",
                    padding: "5px 0px",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>Form opened: </span>
                  {briefData.answeredQuestions
                    ? Math.min(
                        briefData.answeredQuestions,
                        briefData.numberOfQuestions
                      )
                    : 0}
                  /{briefData.numberOfQuestions} complete
                </Typography>
              </Box>
            )}
          </Box>
        );
      case BriefStatusStages.Finished:
        return "Client submitted form";
      case BriefStatusStages.ContentReview:
        return "Content reviewed";
      default:
        return "";
    }
  };

  const checkForClientComments = () => {
    return (
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          height: "30px",
        }}
        onClick={(e) =>
          briefData.unreadBriefComments > 0
            ? navigate(
                `/projects/${project.id}/questionnaire/client?q=${briefData.id}&comment=true`
              )
            : setHeplerAnchorEl(e.currentTarget)
        }
      >
        {briefData.unreadBriefComments > 0 ? (
          <Badge badgeContent={briefData.unreadBriefComments} color="error">
            <CommentSvg />
          </Badge>
        ) : (
          <CommentSvg />
        )}
      </Box>
    );
  };

  const getBriefStatus = () => {
    switch (briefData.status) {
      case "In Progress":
      case "InProgress":
        return "Share form";
      case "Approved":
        return "Use content";
      case BriefStatusStages.Finished:
        return "Review form";
      case BriefStatusStages.Closed:
        return "Get client approval";
      case BriefStatusStages.ContentReview:
        return "Add content";
      case "Waiting":
        return "Send a reminder";
      case "ContentReviewOpened":
        return "View Content";
      case "BriefSent":
        return "Send reminder";
      case "Opened":
      case "Working":
        return "Review form progress";
    }
  };

  const editBriefIsEnabled = () => {
    return briefData.status !== BriefStatusStages.Closed;
  };

  const onAddCollaborator = async (addDueDate?: boolean) => {
    setShowEditCollaboratorDialog(false);
    onShowSelectedBrief({
      ...briefData,
      emailChange: addDueDate ? "DueDate" : "AddEmail",
    });
  };

  const onTestBrief = () => {
    onShowSelectedBrief({ ...briefData, testMode: true });
  };

  const renderStage = (
    stageNumber: number,
    isOnStage: boolean,
    toolTip: string
  ) => {
    return isOnStage ? (
      <Box className={"fill_stage"}>{stageNumber}</Box>
    ) : (
      <Tooltip
        title={toolTip}
        componentsProps={{
          tooltip: {
            sx: {
              background: "#292B28",
              "& .MuiTooltip-arrow": {
                color: "#292B28",
              },
            },
          },
        }}
        arrow={true}
        placement={"top"}
      >
        <Box className={"empty_stage"}>{stageNumber}</Box>
      </Tooltip>
    );
  };

  const calculateUntilDate = (inviteInfo: InviteInfo) => {
    //calculate until date using created date and valid days using moment
    if (inviteInfo.created === "NONONO") return null;
    if (Object.keys(BriefStatusStages).indexOf(briefData.status) >= 6) {
      return (
        <HelpToolTipOnHover
          position="top"
          message={<Box sx={{ textAlign: "center" }}>Daily reminder off</Box>}
          actionNode={
            <NotificationOffSvg width="15px" style={{ cursor: "pointer" }} />
          }
        />
      );
    }
    if (inviteInfo.validForInDays !== 0) {
      return (
        <HelpToolTipOnHover
          position="top"
          message={
            <Box sx={{ textAlign: "center" }}>
              Daily reminder on
              <br />
              (Click to turn off)
            </Box>
          }
          actionNode={
            <NotificationOnSvg
              width="15px"
              style={{ cursor: "pointer" }}
              onClick={() => updateNotification(false, inviteInfo.email)}
            />
          }
        />
      );
    } else {
      return (
        <HelpToolTipOnHover
          position="top"
          message={
            <Box sx={{ textAlign: "center" }}>
              Daily reminder off
              <br />
              (Click to turn on)
            </Box>
          }
          actionNode={
            <NotificationOffSvg
              width="15px"
              style={{ cursor: "pointer" }}
              onClick={() => updateNotification(true, inviteInfo.email)}
            />
          }
        />
      );
    }
  };

  const checkEditBriefType = async (briefId: string) => {
    const token = await getAccessTokenSilence(instance);
    if (token) {
      const briefData = await projectsApi.getUserQuestionnairById(
        briefId,
        token.accessToken
      );
      if (briefData && briefData.template) {
        const isCmsForm = briefData.template.includes("singlesKeys");
        if (isCmsForm) {
          onOpenNewFormForEdit(briefData);
        } else {
          navigate(`/projects/${project.id}/brief/editor/edit/${briefId}`);
        }
      }
    }
  };

  return (
    <Box
      className="briefline"
      sx={{
        flexGrow: 1,
        display: "flex",
        background: selected ? "#f5effb" : "#f9f9f9",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
        height: "70px",
        margin: "10px 0px",
      }}
    >
      <Grid container spacing={0} alignItems="center" height="100%">
        <Grid
          item
          xs={2}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%" }}
        >
          <TableCell
            sx={{
              fontWeight: "500",
              height: "100%",
              justifyContent: "flex-start",
              marginRight: "5px",
              marginLeft: "20px",
            }}
          >
            {briefData.name}
          </TableCell>
        </Grid>
        <Grid item xs={1}>
          <TableCell sx={{ fontSize: "12px" }}>
            {getPrettyDate(briefData.updated, false, getAccountDateFormat())}
          </TableCell>
        </Grid>
        <Grid item xs={1}>
          <TableCell sx={{ fontSize: "13px" }}>
            {briefData.dueDate ? (
              <Box
                sx={{
                  color: checkDateOverDue(briefData.dueDate) ? "red" : "#000",
                }}
              >
                {getPrettyDate(
                  briefData.dueDate,
                  false,
                  getAccountDateFormat()
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  fontSize: "13px",
                  color: "rgb(108, 61, 171)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Object.keys(BriefStatusStages).indexOf(briefData.status) < 6
                    ? onAddCollaborator(true)
                    : {};
                }}
              >
                Add date
              </Box>
            )}
          </TableCell>
        </Grid>
        <Grid item xs={0.2}>
          {getInvitationList().length > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => setShowEditCollaboratorDialog(true)}
            >
              <EditSvg />
            </Box>
          )}
        </Grid>
        <Grid item xs={2.3}>
          {getInvitationList().length > 0 && (
            <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "95%" }}
              >
                {uniq(getInvitationList())
                  .slice(0, 2)
                  .map((inviteInfo, idx) => (
                    <Box
                      key={`email-${idx}`}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          width: "100%",
                        }}
                        className="collaborator_email"
                      >
                        <TextWithTooltip
                          text={inviteInfo.email.toLowerCase()}
                          maxWidth={"85%"}
                        />
                        <Box
                          sx={{
                            width: "100px",
                            verticalAlign: "middle",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            gap: "2px",
                          }}
                        >
                          &nbsp;
                          {inviteInfo.isAnonymous ? (
                            <HelpToolTipOnHover
                              position="top"
                              message={
                                <Box sx={{ textAlign: "center" }}>
                                  Public link
                                </Box>
                              }
                              actionNode={<UnLockSVG width={"12px"} />}
                            />
                          ) : (
                            <HelpToolTipOnHover
                              position="top"
                              message={
                                <Box sx={{ textAlign: "center" }}>
                                  Private link
                                </Box>
                              }
                              actionNode={<LockSVG width={"12px"} />}
                            />
                          )}
                          &nbsp;{calculateUntilDate(inviteInfo)}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                {getInvitationList().length > 2 && (
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                      color: "#6c3dab",
                    }}
                    onClick={() => setShowEditCollaboratorDialog(true)}
                  >
                    more...
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={1}>
          <TableCell sx={{ textAlign: "center" }}>
            <HelpToolTipOnHover
              position="bottom"
              message={"Form Comments"}
              actionNode={checkForClientComments()}
            />
          </TableCell>
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5px",
            }}
          >
            {renderStage(
              1,
              briefData.status === BriefStatusStages.InProgress ||
                briefData.status === BriefStatusStages.InProgress2,
              "Form selected"
            )}
            <Box sx={{ width: "7px", margin: "0 3px" }}>
              <Divider />
            </Box>
            {renderStage(
              2,
              briefData.status === BriefStatusStages.Working ||
                briefData.status === BriefStatusStages.BriefSend ||
                briefData.status === BriefStatusStages.Opened,
              "Form shared"
            )}
            <Box sx={{ width: "7px", margin: "0 3px" }}>
              <Divider />
            </Box>
            {renderStage(
              3,
              briefData.status === BriefStatusStages.Finished,
              "Client submitted form"
            )}
            <Box sx={{ width: "7px", margin: "0 3px" }}>
              <Divider />
            </Box>
            {renderStage(
              4,
              briefData.status === BriefStatusStages.ContentReview,
              "Form reviewed"
            )}
            <Box sx={{ width: "7px", margin: "0 3px" }}>
              <Divider />
            </Box>
            {renderStage(
              5,
              briefData.status === BriefStatusStages.Closed,
              "Content added"
            )}
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "100%",
              fontSize: "12px",
              marginTop: "5px",
            }}
          >
            {getStatus()}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <TableCell sx={{ cursor: "pointer", color: "secondary" }}>
            {briefData.status !== BriefStatusStages.Closed ? (
              briefData.status === BriefStatusStages.InProgress ||
              briefData.status === BriefStatusStages.InProgress2 ? (
                showTestPopup && showTestMessage ? (
                  <HelpPopplerOnHover
                    position="bottom"
                    startOpened={true}
                    useClick={false}
                    overrideWidth={"350px"}
                    autoClose={true}
                    cancelText={"Cancel"}
                    message={
                      'Share the form with your client or preview how it looks by clicking "Test".'
                    }
                    disabled={true}
                    icon={<></>}
                    onCancel={() => {
                      sessionStorage.setItem("showSendTest", "true");
                      setShowTestMessage(false);
                    }}
                    actionNode={
                      <ColorButton
                        onClick={() => {
                          sessionStorage.setItem("showSendTest", "true");
                          briefData.status === BriefStatusStages.Finished
                            ? navigate(
                                `/projects/${project.id}/questionnaire/client?q=${briefData.id}`
                              )
                            : onShowSelectedBrief(briefData);
                        }}
                        sx={{ padding: "6px 6px", width: "180px" }}
                      >
                        {getBriefStatus()}
                      </ColorButton>
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "30px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 400,
                          cursor: "pointer",
                          textAlign: "center",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          sessionStorage.setItem("showSendTest", "true");
                          setShowTestMessage(false);
                          onTestBrief();
                        }}
                      >
                        Test
                      </Box>
                      <Box
                        sx={{
                          fontWeight: 600,
                          cursor: "pointer",
                          textAlign: "right",
                          width: "100%",
                          marginBottom: "10px",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          sessionStorage.setItem("showSendTest", "true");
                          briefData.status === BriefStatusStages.Finished
                            ? navigate(
                                `/projects/${project.id}/questionnaire/client?q=${briefData.id}`
                              )
                            : onShowSelectedBrief(briefData);
                        }}
                      >
                        {"Share"}
                      </Box>
                    </Box>
                  </HelpPopplerOnHover>
                ) : (
                  <HelpToolTipOnHover
                    position="bottom-end"
                    message={
                      'Share the form with your client or preview how it looks by clicking "Test".'
                    }
                    actionNode={
                      <ColorButton
                        onClick={() => {
                          briefData.status === BriefStatusStages.Finished
                            ? navigate(
                                `/projects/${project.id}/questionnaire/client?q=${briefData.id}`
                              )
                            : onShowSelectedBrief(briefData);
                        }}
                        sx={{ padding: "6px 6px", width: "180px" }}
                      >
                        {getBriefStatus()}
                      </ColorButton>
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "30px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 400,
                          cursor: "pointer",
                          textAlign: "center",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          sessionStorage.setItem("showSendTest", "true");
                          setShowTestMessage(false);
                          onTestBrief();
                        }}
                      >
                        Test
                      </Box>
                      <Box
                        sx={{
                          fontWeight: 600,
                          cursor: "pointer",
                          textAlign: "right",
                          width: "100%",
                          marginBottom: "10px",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          sessionStorage.setItem("showSendTest", "true");
                          briefData.status === BriefStatusStages.Finished
                            ? navigate(
                                `/projects/${project.id}/questionnaire/client?q=${briefData.id}`
                              )
                            : onShowSelectedBrief(briefData);
                        }}
                      >
                        {"Share"}
                      </Box>
                    </Box>
                  </HelpToolTipOnHover>
                )
              ) : (
                <ColorButton
                  onClick={() => {
                    briefData.status === BriefStatusStages.Finished
                      ? navigate(
                          `/projects/${project.id}/questionnaire/client?q=${briefData.id}`
                        )
                      : onShowSelectedBrief(briefData);
                  }}
                  sx={{ padding: "6px 6px", width: "180px" }}
                >
                  {getBriefStatus()}
                </ColorButton>
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                <Box>The content is now avaliable</Box>
                <Box>
                  in the{" "}
                  <span
                    style={{ color: "#6c3dab" }}
                    onClick={() => navigateToCms()}
                  >
                    Content Hub
                  </span>
                </Box>
              </Box>
            )}
          </TableCell>
        </Grid>
        <Grid item xs={0.5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <Tooltip
              placement={"bottom"}
              componentsProps={{
                tooltip: {
                  sx: {
                    color: "#000",
                    padding: "20px 26px 20px 26px",
                    boxShadow: "0 9px 15px 0 rgba(0, 0, 0, 0.07)",
                    bgcolor: "#f5f8f7",
                    "& .MuiTooltip-arrow": {
                      color: "#000",
                    },
                  },
                },
              }}
              title={
                <Box>
                  <Box
                    sx={{
                      marginBottom: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => navigateToCms()}
                  >
                    <Box sx={{ marginRight: "20px" }}>
                      <AddContentSvg width="18px" />
                    </Box>
                    Add content
                  </Box>
                  <Box
                    sx={{
                      marginBottom: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => navigateToViewForm()}
                  >
                    <Box sx={{ marginRight: "20px" }}>
                      <PreviewFormSvg width="20px" />
                    </Box>
                    View form
                  </Box>
                  {briefData.status === BriefStatusStages.Finished ? (
                    <HelpPopplerOnHover
                      position="top"
                      useClick={true}
                      overrideWidth={"350px"}
                      autoClose={true}
                      title={"Edit form"}
                      message={
                        "Please note if you make any edits to the form after your client has submitted it, your client will receive an email asking them to provide additional information."
                      }
                      disabled={true}
                      closeOnAction={true}
                      icon={<InvitationIconSVG />}
                      actionNode={
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ marginRight: "20px" }}>
                            <SmallEditSvg />
                          </Box>
                          Edit form
                        </Box>
                      }
                    >
                      <Box
                        sx={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={() =>
                          editBriefIsEnabled()
                            ? checkEditBriefType(briefData.id)
                            : {}
                        }
                      >
                        Confirm
                      </Box>
                    </HelpPopplerOnHover>
                  ) : (
                    <Box
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() =>
                        editBriefIsEnabled()
                          ? checkEditBriefType(briefData.id)
                          : {}
                      }
                    >
                      <Box sx={{ marginRight: "20px" }}>
                        {editBriefIsEnabled() ? (
                          <SmallEditSvg />
                        ) : (
                          <EditDisabledSvg />
                        )}
                      </Box>
                      Edit form
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginTop: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      briefData.status === BriefStatusStages.Finished ||
                      briefData.status === BriefStatusStages.ContentReview
                        ? onReopenBriefShare()
                        : {}
                    }
                  >
                    <Box sx={{ marginRight: "20px" }}>
                      {briefData.status === BriefStatusStages.Finished ||
                      briefData.status === BriefStatusStages.ContentReview ? (
                        <ResendEnableSvg />
                      ) : (
                        <ResendDisableSvg />
                      )}
                    </Box>
                    Resend form
                  </Box>

                  <Box
                    sx={{
                      marginTop: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      color:
                        Object.keys(BriefStatusStages).indexOf(
                          briefData.status
                        ) >= 6
                          ? "#B7B7B7"
                          : "#000",
                    }}
                    onClick={() => {
                      Object.keys(BriefStatusStages).indexOf(briefData.status) <
                      6
                        ? onAddCollaborator()
                        : {};
                    }}
                  >
                    <Box sx={{ marginRight: "20px" }}>
                      {Object.keys(BriefStatusStages).indexOf(
                        briefData.status
                      ) < 6 ? (
                        <InviteSvg width={"20px"} />
                      ) : (
                        <InviteDisableSvg width={"20px"} />
                      )}
                    </Box>
                    Share form
                  </Box>
                  <Divider sx={{ margin: "10px 0px 0px" }} />
                  <Box
                    sx={{
                      marginTop: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={(e) => onDeleteBrief(briefData, e.currentTarget)}
                  >
                    <Box sx={{ marginRight: "20px" }}>
                      <SmallTrashSvg />
                    </Box>
                    Delete form
                  </Box>
                </Box>
              }
              arrow={false}
            >
              <Box
                sx={{ display: "flex", padding: "0 20px", cursor: "pointer" }}
              >
                <BulletsIconSVG />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <WalkThrowPopup
        anchorEl={heplerAnchorEl}
        message={t("projects.briefs.brief_line.comment_helper")}
        direction="left"
        autoClose={true}
        onClose={() => setHeplerAnchorEl(null)}
      />
      {showEditCollaboratorDialog && (
        <EditFormCollaboratorsPopup
          onClose={() => setShowEditCollaboratorDialog(false)}
          form={briefData}
          onAddCollaborator={onAddCollaborator}
        />
      )}
    </Box>
  );
};
