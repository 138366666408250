import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

interface BasicStaticDropDownProps {
  options: string[];
  value: string;
  onChange(value: number): void;
  keyName: string;
  overRideWidth?: number;
}

const BasicStaticDropDown: React.FC<BasicStaticDropDownProps> = ({
  options,
  value,
  onChange,
  keyName,
  overRideWidth
}) => {
  const onBeforeChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    onChange(parseInt(event.target.value, 10));
  };

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: overRideWidth??140 }}>
      <Select
        sx={{
          textDecoration: "none",
          fontFamily: "Nunito",
          "&.MuiInput-root::before": {
            borderBottomColor: "#d8d8d8",
          },
          "&.MuiInput-root:hover::before": {
            borderBottomColor: "#7C48C2",
          },
          "&.MuiInput-root.Mui-focused::after": {
            borderBottomColor: "#7C48C2",
          },
        }}
        labelId="demo-simple-select-standard-label"
        value={value}
        onChange={onBeforeChange}
        inputProps={{
          sx: {
            disableUnderline: true,
            fontFamily: "Nunito",
            "&:focus": {
              background: "none",
            },
          },
        }}
        MenuProps={{
          sx: {
            "& .MuiMenuItem-root.Mui-selected": {
              background: "transparent",
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`menu-${keyName}-${index}`}
            value={index}
            sx={{ fontFamily: "Nunito" }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BasicStaticDropDown;
