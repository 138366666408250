import { Grid } from "@mui/material";
import * as React from "react";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../../cmsSlice";
import { OpenHoursLine } from "./OpenHoursLine";
import _ from "lodash";
import { DAYS_OF_THE_WEEK } from "../../../utils/Constants";

export interface OpenHoursCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  readonly?: boolean;
  onUpdateComp(updateComp: UpdateComp): void;
}

export interface DayOpenHoursInfo {
  name: string
  openHour: string
  closeHour: string
  closeOnDay: boolean
}

export const OpenHoursComp: React.FC<OpenHoursCompProps> = ({
  comp,
  location,
  readonly,
  onUpdateComp
}) => {

  const onUpdate = (
      dayData: DayOpenHoursInfo
  ) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.value = [...deepClone.value.filter( (day : DayOpenHoursInfo) => day.name !== dayData.name), dayData]
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const getDateInfo = (dayName: string): DayOpenHoursInfo => {
    const dayInValue = comp.value.find(( day: DayOpenHoursInfo) => day.name === dayName)
    if (dayInValue) {
      return dayInValue
    } else {
      return {name: dayName, openHour:'',closeHour:'',closeOnDay: false}
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container rowGap={"15px"}>
        {DAYS_OF_THE_WEEK.map((dayName, idx) => (
          <OpenHoursLine
            key={`openHourLine-${idx}`}
            dayData={getDateInfo(dayName)}
            onUpdate={onUpdate}
            readonly={readonly}
          />
        ))}
      </Grid>
    </Grid>
  );
};
