import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BriefData, Project } from "../../projectsSlice";
import * as React from "react";
import { ReactComponent as FinishedBriefSvg } from "../../../assets/project/drawerFinishedBrief.svg";
import { ColorButton } from "../../ProjectUIHelper";

export interface BriefAddContentStageProps {
  project: Project;
  brief: BriefData;
}

export const BriefAddContentStage: FC<BriefAddContentStageProps> = ({
  project,
  brief,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
        <Typography
          onClick={() =>
            navigate(
              `/projects/${project.id}/questionnaire/client?q=${brief.id}`
            )
          }
          color="secondary"
          sx={{
            cursor: "pointer",
            fontWeight: 500,
            display: "inline",
          }}
        >
          Continue reviewing
        </Typography>{" "}
        {"your client's submission or "}
        <Typography
          onClick={() =>
            navigate(
              `/projects/${project.id}/cms/${project.cmsDataId}/content?convert=${brief.id}`
            )
          }
          color="secondary"
          sx={{
            cursor: "pointer",
            fontWeight: 500,
            display: "inline",
          }}
        >
          add
        </Typography>
        {" your client's answers to the Content Hub"}.
      </Box>

      <Divider sx={{ marginTop: "40px" }} />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          margin: "15px 0 17px",
          color: "#6c3dab",
          cursor: "pointer",
          justifyContent: "space-between",
          fontSize: "14px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            onClick={() =>
              navigate(
                `/projects/${project.id}/questionnaire/client?q=${brief.id}`
              )
            }
          >
            Review form
          </Box>
        </Box>
        <ColorButton
          sx={{ padding: "4px 15px" }}
          onClick={() =>
            navigate(
              `/projects/${project.id}/cms/${project.cmsDataId}/content?convert=${brief.id}`
            )
          }
        >
          Add to Hub
        </ColorButton>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        <FinishedBriefSvg />
      </Box>
    </>
  );
};
