import { Box, PopperPlacementType } from "@mui/material";
import React, { FC } from "react";
import { PopUpBase } from "./PopUpBase";
import { ReactComponent as SaveClientBriefIcon } from "../assets/brief/saveClientBriefIcon.svg";

interface WalkThrowPopupProps {
  onClose(): void;
  anchorEl?: any;
  direction: PopperPlacementType;
  message: string;
  subMessage?: string;
  autoClose?: boolean
  icon?: React.ReactNode
  onActionClick?(): void
  action?: string
  disableAction?: boolean
}

export const WalkThrowPopup: FC<WalkThrowPopupProps> = ({
  onClose,
  anchorEl,
  direction,
  message,
  subMessage,
  autoClose,
  icon,
  action,
  onActionClick,
  disableAction
}) => {
  return (
    <PopUpBase
      hideActions = {action === undefined}
      onClose={() => onClose()}
      direction={direction}
      disableAction={action === undefined || disableAction}
      anchorEl={anchorEl}
      svg={icon ?? <SaveClientBriefIcon />}
      actionString={action ?? ""}
      cancelString={"Close"}
      autoClose={autoClose}
      onAction={onActionClick}
    >
      <Box sx={{ fontWeight: 600 }}>{message}</Box>
      {subMessage && (
          <Box sx={{ marginTop: '10px' }}>{subMessage}</Box>
      )}

      {!action && (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            alignSelf: "flex-end",
            marginTop: "15px",
          }}
        />

        <Box
          sx={{
            cursor: "pointer",
            alignSelf: "flex-end",
            marginTop: "5px",
          }}
          onClick={() => onClose()}
        >
          Close
        </Box>
      </Box>
      )}
    </PopUpBase>
  );
};
