import { get } from "lodash";
import TreeUtils from "../../utils/TreeUtils";
import "../cms.scss";
import {
  CMSComponent,
  CmsValuesByTypes,
  ImageValues,
  ListValues,
  Page,
  PageSeoInfo,
  Section,
  Single,
} from "../cmsSlice";

import Papa from "papaparse";
import { SiteInfo, cmsApi } from "../../api/cmsApi";
import { LynxiAccountInfo } from "../../api/backofficeApi";
import _ from "lodash";

export interface CSVConverterLine {
  sectionName: string;
  name: string;
  value: any;
}

const checkIfSingleHasValue = (
  single: Single,
  cmsDic: { [id: string]: any }
) => {
  const singleDic: { [id: string]: any } = {};
  single.components.forEach((comp) => {
    const value = _.get(cmsDic, comp.mapKey, comp.value);
    if (value !== comp.value) {
      singleDic[comp.mapKey] = value;
    }
  });
  return _.keys(singleDic).length > 0;
};

const hasTextSingles = (s: Section, cmsDic: { [id: string]: any }) => {
  return (
    s.singles.filter((single) => checkIfSingleHasValue(single, cmsDic)).length >
    0
  );
};

const getComponentData = (comp: CMSComponent) => {
  switch (comp.type) {
    case "title":
    case "subtitle":
    case "simpleText":
    case "yesno":
      return comp.value;
    case "paragraph":
      return TreeUtils.htmlToText(comp.value);
    case "dropdown": {
      const value = get(comp.value, "value", "");
      if (value !== "") {
        if (value.toLowerCase() !== "other") {
          return value;
        } else {
          return get(comp.value, "other", "");
        }
      }
      return "";
    }
    case "media":
      return comp.value;
    case "gallery":
      return comp.value.length > 0 ? "yes" : "";
    case "multiCheckBox":
    case "multidropdown":
      if (comp.value && comp.value.length > 0) {
        return comp.value;
      } else {
        return "";
      }
    // case "openHours":
    //   if (comp.value && comp.value.length > 0) {
    //     oldDic[comp.mapKey] = comp.value;
    //   }
    //   break;
    // }
    // case "videos": {
    //   if (comp.value && comp.value.length > 0) {
    //     if (comp.value[0]!== '')
    //       oldDic[comp.mapKey] = comp.value;
    //   }
    //   break;
    // }
    case "serviceList": {
      if (comp.value && comp.value.length > 0) {
        const firstItem: ListValues = comp.value[0];
        if (
          (firstItem.image && firstItem.image !== "") ||
          (firstItem.paragraph &&
            TreeUtils.htmlToText(firstItem.paragraph) !== "") ||
          (firstItem.subTitle && firstItem.subTitle !== "") ||
          (firstItem.title && firstItem.title !== "")
        ) {
          return comp.value;
        } else {
          return "";
        }
      }
      break;
    }
    case "seoPages": {
      if (comp.value && comp.value.length > 0) {
        const firstItem: PageSeoInfo = comp.value[0];
        if (
          (firstItem.page && firstItem.page !== "") ||
          (firstItem.title && firstItem.title !== "") ||
          (firstItem.metaData && firstItem.metaData !== "") ||
          (firstItem.keywords && firstItem.keywords !== "")
        ) {
          return comp.value;
        } else {
          return "";
        }
      }
      break;
    }
    default:
      return "";
  }
};

export const getCmsAndConvertToCsv = async (
  cmsId: string,
  token: string,
  accountInfo: LynxiAccountInfo,
  projectName: string
) => {
  const cmsResponse = await cmsApi.getCmsDataFromServer(cmsId, token);
  const cmsDic: { [id: string]: string } = JSON.parse(cmsResponse.content);
  const cmsTypesDics: CmsValuesByTypes = JSON.parse(cmsResponse.aiContent);
  const cmsDataFromResponse: SiteInfo = JSON.parse(cmsResponse.schema);
  if (cmsTypesDics.nonCMSList && cmsTypesDics.nonCMSList.length > 0) {
    TreeUtils.updateCMSWithNonCmsList(
      cmsDataFromResponse.pages,
      cmsTypesDics.nonCMSList
    );
  }
  if (accountInfo && accountInfo.editorSettings) {
    const editorSettings = JSON.parse(accountInfo.editorSettings);
    if (get(editorSettings, "editor")) {
      TreeUtils.updateCmsWithEditor(
        cmsDataFromResponse.pages,
        get(editorSettings, "editor")
      );
    }
    if (get(editorSettings, "labels")) {
      TreeUtils.updateCmsWithLabels(
        cmsDataFromResponse.pages,
        get(editorSettings, "labels")
      );
    }
  }

  if (cmsTypesDics.editor) {
    TreeUtils.updateCmsWithEditor(
      cmsDataFromResponse.pages,
      cmsTypesDics.editor,
      true
    );
  }
  const pages = TreeUtils.fillCmsWithDataFromDic(
    cmsDataFromResponse.pages,
    {},
    cmsTypesDics
  );
  convetToCsv(pages, cmsDic, projectName);
};

export const convetToCsv = (
  pages: Page[],
  cmsDic: { [id: string]: any },
  projectName: string
) => {
  const csvNewData: CSVConverterLine[] = [];
  pages[0].sections
    .filter((s) => hasTextSingles(s, cmsDic))
    .map((section) =>
      section.singles
        .filter((s) => checkIfSingleHasValue(s, cmsDic))
        .map((single) => {
          return single.components
            .filter(
              (r) =>
                getComponentData({
                  ...r,
                  value: _.get(cmsDic, r.mapKey, r.value),
                }) !== ""
            )
            .forEach((_comp) => {
              const comp = {
                ..._comp,
                value: _.get(cmsDic, _comp.mapKey, _comp.value),
              };
              if (
                comp.type === "simpleText" ||
                comp.type === "paragraph" ||
                comp.type === "yesno" ||
                comp.type === "media" ||
                comp.type === "checkbox"
              ) {
                csvNewData.push({
                  sectionName: single.description,
                  name: TreeUtils.htmlToText(
                    comp.type === "simpleText" &&
                      comp.title &&
                      comp.title !== ""
                      ? comp.title
                      : comp.description
                  ),
                  value: getComponentData(comp),
                });
              } else if (comp.type === "gallery") {
                comp.value.forEach((item: ImageValues, index: number) => {
                  const description = TreeUtils.htmlToText(comp.description);
                  if (item.title && item.title !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${description} - item${index + 1} - title`,
                      value: item.title,
                    });
                  }
                  csvNewData.push({
                    sectionName: single.description,
                    name: `${description} - item${index + 1} - image`,
                    value: item.src,
                  });
                });
              } else if (comp.type === "serviceList") {
                comp.value.forEach((item: ListValues, index: number) => {
                  const description = TreeUtils.htmlToText(comp.description);
                  if (item.title && item.title !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${description} - item${index + 1} - title`,
                      value: item.title,
                    });
                  }
                  if (item.paragraph && item.paragraph !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${description} - item${index + 1} - paragraph`,
                      value: TreeUtils.htmlToText(item.paragraph),
                    });
                  }
                  if (item.subTitle && item.subTitle !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${description} - item${index + 1} - subTitle`,
                      value: item.subTitle,
                    });
                  }
                  if (item.image && item.image !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${description} - item${index + 1} - image`,
                      value: item.image,
                    });
                  }
                });
              } else if (comp.type === "seoPages") {
                comp.value.forEach((item: PageSeoInfo, index: number) => {
                  if (item.page && item.page !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${comp.description} - item${index + 1} - Page`,
                      value: item.page,
                    });
                  }
                  if (item.title && item.title !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${comp.description} - item${index + 1} - Title`,
                      value: item.title,
                    });
                  }
                  if (item.metaData && item.metaData !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${comp.description} - item${
                        index + 1
                      } - Meta Description`,
                      value: TreeUtils.htmlToText(item.metaData),
                    });
                  }
                  if (item.keywords && item.keywords !== "") {
                    csvNewData.push({
                      sectionName: single.description,
                      name: `${comp.description} - item${index + 1} - Keywords`,
                      value: TreeUtils.htmlToText(item.keywords),
                    });
                  }
                });
              } else if (
                comp.type === "multiCheckBox" ||
                comp.type === "multidropdown"
              ) {
                csvNewData.push({
                  sectionName: single.description,
                  name: TreeUtils.htmlToText(comp.description),
                  value: comp.value.join(", "),
                });
              }
            });
        })
    );
  const csv = Papa.unparse({
    fields: ["sectionName", "name", "value"],
    data: csvNewData,
  });
  const url = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("style", "visibility:hidden");
  link.setAttribute("download", `${projectName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
