import axios from "axios";
import ApiUtils from "./ApiUtils";
import { CMSResponse } from "./cmsApi";
import { QuestionnaireData } from "./projectsApi";
import { ProjectSocial, UserInfo } from "../projects/projectsSlice";
import { TaskInfo } from "./tasksApi";


export interface ClientUpdateRequest {
  key: string
  content: string
  answeredQuestions: number
  status: string
}

export interface CategoryInfo {
  id: string
  name: string
}

export interface AssociatedContent {
  collaboratorApprovals: string
  id: string
  invitedAt: string
  projectId: string
  projectName: string
  state: string
  dueDate?: string
}

export interface AssociatedBrief {
  answeredQuestions: number
  id: string
  invitedAt: string
  name: string
  numberOfQuestions: number
  projectId: string
  projectName: string
  status: string
  unreadDesignerComments: number
  updatedAt: string,
  lockedByUser?: string
  dueDate?: string
}

export interface AssociatedProjects {
  briefs: AssociatedBrief[],
  content: AssociatedContent[]
  projectId: string
  projectName: string
  designers: UserInfo[]
  social?: ProjectSocial
  tasks?: TaskInfo[]
  dateFormat?: string
}

export interface AssociatedData {
  projects: AssociatedProjects[]
}

export interface UrlResponse {
  url?: string
}

class ClientApi  {

  getClientQuestionnairById = async (questionnaireId:string, token: string): Promise<QuestionnaireData | null> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/briefs/api/Collaborators/${questionnaireId}`, ApiUtils.Get(token))
    if (response.status === 200) {
       return response.data
    } else return null
  }

  updateClientQuestionnair= async (data: ClientUpdateRequest,  token: string): Promise<QuestionnaireData> => {
    const response = await axios.put(`${ApiUtils.serverDomain}/briefs/api/Collaborators`, data, ApiUtils.Put(token))
    return response.data
  }


  updateClientQuestionnairComments= async (comments: any,  id: string, token: string): Promise<boolean> => {
    const response = await axios.put(`${ApiUtils.serverDomain}/briefs/api/Collaborators/${id}/comments/questionnaire`,  comments
    , ApiUtils.Put(token))
    return response.data
  }

  updateCMSComments= async (comments: any,  id: string, token: string): Promise<boolean> => {
    const response = await axios.put(`${ApiUtils.serverDomain}/briefs/api/Collaborators/${id}/comments/cms`,  comments
    , ApiUtils.Put(token))
    return response.data
  }

  getCollaboratorAssociatedTasks= async (token: string): Promise<AssociatedData | undefined> => {
    try {
      const response = await axios.get(`${ApiUtils.serverDomain}/briefs/api/Collaborators/associated`
    , ApiUtils.Get(token))
      if (response.status === 200) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  unlockFormByClient = async (formId: string , token: string): Promise<boolean> => {
    try {
      const response = await axios.post(`${ApiUtils.serverDomain}/briefs/api/Brief/${formId}/unlock`, {}
    , ApiUtils.Put(token))
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
  

  getCollaboratorCmsDataFromServer = async (
    cmsDataId: string,
    token: string
  ): Promise<CMSResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/Collaborators/${cmsDataId}/cms`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getBusniessCategories= async (): Promise<CategoryInfo[]> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/backoffice/api/Categories`, ApiUtils.UnsecureGet())
    return response.data
  }

  getSubBusniessCategories= async (categoryId: string): Promise<CategoryInfo[]> => {
    const params = {
      category: categoryId,
    }
    const response = await axios.get(`${ApiUtils.serverDomain}/backoffice/api/Categories/sub_categories`, ApiUtils.UnsecureGetWithParams(params))
    return response.data
  }

  getAccountLogo= async (token: string): Promise<UrlResponse> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/backoffice/api/accounts/logo`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return {url: undefined};
      }
    } catch {
      return {url: undefined};
    }
  }

}

export const clientApi = new ClientApi();




