import { Box, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { PlanInfo, SubscriptionInfo, paymentApi } from "../../api/paymentApi";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { getAccessTokenSilence } from "../../auth/auth";
import { useMsal } from "@azure/msal-react";
import { PlanCycle, PlanPakageInfo } from "../PackagesConfig";
import "../PaymentPackages.scss";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { useNavigate } from "react-router-dom";
import _, { get } from "lodash";
import { getUserAccountInfo } from "../../projects/projectsSlice";
import { useAppDispatch } from "../../app/hooks";

export interface PackageItemViewerProps {
  planPackage: PlanPakageInfo;
  currentCycleName: string;
  userCurrentPlanId?: string;
  userExistingPlan?: PlanInfo;
  payPalSubscriptionData: any;
}

export const PackageItemViewer: FC<PackageItemViewerProps> = ({
  planPackage,
  currentCycleName,
  userCurrentPlanId,
  userExistingPlan,
  payPalSubscriptionData,
}) => {
  const msalInstance = useMsal();
  const [currentCycle, setCurrentCycle] = React.useState<PlanCycle>();
  const [startId, setStartId] = React.useState<string>();
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const stateRef = React.useRef(startId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setCurrentCycle(
      planPackage.cycles.find(
        (cycleInfo) => cycleInfo.cycle === currentCycleName
      )
    );
  }, [currentCycleName]);

  const registerSubscription = async (planId: string) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const res = await paymentApi.startSubscription(
        token.accessToken,
        "",
        planId
      );
      return res.id;
    }
  };

  const getSubscriptionId = () => {
    const subscriprionId = _.get(payPalSubscriptionData, "subscriptionId", "");
    return subscriprionId;
  };

  const declineSubscription = async (errorData: any) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const res = await paymentApi.declineSubscription(
        token.accessToken,
        stateRef.current ?? "",
        errorData
      );
      return res.id;
    }
  };

  const cancelSubscription = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const res = await paymentApi.cancelSubscription(
        token.accessToken,
        stateRef.current ?? ""
      );
      dispatch(getUserAccountInfo(token.accessToken));
      return res.id;
    }
  };

  const approveSubscription = async (
    subscriptionId: string,
    allresponse: any
  ) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const res = await paymentApi.subscriptionApproved(
        stateRef.current ?? "",
        subscriptionId,
        allresponse,
        token.accessToken
      );
      dispatch(getUserAccountInfo(token.accessToken));
      return res.id;
    }
  };

  React.useEffect(() => {
    stateRef.current = startId;
  }, [startId]);

  return currentCycle ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "calc(25% - 12px)",
        height: "auto !important",
        justifyContent: "space-between",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          background: "#fff",
          display: "flex",
          position: "relative",
          padding: "18px",
          flexDirection: "column",
          borderRadius: "24px",
          overflow: "hidden",
          border: "1px solid #9747ff26",
          transition: "transform .2s cubic-bezier(.25,.46,.45,.94)",
        }}
        className={
          currentCycle.planId === userCurrentPlanId
            ? "priceDivSelected"
            : "priceDiv"
        }
      >
        {currentCycle.planId === userCurrentPlanId && (
          <Box
            sx={{
              position: "absolute",
              top: "78px",
              right: "-31px",
              fontSize: "12px",
              width: "150px",
              backgroundColor: "#6c3dab",
              color: "#fff",
              padding: "5px",
              textAlign: "center",
              transform: "rotate(45deg)",
              transformOrigin: "100% 0%",
              fontWeight: "bold",
            }}
          >
            Current plan
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              color: "#9747ff",
              lineHeight: "44px",
            }}
          >
            {planPackage.name}
          </Box>
          <Box
            sx={{
              color: "#909090",
              fontSize: "16px",
              fontWeight: 400,
              marginBottom: "1rem",
              minHeight: "42px",
              lineHeight: "21px",
            }}
          >
            {planPackage.description}
          </Box>
        </Box>

        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "24px",
            }}
          >
            <Box sx={{ fontSize: "32px", fontWeight: 700 }}>
              {currentCycle.price === 0 ? (
                "Free"
              ) : (
                <>
                  ${currentCycle.price}
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#909090",
                      fontWeight: 400,
                    }}
                  >
                    /mo
                  </span>
                </>
              )}
            </Box>
            <Box sx={{ fontSize: "14px", color: "#909090", minHeight: "42px" }}>
              {currentCycle.price === 0
                ? ""
                : currentCycle.cycle === "month"
                ? "billed monthly"
                : "billed yearly"}
            </Box>
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid #9747ff3d",
              paddingBottom: "12px",
              marginBottom: "12px",
            }}
          >
            {planPackage.projectCount === -1
              ? "Unlimited"
              : planPackage.projectCount}{" "}
            projects
          </Box>
          <Box sx={{ fontSize: "14px" }} className="packageParam">
            {planPackage.params.map((paramName: string, idx: number) => (
              <Box
                sx={{
                  borderBottom:
                    paramName === "" ? "nond" : "1px solid #9747ff3d",
                  paddingBottom: "12px",
                  marginBottom: "12px",
                }}
                key={`package-param-${idx}`}
              >
                {paramName === "" ? <>&nbsp;</> : paramName}
              </Box>
            ))}
          </Box>
        </>
      </Box>
      {(!userCurrentPlanId ||
        (currentCycle.planId !== userCurrentPlanId && userExistingPlan)) &&
        currentCycle.price !== 0 && (
          <Box
            sx={{
              padding: "20px 10px",
              margin: "0 auto",
            }}
          >
            <Tooltip
              placement={"bottom"}
              open={showTooltip}
              PopperProps={{
                onPointerDown: (e) => {
                  if (
                    e.target instanceof Element &&
                    !e.target.classList.contains("MuiTooltip-tooltip")
                  ) {
                    e.preventDefault();
                  }
                },
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    color: "#000",
                    // padding: "5px 20px 10px 20px",
                    padding: "0px",
                    boxShadow: "0 9px 15px 0 rgba(0, 0, 0, 0.07)",
                    bgcolor: "#f5f8f7",
                    "& .MuiTooltip-arrow": {
                      color: "#000",
                    },
                  },
                },
              }}
              title={
                <Box sx={{ padding: "20px 10px", margin: "0 auto" }}>
                  <PayPalButtons
                    style={{ layout: "vertical" }}
                    createSubscription={async (data, actions) => {
                      if (!userExistingPlan) {
                        window.dataLayer.push({
                          event: "Select payment click",
                        });
                        const id = await registerSubscription(
                          currentCycle.planId
                        );
                        setStartId(id);
                        return actions.subscription.create({
                          plan_id: currentCycle.planId, // Replace with your actual plan ID
                          custom_id: id, // Replace with a value you want to save
                        });
                      } else {
                        return actions.subscription.revise(
                          getSubscriptionId(),
                          {
                            plan_id: currentCycle.planId,
                          }
                        );
                      }
                    }}
                    onApprove={async (data: any, actions: any) => {
                      if (!userCurrentPlanId) {
                        await approveSubscription(data.subscriptionID, data);
                      }
                      window.dataLayer.push({
                        conversionValue: 50,
                        event: "payment",
                      });
                      navigate("/myBilling");
                      return Promise.resolve();
                    }}
                    onCancel={async (data: any, actions: any) => {
                      // Handle subscription cancellation
                      console.log("Subscription cancelled:", data);
                      await cancelSubscription();
                    }}
                    onError={async (error) => {
                      console.error("Subscription error:", error);
                      await declineSubscription(error);
                      // Handle subscription error
                    }}
                  />
                </Box>
              }
              arrow={false}
            >
              <Box
                sx={{ display: "flex", padding: "0 20px", cursor: "pointer" }}
              >
                <Box
                  sx={{
                    color: "#fff !important",
                    padding: "6px 32px 6px 31px",
                    backgroundImage:
                      "linear-gradient(131deg, #fb8332 16%, #f8c332 137%)",
                    fontFamily: "Nunito",
                    fontWeight: 600,
                    textTransform: "none",
                    borderRadius: "22px",
                    fontSize: "14px",
                    opacity: 1,
                    ":hover": {
                      backgroundImage:
                        "linear-gradient(131deg, #f8c332 16%, #fb8332 137%)",
                    },
                    lineHeight: 1.3,
                  }}
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  {userCurrentPlanId ? "Upgrade" : "Select"}
                </Box>
              </Box>
            </Tooltip>
          </Box>
        )}
    </Box>
  ) : null;
};
