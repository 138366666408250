import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Box } from "@mui/material";
import { common } from "@mui/material/colors";
import { FC } from "react";
import { BriefData, Project } from "../../projectsSlice";
import * as React from "react";
import { BriefDoneStage } from "./BriefDoneStage";
import { BriefFillStage } from "./BriefFillStage";
import { BriefReminderStage } from "./BriefReminderStage";
import { BriefShareStage } from "./BriefShareStage";
import { BriefStatusStages } from "../../../utils/Constants";
import { BriefAddContentStage } from "./BriefAddContentStage";

export interface BriefStageStatusSwitcherProps {
  project: Project;
  brief: BriefData;
  showComments: boolean;
  onClose(reload?: boolean): void;
}

export const BriefStageStatusSwitcher: FC<BriefStageStatusSwitcherProps> = ({
  project,
  onClose,
  brief,
  showComments,
}) => {
  const renderByStage = () => {
    if (brief.emailChange) {
      return (
        <BriefShareStage project={project} brief={brief} onClose={onClose} />
      );
    }
    switch (brief.status) {
      case BriefStatusStages.InProgress2:
      case BriefStatusStages.InProgress:
        return (
          <BriefShareStage project={project} brief={brief} onClose={onClose} />
        );
      case BriefStatusStages.BriefSend:
        return (
          <BriefReminderStage
            project={project}
            brief={brief}
            onClose={onClose}
          />
        );
      case BriefStatusStages.Opened:
      case BriefStatusStages.Working:
        return (
          <BriefFillStage
            onClose={onClose}
            project={project}
            brief={brief}
            showCommnets={showComments}
          />
        );
      case BriefStatusStages.Finished:      
        return <BriefDoneStage project={project} brief={brief} />;
      case BriefStatusStages.ContentReview:
        return <BriefAddContentStage project={project} brief={brief} />;
    }
  };

  return (
    <>
      <Box
        sx={{
          width: project ? "314px" : "0px",
          transitionTimingFunction: "ease-in-out",
          transition: "width 2s, transform 2s",
          position: "sticky",
          right: '0px',
          top: '250px',
          zIndex: 1300,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          <CloseSharpIcon onClick={() => onClose()} />
        </Box>
        
        <Box
          sx={{
            height: "120px",
            borderTopLeftRadius: "30px",
            display: "flex",
            justifyContent: "center",
            backgroundImage:
              "linear-gradient(124deg, #fb8332 -127%, #f8c332 86%)",
            flexDirection: "column",
          }}
        >
          {brief.testMode && (
          <Box sx={{fontWeight: 800, position: 'absolute', top:0, right:'58px'}}>Preview and test mode</Box>
         )}
          <Box
            sx={{
              width: "260px",
              alignSelf: "center",
              textOverflow: "ellipsis",
              overflow: "hidden",
              paddingRight: "10px",
            }}
          >
            {project.name}
          </Box>
          <Box sx={{ width: "260px", alignSelf: "center", fontWeight: "bold" }}>
            {brief.name}
          </Box>
        </Box>
        <Box
          sx={{
            background: common.white,
            height: "calc(100vh - 390px)",
            overflowY:'scroll',
            padding: "46px 27px 0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {renderByStage()}
        </Box>
      </Box>
    </>
  );
};
