import { Box, Modal } from "@mui/material";
import { FC } from "react";
import * as React from "react";

import useAsyncEffect from "use-async-effect";
import { CmsCommnetsNavigator } from "../../CmsComments/CmsCommnetsNavigator";
import { ColorButton } from "../../../projects/ProjectUIHelper";
import { ImageCommentReview } from "./ImageCommentReview";
import { CmsCommentsManager } from "../../CmsComments/CmsCommentsManager";
import { useAppDispatch } from "../../../app/hooks";
import { ImageReviedData } from "../DesignImagesReview";
import { setNewImageComment } from "../../cmsSlice";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
  width: "95vw",
};

export interface ImageCommentViewerPopupProps {
  show: boolean;
  onClose: () => void;
  isClient?: boolean;
  imageReviewData: ImageReviedData
}

const ImageCommentViewerPopup: FC<ImageCommentViewerPopupProps> = ({
  show,
  onClose,
  isClient,
  imageReviewData
}) => {
  const [imageSrc, setImageSrc] = React.useState<string>("");
  const [imageTitle, setImageTitle] = React.useState<string>("");
  const [commentId, setCommentId] = React.useState<string>("");
  const [baseCommentId, setBaseCommentId] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const handleClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  useAsyncEffect(async () => {
    setImageSrc(imageReviewData.imageSrc);
    setBaseCommentId(imageReviewData.commentId);

    if (imageReviewData.imageTitle) {
      setImageTitle(imageReviewData.imageTitle);
    }

      // dispatch(
      //   setComments(
      //     commentsResponse.comments.filter(
      //       (c) => c.name.indexOf(`${_baseCommentId}-`) === 0
      //     )
      //   )
      // );
  }, [commentId]);

  const onCloseComments = () => {
    setAnchorEl(null);
    setCommentId("");
    dispatch(setNewImageComment(undefined));
  };

  const onShowComment = (elem: HTMLDivElement, addedLocation: string) => {
    setAnchorEl(elem);
    setCommentId(`${baseCommentId}-#${addedLocation}`);
  };

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        sx={{ zIndex: 1000 }}
        hideBackdrop={false}
        disableEnforceFocus
      >
        <Box sx={style}>
          <Box
            sx={{
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f4f8f7",
              flexDirection: "column",
              paddingBottom: "10px",
            }}
          >
            <Box
              sx={{
                paddingTop: "30px",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                width: "100%",
                display: "flex",
                paddingBottom: "30px",
                backgroundColor: "#fff",
              }}
            >
              <Box
                sx={{
                  width: "33%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CmsCommnetsNavigator
                  isDesignReviewComments={true}
                  isClient={isClient}
                  noCommentsHelper={
                    "Review the mockup and click anywhere to add a comment"
                  }
                />
              </Box>
              <Box
                sx={{
                  width: "33%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <ColorButton onClick={() => onClose()}>Done</ColorButton>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
          <Box sx={{ display: "flex", width: "100%", maxHeight:'70vh'}}>
            {baseCommentId !== "" && (
              <ImageCommentReview
                commentId={baseCommentId}
                imgSrc={imageSrc}
                onShowComment={onShowComment}
                isClient={isClient}
              />
            )}
          </Box>
          <CmsCommentsManager
            commentLocation={"bottom"}
            cmsKey={commentId}
            onClose={onCloseComments}
            anchorEl={anchorEl}
            isClient={isClient ? true : false}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ImageCommentViewerPopup;
