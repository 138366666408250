import { Box, Divider } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import { FC } from "react";
import { useAsyncEffect } from "use-async-effect";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { CMSWorkingMode, ContentHubFilterType } from "../../utils/Constants";
import TreeUtils from "../../utils/TreeUtils";
import "../cms.scss";
import {
  CMSState,
  Section,
  Single,
  UpdateComp,
  setTriggerAddTopic,
  updateCmsItemsUndoData,
  updateCmsValues,
  updatePages,
} from "../cmsSlice";
import { SingleComp } from "./SingleComp";
import { EmptySection } from "../Editor/EmptySection";
import { SectionHelperBox } from "../Editor/SectionHelperBox";
import parse from "html-react-parser";
import _ from "lodash";

export interface SectionCompProps {
  active: boolean;
  section: Section;
  sectionIdx: number;
  showFillMode: boolean;
  mode: CMSWorkingMode;
  onUpdateSingleApproved?(singleKey: string, approve: boolean): void;
  changeIdForSearch?: boolean;
  compareByName?: boolean;
}

export const SectionComp: FC<SectionCompProps> = ({
  active,
  section,
  sectionIdx,
  showFillMode,
  mode,
  onUpdateSingleApproved,
  changeIdForSearch,
  compareByName,
}) => {
  const dispatch = useAppDispatch();
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  useAsyncEffect(() => {
    if (active) {
      scrollTo();
    }
  }, [active]);

  useAsyncEffect(() => {
    window.scrollTo(0, 0);
  }, [sectionIdx]);

  const checkifHideDivider = () => {
    const orderedFormSectionsQuestions = _.orderBy(
      cmsState.formSectionsQuestions,
      ["sectionIndex"],
      ["asc"]
    );
    if (
      orderedFormSectionsQuestions.length > 0 &&
      orderedFormSectionsQuestions[0].sectionIndex === sectionIdx
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onUpdateComp = (item: UpdateComp) => {
    if (item.comp.type === "paragraph") {
      const currentItemUndoData = _.get(
        cmsState.cmsItemsUndoData,
        item.comp.mapKey
      );
      const currentItemData = _.get(
        cmsState.cmsItems,
        item.comp.mapKey
      );
      if (currentItemUndoData) {
        dispatch(
          updateCmsItemsUndoData({
            key: item.comp.mapKey,
            value: {
              past: [...currentItemUndoData.past, currentItemUndoData.present],
              present: item.comp.value,
              future: [...currentItemUndoData.future],
            },
          })
        );
      } else {
        dispatch(
          updateCmsItemsUndoData({
            key: item.comp.mapKey,
            value: { past: [currentItemData], present: item.comp.value, future: [] },
          })
        );
      }
    }
    dispatch(
      updateCmsValues({
        key: item.comp.mapKey,
        value: item.comp.value,
        forceRender: item.forceRender,
      })
    );
  };

  const filterSingleByFilterMode = (single: Single) => {
    switch (cmsState.cmsFilterMode) {
      case ContentHubFilterType.Approved: {
        return (
          get(cmsState.alreadyApproved, `${single.singleKey}`, []).length > 0
        );
      }
      case ContentHubFilterType.Filled: {
        return TreeUtils.singleHasValueNew(single, cmsState.cmsItems);
      }
      case ContentHubFilterType.SelectedQuestions: {
        return cmsState.formQuestions.includes(single.singleKey);
      }
      case ContentHubFilterType.Lynxi: {
        return single.isAI;
      }
    }
  };
  const removeSingle = (singleId: number) => {
    const pages = [...cmsState.pages];
    const page = pages[cmsState.currentPage];
    const newPage = {
      ...page,
      sections: [
        ...page.sections.map((section, idx) => ({
          ...section,
          singles:
            idx === cmsState.currentSection
              ? [...section.singles.filter((_, idx) => idx !== singleId)]
              : [...section.singles],
        })),
      ],
    };
    // if (cmsContext) {
    //   cmsContext.onUpdateEditor(newPage, cmsContext.getLastAddedMetaId());
    // }
    dispatch(updatePages([newPage]));
  };

  const renderSingle = (
    single: Single,
    singleIndex: number,
    subSectionIndex?: number
  ) => {
    return !showFillMode || filterSingleByFilterMode(single) ? (
      <SingleComp
        key={`${section.type}-${sectionIdx}-single-${singleIndex}`}
        single={single}
        singleIdx={singleIndex}
        subSectionIndex={subSectionIndex}
        sectionIndex={sectionIdx}
        onUpdateComp={onUpdateComp}
        onUpdateSingleApproved={onUpdateSingleApproved}
        showIcon={true}
        readonly={mode !== CMSWorkingMode.Edit}
        removeSingle={removeSingle}
        mode={mode}
        changeIdForSearch={changeIdForSearch}
        noComment={mode === CMSWorkingMode.FormReview}
        sectionName={section.description}
      />
    ) : null;
  };

  const renderFormSection = () => {
    const formSection = cmsState.formSectionsQuestions.find((s) =>
      compareByName
        ? s.name === section.description
        : s.sectionIndex === sectionIdx
    );
    if (
      cmsState.cmsFilterMode === ContentHubFilterType.SelectedQuestions ||
      (!cmsState.inFormBuildMode && !cmsState.inEditMode)
    ) {
      if (
        formSection &&
        formSection.singlesIds.length > 0 &&
        !formSection.hide
      ) {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: !checkifHideDivider() ? "30px" : "0px",
              alignItems: "center",
              width: "100%",
              marginLeft:
                !cmsState.inFormBuildMode && !cmsState.inEditMode
                  ? "0px"
                  : "75px",
            }}
          >
            {!checkifHideDivider() && (
              <Divider sx={{ width: "100%", margin: "0 auto" }} />
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center",
                // marginBottom: "15px",
                marginTop: "40px",
                gap: "5px",
                width: "100%",
              }}
              className={"compDescription"}
            >
              <Box sx={{ fontWeight: 600, fontSize: "18px" }}>
                {formSection.title ?? formSection.name}
              </Box>
              <div>{parse(formSection.description ?? "")}</div>
            </Box>
          </Box>
        );
      } else {
        return null;
      }
    } else {
      return (
        <SectionHelperBox
          section={section}
          sectionName={section.description}
          sectionIdx={sectionIdx}
          formSection={formSection}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        margin: "0px auto",
        width: "92%",
        maxWidth: "1100px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {mode === CMSWorkingMode.FormReview && (
        <Box sx={{ display: "flex", width: "100%" }}>{renderFormSection()}</Box>
      )}
      {active && section && section.singles.length > 0 ? (
        section.singles.map((single, i) => renderSingle(single, i))
      ) : !cmsState.isClientMode &&
        mode !== CMSWorkingMode.View &&
        cmsState.cmsFilterMode === ContentHubFilterType.None ? (
        <EmptySection
          onAddNewTopic={() => dispatch(setTriggerAddTopic(true))}
        />
      ) : null}
    </Box>
  );
};
