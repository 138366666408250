import { Box, Dialog, Divider, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { BriefData, refreshActiveProject } from "../../projectsSlice";
import { ReactComponent as SmallTrashSvg } from "../../../assets/project/smallTrash.svg";
import { HelpPopplerOnHover } from "../../../basic/HelpPopplerOnHover";
import { ColorButton, breakTextToLines } from "../../ProjectUIHelper";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../../auth/auth";
import { backofficeApi } from "../../../api/backofficeApi";
import { projectsApi } from "../../../api/projectsApi";
import { useAppDispatch } from "../../../app/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { BasicPageBlocker } from "../../../basic/BasicPageBlocker";

interface EditFormCollaboratorsPopupProps {
  onClose(): void;
  form: BriefData;
  onAddCollaborator(): void;
}

export const EditFormCollaboratorsPopup: FC<
  EditFormCollaboratorsPopupProps
> = ({ onClose, form, onAddCollaborator }) => {
  const [onDelete, setOnDelete] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const instance = useMsal();
  const dispatch = useAppDispatch();

  const onChangeClient = async (email: string) => {
    setOnDelete(true);
    const token = await getAccessToken(instance);
    if (token) {
      await backofficeApi.deleteInvitation(email, form.id, token);
      await projectsApi.removeCollaboratorFromBrief(
        form.id,
        form.collaborators
          .filter((r) => r.email.toLowerCase() === email.toLowerCase())
          .map((r) => r.id),
        token
      );
      if (form.invitations?.length === 1) {
        await projectsApi.updateUserQuestionnairStatus(
          form.id,
          "InProgress",
          token
        );
      }
      setOnDelete(false);
      dispatch(refreshActiveProject(token));
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      closeAfterTransition
      sx={{ zIndex: 4000}}
      PaperProps={{
        style: { borderRadius: '20px' }
      }}
    >
      <Box
        sx={{
          width: "450px",
          display: "flex",
          flexDirection: "column",         
        }}
      >
        <BasicPageBlocker show={onDelete} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 600,
            marginBottom: "20px",
            padding: "15px 20px 10px 20px",
            backgroundImage:
              "linear-gradient(124deg, #fb8332 -127%, #f8c332 86%)",
          }}
        >
          <Box>Form Collaborators</Box>
          <Box sx={{cursor:'pointer'}} onClick={ () => onClose()}><CloseIcon /> </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", padding: "0 20px 20px 20px" , justifyContent: 'space-between',  minHeight: "200px"}}>
          <Box sx={{ display: "flex", flexDirection: "column", padding:'0 20px' }}>
            <Box sx={{fontSize: '14px', marginBottom:'20px'}}>The following people have access to this form:</Box>
          <Grid container rowGap={'5px'}>
            {(form.invitations ?? []).map((inviteInfo, index) => (
              <React.Fragment key={index}>
                <Grid item xs={11}>
                  <Typography noWrap sx={{ width: "100%", textAlign: "left" }}>
                    {inviteInfo.email.toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <HelpPopplerOnHover
                    position="top"
                    useClick={true}
                    overrideWidth={"350px"}
                    autoClose={true}
                    title={t("projects.change_email_popup.title")}
                    message={breakTextToLines(
                      t("projects.change_email_popup.message")
                    )}
                    disabled={true}
                    closeOnAction={true}
                    icon={<svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fillRule="nonzero" stroke="#FFF" fill="none">
                        <path d="M23.8 4.6H2.2a1.2 1.2 0 1 0 0 2.4h2.4v14.4c0 1.988 1.612 3.6 3.6 3.6h9.6c1.988 0 3.6-1.612 3.6-3.6V7h2.4a1.2 1.2 0 1 0 0-2.4zm-12 13.2a1.2 1.2 0 1 1-2.4 0v-6a1.2 1.2 0 1 1 2.4 0v6zm4.8 0a1.2 1.2 0 1 1-2.4 0v-6a1.2 1.2 0 1 1 2.4 0v6zM10.6 3.4h4.8a1.2 1.2 0 1 0 0-2.4h-4.8a1.2 1.2 0 1 0 0 2.4z" />
                      </g>
                    </svg>}
                    actionNode={
                      <Box sx={{ marginLeft: "8px", cursor: "pointer" }}>
                        <SmallTrashSvg />
                      </Box>
                    }
                  >
                    <Box
                      sx={{ fontWeight: 600, cursor: "pointer" }}
                      onClick={() => onChangeClient(inviteInfo.email)}
                    >
                      {t("projects.change_email_popup.action")}
                    </Box>
                  </HelpPopplerOnHover>
                </Grid>
                <Divider sx={{ width: "100%", marginBottom:'5px' }} />
              </React.Fragment>
            ))}
          </Grid>
          </Box>

          <Box
            sx={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ColorButton sx={{fontSize:'14px', padding:'6px 12px'}} onClick={() => onAddCollaborator()}>
              Add new collaborator
            </ColorButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
