import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adminApi } from '../api/adminApi';

export interface AiDataItem {
  id: string
  name: string
  businessCategory: string
  parameters : string
  placeHolderFormat: string
  cmsIds: string[]
  tokens: string[]
  text?: string
}

// export const AiDataItemBuilder = (
//   name: string,
//   businessCategory: string,
//   parameters : string,
//   placeHolderFormat: string,
//   cmsIds: string[]): AiDataItem => ({
//     compIdx,
//     singleIdx,
//     sectionIdx,
//     subSectionIdx,
//     pageIdx,
// })
export interface AIState {
  list: AiDataItem[],
}

const initialState: AIState = {
  list: [],
};

export const getAIItems = createAsyncThunk(
  'aiItems/getAIItems',
  async (token: string) => {
    const response = await adminApi.getAllAiItems(token)
    return response.items
  }
)

export const aiSlice = createSlice({
  name: 'aiItems',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<AiDataItem[]>) => {
      state.list = action.payload
    },
    addToList: (state, action: PayloadAction<AiDataItem>) => {
      state.list = [...state.list, action.payload]
    },   
    deleteFromList: (state, action: PayloadAction<AiDataItem>) => {
      state.list = [...state.list.filter(aiItem => aiItem.name !== action.payload.name)]
    },   
  },
  extraReducers: (builder) => {
    builder.addCase(getAIItems.fulfilled, (state, action) => {
       state.list = action.payload
    })
  },
});

export const { setList, addToList, deleteFromList } = aiSlice.actions;
export default aiSlice.reducer;
