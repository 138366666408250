import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { getPrettyDate } from "../../../../utils/DateUtils";
import { ColorButton, TableCell } from "../../../../projects/ProjectUIHelper";
import _ from "lodash";
import { useAccountSettingsData } from "../../../../hooks/useAccountSettingsData";
import { InvoiceData } from "../../../../api/backofficeApi";
import moment from "moment";

export interface PaymentHistoryLineProps {
  paymentInfo: any;
  invoiceList: InvoiceData[]
}

export const PaymentHistoryLine: FC<PaymentHistoryLineProps> = ({
  paymentInfo,
  invoiceList
}) => {

  const [amount, setAmount] = React.useState<string>('');
  const {getAccountDateFormat} = useAccountSettingsData()


  const getPdf = () => {
      const invoiceExists = invoiceList.find( invoice => {
        const date =  moment.utc(invoice.date)
        const paymentDate = moment.utc(paymentInfo.time)
        return date.month() === paymentDate.month() && date.year() === paymentDate.year()
      })
      if (invoiceExists) {
        return (
          <ColorButton onClick={() => window.open(invoiceExists.url, '_blank')}>Receipt</ColorButton>
        )
      }
      return null
    }

  React.useEffect(() => { 
    if ( paymentInfo) {
      const amounts = _.get(paymentInfo, 'amount_with_breakdown', {})
      const grossAmount = _.get(amounts, 'gross_amount', {})
      const currencyCode = _.get(grossAmount, 'currency_code', '')
      const value = _.get(grossAmount, 'value', '')
      setAmount(`${currencyCode} ${value}`)
    }
  }, [paymentInfo]);


  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "felx",
        background: "#fff",
        alignItems: "center",
        height: "63px",
        margin: "0px 0px 25px",
        "&:hover": {
          backgroundColor: "#f5effb",
          "& .p_title": {
            fontWeight: 700,
          },
        },
      }}
    >
      <Grid container spacing={1} alignItems="center" height="100%">
        <Grid
          item
          xs={4}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%", padding:'0px !Important' }}
        >
          <TableCell>{getPrettyDate(paymentInfo.time, false, getAccountDateFormat())}</TableCell>
         
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%", padding:'0px !Important'  }}
        >
           <TableCell sx={{fontSize:'14px', fontWeight:500}}>{amount}</TableCell>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%", padding:'0px !Important'  }}
        >
           <TableCell sx={{fontSize:'14px', fontWeight:500}}>{getPdf()}</TableCell>
        </Grid>
      </Grid>
    </Box>
  );
};
