import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationApi } from '../api/notificationApi';
import { projectsApi } from '../api/projectsApi';
import { setActiveProject, setFilesForceUpdate } from '../projects/projectsSlice';


export interface ProjectActivity {
  id: string
  projectId: number
  questionnaireId?: number
  userId?: string
  createdAt: string
  viewed: boolean
  activity: string
  type?: string
}

export interface NotificationsState {
  notifications: ProjectActivity[],
  newActivities: ProjectActivity[],
  startDate: string
}

const initialState: NotificationsState = {
  notifications: [],
  newActivities: [],
  startDate : "2022-01-01T20:07:49.582Z"
};

export const getNotifications = createAsyncThunk(
  'notifications/getAllNotifications',
  async (token: string,  { getState ,dispatch }) => {
    const {notificationCenter,  projectsData} = getState() as any
    const response = await notificationApi.getUserActivities(notificationCenter.startDate, token)
    if (response.activities.length > 0 ) {
      //dispatch(getUserProjects(token))
      const res = document.location.pathname.match("\\/projects\\/(.*)/.*?")
      if (res &&res.length === 2 && projectsData.activeProject && res[1] === projectsData.activeProject.id) {
        const projectInfo = await projectsApi.getUserProjectById(
          projectsData.activeProject.id,
          token
        );
        dispatch(setActiveProject(projectInfo))
      }
      const hasFilesUpdate = response.activities.filter( a => a.type && a.type === 'StorageFile').length > 0
      if (hasFilesUpdate) {
        dispatch(setFilesForceUpdate())
      }
    }
    return response.activities
  }
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<ProjectActivity[]>) => {
      state.notifications = action.payload
    },
    updateNotifications : (state, action: PayloadAction<ProjectActivity[]>) => {
      state.notifications = [...action.payload, ...state.notifications]
      if (action.payload.length> 0){
        state.startDate = action.payload[0].createdAt
      }
    },
    resetNewActivities:(state) => {
      state.newActivities = []
    },
    addNotification: (state, action: PayloadAction<ProjectActivity>) => {
      state.notifications = [...state.notifications, action.payload]
    },   
    deleteNotifications: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter ( n => n.id !==  action.payload)
    },   
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
       state.notifications = [...action.payload, ...state.notifications]
       if (state.notifications.length>0 && state.startDate !== '2022-01-01T20:07:49.582Z') {
         state.newActivities = action.payload
       }
       if (action.payload.length> 0){
         state.startDate = action.payload[0].createdAt
       }
    })
  }
});

export const { setNotifications, addNotification, deleteNotifications, resetNewActivities} = notificationsSlice.actions;
export default notificationsSlice.reducer;
