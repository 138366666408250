import { Droppable } from "react-beautiful-dnd";
import * as React from "react";

export interface DropProps {
  id: string
  type: string
}

export const Drop :React.FC<DropProps> = ({ id, type, ...props }) => {
  return (
    <Droppable droppableId={id} type={type}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.droppableProps} {...props}>
            {props.children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};
