import { Backdrop, CircularProgress } from "@mui/material";
import React, { FC } from "react";

export interface BasicPageBlockerProps {
  show: boolean;
  useAbsolute?: boolean;
}

export const BasicPageBlocker: FC<BasicPageBlockerProps> = ({
  show,
  useAbsolute,
}) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: 5000,
        position: useAbsolute ? "absolute" : "",
      }}
      open={show}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
