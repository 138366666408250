import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { getAccessTokenSilence } from "../auth/auth";
import { useMsal } from "@azure/msal-react";
import { CMSState, CmsValuesByTypes, setTypesDics } from "../cms/cmsSlice";
import { cmsApi } from "../api/cmsApi";
import _ from "lodash";

export const useUpdateCmsWatchedItems = () => {
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const msalInstance = useMsal();
  const dispatch = useAppDispatch();

  const updateWatchedItems = async (mapKeys: string[]) => {
    if (cmsState.cmsValuesByTypes && cmsState.cmsValuesByTypes.markedChanges) {
      const markedChanges = cmsState.cmsValuesByTypes.markedChanges ?? [];
      const mapKeysToUpdate = mapKeys.filter((item) =>
        markedChanges.includes(item)
      );
      if (mapKeysToUpdate.length > 0) {
        const token = await getAccessTokenSilence(msalInstance);
        if (token) {
          const cmsResponse = await cmsApi.getCmsDataFromServer(
            cmsState.metaDataId,
            token.accessToken
          );
          const cmsTypesDics: CmsValuesByTypes = JSON.parse(
            cmsResponse.aiContent
          );
          const updatedMarkefChanges = (
            cmsTypesDics.markedChanges ?? []
          ).filter((item) => mapKeysToUpdate.indexOf(item) === -1);
          const updatedCmsTypesDics = {
            ...cmsTypesDics,
            markedChanges: updatedMarkefChanges,
          };
          const currentDic: CmsValuesByTypes = {
            ...cmsState.cmsValuesByTypes,
            brief: _.get(cmsState.cmsValuesByTypes, "brief", {}),
            ai: _.get(cmsState.cmsValuesByTypes, "ai", {}),
            expert: _.get(cmsState.cmsValuesByTypes, "expert", {}),
            experts: _.get(cmsState.cmsValuesByTypes, "experts", {}),
            social: _.get(cmsState.cmsValuesByTypes, "social", {}),
            nonCMSList: _.get(cmsState.cmsValuesByTypes, "nonCMSList", []),
            markedChanges: updatedMarkefChanges,
          };
          dispatch(setTypesDics(currentDic));
          await cmsApi.updateCmsAiData(
            JSON.stringify(updatedCmsTypesDics),
            cmsState.metaDataId,
            token.accessToken
          );
        }
      }
    }
  };

  return { updateWatchedItems };
};
