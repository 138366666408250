import { Box, Fab } from "@mui/material";
import React, { FC } from "react";
import { useDropzone } from "react-dropzone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

export interface DragAndDropInputProps {
  onFileSelect(files: File[]): void;
  onlyUpload?: boolean;
  allfiles?: boolean;
  accepted?: { [key: string]: string[] };
  singleFile?: boolean;
}

export const DragAndDropInput: FC<DragAndDropInputProps> = ({
  onFileSelect,
  onlyUpload,
  allfiles,
  singleFile,
  accepted,
}) => {
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (onFileSelect && acceptedFiles.length > 0) {
        onFileSelect(acceptedFiles);
      }
    },
    [onFileSelect]
  );

  const onDropRejected = (rejectedFiles: any) => {
    // Handle rejected files
  };

  const acceptedFileTypes = allfiles
    ? undefined
    : accepted ?? {
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
        "image/gif": [".gif"],
      };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: acceptedFileTypes,
  });

  const getTextTitle = () => {
    return onlyUpload || singleFile ? (
      <Box>
        Drag and drop {allfiles ? "file" : "image"} here, <br />
        or click to select {allfiles ? "file" : "image"}
      </Box>
    ) : allfiles ? (
      <Box>
        Drag and drop files here, <br />
        or click to select files
      </Box>
    ) : (
      <Box>
        Drag and drop images here, <br />
        or click to select images
      </Box>
    );
  };

  const getDropTitle = () => {
    return onlyUpload
      ? "Drop the image here"
      : allfiles
      ? "Drop the files here"
      : "Drop the images here";
  };

  return (
    <Box
      {...getRootProps()}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      bgcolor={isDragActive ? "grey.100" : "transparent"}
    >
      <input
        {...getInputProps()}
        accept="image/*"
        multiple={!!onlyUpload}
        type="file"
      />
      {isDragActive ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {getDropTitle()}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "50%",
            height: "100%",
            textAlign: "center",
            gap: "10px",
          }}
        >
          {getTextTitle()}
          <label htmlFor="contained-button-file">
            <Fab component="span" className={"uploadbutton"} size="small">
              <AddPhotoAlternateIcon fontSize="medium" />
            </Fab>
          </label>
        </Box>
      )}
    </Box>
  );
};
