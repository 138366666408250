import { Backdrop, Box, CircularProgress } from "@mui/material";
import * as React from "react";
import "survey-core/defaultV2.min.css";
import useAsyncEffect from "use-async-effect";
import { SingleCMSSvg } from "../SingleCMSSvg";
import { ContentItemType } from "./ContentPickerPopup";

import RefreshIcon from "@mui/icons-material/Refresh";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { useTranslation } from "react-i18next";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";

export interface ContentPickerItemProps {
  contentItem: ContentItemType;
  onContentPicked(content: string, type: string): void;
  categoryAndSubExists: boolean;
  readonly?: boolean;
  onGoToCategorySet(): void;
  description: string
  onGetAiSuggest?(): Promise<string>;
  updateActivities?(stage: string): void
}

export const ContentPickerItem: React.FC<ContentPickerItemProps> = ({
  contentItem,
  onContentPicked,
  categoryAndSubExists,
  readonly,
  onGoToCategorySet,
  onGetAiSuggest,
  description,
  updateActivities
}) => {
  const [data, setData] = React.useState<string>("");
  const [startIdx, setStartIdx] = React.useState<number>(-1);
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const getTitle = () => {
    switch (contentItem.type) {
      case "ai":
        return t("cms.content_picker.ai.title");
      case "client":
        return t("cms.content_picker.client.title");
      case "designer":
        return t("cms.content_picker.designer.title");
      case "expert":
        return t("cms.content_picker.expert.title");
      default:
        return "";
    }
  };

  const getDescription = () => {
    switch (contentItem.type) {
      case "ai":
        return t("cms.content_picker.ai.sub_title");
      case "client":
        return t("cms.content_picker.client.sub_title");
      case "designer":
        return t("cms.content_picker.designer.sub_title");
      case "expert":
        return t("cms.content_picker.expert.sub_title");
      default:
        return "";
    }
  };

  const getSvgName = () => {
    switch (contentItem.type) {
      case "expert":
        return "expertIcon";
      case "designer":
        return "designerIcon";
      case "ai":
        return "aiIcon";
      case "client":
        return "clientIcon";
      default:
        return "";
    }
  };

  const genrateRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const replaceContent = async () => {
    if (contentItem.type === 'ai' && onGetAiSuggest) {
      setOnLoading(true)
      const response = await onGetAiSuggest()
      if (response !== '') {
        setData(response)
      }
      setOnLoading(false)
    } else {
      if (contentItem.options && contentItem.options.length > 0) {
        let num = startIdx + 1;
        if (num === contentItem.options.length) {
          num = 0;
        }
        setData(contentItem.options[num]);
        setStartIdx(num);
      }
    }
  };

  useAsyncEffect(async () => {
    if (
      contentItem.content === "" &&
      contentItem.options && 
      contentItem.options.length > 0       
    ) {
      if (contentItem.options.length > 1) {
        const num = genrateRandomNumber(1, contentItem.options.length) - 1;
        setData(contentItem.options[num]);
        setStartIdx(num);
      } else setData(contentItem.options[0]);
    } else {
      setData(contentItem.content);
    }
  }, [contentItem]);

  const renderEmptyState = () => {
    switch (contentItem.type) {
      case "expert":
        if (categoryAndSubExists) {
          return (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Box>
                <SingleCMSSvg name="noContent" />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#555",
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
                {t('cms.content_picker.expert.empty.category.message')}                 
              </Box>
              <Box sx={{ marginTop: "30px" }}>
                <ColorButton onClick={() => updateActivities? updateActivities("Expert Content Request"): {}}>{t('cms.content_picker.expert.empty.category.action')}</ColorButton>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Box>
                <SingleCMSSvg name="noContent" />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#555",
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
                {t('cms.content_picker.expert.empty.no_category.message')}
              </Box>
              <Box sx={{ marginTop: "30px" }}>
                <ColorButton onClick={onGoToCategorySet}>
                  {t('cms.content_picker.expert.empty.no_category.action')}
                  
                </ColorButton>
              </Box>
            </Box>
          );
        }
      case "ai":
          return (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Box>
                <SingleCMSSvg name="noContent" />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#555",
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
               {t('cms.content_picker.ai.empty.message').replace('$$description$$', description)}
              </Box>
              <Box sx={{ marginTop: "30px" }}>
                <ColorButton onClick ={ () => replaceContent()}>{t('cms.content_picker.ai.empty.action')}</ColorButton>
              </Box>
            </Box>
          );
      case "designer":
      case "client":
        return (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <Box>
              <SingleCMSSvg name="noContent" />
            </Box>
            <Box
              sx={{
                textAlign: "center",
                color: "#555",
                fontFamily: "Nunito",
                fontSize: "14px",
                marginTop: "20px",
              }}
            >
              {t('cms.content_picker.other.empty.message')}
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "0 10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginBottom: "10px",
          fontWeight: "500",
        }}
      >
        {getTitle()}
      </Box>
      <Box
        sx={{
          width: "90%",
          textAlign: "center",
          fontSize: "14px",
          margin: "0 auto",
          marginBottom: "10px",
          height: "40px",
        }}
      >
        {getDescription()}
      </Box>
      <Box sx={{ bgcolor: "#fff", display: "flex", flexDirection: "column" , position: "relative"}}>
      <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              borderTopLeftRadius: "30px",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={onLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        <Box
          sx={{
            width: "100%",
            padding: "20px 34px",
            height: "35vh",
            overflowY: data === "" ? "none" : "auto",
            scrollbarWidth: "3px",
            fontFamily: "Nunito",
            fontsize: "14px",
            color: "#555",
          }}
        >
          
          {data === ""
            ? renderEmptyState()
            : data
                .trim()
                .split("\n")
                .map((c, idx) => (
                  <React.Fragment key={`text-comp-${idx}`}>
                    {c}
                    <br />
                  </React.Fragment>
                ))}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems:'flex-end',
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box sx={{display: "flex", alignSelf:'flex-end', flexGrow: 1,}}>
            <SingleCMSSvg name="contentPopup" />
            <Box
              sx={{
                position: "absolute",
                top: contentItem.type === "expert" ? "42px" : "37px",
                left: contentItem.type === "expert" ? "6px" : "8px",
              }}
            >
              <SingleCMSSvg name={getSvgName()} />
            </Box>
          </Box>
          <Box sx={{padding: '10px', cursor: 'pointer'}}>
            {((contentItem.options && contentItem.options.length > 1) || (contentItem.type === 'ai' && contentItem.content !=='')) && (
              <HelpToolTipOnHover
              position="bottom"
              message={"Regenerate content"}
              actionNode={<RefreshIcon onClick ={ () => replaceContent()}/>}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <ColorButton
          disabled={readonly || data === ""}
          onClick={() => onContentPicked(data.trim(), contentItem.type)}
        >
          Select
        </ColorButton>
      </Box>
    </Box>
  );
};
