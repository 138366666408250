import {
  Backdrop,
  Box,
  CircularProgress,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { CommentData } from "../api/cmsApi";
import { DESIGNER_LANG_TOKEN } from "../utils/Constants";
import { CommentLine } from "./CommentLine";
import { ReactComponent as BlackCommentSvg } from "../assets/blackComment.svg";

export interface CommnetsViewerProps {
  position: PopperPlacementType;
  anchorEl?: any;
  onClose(): void;
  comments: CommentData[];
  isClient?: boolean;
  onAddComment(newComment: string): void;
  designerName?: string;
  isBriefsComment?: boolean;
  showSendMessage?: boolean;
  commentKey: string;
  addedOffset?: number;
}

export const CommentsViewer: FC<CommnetsViewerProps> = ({
  anchorEl,
  comments,
  onClose,
  isClient,
  onAddComment,
  position,
  designerName,
  isBriefsComment,
  showSendMessage,
  commentKey,
  addedOffset,
}) => {
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [showReply, setShowReply] = React.useState<boolean>(false);
  const [newComment, setNewComment] = React.useState<string>("");
  const [helperMode, setHelperMode] = React.useState<boolean>(false);
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  useAsyncEffect(async () => {
    const showedCommentHelper = true; //sessionStorage.getItem("showedCommentHelper");
    if (
      !showedCommentHelper &&
      isClient &&
      comments.length === 0 &&
      isBriefsComment
    ) {
      setHelperMode(true);
    } else {
      setShowReply(comments.length === 0);
    }
  }, [anchorEl, comments]);

  useAsyncEffect(async () => {
    setOnLoading(false);
  }, [showSendMessage]);

  const onDeleteComment = () => {
    setShowReply(false);
    setNewComment("");
    if (comments.length === 0) {
      onBeforeClose();
    }
  };

  const addComment = () => {
    setOnLoading(true);
    onAddComment(newComment);
    // onDeleteComment();
  };

  const onBeforeClose = () => {
    if (helperMode) {
      sessionStorage.setItem("showedCommentHelper", "true");
    }
    onClose();
  };

  const onBeforeCloseAway = () => {
    if (helperMode) {
      sessionStorage.setItem("showedCommentHelper", "true");
    } else {
      // onClose();
    }
  };

  const renderHelpMode = () => {
    return (
      <>
        <Box sx={{ fontWeight: 500, fontSize: "14px" }}>
          {t("comments.help_message").replace(
            DESIGNER_LANG_TOKEN,
            designerName ?? ""
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              alignSelf: "flex-end",
              marginTop: "5px",
            }}
          />
          <Box
            sx={{
              cursor: "pointer",
              alignSelf: "flex-end",
              marginTop: "5px",
            }}
            onClick={() => onBeforeClose()}
          >
            {t("comments.close_btn")}
          </Box>
        </Box>
      </>
    );
  };

  const renderSuccessSendMessage = () => {
    return (
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          background: "#292B28",
          padding: "20px 20px 20px",
          borderRadius: "8px",
          zIndex: 100,
        }}
      >
        Comment sent successfuly
      </Box>
    );
  };

  const getTextForAction = () => {
    if (comments.length === 0) return "Add";
    if (isClient) {
      if (comments[comments.length - 1].source === "Collaborator") {
        return "Add";
      } else return "Reply";
    } else if (comments[comments.length - 1].source !== "Collaborator") {
      return "Add";
    } else return "Reply";
  };

  return (
    <>
      {anchorEl && (
        <ClickAwayListener onClickAway={() => onBeforeCloseAway()}>
          <Popper
            open={anchorEl !== undefined}
            modifiers={[
              {
                name: "arrow",
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
              {
                name: "offset",
                options: {
                  offset: (state: any) => {
                    if (state.placement === "right-start") {
                      return [-20, 0];
                    } else if (state.placement === "left-start") {
                      return commentKey.indexOf("__") !== -1
                        ? [-20, 10]
                        : [-20, -50 + (addedOffset ?? 0)];
                    } else if (state.placement === "left-end") {
                      return [5, -50 + (addedOffset ?? 0)];
                    } else {
                      return [5, 0];
                    }
                  },
                },
              },
            ]}
            className={"commentPopper"}
            anchorEl={anchorEl}
            placement={position}
            style={{ zIndex: 3000 }}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  className={"commentPaper"}
                  sx={{
                    width: "280px",
                    background: "rgba(0,0,0,0)",
                    color: "#fff",
                    marginTop: "0px",
                    padding: 0,
                  }}
                >
                  <Box>
                    <Backdrop
                      className={"commentOver"}
                      sx={{
                        color: "#fff",
                        position: "absolute",
                        borderTopLeftRadius: "30px",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={onLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box
                      className={"arrowComment"}
                      sx={{
                        height: "16px",
                        width: "16px",
                        backgroundColor: "#292B28",
                        position: "absolute",
                        borderTop: "1px solid rgba(0, 0, 0, 0.3)",
                        transform:
                          "translate(50%, -50%) rotate(45deg) !important",
                      }}
                      ref={setArrowRef}
                    />
                    {!showSendMessage && (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          background: "#292B28",
                          padding: "10px 20px 15px",
                          zIndex: 100,
                          borderRadius: "8px",
                          marginBottom: "5px",
                          alignContent: "center",
                        }}
                      >
                        <BlackCommentSvg />
                        {isClient && comments.length === 0 && (
                          <Box
                            sx={{
                              fontWeight: 800,
                              background: "#292B28",
                              marginLeft: "15px",
                            }}
                          >
                            {t("comments.header").replace(
                              DESIGNER_LANG_TOKEN,
                              designerName ?? ""
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                    {helperMode ? (
                      renderHelpMode()
                    ) : showSendMessage ? (
                      renderSuccessSendMessage()
                    ) : (
                      <>
                        {comments.length > 0 && (
                          <Box
                            className="commentManager"
                            sx={{
                              maxHeight: "400px",
                              overflowY: "auto",
                              border: "none",
                              zIndex: 100,
                            }}
                          >
                            {comments.map(
                              (comment: CommentData, idx: number) => (
                                <CommentLine
                                  key={`notification-${idx}`}
                                  comment={comment}
                                />
                              )
                            )}
                          </Box>
                        )}
                        {showReply ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              postion: "relative",
                              borderRadius: "8px",
                            }}
                          >
                            <Box
                              sx={{
                                background: isClient ? "#292B28" : "#fff",
                                boxShadow:
                                  "25px 39px 60px 0 rgba(0, 0, 0, 0.07)",
                                color: isClient ? "#fff" : "#000",
                                padding: "8px 5px",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                              }}
                            >
                              <TextField
                                sx={{ width: "100%" }}
                                className={"inputWithVal"}
                                placeholder={t("comments.placeholder")}
                                multiline
                                autoFocus
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  sx: {
                                    color: isClient ? "#fff" : "#000",
                                    padding: "5px",
                                  },
                                }}
                                maxRows={6}
                                rows={6}
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "0px",
                                padding: "8px 10px",
                                background: "#292B28",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                                zIndex: 100,
                              }}
                            >
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  alignSelf: "flex-end",
                                  marginTop: "5px",
                                }}
                                onClick={() => onDeleteComment()}
                              >
                                {t("comments.cancel_btn")}
                              </Box>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  alignSelf: "flex-end",
                                  marginTop: "5px",
                                  opacity:
                                    newComment.length === 0 ? "0.6" : "1",
                                  color:
                                    newComment.length === 0
                                      ? "#C6C6C6"
                                      : "#fff",
                                }}
                                onClick={() =>
                                  newComment.length !== 0 ? addComment() : {}
                                }
                              >
                                {t("comments.send_btn")}
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              background: "#292B28",
                              padding: "8px 15px",
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                // marginTop: "5px",
                                borderRadius: "8px",
                              }}
                              onClick={() => onBeforeClose()}
                            >
                              Close
                            </Box>
                            <Box
                              sx={{
                                cursor: "pointer",
                                borderRadius: "8px",
                              }}
                              onClick={() => setShowReply(true)}
                            >
                              {getTextForAction()}
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};
