import * as React from "react";
import BasicPopupDialog from "../basic/BasicPopupDialog";
import AccountSettings from "./AccountSettings";

export interface AccountSettingsPopupProps {
  show: boolean;
  onClose: () => void;
}

const AccountSettingsPopup: React.FC<AccountSettingsPopupProps> = ({
  show,
  onClose,
}) => {
  return (
    <BasicPopupDialog show={show} onClose={() => onClose()} width={"95vw"} zIndex={3001}>
      <AccountSettings onExternalClose={() => onClose()} />
    </BasicPopupDialog>
  );
};

export default AccountSettingsPopup;
