import * as React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { ReactComponent as ShareEmailSuccessSvg } from "../../../assets/project/shareMailSuccess.svg";
import { ReactComponent as ShareEmailFailedSvg } from "../../../assets/project/shareMailFail.svg";

export interface ReminderHandleProps {
  shareResult: boolean
  onCloseShare(success?:boolean):void
  successMessage: string
}

export const ReminderHandle: FC<ReminderHandleProps> = ({
  shareResult,
  onCloseShare,  
  successMessage
}) => {

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        {shareResult ? <ShareEmailSuccessSvg /> : <ShareEmailFailedSvg />}
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 500,
          }}
        >
          {shareResult
            ? "We sent a reminder!"
            : "Opps, there was a problem"}
        </Typography>
        <br />
        <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
          {shareResult
            ? successMessage
            : "Please try sending the reminder again"}
        </Box>
        <br />
        <Typography
          color="secondary"
          onClick={() => onCloseShare(shareResult)}
          sx={{
            cursor: "pointer",
            fontWeight: 500,
          }}
        >
          {shareResult ? "Got it" : "Try again"}
        </Typography>
      </Box>
    </Box>
  );
};
