import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { BasicMessagePopup } from "./BasicMessagePopup";
import { ColorButton } from "../projects/ProjectUIHelper";

export interface FlowMessagePopupProps {
  message?: React.ReactNode | string;
  onClose(): void;
  title?: React.ReactNode | string;
  icon?: React.ReactNode;
  action: string;
  open: boolean;
  extraWidth?: string;
  disableAutoClose?: boolean;
}

export const FlowMessagePopup: FC<FlowMessagePopupProps> = ({
  message,
  onClose,
  title,
  icon,
  action,
  open,
  extraWidth,
  disableAutoClose,
}) => {
  return (
    <BasicMessagePopup
      icon={icon}
      disableAutoClose={disableAutoClose}
      onClose={onClose}
      open={open}
      extraWidth={extraWidth}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          flexDirection: "column",
          fontFamily: "Nunito",
          justifyContent: "center",
          fontSize: "14px",
          width: "60%",
          paddingBottom: "20px",
        }}
      >
        <Box sx={{ fontWeight: 600, marginTop: "32px", fontSize: "16px" }}>
          {title}
        </Box>
        <Box sx={{ fontWeight: 400, marginTop: "20px", marginBottom: "35px" }}>
          {message}
        </Box>
        <ColorButton onClick={() => onClose()}>{action}</ColorButton>
        {/* <Box sx={{ fontWeight: 600, marginTop: "30px", marginBottom: '60px', color:'#6c3dab', cursor:'pointer' }}
                 onClick = { () => onClose()}
            >
              
            </Box> */}
      </Box>
    </BasicMessagePopup>
  );
};
