import { Box, Divider, TextField } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ColorButton } from "../../projects/ProjectUIHelper";
import useAsyncEffect from "use-async-effect";
import {
  CMSComponent,
  CMSListComponent,
  CMSState,
  Single,
  updatePages,
} from "../cmsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { cloneDeep } from "lodash";
import { CMSManagerContext } from "../../routes/DesignerRouter";
import { BasicRichEditor } from "../../basic/BasicRichEditor/BasicRichEditor";
import { EditorEditMultiCheckBox } from "./EditorEditMultiCheckBox";
import _ from "lodash";
import { EditServiceCompLabels } from "./EditServiceCompLabels";
import { BasicDraggableDialog } from "../../basic/BasicDraggableDialog";

export interface UpdateTopicInfo {
  title: string;
  placeholder: string;
  sectionTitle: string;
}

export interface EditorEditTopicPopupProps {
  onClose(): void;
  currentSingle: Single;
}

export const EditorEditTopicPopup: FC<EditorEditTopicPopupProps> = ({
  onClose,
  currentSingle,
}) => {
  const [sectionTitle, setSectionTitle] = React.useState<string>("");
  const [sectionDescription, setSectionDescription] = React.useState<string>();
  const [components, setComponents] = React.useState<CMSComponent[]>([]);

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const dispatch = useAppDispatch();
  const cmsContext = React.useContext(CMSManagerContext);

  const setComponenstTitle = (idx: number, title: string) => {
    const newComponents = cloneDeep(components);
    newComponents[idx].description = title;
    setComponents(newComponents);
  };

  const setComponentsPlaceholder = (idx: number, placeholder: string) => {
    const newComponents = cloneDeep(components);
    newComponents[idx].placeHolder = placeholder;
    setComponents(newComponents);
  };

  const setComponentsOptions = (idx: number, options: string[]) => {
    const newComponents = cloneDeep(components);
    newComponents[idx].options = options;
    setComponents(newComponents);
  };

  const setComponentLabels = (idx: number, labels: string[]) => {
    const newComponents = cloneDeep(components);
    (newComponents[idx] as CMSListComponent).labels = labels;
    setComponents(newComponents);
  };

  useAsyncEffect(async () => {
    setComponents(currentSingle.components);
    setSectionTitle(currentSingle.description);
    setSectionDescription(currentSingle.header);
  }, [currentSingle]);

  const renderComponents = () => {
    return components.map((comp, idx) => (
      <Box
        key={`comp-edit-${idx}`}
        sx={{
          display: "flex",
          flexDirection: "column",
          // gap: "5px",
        }}
      >
        {!comp.hideDescription && (
          <>
            <Box>
              {comp.type === "media" || comp.type === "gallery"
                ? ""
                : comp.description &&
                  comp.description !== "" &&
                  comp.type === "paragraph"
                ? "Question"
                : ""}
            </Box>
            {comp.type === "paragraph" ||
            comp.type === "multiCheckBox" ||
            comp.type === "gallery" ||
            comp.type === "rating" ? (
              <BasicRichEditor
                value={comp.description}
                onUpdate={(value: string) => setComponenstTitle(idx, value)}
              />
            ) : (
              <TextField
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  background: "#f9f9f9",
                  "& input.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                    WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                  },
                }}
                inputProps={{ className: "dropDownOther" }}
                placeholder={"Enter your question"}
                maxRows={1}
                value={comp.description}
                onChange={(e) => {
                  setComponenstTitle(idx, e.target.value);
                }}
              />
            )}
          </>
        )}
        {(comp.type === "multiCheckBox" ||
          comp.type === "multidropdown" ||
          (comp.type === "dropdown" &&
            !comp.remoteLoad &&
            comp.mapKey !== "73")) && (
          <Box>
            <EditorEditMultiCheckBox
              options={comp.options}
              onSetComponentsOptions={(options: string[]) =>
                setComponentsOptions(idx, options)
              }
            />
          </Box>
        )}
        {(comp.type === "paragraph" || comp.placeHolder) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Box>Placeholder</Box>
            <TextField
              sx={{
                flexGrow: 1,
                width: "100%",
                background: "#f9f9f9",
                "& input.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                },
              }}
              inputProps={{ className: "dropDownOther" }}
              placeholder={"Enter placehonlder text"}
              multiline={true}
              rows={4}
              maxRows={4}
              value={comp.placeHolder ?? ""}
              onChange={(e) => {
                setComponentsPlaceholder(idx, e.target.value);
              }}
            />
          </Box>
        )}
        {comp.type === "serviceList" && (
          <EditServiceCompLabels
            labels={(comp as CMSListComponent).labels ?? []}
            hiddenLabels={comp.hide ?? []}
            onSetLabels={(labels: string[]) => setComponentLabels(idx, labels)}
          />
        )}
        <Box sx={{ width: "100%", margin: "5px 0 5px" }} />
      </Box>
    ));
  };

  const onUpdateClick = () => {
    const newPage = cloneDeep(cmsState.pages[0]);
    const section = newPage.sections[cmsState.currentSection];
    const single = section.singles.find(
      (s) => s.singleKey === currentSingle.singleKey
    );
    if (single) {
      if (cmsContext) {
        if (single.isNew) {
          cmsContext.onUpdateEditor(newPage, cmsContext.getLastAddedMetaId());
        } else {
          const updateDic: { [id: string]: any } = {};
          const singleDic: { [id: number]: any } = {};
          const singleDescriptionDic: { [id: number]: any } = {};
          components.map((comp, idx) => {
            if (comp.type === "paragraph") {
              if (
                comp.description !== single.components[idx].description ||
                comp.placeHolder !== single.components[idx].placeHolder
              ) {
                updateDic[comp.mapKey] = {
                  title: comp.description,
                  placeHolder: comp.placeHolder,
                };
              }
            } else if (
              comp.type === "multiCheckBox" ||
              comp.type === "multidropdown" ||
              comp.type === "dropdown"
            ) {
              if (
                comp.description !== single.components[idx].description ||
                !_.isEqual(comp.options, single.components[idx].options)
              ) {
                updateDic[comp.mapKey] = {
                  title: comp.description,
                  options: comp.options,
                };
              }
            } else if (comp.type === "serviceList") {
              if (
                !_.isEqual(
                  (comp as CMSListComponent).labels,
                  (single.components[idx] as CMSListComponent).labels
                )
              ) {
                updateDic[comp.mapKey] = {
                  labels: (comp as CMSListComponent).labels,
                };
              }
            } else {
              if (comp.description !== single.components[idx].description) {
                updateDic[comp.mapKey] = { title: comp.description };
              }
            }
          });
          if (single.description !== sectionTitle) {
            singleDic[single.singleKey] = sectionTitle;
          }
          if (single.header !== sectionDescription) {
            singleDescriptionDic[single.singleKey] = sectionDescription;
          }
          cmsContext.onUpdateLynxiSections(
            singleDic,
            singleDescriptionDic,
            updateDic
          );
        }
      }
      single.description = sectionTitle;
      single.header = sectionDescription;
      single.components = components;
      dispatch(updatePages([newPage]));
    }
    onClose();
  };

  return (
    <BasicDraggableDialog title={"Customize Section/Question"} onClose={onClose}>
      <Box sx={{ fontWeight: 400, marginTop: "20px", marginBottom: "10px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "30px",
            marginTop: "20px",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box>Section title</Box>
              <TextField
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  background: "#f9f9f9",
                  "& input.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                    WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                  },
                }}
                inputProps={{ className: "dropDownOther" }}
                placeholder={"Enter section title"}
                maxRows={1}
                value={sectionTitle}
                onChange={(e) => {
                  setSectionTitle(e.target.value);
                }}
              />
            </Box>

            {currentSingle.header && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <Box>Section description</Box>
                <TextField
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    background: "#f9f9f9",
                    "& input.Mui-disabled": {
                      color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                    },
                  }}
                  inputProps={{ className: "dropDownOther" }}
                  placeholder={"Enter section description"}
                  maxRows={1}
                  value={sectionDescription}
                  onChange={(e) => {
                    setSectionDescription(e.target.value);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", margin: "20px 0" }}>
          <Divider sx={{ borderColor: "#dfdfdf", width: "100%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            maxHeight: "40vh",
            overflowY: "auto",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {components.length > 1 && <Box>Labels:</Box>}
          {renderComponents()}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
          Cancel
        </Box>
        <ColorButton onClick={() => onUpdateClick()}>Update</ColorButton>
      </Box>
    </BasicDraggableDialog>
  );
};
