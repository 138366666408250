import { Box, TextField, Typography, Fab } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { InputEmailField } from "../basic/InputEmailField";
import { Project } from "../projects/projectsSlice";
import { ColorButton, CustomCheckBox } from "../projects/ProjectUIHelper";
import { ReactComponent as ShareFailerSVG } from "../assets/cms/shareFailer.svg";
import { ReactComponent as CopyLinkSVG } from "../assets/link.svg";
import { ReactComponent as CheckSvg } from "../assets/checkbox.svg";
import InfoIcon from "@mui/icons-material/Info";

import { BasicMessagePopup } from "./BasicMessagePopup";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { ShareLinkPopup } from "../projects/ProjectBriefs/BriefStageStatus/ShareLinkPopup";
import { ShareBtnWithBrandingCheck } from "../settings/ShareBtnWithBrandigCheck";
import { HelpToolTipOnHover } from "../basic/HelpToolTipOnHover";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import {useAccountSettingsData} from "../hooks/useAccountSettingsData";

export interface ShareMessagePopupProps {
  onClose(success: boolean): void;
  onShare(
    shareEmails: string[],
    description: string,
    subject: string,
    isAnonymous?: boolean,
    dueDate?: Dayjs,
    dailyReminder?: boolean
  ): Promise<boolean>;
  project: Project;
  title: string;
  message: string;
  subject: string;
  header?: string;
  action?: string;
  hideEmail?: boolean;
  singleShare?: boolean;
  hideLink?: boolean;
  onCopyLink?(emails: string[]): void;
  addedContent?: React.ReactNode;
  allowAnonymous?: boolean;
}

export const ShareMessagePopup: React.FC<ShareMessagePopupProps> = ({
  onClose,
  onShare,
  project,
  title,
  message,
  subject,
  header,
  action,
  hideEmail,
  singleShare,
  hideLink,
  onCopyLink,
  addedContent,
  allowAnonymous,
}) => {
  const [userEmails, setUserEmails] = React.useState<string[]>([""]);
  const [showCopyLinkPopup, setShowCopyLinkPopup] =
    React.useState<boolean>(false);
  const [emailSubject, setEmailSubject] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [shareResult, setShareResult] = React.useState<boolean | undefined>();
  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);
  const [linkForAll, setLinkForAll] = React.useState<boolean>(false);
  const [dailyReminder, setDailyReminder] = React.useState<boolean>(false);
  const [enableDueDate, setEnableDueDate] = React.useState<boolean>(false);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const {getAccountDateFormat} = useAccountSettingsData()

  const dateFormat = React.useMemo(() => getAccountDateFormat(), [getAccountDateFormat]);

  const onShareClick = async () => {
    setOnProcessing(true);
    const shared = await onShare(
      userEmails,
      description,
      emailSubject,
      linkForAll,
      dueDate??undefined,
      dailyReminder
    );
    if (shared) {
      onClose(true);
    } else {
      setOnProcessing(false);
      setShareResult(true);
    }
  };

  useAsyncEffect(async () => {
    const email = get(JSON.parse(project.additionalData), "8000", "");
    if (email !== "") {
      setUserEmails([email]);
    }
    setEmailSubject(subject);
    setDescription(message);
  }, []);

  const setEmail = (email: string, idx: number) => {
    const emails = [...userEmails];
    emails[idx] = email;
    setUserEmails(emails);
  };

  const removeEmail = (index: number) => {
    const array = [...userEmails];
    array.splice(index, 1);
    setUserEmails(array);
  };

  const emailExists = () => {
    return userEmails.filter((l) => l !== "").length === userEmails.length;
  };

  const renderAddEmailButton = () => {
    return (
      <Fab
        size="small"
        sx={{
          background: "#8B4FC6",
          color: "#fff",
          transform: "scale(0.6)",
          "&:hover": { color: "#000" },
        }}
        onClick={() => setUserEmails([...userEmails, ""])}
      >
        <AddIcon fontSize="small" />
      </Fab>
    );
  };

  const renderEmailFailed = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          paddingTop: "30%",
          paddingBottom: "30%",
        }}
      >
        <Box sx={{ marginBottom: "30px" }}>
          <ShareFailerSVG />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Opps, there was a problem
          </Typography>
          <br />
          <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
            Please try sending it again
          </Box>
          <ColorButton
            sx={{ marginTop: "40px" }}
            onClick={() => setShareResult(undefined)}
          >
            Try again
          </ColorButton>
        </Box>
      </Box>
    );
  };

  return (
    <BasicMessagePopup
      title={title}
      onClose={() => onClose(false)}
      open={true}
      zIndex={1200}
      extraWidth={"40vw"}
      disableAutoClose={true}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          paddingTop: "20px",
          padding: "0 10%",
          position: "relative",
        }}
      >
        {shareResult === undefined ? (
          <>
            <Box
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: 300,
                paddingTop: "50px",
                marginBottom: "10px",
              }}
            >
              {header ?? ""}
            </Box>
            {!hideEmail && (
              <>
                {userEmails.map((email: string, idx: number) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                    key={`email-${idx}`}
                  >
                    <Box sx={{ width: "80%", flexGrow: 1 }}>
                      <InputEmailField
                        handleChange={(val) => setEmail(val, idx)}
                        defaultValue={email}
                        placeholder={"Email"}
                        isFullWidth={true}
                        required={false}
                      />
                    </Box>

                    <Box
                      sx={{
                        width: "40px",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {userEmails.length === 1 && renderAddEmailButton()}
                      {idx > 0 && (
                        <>
                          <Box
                            sx={{ alignSelf: "flex-start", cursor: "pointer" }}
                          >
                            <Fab
                              size="small"
                              sx={{
                                background: "#cbcbcb",
                                color: "#fff",
                                transform: "scale(0.6)",
                                "&:hover": { color: "#000" },
                              }}
                              onClick={() => removeEmail(idx)}
                            >
                              <CloseIcon fontSize="small" />
                            </Fab>
                          </Box>
                          {userEmails.length > 1 &&
                            idx === userEmails.length - 1 &&
                            !singleShare && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {renderAddEmailButton()}
                                </Box>
                              </Box>
                            )}
                        </>
                      )}
                    </Box>
                  </Box>
                ))}
              </>
            )}
            <Box
              sx={{ width: "100%", marginTop: "28px", paddingRight: "40px" }}
            >
              <TextField
                sx={{ width: "100%", background: "#f9f9f9" }}
                label={"Subject"}
                className={emailSubject !== "" ? "inputWithVal" : ""}
                required={true}
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Box>
            <Box
              sx={{ width: "100%", marginTop: "28px", paddingRight: "40px" }}
            >
              <TextField
                sx={{ width: "100%", background: "#f9f9f9" }}
                multiline
                label={"Message"}
                required={true}
                className={description !== "" ? "inputWithVal" : ""}
                maxRows={5}
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
            {addedContent}
            {allowAnonymous && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "5px",
                    alignItems: "center",
                  }}
                >
                  <CustomCheckBox
                    checkedIcon={
                      <CheckSvg style={{ transform: "scale(0.7)" }} />
                    }
                    checked={linkForAll}
                    onChange={() => setLinkForAll(!linkForAll)}
                  />
                  <Box sx={{ fontSize: "14px" }}>
                    Anyone with a link can access
                  </Box>
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <CustomCheckBox
                checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
                checked={dailyReminder}
                onChange={() => setDailyReminder(!dailyReminder)}
              />
              <Box
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Send a daily reminder
                <HelpToolTipOnHover
                  position="top-start"
                  size={250}
                  message={
                    "Lynxi will send a daily email reminder to approve the content."
                  }
                  actionNode={<InfoIcon fontSize="small" />}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems:'center'  }}>
              <CustomCheckBox
                checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
                checked={enableDueDate}
                onChange={() => setEnableDueDate(!enableDueDate)}
              />
              <DatePicker
                label="Due date"
                className="datePicker"
                value={dueDate}
                format={dateFormat}
                disabled={!enableDueDate}
                onChange={(newValue: any) => setDueDate(newValue)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                marginTop: "28px",
                paddingBottom: "30px",
                paddingRight: "40px",
                justifyContent:
                  hideEmail || hideLink ? "flex-end" : "space-between",
              }}
            >
              {!hideEmail && !hideLink && (
                <Box
                  sx={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => setShowCopyLinkPopup(true)}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CopyLinkSVG style={{ marginRight: "5px" }} /> Copy link
                  </Box>
                </Box>
              )}
              <ShareBtnWithBrandingCheck
                btnText={action ?? "Share"}
                disabled={
                  !emailExists() ||
                  description === "" ||
                  subject === "" ||
                  onProcessing
                }
                onContinue={() => onShareClick()}
                onProcessing={onProcessing}
              />
            </Box>
          </>
        ) : (
          renderEmailFailed()
        )}
      </Box>

      {showCopyLinkPopup && (
        <ShareLinkPopup
          isContentHub={true}
          projectId={project.id}
          id={project.cmsDataId ?? ""}
          initialEmail={userEmails[0]}
          allowAnonymous={allowAnonymous}
          allowTimeLimit={true}
          allowDueDate={true}
          defaultLinkForAll={linkForAll}
          onClose={(linkWasGenerated: boolean, email?: string) => {
            setShowCopyLinkPopup(false);
            if (linkWasGenerated && onCopyLink) {
              onCopyLink(email ? [email] : []);
            }
          }}
        />
      )}
    </BasicMessagePopup>
  );
};
