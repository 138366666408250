import { useAccount, useMsal } from "@azure/msal-react";
import { get } from "lodash";
import * as React from "react";
import { isMobileOnly } from "react-device-detect";
import { Route, Routes, useLocation } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { getAccessToken } from "../auth/auth";
import { getUserAccountInfo, getUserInfo } from "../projects/projectsSlice";
import { TopHeader } from "../topHeader/TopHeader";
import ScrollToTopFab from "../basic/ScrollToTopFab";
import { LogoutPage } from "../loginPage/LogoutPage";

const LazyFormViewerSwitcher = React.lazy(
  () => import("../Briefs/Viewer/FormViewerSwitcher")
);

const LazyCMSCollaboratorViewer = React.lazy(
  () => import("../cms/CMSCollaboratorViewer")
);

const LazyCollaboratorDashBoard = React.lazy(
  () => import("../collaborator/CollaboratorDashBoard")
);
const LazyImageCommentViewers = React.lazy(
  () => import("../cms/components/ImageCommentViewer/ImageCommentViewer")
);

const LazyDesingerRouter = React.lazy(() => import("./DesignerRouter"));

export const DesignerContext = React.createContext({
  name: "Designer",
  id: "",
  accountId: "",
  email: "",
  role: "",
});

export const AppRouter: React.FC = () => {
  const [role, setRole] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>("");
  const [designerEmail, setDesignerEmail] = React.useState<string>("");
  const [accountId, setAccountId] = React.useState<string>("");
  const [designerName, setDesignerName] = React.useState<string>("Designer");
  const account = useAccount();
  const dispatch = useAppDispatch();
  const instance = useMsal();
  const projectsState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const location = useLocation();
  // const analytics = useAnalytics();
  // React.useEffect(() => {
  //   // send page view on route change
  //   analytics.page();

  // }, [location]);

  useAsyncEffect(async () => {
    // console.log('Env', process.env.NODE_ENV)
    const role =
      account && account.idTokenClaims
        ? (get(account.idTokenClaims, "extension_Role", "guest") as string)
        : "guest";
    setRole(role);
    if (account) {
      if (account.idTokenClaims) {
        const firstName = get(account.idTokenClaims, "given_name", "Designer");
        const familytName = get(account.idTokenClaims, "family_name", "");
        setDesignerEmail(
          account.idTokenClaims.emails ? account.idTokenClaims.emails[0] : ""
        );
        const lynxiAccount: string = get(
          account.idTokenClaims,
          "extension_LynxiAccounts",
          ""
        ) as string;
        if (lynxiAccount !== "") {
          const accountList = JSON.parse(lynxiAccount);
          setAccountId(accountList[0]);
        }
        setDesignerName(`${firstName} ${familytName}`);
      } else {
        setDesignerName(account.name ? account.name : "Designer");
      }

      if (role === "BusinessOwner") {
        // window.dataLayer.push({ event: "signup_success" });
        localStorage.setItem("lynxiUser", "true");
      }

      setUserId(account.localAccountId);
    }
    const token = await getAccessToken(instance);
    if (token) {
      dispatch(getUserInfo(token));
      if (role === "Designer" || role === "Administrator") {
        dispatch(getUserAccountInfo(token));
      }
    }
  }, []);

  const calculaePageMargin = () => {
    if (projectsState.drawerOpen) {
      return "0px 0px 0px 4%";
    } else if (location.pathname.indexOf("/websiteBuilder/") >= 0) {
      return "0px";
    } else if (location.pathname.indexOf("/cms/") >= 0) {
      return "0 2% 0 4%";
    } else {
      return "0 4%";
    }
  };

  return (
    <>
      {account && (projectsState.accountInfo || role === "BusinessOwner") ? (
        <>
          <DesignerContext.Provider
            value={{
              name: designerName,
              id: userId,
              accountId: accountId,
              email: designerEmail,
              role: role ?? "",
            }}
          >
            {role &&
              (role === "Designer" || role === "Administrator" ? (
                <LazyDesingerRouter
                  localAccountId={account.localAccountId}
                  role={role}
                />
              ) : (
                <>
                  {!projectsState.hideHeader && (
                    <TopHeader userId={account.localAccountId} />
                  )}
                  <div
                    className={"pageWrapper"}
                    style={{
                      margin: calculaePageMargin(),
                    }}
                  >
                    {isMobileOnly ? (
                      <>
                        <Routes>
                          <Route
                            path="/questionnaire/collaborator"
                            element={
                              <LazyFormViewerSwitcher
                                isClient={true}
                                isMobile={true}
                              />
                            }
                          />
                          <Route
                            path="/brief/collaborator"
                            element={
                              <LazyFormViewerSwitcher
                                isClient={true}
                                isMobile={true}
                              />
                            }
                          />
                          <Route
                            path="/anonymous/brief/collaborator"
                            element={
                              <LazyFormViewerSwitcher
                                isClient={true}
                                isMobile={true}
                              />
                            }
                          />
                          <Route
                            path="/cms/collaborator/:cmsId"
                            element={
                              <LazyCMSCollaboratorViewer isMobile={true} />
                            }
                          />

                          <Route
                            path="/projects/:projectId/collaborator/*"
                            element={<LazyCollaboratorDashBoard />}
                          />

                          <Route
                            path="*"
                            element={<LazyCollaboratorDashBoard />}
                          />
                        </Routes>
                        <ScrollToTopFab />
                      </>
                    ) : (
                      <Routes>
                        <Route
                          path="/questionnaire/collaborator"
                          element={<LazyFormViewerSwitcher isClient={true} />}
                        />
                        <Route
                          path="/brief/collaborator"
                          element={<LazyFormViewerSwitcher isClient={true} />}
                        />
                        <Route
                          path="/anonymous/brief/collaborator"
                          element={<LazyFormViewerSwitcher isClient={true} />}
                        />
                        <Route
                          path="/cms/collaborator/:cmsId/imageComments"
                          element={<LazyImageCommentViewers isClient={true} />}
                        />
                        <Route
                          path="/cms/collaborator/:cmsId"
                          element={<LazyCMSCollaboratorViewer />}
                        />
                        <Route
                          path="/projects/:projectId/*"
                          element={<LazyCollaboratorDashBoard />}
                        />
                        <Route
                          path="*"
                          element={<LazyCollaboratorDashBoard />}
                        />
                      </Routes>
                    )}
                  </div>
                </>
              ))}
          </DesignerContext.Provider>
        </>
      ) : (
        <Routes>
          <Route path="/logout" element={<LogoutPage />} />
        </Routes>
      )}
    </>
  );
};
