import React, { FC } from "react";
import {
  Section,
  Single,
  setCurrentPage,
  setCurrentSection,
} from "../../cmsSlice";
import { Drag } from "../DragDrop/Drag";
import { Box } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { BasicContextMenu } from "../../../basic/BasicContextMenu";
import { Drop } from "../DragDrop";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SmallEditSvg } from "../../../assets/project/smallEdit.svg";
import { ReactComponent as SmallTrashSvg } from "../../../assets/project/smallTrash.svg";
import { ReactComponent as SettingsSmallSVG } from "../../../assets/project/settingsSmall.svg";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useUpdateCmsWatchedItems } from "../../../hooks/useUpdateCmsWatchedItems";
import CheckIcon from "@mui/icons-material/Check";
export interface SideBarMenuItemProps {
  section: Section;
  sectionIndex: number;
  pageIndex: number;
  onEditSection(idx: number): void;
  onDeleteSection(event: any, idx: number): void;
  background?: string;
  pageName: string;
}

export const SideBarMenuItem: FC<SideBarMenuItemProps> = ({
  section,
  sectionIndex,
  pageIndex,
  onEditSection,
  onDeleteSection,
  pageName,
  background,
}) => {
  const dispatch = useAppDispatch();
  const cmsState = useSelector((state: RootState) => state.cmsData);
  const navigate = useNavigate();
  const [showSingles, setShowSingles] = React.useState<boolean>(false);
  const { updateWatchedItems } = useUpdateCmsWatchedItems();

  const navigateToSingle = (single: Single) => {
    updateWatchedItems(single.components.map((comp) => comp.mapKey));
    const singleIndex = `${single.singleKey}`;
    const element = document.getElementById(
      cmsState.inFormBuildMode
        ? `build-${singleIndex}`
        : `comment-${singleIndex}`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const sectionHasUnseenFormContent = () => {
    const sectionSingles = section.singles;
    const mapKeys = sectionSingles.flatMap((single) => {
      return single.components.map((comp) => comp.mapKey);
    });
    const unseenKeys = cmsState.cmsValuesByTypes?.markedChanges ?? [];
    // check if current mapkeys are in unseen keys
    const hasUnseen = mapKeys.some((key) => unseenKeys.includes(key));
    return hasUnseen;
  };

  const sectionHasSelectQuestion = () => {
    const mapKeys = section.singles.map((single) => single.singleKey);
    const unseenKeys = (cmsState.formSectionsQuestions ?? [])
      .map((r) => r.singlesIds)
      .flat();
    const hasUnseen = mapKeys.some((key) => unseenKeys.includes(key));
    return hasUnseen;
  };

  const singleHasUnseenFormContent = (single: Single) => {
    const mapKeys = single.components.map((comp) => comp.mapKey);
    const unseenKeys = cmsState.cmsValuesByTypes?.markedChanges ?? [];
    // check if current mapkeys are in unseen keys
    const hasUnseen = mapKeys.some((key) => unseenKeys.includes(key));
    return hasUnseen;
  };

  const SingleHasSelectQuestion = (single: Single) => {
    const unseenKeys = (cmsState.formSectionsQuestions ?? [])
      .map((r) => r.singlesIds)
      .flat();
    const hasUnseen = unseenKeys.includes(single.singleKey);
    return hasUnseen;
  };

  return (
    <Drag
      className="draggable-category"
      id={section.description}
      index={sectionIndex}
      inner={false}
    >
      <Box
        key={`section-nav-${pageIndex}-${sectionIndex}`}
        onClick={() => {
          !background
            ? navigate(`${pageName.toLowerCase().replace(" ", "")}#top`)
            : {};
          dispatch(setCurrentSection(sectionIndex));
          dispatch(setCurrentPage(pageIndex));
        }}
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: "8px 0px",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "30px",
          }}
        >
          <Box
            sx={{
              gap: "3px",
              display: "flex",
              width: "100%",
              fontSize: "14px",
              alignItems: "center",
              color:
                cmsState.currentSection === sectionIndex &&
                cmsState.currentPage === pageIndex
                  ? "#6c3dab"
                  : "#000",
              fontWeight:
                cmsState.currentSection === sectionIndex &&
                cmsState.currentPage === pageIndex
                  ? 500
                  : 300,
              "&:hover": { background: "#F5F8F7" },
            }}
          >
            {section.description}
            {cmsState.inFormBuildMode && sectionHasSelectQuestion() && (
              <CheckIcon style={{ fontSize: "16px" }} />
            )}
            {!cmsState.inFormBuildMode && sectionHasUnseenFormContent() && (
              <Box
                sx={{
                  marginLeft: "0px",
                  width: "5px",
                  height: "5px",
                  background: "red",
                  borderRadius: "10px",
                }}
              ></Box>
            )}
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            {cmsState.inEditMode &&
              section.type === "new" &&
              cmsState.inEditStructureMode && (
                <BasicContextMenu
                  title={""}
                  icon={<SettingsSmallSVG width="18px" />}
                  menuItems={[
                    {
                      label: "Edit",
                      icon: <SmallEditSvg />,
                      func: () => onEditSection(sectionIndex),
                    },
                    {
                      label: "Delete",
                      icon: <SmallTrashSvg />,
                      func: (e) => onDeleteSection(e, sectionIndex),
                    },
                  ]}
                />
              )}
            <Box onClick={() => setShowSingles(!showSingles)}>
              {showSingles ? (
                <KeyboardArrowUpIcon
                  fontSize="small"
                  sx={{ marginLeft: "5px", color: "#6c3dab" }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={{ marginLeft: "5px", color: "#6c3dab" }}
                />
              )}
            </Box>
          </Box>
        </Box>
        {showSingles && (
          <Drop
            key={section.description}
            id={section.description}
            type="droppable-item"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              {section.singles.map((single, index) => {
                return (
                  <Drag
                    className="draggable"
                    key={`${single.singleKey}`}
                    id={`${single.singleKey}`}
                    index={index}
                    inner={true}
                  >
                    <div
                      className="item"
                      onClick={() => navigateToSingle(single)}
                    >
                      {single.description}
                      {cmsState.inFormBuildMode &&
                        SingleHasSelectQuestion(single) && (
                          <CheckIcon style={{ fontSize: "14px" }} />
                        )}
                      {!cmsState.inFormBuildMode &&
                        singleHasUnseenFormContent(single) && (
                          <Box
                            sx={{
                              marginLeft: "0px",
                              width: "5px",
                              height: "5px",
                              background: "red",
                              borderRadius: "10px",
                            }}
                          ></Box>
                        )}
                    </div>
                  </Drag>
                );
              })}
            </Box>
          </Drop>
        )}
      </Box>
    </Drag>
  );
};
