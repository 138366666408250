import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { BasicMessagePopup } from "./BasicMessagePopup";
import { ReactComponent as ErrorSVG } from "../assets/errorIcon.svg";

export interface ErrorMessagePopupProps {
  message?: React.ReactNode | string;
  onClose(): void;
}

export const ErrorMessagePopup: FC<ErrorMessagePopupProps> = ({
  message,
  onClose,
}) => {
  return (
    <BasicMessagePopup
      icon={<ErrorSVG/>}
      onClose={onClose}
      open={true}
      disableAutoClose={true}
      size='compact'
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          flexDirection: "column",
          fontFamily: "Nunito",
          justifyContent: "center",
          fontSize: "14px",
          width: "70%",
          marginTop:'30px'
        }}
      >
        {message}
       </Box>
    </BasicMessagePopup>
  );
};
