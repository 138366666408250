export interface PlanCycle {
  price: number;
  currency: string;
  cycle: string;
  planId: string;
}

export interface PlanPakageInfo {
  name: string;
  description: string;
  cycles: PlanCycle[];
  projectCount: number;
  params: string[];
}

export const TestPackage: PlanPakageInfo = {
  name: "Test",
  description: "Designed to get to know Lynxi.",
  cycles: [
    {
      price: 0.1,
      currency: "$",
      cycle: "month",
      planId: "P-3UP35554TF8950019MTVA3CY",
    },
  ],
  projectCount: 3,
  params: [
    "Unlimited custom form templates",
    "Up to 2 Team Collaboration",
    "1 GB storage space",
    "Write content with Lynxi AI",
    "Email Branding",
    "Time Tracking",
    "Email support",
    "",
    "",
  ],
};

export const AValiablePackages = [
  // {
  //   name: "Test",
  //   description: "Designed to get to know Lynxi.",
  //   cycles: [
  //     { price: 0.1, currency: "$", cycle: "month", planId:'P-3UP35554TF8950019MTVA3CY' },
  //   ],
  //   projectCount: 3,
  //   params: [
  //     "Unlimited custom form templates",
  //     "Up to 2 Team Collaboration",
  //     "1 GB storage space",
  //     "Write content with Lynxi AI",
  //     "Email Branding",
  //     "Time Tracking",
  //     "Email support",
  //     "",
  //     ""
  //   ],
  // },
  {
    name: "Starter",
    description: "Designed to get to know Lynxi.",
    cycles: [
      {
        price: 0,
        currency: "$",
        cycle: "month",
        planId: "P-7CR254192W500452JMT2KRII",
      },
      {
        price: 0,
        currency: "$",
        cycle: "year",
        planId: "P-9CU82941WA698031RMT2KWQQ",
      },
    ],
    projectCount: 1,
    params: [
      "Unlimited custom form templates",
      "Team Collaboration – One member",
      "1 GB storage space",
      "Write content with Lynxi AI",
      "Advanced Checklists",
      "Email Branding",
      "Time Tracking",
      "Email support",
      "",
      "",
    ],
  },
  {
    name: "Business",
    description: "Designed to launch your business.",
    cycles: [
      {
        price: 8,
        currency: "$",
        cycle: "month",
        planId: "P-2V335223G90446726MT2KSBI",
      },
      {
        price: 7,
        currency: "$",
        cycle: "year",
        planId: "P-03519689BR227191TMT2KXBI",
      },
    ],
    projectCount: 4,
    params: [
      "Unlimited custom form templates",
      "Team Collaboration – Up to 2 members",
      "10 GB storage space",
      "Write content with Lynxi AI",
      "Advanced Checklists",
      "Email Branding",
      "Time Tracking",
      "Email support",
      "",
      "",
    ],
  },
  {
    name: "Premium",
    description: "Designed to grow your business.",
    cycles: [
      {
        price: 19,
        currency: "$",
        cycle: "month",
        planId: "P-67L78452JE500724BMT2KSPI",
      },
      {
        price: 16,
        currency: "$",
        cycle: "year",
        planId: "P-7FG968589S969520RMT2KXUY",
      },
    ],
    projectCount: 12,
    params: [
      "Unlimited custom form templates",
      "Team Collaboration – Up to 4 members",
      "20 GB storage space",
      "Write content with Lynxi AI",
      "Advanced Checklists",
      "Email Branding",
      "Time Tracking",
      "Email support",
      "",
      "",
    ],
  },
  {
    name: "Pro",
    description: "Designed to streamline your team workflow.",
    cycles: [
      {
        price: 39,
        currency: "$",
        cycle: "month",
        planId: "P-1V0403333T639051UMT2KS4I",
      },
      {
        price: 33,
        currency: "$",
        cycle: "year",
        planId: "P-3EP83917JY894490UMT2KYAY",
      },
    ],
    projectCount: -1,
    params: [
      "Unlimited custom form templates",
      "Team Collaboration – Up to 6 members",
      "1 TB storage space",
      "Write content with Lynxi AI",
      "Advanced Checklists",
      "Email Branding",
      "Time Tracking",
      "Email support",
      "Priority Support",
      "Customer Success Manager",
    ],
  },
  // {
  //   name: "Studio",
  //   description: "Designed to streamline high-scale businesses.",
  //   cycles: [
  //     { price: 99, currency: "$", cycle: "month", planId:'P-57350036444951815MT2KTII' },
  //     { price: 83, currency: "$", cycle: "year", planId:'P-3E404886N32903212MT2KYPQ' },
  //   ],
  //   projectCount: -1,
  //   params: [
  //     "Unlimited custom form templates",
  //     "Unlimited Team Collaboration",
  //     "1 TB storage space",
  //     "Write content with Lynxi AI",
  //     "Email Branding",
  //     "Time Tracking",
  //     "Email support",
  //     "Priority Support",
  //     "Customer Success Manager",
  //   ],
  // },
];
