import { Box, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { ContextMenuItem } from "./BasicContextMenu";

export interface BasicToolTipContextMenuProps {
  children: React.ReactElement
  menuItems: ContextMenuItem[];
}

export const BasicToolTipContextMenu: FC<BasicToolTipContextMenuProps> = ({
  children,
  menuItems,
}) => {
  return (
    <Tooltip
      placement={"bottom-start"}
      sx={{ zIndex: 5100 }}
      PopperProps = {{
        onPointerDown: e => {
          if (
            e.target instanceof Element &&
            !e.target.classList.contains("MuiTooltip-tooltip")
          ) {
            e.preventDefault();
          }
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            zIndex: 5100,
            color: "#000",
            padding: "10px 26px 10px 26px",
            boxShadow: "0 9px 15px 0 rgba(0, 0, 0, 0.07)",
            bgcolor: "#f5f8f7",
            "& .MuiTooltip-arrow": {
              color: "#f5f8f7",
            },
          },
        },
      }}
      title={
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {menuItems.map((item, index) => (
            <Box
              key={`contexc-menu-item-${index}`}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={item.func}
            >
              <Box sx={{ marginRight: "20px" }}>{item.icon}</Box>
              {item.label}
            </Box>
          ))}
        </Box>
      }
      arrow={true}
    >
      {children}
    </Tooltip>
  );
};
