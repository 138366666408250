export const SCHEDULER_URL_CMS_ID = '1003'
export const CUSTOMER_NAME_CMS_ID = '44'
export const CUSTOMER_EMAIL_CMS_ID = '8000'
export const CUSTOMER_PHONE_CMS_ID = '3'
export const BUSINESS_NAME_CMS_ID = '42'
export const BUSINESS_CATEGORY_CMS_ID = '39'
export const BUSINESS_SUB_CATEGORY_CMS_ID = '40'
export const BUSINESS_FOUNDED_CMS_ID = '41'
export const BUSINESS_EMAIL_CMS_ID = '2'
export const BUSINESS_PHONE_CMS_ID = '3'
export const BUSINESS_ADDRESS_CMS_ID = '1'
export const PROJECT_NAME_CMS_ID = '8001'
export const BUSINESS_CITY_CMS_ID = '70'
export const BUSINESS_COUNTRY_CMS_ID = '73'
export const CONTENT_HUB_ABOUT = '48'
export const CONTENT_HUB_TAGLINE = '50'
export const CONTENT_HUB_INTRO = '49'

export const CUSTOMER_OWNER_CMS_ID = '444'



export const SERVICE_CMS_ID = '167'
export const KEYWORDS_CMS_ID = '730'


export const DESIGNER_LANG_TOKEN = "$$Designer$$"
export const CLIENT_LANG_TOKEN = "$$Client$$"
export const BRIEF_NAME_LANG_TOKEN = "$$BriefName$$"
export const SOCIAL_NAME_LANG_TOKEN = "$$SocialName$$"


export const ContentHubSocialConnectedFields = [
  { cmsKey: CONTENT_HUB_ABOUT, social: ["Facebook"] },
  { cmsKey: CONTENT_HUB_INTRO, social: ["Facebook"] }]


export enum ContentHubFilterType {
  None = 0,
  Filled = 1,
  Lynxi = 2,
  Approved = 3,
  SelectedQuestions = 4
}

export enum CMSWorkingMode {
  Edit = 0,
  View = 1,
  ContentReview =2,
  FormReview = 3
}


export enum BriefStatusStages {
  New = 'new',
  InProgress = "In Progress",
  InProgress2 = "InProgress",
  BriefSend = "BriefSent",
  Opened = 'Opened',
  Working = 'Working',
  Finished = 'Finished',
  ContentReview = 'ContentReview',
  Closed = 'Closed'
}

export enum CMSShareStatusStages {
  Shared = 'Shared',            // Content shared with client but client did not open yet.
  Viewed = 'Viewed',            // Client viewed the shared content
  Reopened = 'Reopened',
  InProgress ='InProgress',
  PartiallyApproved = 'PartiallyApproved', // Client partially approved the content
  Approved = 'Approved'         // Client approved the content
}

export enum CollaborationTypeEnum
    {
        None = 0,
        Viewer = 8,
        Collaborator = 64,
        CoOwner = 128,
        Owner = 255
    }

export const DAYS_OF_THE_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];


export const COUNTRY_LIST = [
"Afghanistan",
"Albania",
"Algeria",
"American Samoa",
"Andorra",
"Angola",
"Anguilla",
"Antarctica",
"Antigua and Barbuda",
"Argentina",
"Armenia",
"Aruba",
"Australia",
"Austria",
"Azerbaijan",
"Bahamas",
"Bahrain",
"Bangladesh",
"Barbados",
"Belarus",
"Belgium",
"Belize",
"Benin",
"Bermuda",
"Bhutan",
"Bolivia",
"Bosnia and Herzegowina",
"Botswana",
"Bouvet Island",
"Brazil",
"British Indian Ocean Territory",
"Brunei Darussalam",
"Bulgaria",
"Burkina Faso",
"Burundi",
"Cambodia",
"Cameroon",
"Canada",
"Cape Verde",
"Cayman Islands",
"Central African Republic",
"Chad",
"Chile",
"China",
"Christmas Island",
"Cocos (Keeling) Islands",
"Colombia",
"Comoros",
"Congo",
"Congo, the Democratic Republic of the",
"Cook Islands",
"Costa Rica",
"Cote d'Ivoire",
"Croatia (Hrvatska)",
"Cuba",
"Cyprus",
"Czech Republic",
"Denmark",
"Djibouti",
"Dominica",
"Dominican Republic",
"East Timor",
"Ecuador",
"Egypt",
"El Salvador",
"Equatorial Guinea",
"Eritrea",
"Estonia",
"Ethiopia",
"Falkland Islands (Malvinas)",
"Faroe Islands",
"Fiji",
"Finland",
"France",
"France, Metropolitan",
"French Guiana",
"French Polynesia",
"French Southern Territories",
"Gabon",
"Gambia",
"Georgia",
"Germany",
"Ghana",
"Gibraltar",
"Greece",
"Greenland",
"Grenada",
"Guadeloupe",
"Guam",
"Guatemala",
"Guinea",
"Guinea-Bissau",
"Guyana",
"Haiti",
"Heard and Mc Donald Islands",
"Holy See (Vatican City State)",
"Honduras",
"Hong Kong",
"Hungary",
"Iceland",
"India",
"Indonesia",
"Iran (Islamic Republic of)",
"Iraq",
"Ireland",
"Israel",
"Italy",
"Jamaica",
"Japan",
"Jordan",
"Kazakhstan",
"Kenya",
"Kiribati",
"Korea, Democratic People's Republic of",
"Korea, Republic of",
"Kuwait",
"Kyrgyzstan",
"Lao People's Democratic Republic",
"Latvia",
"Lebanon",
"Lesotho",
"Liberia",
"Libyan Arab Jamahiriya",
"Liechtenstein",
"Lithuania",
"Luxembourg",
"Macau",
"Macedonia, The Former Yugoslav Republic of",
"Madagascar",
"Malawi",
"Malaysia",
"Maldives",
"Mali",
"Malta",
"Marshall Islands",
"Martinique",
"Mauritania",
"Mauritius",
"Mayotte",
"Mexico",
"Micronesia, Federated States of",
"Moldova, Republic of",
"Monaco",
"Mongolia",
"Nunito",
"Morocco",
"Mozambique",
"Myanmar",
"Namibia",
"Nauru",
"Nepal",
"Netherlands",
"Netherlands Antilles",
"New Caledonia",
"New Zealand",
"Nicaragua",
"Niger",
"Nigeria",
"Niue",
"Norfolk Island",
"Northern Mariana Islands",
"Norway",
"Oman",
"Pakistan",
"Palau",
"Panama",
"Papua New Guinea",
"Paraguay",
"Peru",
"Philippines",
"Pitcairn",
"Poland",
"Portugal",
"Puerto Rico",
"Qatar",
"Reunion",
"Romania",
"Russian Federation",
"Rwanda",
"Saint Kitts and Nevis",
"Saint LUCIA",
"Saint Vincent and the Grenadines",
"Samoa",
"San Marino",
"Sao Tome and Principe",
"Saudi Arabia",
"Senegal",
"Seychelles",
"Sierra Leone",
"Singapore",
"Slovakia (Slovak Republic)",
"Slovenia",
"Solomon Islands",
"Somalia",
"South Africa",
"South Georgia and the South Sandwich Islands",
"Spain",
"Sri Lanka",
"St. Helena",
"St. Pierre and Miquelon",
"Sudan",
"Suriname",
"Svalbard and Jan Mayen Islands",
"Swaziland",
"Sweden",
"Switzerland",
"Syrian Arab Republic",
"Taiwan, Province of China",
"Tajikistan",
"Tanzania, United Republic of",
"Thailand",
"Togo",
"Tokelau",
"Tonga",
"Trinidad and Tobago",
"Tunisia",
"Turkey",
"Turkmenistan",
"Turks and Caicos Islands",
"Tuvalu",
"Uganda",
"Ukraine",
"United Arab Emirates",
"United Kingdom",
"United States",
"United States Minor Outlying Islands",
"Uruguay",
"Uzbekistan",
"Vanuatu",
"Venezuela",
"Viet Nam",
"Virgin Islands (British)",
"Virgin Islands (U.S.)",
"Wallis and Futuna Islands",
"Western Sahara",
"Yemen",
"Yugoslavia",
"Zambia",
"Zimbabwe"
];