import { Box, TextField } from "@mui/material";
import React, { FC } from "react";

export interface EditServiceCompLabelsProps {
  labels: string[];
  hiddenLabels: string[];
  onSetLabels(labels: string[]): void;
}

export const EditServiceCompLabels: FC<EditServiceCompLabelsProps> = ({
  labels,
  onSetLabels,
  hiddenLabels,
}) => {
  const [startLabels, setStartLabels] = React.useState<string[]>(labels);

  const setLabel = (idx: number, value: string) => {
    const newOptions = [...labels];
    newOptions[idx] = value;
    onSetLabels(newOptions);
  };

  const onReset = () => {
    onSetLabels(startLabels);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "10px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>Labels:</Box>
        <Box
          sx={{
            cursor: "pointer",
            color: "#7c48c2",
            fontSize: "14px",
          }}
          onClick={() => onReset()}
        >
          Reset all
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {labels.map((option: string, idx: number) => (
          hiddenLabels.includes(option) ? null :
          <TextField
            key={`label-${idx}`}
            sx={{
              flexGrow: 1,
              width: "100%",
              background: "#f9f9f9",
              "& input.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
              },
            }}
            inputProps={{ className: "dropDownOther" }}
            placeholder={"Enter new item"}
            rows={1}
            maxRows={1}
            value={option ?? ""}
            onChange={(e) => {
              setLabel(idx, e.target.value);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
