import { Box, Divider, Modal } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { CMSWorkingMode, ContentHubFilterType } from "../../utils/Constants";
import { CMSState } from "../cmsSlice";
import { SectionComp } from "../components/SectionComp";
import useAsyncEffect from "use-async-effect";
import { SuccessMessagePopup } from "../../Massages/SuccessMessagePopup";
import { ReactComponent as NewCheckPopupSVG } from "../../assets/cms/newCheck.svg";
import "../cms.scss";
import { CMSSideBar } from "../SideBar/CMSSideBar";
import { breakTextToLines } from "../../projects/ProjectUIHelper";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
  maxHeight: "95vh",
};

export interface ContentHubPopupBaseProps {
  show: boolean;
  onClose: () => void;
  header: React.ReactNode;
  noSideBar?: boolean;
  onLoading?: boolean;
}

const ContentHubPopupBase: FC<ContentHubPopupBaseProps> = ({
  show,
  onClose,
  header,
  noSideBar,
  onLoading
}) => {
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const [showWelcomeMessage, setShowWelcomeMessage] =
    React.useState<boolean>(false);

  const handleClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  useAsyncEffect(async () => {
    if (!noSideBar) {
      setShowWelcomeMessage(true);
    }
  }, []);

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        sx={{ zIndex: 2400 }}
        hideBackdrop={false}
        disableEnforceFocus
      >
        <Box sx={style} className={"formPopUpSize"}>
        {onLoading && (
            <BasicPageBlocker show={onLoading} />
          )}
          <Box
            sx={{
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f4f8f7",
              flexDirection: "column",
              paddingBottom: "10px",
            }}
          >
            <Box
              sx={{
                paddingTop: "30px",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                width: "100%",
                display: "flex",
                paddingBottom: "20px",
                backgroundColor: "#f4f8f7",
              }}
            >
              {header}
            </Box>
            <Divider sx={{ margin: "0 40px 20px" }} flexItem />
            <Box sx={{ display: "flex", width: "100%" }}>
              {!noSideBar && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 0 0 30px",
                  }}
                >
                  <CMSSideBar background="#f4f8f7" />
                </Box>
              )}
              <div className={"mainPage"} style={{ background: "#f5f8f7" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    paddingTop: "20px",
                    flexDirection: "column",
                    flexGrow: 1,
                    height: "calc(100vh - 150px)",
                    overflowY: "auto",
                    margin: "0 auto",                
                    maxWidth: '1100px'
                  }}
                  id={"containerDivPopup"}
                >
                  {cmsState.cmsFilterMode !== ContentHubFilterType.None || cmsState.inFormBuildMode === false ? (
                    <>
                      {cmsState.pages.length > 0 &&
                        cmsState.pages[cmsState.currentPage].sections.length >
                          0 &&
                        cmsState.pages[cmsState.currentPage].sections.map(
                          (section, idx) => (
                            <SectionComp
                              key={`section-${idx}-${cmsState.metaDataId}`}
                              active={true}
                              section={section}
                              sectionIdx={idx}
                              showFillMode={cmsState.inFormBuildMode}
                              changeIdForSearch={true}
                              mode={CMSWorkingMode.FormReview}
                              compareByName={true}
                            />
                          )
                        )}
                    </>
                  ) : (
                    cmsState.pages.length > 0 &&
                    cmsState.pages[cmsState.currentPage].sections.length >
                      0 && (
                      <SectionComp
                        compareByName={true}
                        key={`section-${cmsState.currentSection}-${cmsState.metaDataId}`}
                        active={true}
                        section={
                          cmsState.pages[cmsState.currentPage].sections[
                            cmsState.currentSection
                          ]
                        }
                        sectionIdx={cmsState.currentSection}
                        changeIdForSearch={true}
                        showFillMode={false}
                        mode={CMSWorkingMode.FormReview}
                      />
                    )
                  )}
                </Box>
              </div>
            </Box>
          </Box>
          {showWelcomeMessage && (
            <SuccessMessagePopup
              open={showWelcomeMessage}
              title={"Create easy forms in minutes"}
              icon={<NewCheckPopupSVG />}
              message={
                  breakTextToLines("You can choose or create new questions\nyou want to ask your client.")
              }
              onClose={() => setShowWelcomeMessage(false)}
              onContinue={() => setShowWelcomeMessage(false)}
              continueAction={"Got it"}
              showAction={false}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ContentHubPopupBase;
