import * as React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";

import { useMsal } from "@azure/msal-react";
import { getAccessTokenSilence } from "../../auth/auth";
import { socialApi } from "../../api/socialApi";

export const GetAccessCodeToSocial: React.FC = () => {

  const [code, setCode] = useState<string>("")
  const [projectId, setProjectId] = useState<string>("")

  const navigate = useNavigate()
  const msalInstance = useMsal()
  const [searchParams] = useSearchParams()

 const checkForLinkedInLoginInfo = async () => {
    const _code = searchParams.get('code')
    if (_code) {
      setCode(_code)
    }
    const _projectId = searchParams.get('projectId')
    if (_projectId) {
      setProjectId(_projectId)
    }
    
  }

  useAsyncEffect( () => {
    checkForLinkedInLoginInfo()
  }, [])

  useAsyncEffect( async () => {
    if (code !== '' && projectId !== '') {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        console.log('code', code)
        await socialApi.connectToLinkedIn(code, projectId, token.accessToken)
        navigate(`/projects/${projectId}/details?connected=linkedIn`)
      }
    }
  }, [code, projectId])


  return (
    <>
    &nbsp;
    </>
  )
}
