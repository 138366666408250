import { Box, Grid, Rating } from "@mui/material";
import * as React from "react";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import parse from "html-react-parser";

export interface RatingCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const RatingComp: React.FC<RatingCompProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly,
}) => {
  const onChange = (value: number) => {
    const updateComp = { ...comp, value: value };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  return (
    <Grid item xs={comp.fullWidth ? 12 : 6}>
      <Box
        sx={{ marginBottom: "5px", fontSize: "14px", fontWeight: "500",}}
        className={"compDescription"}
      >
        <div>{parse(comp.description)}</div>
      </Box>
      <Rating
        disabled={readonly}
        value={comp.value}
        onChange={(event, newValue) => {
          onChange(newValue ?? -1);
        }}
      />
    </Grid>
  );
};
