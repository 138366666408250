import { useMsal } from "@azure/msal-react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { UserSiteImage } from "../api/cmsApi";
import { SharedFile, projectsApi } from "../api/projectsApi";
import { UploadDocumentResponse } from "../api/uploadApi";
import { getAccessTokenSilence } from "../auth/auth";
import { ShareDialogWithStepPopup } from "../basic/ShareDialogWithStepPopup";
import ProjectUtils from "../projects/ProjectUtils";
import { Project } from "../projects/projectsSlice";
import TreeUtils from "../utils/TreeUtils";
import { useDesginerInfo } from "../hooks/useDesginerName";
import { DragAndDropInput } from "../basic/DragAndDropInput";
import { Dayjs } from "dayjs";

export interface ShareFilesPopupProps {
  onClose(showOnlySaveMessage: boolean): void;
  onSharedSuccess(): void;
  project: Project;
  fileToShare?: SharedFile;
  title: string;
  onlyInvite?: boolean;
}

export const ShareFilesPopup: React.FC<ShareFilesPopupProps> = ({
  onClose,
  onSharedSuccess,
  project,
  fileToShare,
  title,
  onlyInvite,
}) => {
  const [emailSubject, setEmailSubject] = React.useState<string>("");
  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);
  const [sharedFiles, setSharedFiles] = React.useState<SharedFile[]>([]);
  const [firstStage, setFirstStage] = React.useState<boolean>(true);

  const msalInstance = useMsal();
  const { getDesignerName } = useDesginerInfo();

  useAsyncEffect(async () => {
    if (fileToShare || onlyInvite) {
      setFirstStage(false);
      if (fileToShare) {
        setSharedFiles([fileToShare]);
      }
    }
  }, [fileToShare, onlyInvite]);

  const handleUploadClick = async (files: File[]) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      try {
        setOnProcessing(true);
        let imageList: UserSiteImage[] = [];
        const projectId = project.id;
        const allPromises: Promise<UploadDocumentResponse>[] = files.map(
          (file: File) => {
            return ProjectUtils.uploadSharedFileToAzureStorage(
              file,
              token.accessToken,
              projectId
            );
          }
        );

        const allResponse = await Promise.all(allPromises);
        imageList = allResponse.map((res, idx) => {
          return {
            description: TreeUtils.getFileNameWithoutExt(files[idx]),
            url: res.url,
            imageId: "",
          };
        });
        if (imageList.filter((r) => r.url !== "").length > 0) {
          const reportFilesResponse = await projectsApi.reportSharedFileInfo(
            project.id,
            imageList[0].description,
            imageList[0].url,
            token.accessToken
          );
          setSharedFiles([...sharedFiles, reportFilesResponse]);
          onClose(true);
        }
        setOnProcessing(false);
      } catch (e) {
        setOnProcessing(false);
      }
    }
  };

  useAsyncEffect(async () => {
    setEmailSubject(
      `${getDesignerName()} ${
        onlyInvite ? "invite you to share files" : "shared a new file"
      }`
    );
  }, []);

  const onShare = async (
    subject: string,
    message: string,
    emails: string[],
    isAnonymous: boolean,
    validForInDays: number,
    token: string,
    dueDate?: Dayjs,
  ) => {
    let messageFileName = "";
    if (sharedFiles.length === 1) {
      messageFileName = sharedFiles[0].description;
    }

    const mailResult = await projectsApi.shareFilesWithClients(
      project.id,
      subject,
      message === "" ? messageFileName : message,
      emails,
      sharedFiles.map((r) => r.id),
      token
    );
    return mailResult;
  };

  return (
    <>
      <ShareDialogWithStepPopup
        onClose={() => onClose(sharedFiles.length > 0 && !fileToShare)}
        onShare={onShare}
        onSharedSuccess={onSharedSuccess}
        copyLinkId={project.id}
        allowAnonymous={false}
        firstStage={firstStage}
        hideCloseOnFirstStage={true}
        emailMessageDefault={""}
        blockUser={onProcessing}
        emailSubjectDefault={emailSubject}
        shareBtnTitle={onlyInvite ? "Invite" : "Share"}
        firstStageComp={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box sx={{ marginTop: "20px" }}>
              Upload and share any relevant file for your project.
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                background: "#F9F9F9",
                height: "140px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
                border: "solid 1px #e9ebea",
                marginTop: "40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Backdrop
                    sx={{
                      color: "#fff",
                      position: "absolute",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={onProcessing}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop> 
                {sharedFiles.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        fontWeight: "500",
                        height: "100%",
                        justifyContent: "flex-start",
                        margin: "0 5px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`/images/files-type/${sharedFiles[0].link
                          .split(".")
                          .pop()
                          ?.replace(" ", "_")}-file.svg`}
                      />
                      {sharedFiles[0].description}
                    </Box>
                ) : (
                    <Box sx={{ display: "flex", width: "100%", height:'100%', alignItems:'center' }}>
                      <DragAndDropInput
                        onFileSelect={handleUploadClick}
                        onlyUpload={false}
                        allfiles={true}
                        singleFile={false}
                      />
                    </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                marginTop: "50px",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  color: "#000000",
                }}
                onClick={() => onClose(false)}
              >
                Cancel
              </Box>
            </Box>
          </Box>
        }
        project={project}
        title={title}
        header={
          onlyInvite
            ? "send invite to client"
            : "share files with your client's"
        }
      />
    </>
  );
};
