import { Box, Divider } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BriefData, Project } from "../../projectsSlice";
import * as React from "react";
import { ReactComponent as FinishedBriefSvg } from "../../../assets/project/drawerFinishedBrief.svg";
import { ColorButton } from "../../ProjectUIHelper";

export interface BriefDoneStageProps {
  project: Project;
  brief: BriefData;
}

export const BriefDoneStage: FC<BriefDoneStageProps> = ({ project, brief }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
        Your client submitted the brief!
        <br />
        <br />
        Please review it and leave any comments.
        <br />
        <br />
        Once the brief is ready, add it to the Content Hub.
      </Box>

      <Divider sx={{ marginTop: "40px" }} />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          margin: "15px 0 17px",
          color: "#6c3dab",
          cursor: "pointer",
          justifyContent: "flex-end",
          fontSize: "14px",
        }}
      >
          <ColorButton sx={{padding: '4px 15px' }}
            onClick={() =>
              navigate(
                `/projects/${project.id}/questionnaire/client?q=${brief.id}`
              )
            }
          >
            Review form
          </ColorButton>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        <FinishedBriefSvg />
      </Box>
    </>
  );
};
