import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { without } from "lodash";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import { CustomCheckBox } from "../../projects/ProjectUIHelper";
import { CMSFieldCopyBtn } from "../../basic/CMSFieldCopyBtn";
import { ReactComponent as CheckSvg } from "../../assets/checkbox.svg";

export interface MultiDropDownProps {
  comp: CMSComponent;
  location: PositionIndex;
  padding: number;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const MultiDropDown: React.FC<MultiDropDownProps> = ({
  comp,
  padding,
  location,
  onUpdateComp,
  readonly,
}) => {
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);

  useAsyncEffect(async () => {
    setSelectedValues(comp.value);
  }, [comp.value]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedValues(event.target.value as string[]);
    const updateComp = {
      ...comp,
      value: [...(event.target.value as string[])],
    };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    const newValues = without(selectedValues, value);
    setSelectedValues(newValues);
    const updateComp = { ...comp, value: [...newValues] };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  return (
    <Grid item xs={padding}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-mutiple-chip-checkbox-label"
              sx={{ top: "-6px", "&.MuiInputLabel-shrink": { top: "0px" } }}
            >
              {comp.description}
            </InputLabel>
            <Select
              disabled={readonly ? true : false}
              multiple
              label={comp.description}
              value={selectedValues}
              onChange={handleChange}
              displayEmpty = {comp.placeHolder &&
                comp.placeHolder !== ""? true : false}
              MenuProps={{
                disablePortal: true,
                style: { width: "150px", height: "200px" },
              }}
              renderValue={(selected) => {
                if (
                  (selected as string[]).length === 0 &&
                  comp.placeHolder &&
                  comp.placeHolder !== ""
                ) {
                  return <em>{comp.placeHolder}</em>;
                }
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        clickable
                        deleteIcon={
                          readonly ? (
                            <></>
                          ) : (
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          )
                        }
                        sx={{ marginRight: "5px" }}
                        onDelete={(e) => handleDelete(e, value)}
                      />
                    ))}
                  </Box>
                );
              }}
            >
              {comp.placeHolder && comp.placeHolder !== "" && (
                <MenuItem disabled value="">
                  <em>{comp.placeHolder}</em>
                </MenuItem>
              )}
              {comp.options &&
                comp.options.map((name) => (
                  <MenuItem key={name} value={name}>
                    <CustomCheckBox
                      checkedIcon={
                        <CheckSvg style={{ transform: "scale(0.7)" }} />
                      }
                      checked={selectedValues.includes(name)}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <CMSFieldCopyBtn value={comp.value.join(", ")} />
      </Box>
    </Grid>
  );
};
