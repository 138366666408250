import * as React from "react";
import { HelpToolTipOnHover } from "../basic/HelpToolTipOnHover";

import { ReactComponent as BrandingSvg } from "../assets/project/branding.svg";
import { Box, CircularProgress } from "@mui/material";
import { ColorButton } from "../projects/ProjectUIHelper";
import { useAppDispatch } from "../app/hooks";
import { setShowAccountPopup } from "../projects/projectsSlice";
import { useDesginerInfo } from "../hooks/useDesginerName";

export interface ShareBtnWithBrandingCheckProps {
  onContinue(): void;
  btnText: string;
  disabled?: boolean;
  onProcessing?: boolean;
  onShowAccount?(): void;
}

export const ShareBtnWithBrandingCheck: React.FC<
  ShareBtnWithBrandingCheckProps
> = ({ onContinue, btnText, disabled, onProcessing, onShowAccount }) => {
  const dispatch = useAppDispatch();
  const { isBrandingExists } = useDesginerInfo();

  return (isBrandingExists() || disabled) ? (
    <Box sx={{ m: 1, position: "relative" }}>
      <ColorButton
        disabled={disabled}
        onClick={() => {
          if (onProcessing) return;
          onContinue();
        }}
      >
        {btnText}
      </ColorButton>
      {onProcessing && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
            color: "#6c3dab",
          }}
        />
      )}
    </Box>
  ) : (
    <HelpToolTipOnHover
      position="bottom-end"
      message={
        "Before sharing the form with your clients, personalize Lynxi with your business info and logo."
      }
      icon={<BrandingSvg />}
      size={350}
      actionNode={
        <Box sx={{ m: 1, position: "relative" }}>
          <ColorButton
            sx={{ padding: "5px 21.5px 5px 20.5px" }}
            disabled={disabled || onProcessing}
          >
            {btnText}
          </ColorButton>
          {onProcessing && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
                color: "#6c3dab",
              }}
            />
          )}
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "30px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            fontWeight: 400,
            cursor: "pointer",
            textAlign: "center",
            textDecoration: "underline",
          }}
          onClick={() => {
            localStorage.setItem("showBranding", "true");
            onContinue();
          }}
        >
          Proceed without it
        </Box>
        <Box
          sx={{
            fontWeight: 600,
            cursor: "pointer",
            textAlign: "right",
            textDecoration: "underline",
          }}
          onClick={() => {
            localStorage.setItem("showBranding", "true");
            if (onShowAccount) {
              onShowAccount();
            } else {
              dispatch(setShowAccountPopup(true));
            } 
          }}
        >
          Add your brand
        </Box>
      </Box>
    </HelpToolTipOnHover>
  );
};
