import { useMsal } from "@azure/msal-react";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { useTranslation } from "react-i18next";
import { Box, Modal } from "@mui/material";
import { getAccessTokenSilence } from "../../../auth/auth";
import { SecondStageMsg } from "../../../cms/AIManager/SecondStageMsg";
import { adminApi } from "../../../api/adminApi";
import { ReactComponent as FailedSvg } from "../../../assets/project/shareMailFail.svg";
import { ColorButton } from "../../../projects/ProjectUIHelper";
import { CMSState } from "../../../cms/cmsSlice";
import { get } from "lodash";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

export interface AiDialogForPageSeoProps {
  startAiProcess: boolean;
  onFinish(postText: string, aiFailed?: boolean): void;
  pageName: string;
  mapKey: string;
  projectId: string;
  overRideTitle: string;
  timeOut?: number;
}

const AiDialogForPageSeo: React.FC<AiDialogForPageSeoProps> = ({
  startAiProcess,
  pageName,
  onFinish,
  mapKey,
  projectId,
  overRideTitle,
  timeOut,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [aiRunFinished, setAiRunFinished] = React.useState<boolean>(false);
  const [timerIsOn, setTimerIsOn] = React.useState<boolean>(false);
  const [startCounting, setStartCounting] = React.useState<boolean>(false);
  const [postText, setPostText] = React.useState<string>("");
  const { t } = useTranslation();
  const msalInstance = useMsal();
  const [aiFailed, setAiFailed] = React.useState<boolean>(false);
  const [showAiFailedMessage, setShowAiFailedMessage] =
    React.useState<boolean>(false);
  const cmsState: CMSState = useAppSelector(
      (state: RootState) => state.cmsData
    );
  

  useAsyncEffect(async () => {
    if (startAiProcess) {
      checkAiStatus();
    }
  }, [startAiProcess]);

  const checkAiStatus = async () => {
    setAiRunFinished(false);
    setStartCounting(false);
    setTimerIsOn(false);

    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const aiItemList = await adminApi.getAllAiItems(token.accessToken);
      const aiItemsForCMS = aiItemList.items.filter(
        (item) =>
          item.cmsIds.length > 0 &&
          item.cmsIds.indexOf(mapKey) > -1 &&
          item.tokens.length > 0
      );
      // SUB_CATEGORY_NAME|#|COMPANY_NAME|#|COMPANY_ADDRESS|#|COMPANY_WEBSITE|#|PAGE_NAME
      if (aiItemsForCMS.length === 1) {
        setOpen(true);
        setStartCounting(true);
        const dic = cmsState.cmsItems;//   TreeUtils.extractDicFromCms(cmsState.pages);
        const subCategory = get(get(dic, "40", ""), "value", "");
        const businessName = get(dic, "42", "");
        const companyAddress = get(dic, "1", "");
        const companyWebsite = get(dic, "5", "");
        const usedAnswers: { [id: string]: string } = {
          SUB_CATEGORY_NAME: subCategory,
          COMPANY_NAME: businessName,
          COMPANY_ADDRESS: companyAddress,
          COMPANY_WEBSITE: companyWebsite,
          PAGE_NAME: pageName,
        };
        const aiResponse = await adminApi.processAiTemaplate(
          cmsState.project ? cmsState.project.id :'',
          { id: aiItemsForCMS[0].id, data: usedAnswers, cmsIds: [] },
          token.accessToken
        )
        if (aiResponse && aiResponse.length > 0) {
          setPostText(aiResponse.join(" ").trim());
          setAiRunFinished(true);
        } else if (!aiResponse) {
          setAiFailed(true);
        } else {
          setPostText("");
          setAiFailed(true);
          setAiRunFinished(true);
        }
      } else {
        setPostText("");
        setAiFailed(true);
        setAiRunFinished(true);
      }
    }
  };

  const onClose = () => {
    if (aiFailed) {
      setShowAiFailedMessage(true);
    } else {
      setOpen(false);
      onFinish(postText, aiFailed);
    }
  };

  const onCloseAfterFail = () => {
    setOpen(false);
    onFinish(postText, aiFailed);
  };

  useAsyncEffect(async () => {
    if (startCounting) {
      setTimerIsOn(true);
      setTimeout(
        () => {
          setTimerIsOn(false);
        },
        timeOut ? timeOut : 8000
      );
    }
  }, [startCounting]);

  useAsyncEffect(async () => {
    if (!timerIsOn && (aiRunFinished || aiFailed)) {
      onClose();
    }
  }, [timerIsOn]);

  useAsyncEffect(async () => {
    if ((aiRunFinished || aiFailed) && !timerIsOn) {
      onClose();
    }
  }, [aiRunFinished, aiFailed]);

  return (
    <>
      <Modal open={open} closeAfterTransition sx={{ zIndex: 4000 }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            outline: "none",
            width: "600px",
          }}
        >
          <Box
            sx={{
              minHeight: "340px",
              width: "100%",
              bgcolor: "#f5f8f7",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "30px",
            }}
          >
            {showAiFailedMessage ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                  textAlign: "center",
                  flexDirection: "column",
                  fontFamily: "Nunito",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: "40px",
                  paddingBottom: "20px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FailedSvg />
                </Box>
                <Box sx={{ fontWeight: 500, marginTop: "32px" }}>
                  {t("cms.progress_popup.ai_failed.full_message")}
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                    alignSelf: "center",
                    justifyContent: "center",
                    color: "#6c3dab",
                    padding: "35px 0px 30px",
                  }}
                  onClick={() => onClose()}
                >
                  <ColorButton onClick={() => onCloseAfterFail()}>
                    Continue
                  </ColorButton>
                </Box>
              </Box>
            ) : (
              <SecondStageMsg overRideTitle={overRideTitle} />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AiDialogForPageSeo;
