import { Box, Grid, TextField } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { CmsComment } from "../CmsComments/CmsComment";
import {
  CMSGalleryComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import { RenderImage } from "./RenderImage";
import parse from "html-react-parser";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

export interface GalleryCompProps {
  comp: CMSGalleryComponent;
  location: PositionIndex;
  onShowComment?(e: any, commentKey: string): void;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
  singleKey: number;
  onlyImages?: boolean;
}

export const GalleryComp: React.FC<GalleryCompProps> = ({
  comp,
  location,
  onUpdateComp,
  onShowComment,
  singleKey,
  readonly,
  onlyImages,
}) => {
  const cmsState = useAppSelector((state: RootState) => state.cmsData);

  const renderImage = (src: string, index: number, type?: string) => {
    return (
      <RenderImage
        value={src}
        index={index}
        center={true}
        updateImage={updateImage}
        removeImage={removeImage}
        readonly={readonly}
        contentType={type}
        allfiles={comp.allowAllfiles}
        newWidth={comp.hasFullReview ? "250px" : undefined}
      />
    );
  };

  const updateImage = (index: number, img: string, description?: string) => {
    const deepClone = _.cloneDeep(comp);
    if (index === -1) {
      deepClone.value = [
        ...deepClone.value,
        { src: img, id: `${new Date().getTime()}`, title: description ?? "" },
      ];
    } else {
      deepClone.value[index].src = img;
      deepClone.value[index].title = description ?? "";
    }
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const removeImage = (index: number) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.value.splice(index, 1);
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const onTextChange = (e: any, index: number) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.value[index].title = e.target.value;
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            fontFamily: "Nunito",
            marginBottom: "20px",
            fontWeight: "500",
            fontSize: "14px",
          }}
          className={"compDescription"}
        >
          <div>{parse(comp.description)}</div>
        </Box>
        {comp.fullWidth ? (
          <Grid container spacing={3}>
            {comp.value.map((img, i) => (
              <Grid item xs={12} key={`image-${i}`}>
                {renderImage(img.src, i)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box border={"1px solid #808080"} padding={"10px 10px"}>
            <Grid container spacing={2} textAlign={"center"}>
              {comp.value.map((img, i) => (
                <Grid
                  item
                  xs={cmsState.sideBarMode ? 12 : comp.hasFullReview ? 6 : 4}
                  key={`image-${i}`}
                  justifyContent={"center"}
                  sx={{ position: "relative" }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {renderImage(img.src, i, img.type)}
                      {!onlyImages && (
                        <Box marginTop={"10px"}>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Description"
                            multiline
                            maxRows={4}
                            value={img.title}
                            disabled={readonly}
                            onChange={(e) => onTextChange(e, i)}
                          />
                        </Box>
                      )}
                    </Box>
                    <CmsComment
                      onShowComment={(e) =>
                        onShowComment
                          ? onShowComment(e, `${singleKey}__${i}`)
                          : undefined
                      }
                      singleKey={
                        comp.hasFullReview
                          ? `${singleKey}__${img.id}`
                          : `${singleKey}__${i}`
                      }
                      isClient={readonly}
                      noComment={!onShowComment}
                      noText={true}
                    />
                  </Box>
                </Grid>
              ))}
              {!readonly && (
                <Grid
                  item
                  xs={cmsState.sideBarMode ? 12 : comp.hasFullReview ? 6 : 4}
                >
                  {renderImage("", -1)}
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </Grid>
    </>
  );
};
