import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { ProjectState, setAccountInfo } from "../projects/projectsSlice";
import { TaskSavedList } from "../api/tasksApi";
import { useMsal } from "@azure/msal-react";
import { getAccessTokenSilence } from "../auth/auth";
import { backofficeApi } from "../api/backofficeApi";

export const useAccountSettingsData = () => {
  const projectState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const dispatch = useAppDispatch();
  const instance = useMsal();

  const getAccountDateFormat = () => {
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(
        projectState.accountInfo.additionalData ?? "{}"
      );
      const dateFormat = _.get(additionalData, "dateFormat", ["DD/MM/YYYY"]);
      return dateFormat[0];
    } else return "DD/MM/YYYY";
  };

  const getAccountProjectsLabels = () => {
    const defaultList = [
      { label: "On Hold", color: "#929292" },
      { label: "In Progress", color: "#12d87c" },
      { label: "Pending", color: "#f6a111" },
      { label: "Canceled", color: "#e4572e" },
      { label: "Client Delay", color: "#955f05" },
      { label: "Ghosted", color: "#000" },
      { label: "Completed", color: "#0a9856" },
    ];
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(
        projectState.accountInfo.additionalData ?? "{}"
      );
      return _.get(additionalData, "labelsList", defaultList);
    } else return defaultList;
  };

  const getAccountTaskLabels = () => {
    const defaultList = [
      { label: "Not Started", color: "#f6a111", isSystem: true },
      { label: "Viewed", color: "#3461eb", isSystem: true },
      { label: "In Process", color: "#12d87c", isSystem: true },
      { label: "On Hold", color: "#929292", isSystem: true },
      { label: "Canceled", color: "#e4572e", isSystem: true },
      { label: "Done", color: "#0a9856", isSystem: true },
      { label: "Approved", color: "#0a9856", isSystem: true },
    ];
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(
        projectState.accountInfo.additionalData ?? "{}"
      );
      return _.get(additionalData, "taskLabelsList", defaultList);
    } else return defaultList;
  };

  const getTaskTemplateList = () => {
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(
        projectState.accountInfo.additionalData ?? "{}"
      );
      const templates = _.get(additionalData, "taskList", []);
      return templates;
    } else return [];
  };

  const addTaksTemplatesList = async (template: TaskSavedList) => {
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(
        projectState.accountInfo?.additionalData ?? "{}"
      );
      const templates = _.get(additionalData, "taskList", []);
      const updatedAdditionalData = {
        ...additionalData,
        taskList: [...templates, template],
      };
      const updatedAccountInfo = {
        ...projectState.accountInfo,
        additionalData: JSON.stringify(updatedAdditionalData),
      };
      dispatch(setAccountInfo(updatedAccountInfo));
      const token = await getAccessTokenSilence(instance);
      if (token) {
        const data = {
          ...updatedAccountInfo,
          accountId: projectState.accountInfo.id,
        };
        await backofficeApi.updateAccountSettings(data, token.accessToken);
      }
    }
  };

  return {
    getTaskTemplateList,
    addTaksTemplatesList,
    getAccountDateFormat,
    getAccountProjectsLabels,
    getAccountTaskLabels,
  };
};
