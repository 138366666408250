import { Box, Grid, Typography } from "@mui/material";
import * as React from "react";
import { CMSComponent, PositionIndex, UpdateComp, UpdateCompBuilder } from '../cmsSlice';
import { RenderImage } from "./RenderImage";
import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import _ from "lodash";


export interface ImageListCompProps {
  comp: CMSComponent
	location: PositionIndex
  onUpdateComp(updateComp: UpdateComp): void
}

export const ImageListComp: React.FC<ImageListCompProps> = ({comp, location, onUpdateComp}) => {

  const [totalVisibleImages, setTotalVisibleImages] = useState<number>(0)

  const renderImage = (index: number) => {
    return (
      <RenderImage
        value={comp.value}
        fullWidth={comp.fullWidth}
        index={index}
        center={false}
        updateImage ={updateImage}
        removeImage = {removeImage}
      />
    )
  }

  const updateImage = (index: number, img: string) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.components[index].value = img
    onUpdateComp(UpdateCompBuilder(location, deepClone))
  }

  const removeImage = (index: number) => {
    updateImage(index, '')
  }

  useAsyncEffect( () => {
    setTotalVisibleImages(comp.components.filter(img => img.value !== '').length)
  }, [comp])

  return (
    <>
      <Grid item xs={12}>
        <Typography>
          {comp.description}
        </Typography>
        {comp.fullWidth ? (
          <Grid container spacing={3}>
            {comp.components.map((img, i) =>
              i < totalVisibleImages + 1 &&
              (<Grid item xs={12} key={`image-${i}`}>
                {renderImage(i)}
              </Grid>)
            )}

          </Grid>
        ) : (
          <Box border={'1px solid #808080'} padding={"10px 10px"}>
            <Grid container spacing={3}>
              {comp.components.map((img, i) =>
                i < totalVisibleImages + 1 &&
                (<Grid item xs={3} key={`image-${i}`}>
                  {renderImage(i)}
                </Grid>)
              )}
            </Grid>
          </Box>
        )}
      </Grid>
    </>
  )
}

