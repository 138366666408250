import { useMsal } from "@azure/msal-react";
import { Box, ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { backofficeApi } from "../../api/backofficeApi";
import {getAccessTokenSilence} from "../../auth/auth";
import { ClientDomain } from "../../authConfig";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { Project, ProjectState } from "../projectsSlice";
import { convetToCsv, getCmsAndConvertToCsv } from "../../cms/PDFViewer/CSVConverter";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

export interface DownloadContentPopoverProps {
  project: Project;
  anchorElement: React.ReactNode;
  showToolTip?: boolean;
  onDownLoadUsed?():void
  cmsState?: any;
}

export const DownloadContentPopover: FC<DownloadContentPopoverProps> = ({
  project,
  anchorElement,
  showToolTip,
  onDownLoadUsed,
  cmsState
}) => {
  const instance = useMsal();
  const [popupAnchorEl, setPopupAnchorEl] =
    React.useState<HTMLDivElement | null>(null);
  const projectState: ProjectState = useAppSelector(
      (state: RootState) => state.projectsData
    );

  const downloadProjectImages = async () => {
    const token = await getAccessTokenSilence(instance);
    if (token) {
      const link = await backofficeApi.getProjectImagesZipLink(
        project.id,
        token.accessToken
      );
      if (onDownLoadUsed) {
        onDownLoadUsed()
      }
      const anchor = document.createElement("a");
      anchor.href = link;
      anchor.click();
      anchor.remove();     
    }
  };

  const prepareForCSV = async () => {
    const token = await getAccessTokenSilence(instance);
    if (token && projectState.accountInfo) {
        getCmsAndConvertToCsv(project.cmsDataId ?? "",token.accessToken, projectState.accountInfo,  project.name)
    }
  }


  return (
    <>
      <ClickAwayListener onClickAway={() => setPopupAnchorEl(null)}>
        <Box
          onClick={(e) => setPopupAnchorEl(e.currentTarget)}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            paddingLeft: "20px",
          }}
        >
          {showToolTip ? (
            <HelpToolTipOnHover
              position="top"
              message={"Download content"}
              actionNode={anchorElement}
            />
          ) : (
            anchorElement
          )}
        </Box>
      </ClickAwayListener>
      <Popper
        open={popupAnchorEl !== null}
        anchorEl={popupAnchorEl}
        placement={"bottom-end"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box
                sx={{
                  boxShadow: "0 9px 15px 0 rgba(0, 0, 0, 0.07)",
                  background: "#f5f8f7",
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: 400,
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (onDownLoadUsed) {
                      onDownLoadUsed()
                    }
                    window.open(
                      `https://${ClientDomain}/projects/${
                        project.id
                      }/cms/pdf/${project.cmsDataId ?? ""}`
                    )
                  }}
                >
                  Download content to PDF
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    if (onDownLoadUsed) {
                      onDownLoadUsed()
                    }
                    if (cmsState) {
                        convetToCsv(cmsState.pages,cmsState.cmsItems, project.name)
                    } else {
                       prepareForCSV()
                    }
                  }}
                >
                  Download content as CSV
                </Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => downloadProjectImages()}
                >
                  Download all images
                </Box>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
