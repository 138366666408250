import { Draggable } from "react-beautiful-dnd";
import * as React from "react";
import { Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

export interface DragProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  index: number;
  inner: boolean;
}

export const Drag: React.FC<DragProps> = ({ id, index, inner, ...props }) => {
  const cmsState = useSelector((state: RootState) => state.cmsData);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                {cmsState.inEditStructureMode ? (
                  <div className="drag-handle" {...provided.dragHandleProps}>
                    <svg
                      width="20"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="5" cy="5" r="2" fill="#dbdbdb" />
                      <circle cx="10" cy="5" r="2" fill="#dbdbdb" />
                      <circle cx="5" cy="10" r="2" fill="#dbdbdb" />
                      <circle cx="10" cy="10" r="2" fill="#dbdbdb" />
                      <circle cx="5" cy="15" r="2" fill="#dbdbdb" />
                      <circle cx="10" cy="15" r="2" fill="#dbdbdb" />
                    </svg>
                  </div>
                ) : inner ? (
                  <div style={{ width: "20px" }} />
                ) : null}
                {props.children}
              </Box>
              {!inner && (
                <Box
                  sx={{ width: "100%", margin: "0 auto", paddingRight: "30px" }}
                >
                  <Divider sx={{ color: "#ececec" }} />
                </Box>
              )}
            </Box>
          </div>
        );
      }}
    </Draggable>
  );
};
