import { Grid, Typography } from '@mui/material';
import * as React from "react";

export const ListComp: React.FC = () => {
	return (
		<Grid item xs={6}>
			<Typography>
			</Typography>
		</Grid>
	)
}

