import { Box } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { DependOnValue } from "../../cms/components/DropDownComp";
import {
  BUSINESS_CATEGORY_CMS_ID,
  BUSINESS_NAME_CMS_ID,
  BUSINESS_SUB_CATEGORY_CMS_ID,
  CUSTOMER_EMAIL_CMS_ID,
  CUSTOMER_NAME_CMS_ID,
  CUSTOMER_PHONE_CMS_ID,
  BUSINESS_PHONE_CMS_ID,
  BUSINESS_FOUNDED_CMS_ID,
} from "../../utils/Constants";
import { Project } from "../projectsSlice";
import { getProjectSvgRocket } from "../ProjectUIHelper";
import { useAccountSettingsData } from "../../hooks/useAccountSettingsData";
import { LabelItem } from "../../basic/StatusDropDown/StatusDropDown";

export interface ProjectSummaryProps {
  project: Project;
}

export const ProjectSummary: React.FC<ProjectSummaryProps> = ({ project }) => {
  const [projectInfo, setProjectInfo] = React.useState<{
    [id: string]: any;
  }>({});
  const navigate = useNavigate();
  const { getAccountProjectsLabels } = useAccountSettingsData();

  useAsyncEffect(async () => {
    const projectDetails = JSON.parse(project.content ? project.content : "{}");
    setProjectInfo(projectDetails);
  }, [project]);

  const getCategoryAndSubCateogryText = () => {
    const category: DependOnValue = get(projectInfo, BUSINESS_CATEGORY_CMS_ID, {
      value: "",
      other: "",
    });
    const subCategory: DependOnValue = get(
      projectInfo,
      BUSINESS_SUB_CATEGORY_CMS_ID,
      { value: "", other: "" }
    );
    return `${category.value ? category.value : category.other}/${
      subCategory.value ? subCategory.value : subCategory.other
    }`;
  };

  const getProjectStatusColor = (status: string) => {
    return (
      getAccountProjectsLabels().find(
        (item: LabelItem) => item.label === status
      )?.color ?? "#000"
    );
  };

  const getProjectStatusText = () => {
    if (project.status === "New") {
      return "In Progress";
    }
    return project.status === "ContentBeingCreated"
      ? "in Progress"
      : project.status;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          fontWeight: "bold",
          justifyContent: "flex-start",
          display: "flex",
          fontSize: "18px",
          marginBottom: "10px",
        }}
      >
        <Box sx={{ margin: "0 10px" }}>
          {getProjectSvgRocket(getProjectStatusColor(project.status))}
        </Box>
        <Box sx={{ marginRight: "5px" }}>{getProjectStatusText()}</Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1, height: "100px" }}>
          <Box
            sx={{
              display: "flex",
              borderRadius: "10px",
              height: "100%",
              color: "#fff",
              justifyContent: "center",
              backgroundImage:
                "linear-gradient(117deg, #fb8332 20%, #f8c332 131%)",
            }}
          >
            <Box
              sx={{
                padding: "0 22px",
                fontSize: "17px",
                fontWeight: "bold",
                borderRight: "solid 1px #e9ebea",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              Client
            </Box>
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                }}
              >
                {get(projectInfo, CUSTOMER_NAME_CMS_ID, "") === "" ? (
                  <Box
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      navigate(
                        `/projects/${project.id}/view?param=${CUSTOMER_NAME_CMS_ID}`
                      )
                    }
                  >
                    Add name
                  </Box>
                ) : (
                  <Box>{get(projectInfo, CUSTOMER_NAME_CMS_ID, "")}</Box>
                )}
                {get(projectInfo, CUSTOMER_EMAIL_CMS_ID, "") === "" ? (
                  <Box
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      navigate(
                        `/projects/${project.id}/view?param=${CUSTOMER_EMAIL_CMS_ID}`
                      )
                    }
                  >
                    Add email
                  </Box>
                ) : (
                  <Box>{get(projectInfo, CUSTOMER_EMAIL_CMS_ID, "")}</Box>
                )}
                <Box>{get(projectInfo, CUSTOMER_PHONE_CMS_ID, "")}</Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "5%" }} />
          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexGrow: 1,
              color: "#fff",
              borderRadius: "10px",
              backgroundImage:
                "linear-gradient(101deg, #3023ae -34%, #c86dd7 104%)",
            }}
          >
            <Box
              sx={{
                padding: "0 22px",
                fontSize: "17px",
                fontWeight: "bold",
                borderRight: "solid 1px #e9ebea",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              Business <br /> Details
            </Box>
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                }}
              >
                <Box>{get(projectInfo, BUSINESS_NAME_CMS_ID, "")}</Box>
                <Box>{getCategoryAndSubCateogryText()}</Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                }}
              >
                {get(projectInfo, BUSINESS_PHONE_CMS_ID, "") === "" ? (
                  <Box
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      navigate(
                        `/projects/${project.id}/view?param=${BUSINESS_PHONE_CMS_ID}`
                      )
                    }
                  >
                    Add phone number
                  </Box>
                ) : (
                  <Box>{get(projectInfo, BUSINESS_PHONE_CMS_ID, "")}</Box>
                )}

                {get(projectInfo, BUSINESS_FOUNDED_CMS_ID, "") === "" ? (
                  <Box
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      navigate(
                        `/projects/${project.id}/view?param=${BUSINESS_FOUNDED_CMS_ID}`
                      )
                    }
                  >
                    Add founded at
                  </Box>
                ) : (
                  <Box>{get(projectInfo, BUSINESS_FOUNDED_CMS_ID, "")}</Box>
                )}
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {/* <Box
              sx={{
                display: "flex",
                padding: "0 20px 10px",
                justifyContent: 'flex-end',
                alignItems: "flex-end",
                height: "100%",
              }}
            >
              <HelpToolTipOnHover
                position="bottom-end"
                message={"Edit project settings"}
                actionNode={
                  <Fab
                    size="small"
                    sx={{
                      background: "#000",
                      width: "35px",
                      height: "35px",
                      color: "#fff",
                      "&:hover": { color: "#000" },
                    }}
                    onClick={() => navigate(`/projects/${project.id}/view`)}
                  >
                    <EditOutlinedIcon
                      fontSize="medium"
                      sx={{ width: "18px" }}
                    />
                  </Fab>
                }
              ></HelpToolTipOnHover>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
