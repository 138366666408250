import { useMsal } from "@azure/msal-react";
import { Badge, Box } from "@mui/material";
import React, { FC } from "react";
import useAsyncEffect from "use-async-effect";
import { notificationApi } from "../api/notificationApi";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import {getAccessToken} from "../auth/auth";
import { HelpToolTipOnHover } from "../basic/HelpToolTipOnHover";
import { NotificationLine } from "./NotificationLine";
import {
  deleteNotifications,
  getNotifications,
  NotificationsState,
  ProjectActivity,
  resetNewActivities,
  setNotifications,
} from "./notificationsSlice";
import { toast } from "react-toastify";

import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

export interface NotificatiionCenterProps {
  userType: string;
  userId: string;
}

export const NotificatiionCenter: FC<NotificatiionCenterProps> = ({
  userType, userId
}) => {
  const [loadOnce, setLoadOnce] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const msalInstance = useMsal();

  const notificationCenter: NotificationsState = useAppSelector(
    (state: RootState) => state.notificationCenter
  );

  const onDeleteNotification = async (activityId: string) => {
    const accessToken = await getAccessToken(msalInstance);
    if (accessToken) {
      if (await notificationApi.deleteUserActivitie(activityId, accessToken)) {
        dispatch(deleteNotifications(activityId));
      }
    }
  };

  const updateNotifications = async () => {
    const accessToken = await getAccessToken(msalInstance);
    if (accessToken) {
      dispatch(getNotifications(accessToken));
    }
  };

  useAsyncEffect(async () => {
    if (loadOnce) {
      const id = setInterval(async () => {
        updateNotifications();
      }, 30000);
      return () => clearInterval(id);
    }
  }, [loadOnce]);

  useAsyncEffect(async () => {
    if (notificationCenter.newActivities.length>0) {
      notificationCenter.newActivities.forEach( message => {
        if (message.userId && message.userId !== userId) {
          toast.info(message.activity, {
            autoClose: 3000,
          });
        }
      })
      dispatch(resetNewActivities())
    }
  }, [notificationCenter.newActivities]);

  useAsyncEffect(async () => {
    if (userType === "Designer" || userType === "Administrator") {
      updateNotifications();
      setLoadOnce(true);
    }
  }, [userType]);

  const deleteAllActivities = async (e: React.MouseEvent ) => {
    e.stopPropagation()
    const accessToken = await getAccessToken(msalInstance);
    if (accessToken) {
      notificationApi.deleteAllActivitie(accessToken);
      dispatch(setNotifications([]));
    }
  };

  return (
    <>
      <HelpToolTipOnHover
        position="bottom-end"
        message={""}
        actionNode={
            <Badge
              badgeContent={notificationCenter.notifications.length}
              color="secondary"
              sx={{cursor: 'pointer'}}
            >
              <svg
                width="25"
                height="31"
                viewBox="0 0 25 31"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#000" fillRule="nonzero">
                  <path d="M24.85 25.161a10.557 10.557 0 0 1-3.145-7.506v-6.29a9.194 9.194 0 0 0-7.066-8.954v-.314A2.097 2.097 0 0 0 12.542 0h-.126a2.097 2.097 0 0 0-2.097 2.097v.346a9.52 9.52 0 0 0-7.024 9.289v5.923A10.557 10.557 0 0 1 .15 25.161a.524.524 0 0 0 .367.892h23.966a.524.524 0 0 0 .367-.892zM11.41 2.097c0-.58.47-1.049 1.048-1.049h.126c.579 0 1.048.47 1.048 1.049v.073H11.41v-.073zM1.743 25.004a11.532 11.532 0 0 0 2.6-7.339v-5.933a8.387 8.387 0 0 1 7.59-8.492 8.177 8.177 0 0 1 8.765 8.156v6.29a11.532 11.532 0 0 0 2.6 7.34l-21.555-.022zM15.771 27.206a.535.535 0 0 0-.671.325 2.736 2.736 0 0 1-5.158 0 .527.527 0 0 0-.996.346 3.795 3.795 0 0 0 7.15 0 .535.535 0 0 0-.325-.671z" />
                </g>
              </svg>
          </Badge>
        }
      >
        <Box
          sx={{ diplay: "flex", flexDirection: "column", minWidth: "250px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              <svg
                width="25"
                height="31"
                viewBox="0 0 25 31"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#FFF" fillRule="nonzero">
                  <path d="M24.85 25.161a10.557 10.557 0 0 1-3.145-7.506v-6.29a9.194 9.194 0 0 0-7.066-8.954v-.314A2.097 2.097 0 0 0 12.542 0h-.126a2.097 2.097 0 0 0-2.097 2.097v.346a9.52 9.52 0 0 0-7.024 9.289v5.923A10.557 10.557 0 0 1 .15 25.161a.524.524 0 0 0 .367.892h23.966a.524.524 0 0 0 .367-.892zM11.41 2.097c0-.58.47-1.049 1.048-1.049h.126c.579 0 1.048.47 1.048 1.049v.073H11.41v-.073zM1.743 25.004a11.532 11.532 0 0 0 2.6-7.339v-5.933a8.387 8.387 0 0 1 7.59-8.492 8.177 8.177 0 0 1 8.765 8.156v6.29a11.532 11.532 0 0 0 2.6 7.34l-21.555-.022zM15.771 27.206a.535.535 0 0 0-.671.325 2.736 2.736 0 0 1-5.158 0 .527.527 0 0 0-.996.346 3.795 3.795 0 0 0 7.15 0 .535.535 0 0 0-.325-.671z" />
                </g>
              </svg>
            </Box>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={(e) => deleteAllActivities(e)}
            >
              Clear All
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Notifications
            </Box>
            <Box
              className={"notificationWrapper"}
              sx={{
                maxHeight: "400px",
                overflowY: "auto",
                marginTop: "20px",
                paddingRight: '20px'
              }}
            >
              {notificationCenter.notifications.map(
                (notification: ProjectActivity, idx: number) => (
                  <NotificationLine
                    key={`notification-${idx}`}
                    onDeleteNotification={onDeleteNotification}
                    // projects={projects}
                    notification={notification}
                  />
                )
              )}
            </Box>
          </Box>
        </Box>
      </HelpToolTipOnHover>
      <ToastContainer position="top-right" newestOnTop autoClose={8000} />
    </>
  );
};
