import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommentData, UserSiteImage } from "../api/cmsApi";
import { SavedQuestion } from "../api/surveyApi";
import { Project } from "../projects/projectsSlice";
import _ from "lodash";
import moment from "moment";
import { ContentHubFilterType } from "../utils/Constants";
import { ImageCommentPointRec } from "./components/ImageCommentViewer/ImageCommentPoint";

export interface EditorAddedData {
  existingSections: Section[];
  addedSections: Section[];
}

export interface CmsItemUndoData {
  past: any[];
  present: any;
  future: any[];
}



export interface EditorLabeledData {
  singlesLabels?: { [id: number]: string };
  singlesDescriptionLabels?: { [id: number]: string };
  compLabels?: { [id: string]: any };
}

export interface WebSiteDataAndSettings {
  template?: { [id: string]: any };
  cmsData?: { [id: string]: any };
}

export interface CmsValuesByTypes {
  brief: { [id: string]: any };
  ai: { [id: string]: any };
  expert: { [id: string]: any };
  experts: { [id: string]: any };
  nonCMSList: SavedQuestion[];
  social: { [id: string]: any };
  editor?: EditorAddedData;
  config?: { [id: string]: any };
  website?: WebSiteDataAndSettings;
  markedChanges?: string[]
}

export interface CMSApprovalItem {
  name: string;
  email?: string;
  date: string;
}

export interface CMSApprovalList {
  approvals: { [id: string]: CMSApprovalItem[] };
}

export interface ListValues {
  title: string;
  paragraph: string;
  subTitle: string;
  image: string;
  name?: string;
  fromTemplate?: boolean;
  isHidden?: boolean;
}

export interface PageSeoInfo {
  page: string
  title: string;
  metaData: string;
  keywords: string;
}

export interface ImageValues {
  src: string;
  title?: string;
  paragraph?: string;
  type?: string;
  id?: string;
  fromTemplate?: boolean;
  isHidden?: boolean;
}

export interface SingleSerachItem {
  sectionIndex: number;
  singleId: number;
  title: string;
}

export interface CMSComponent {
  components: CMSComponent[];
  description: string;
  listType: string;
  value: any;
  mapKey: string;
  type: string;
  fullWidth?: boolean;
  colSize?: number;
  metaId?: number;
  placeHolder?: string;
  required?: boolean;
  options?: string[];
  inputType?: string;
  dependsOn?: string;
  multi?: boolean;
  showContentPopup?: boolean;
  action?: string;
  remoteLoad?: boolean;
  hide?: string[];
  title?: string;
  onlyUpload?: boolean;
  size?: string;
  isAI?: boolean;
  hasFullReview?: boolean;
  allowAllfiles?: boolean;
  height?: string;
  display?: string;
  hideDescription?: boolean;  
  isFromTemplate?: boolean;              
}

export interface CMSListComponent extends CMSComponent {
  value: ListValues[];
  labels?: string[];
  id?: string;
}

export interface CMSGalleryComponent extends CMSComponent {
  value: ImageValues[];
}

export const CMSComponentBuilder = (
  components: CMSComponent[],
  description: string,
  listType: string,
  value: string | ListValues[],
  mapKey: string,
  type: string,
  fullWidth?: boolean,
  placeHolder?: string
): CMSComponent => ({
  components,
  description,
  listType,
  value,
  mapKey,
  type,
  fullWidth,
  placeHolder,
});

export interface Single {
  components: CMSComponent[];
  description: string;
  icon: string;
  showContentPopup?: boolean;
  singleKey: number;
  extra?: string;
  approved?: boolean;
  isAI?: boolean;
  header?: string;
  noSideBox?: boolean;
  isNew?: boolean;
  isGlobal?: boolean;
  isContentSeperator?: boolean;
}

export interface SubSection {
  singles: Single[];
  name: string;
}

export interface PositionIndex {
  compIdx: number;
  singleIdx: number;
  sectionIdx: number;
  subSectionIdx: number;
  pageIdx: number;
}

export interface SinglePosition {
  singleIdx: number;
  sectionIdx: number;
  pageIdx: number;
}

export const PositionIndexBuilder = (
  compIdx: number,
  singleIdx: number,
  sectionIdx: number,
  subSectionIdx: number,
  pageIdx: number
): PositionIndex => ({
  compIdx,
  singleIdx,
  sectionIdx,
  subSectionIdx,
  pageIdx,
});

export interface UpdateComp {
  location: PositionIndex;
  comp: CMSComponent;
  forceRender?: boolean;
}

export interface UpdateCmsItem {
  key: string;
  value: any;
  forceRender?: boolean;
}

export interface UpdateSingleApprovedStruct {
  location: SinglePosition;
  approved: boolean;
}

export interface UpdateSingleApprovedInfo {
  singleKey: string;
  approved: boolean;
  clientName: string;
}

export const UpdateCompBuilder = (
  location: PositionIndex,
  comp: CMSComponent
): UpdateComp => ({
  location,
  comp,
});

export interface Section {
  singles: Single[];
  description: string;
  type: string;
  subSections?: SubSection[];
  title?: string;
}

export interface Page {
  sections: Section[];
  icon: string;
  description: string;
}

export interface CMSTree {
  pages: Page[];
}

export interface FormSection {
  singlesIds: number[];
  name: string;
  description?: string;
  title?: string;
  sectionIndex: number;
  singles?: Single[];
  hide?: boolean;
  origSinglesOrder?: number[];
}

export interface UpdateAllSectionSinglesPayload {
  mark: boolean;
  singlesIdx: number[];
}


export interface CMSState {
  sections: Section[];
  pages: Page[];
  cmsSchema: Page[];
  siteName: string;
  siteImages: UserSiteImage[];
  accountImages: UserSiteImage[];
  selectedTemplate?: string;
  metaDataId: string;
  siteUrl?: string;
  currentSection: number;
  currentPage: number;
  recalcluateScoreCnt: number;
  templateId: number;
  project?: Project;
  cmsValuesByTypes?: CmsValuesByTypes;
  onlyFilledView: boolean;
  comments: CommentData[];
  blockCopy: boolean;
  cmsIsPublished: boolean;
  approvals: { [id: string]: CMSApprovalItem[] };
  alreadyApproved: { [id: string]: CMSApprovalItem[] };
  forceRendering: number;
  cmsFilterMode: ContentHubFilterType;
  newImageComment?: ImageCommentPointRec;
  isClientMode: boolean;
  singlesSearchList: SingleSerachItem[];
  cmsItems: { [id: string]: any };
  cmsItemsUndoData: { [id: string]: CmsItemUndoData };
  cmsStartItems: { [id: string]: any };
  hiddenList: { [id: string]: any };
  templateValues: { [id: string]: any };
  dirtyFlag: boolean;
  inFormBuildMode: boolean;
  formQuestions: number[];
  formSectionsQuestions: FormSection[];
  inEditMode: boolean;
  cmsKeyForNewQuestion: number;
  triggerAddTopic: boolean;
  inFormMode?: boolean;
  projectId?: string;
  mobileView?: boolean;
  needToRefreshFormComments: boolean;
  needToRefreshCMSComments: boolean;
  firstSingleShowen: boolean;
  inEditStructureMode: boolean;
  anonymousEmail?: string;
  webSiteMode?: boolean
  sideBarMode?: boolean

}

const initialState: CMSState = {
  sections: [],
  pages: [],
  cmsSchema: [],
  siteName: "",
  selectedTemplate: undefined,
  siteImages: [],
  accountImages:[],
  metaDataId: "",
  siteUrl: undefined,
  currentSection: 0,
  currentPage: 0,
  recalcluateScoreCnt: 0,
  templateId: -1,
  project: undefined,
  cmsValuesByTypes: undefined,
  onlyFilledView: false,
  comments: [],
  blockCopy: false,
  cmsIsPublished: false,
  approvals: {},
  alreadyApproved: {},
  forceRendering: 0,
  cmsFilterMode: ContentHubFilterType.None,
  newImageComment: undefined,
  isClientMode: false,
  singlesSearchList: [],
  cmsItems: {},
  cmsItemsUndoData: {},
  cmsStartItems: {},
  hiddenList : {},
  templateValues: {},
  dirtyFlag: false,
  inFormBuildMode: false,
  formQuestions: [],
  formSectionsQuestions: [],
  inEditMode: false,
  cmsKeyForNewQuestion: 20000,
  triggerAddTopic: false,
  inFormMode: false,
  projectId: undefined,
  mobileView: false,
  needToRefreshFormComments: false,
  needToRefreshCMSComments: false,
  firstSingleShowen: false,
  inEditStructureMode: false,
  anonymousEmail: undefined,
  webSiteMode: false,
  sideBarMode: false
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    resetCMS: () => initialState,
    setSections: (state, action: PayloadAction<Section[]>) => {
      state.sections = action.payload;
    },
    setPages: (state, action: PayloadAction<Page[]>) => {
      state.pages = action.payload;
      state.dirtyFlag = false;
      state.forceRendering++;
    },
    setCmsSchema: (state, action: PayloadAction<Page[]>) => {
      state.cmsSchema = action.payload;
    },
    updatePages: (state, action: PayloadAction<Page[]>) => {
      state.pages = action.payload;
      state.forceRendering++;
    },
    updateSiteName: (state, action: PayloadAction<string>) => {
      state.siteName = action.payload;
    },
    setSiteUrl: (state, action: PayloadAction<string>) => {
      state.siteUrl = action.payload;
    },
    setMetaDataId: (state, action: PayloadAction<string>) => {
      state.metaDataId = action.payload;
    },
    setProjectId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload;
    },
    setSiteImages: (state, action: PayloadAction<UserSiteImage[]>) => {
      state.siteImages = action.payload;
    },
    setAccountImages: (state, action: PayloadAction<UserSiteImage[]>) => {
      state.accountImages = action.payload;
    },    
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTemlateId: (state, action: PayloadAction<number>) => {
      state.templateId = action.payload;
    },
    setCurrentSection: (state, action: PayloadAction<number>) => {
      state.currentSection = action.payload;
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    setTypesDics: (state, action: PayloadAction<CmsValuesByTypes>) => {
      state.cmsValuesByTypes = action.payload;
    },
    setOnlyFilledMode: (state, action: PayloadAction<boolean>) => {
      state.onlyFilledView = action.payload;
    },
    setInEditMode: (state, action: PayloadAction<boolean>) => {
      state.inEditMode = action.payload;
    },
    setInFormMode: (state, action: PayloadAction<boolean>) => {
      state.inFormMode = action.payload;
    },
    setTriggerAddTopic: (state, action: PayloadAction<boolean>) => {
      state.triggerAddTopic = action.payload;
    },
    setClientMode: (state, action: PayloadAction<boolean>) => {
      state.isClientMode = action.payload;
    },
    setAnnonymousEmail: (state, action: PayloadAction<string>) => {
      state.anonymousEmail = action.payload;
    },
    setMobileView: (state, action: PayloadAction<boolean>) => {
      state.mobileView = action.payload;
    },
    setSideBarMode: (state, action: PayloadAction<boolean>) => {
      state.sideBarMode = action.payload;
      state.mobileView = action.payload;
    },
    setInEditStructureMode: (state, action: PayloadAction<boolean>) => {
      state.inEditStructureMode = action.payload;
    },
    setInFormBuildMode: (state, action: PayloadAction<boolean>) => {
      state.inFormBuildMode = action.payload;
      if (action.payload === false) {
        state.formQuestions = [];
        state.formSectionsQuestions = [];
        state.inEditMode = true;
      } else {
        state.cmsFilterMode = ContentHubFilterType.None;
        // state.inEditMode = false
      }
    },
    setNewQuestionKey: (state) => {
      state.cmsKeyForNewQuestion++;
    },
    setInFormQuestions: (state, action: PayloadAction<number>) => {
      if (state.formQuestions.indexOf(action.payload) === -1) {
        state.formQuestions.push(action.payload);
      } else {
        state.formQuestions = state.formQuestions.filter(
          (id) => id !== action.payload
        );
      }
    },
    updateInFormQuestions: (state, action: PayloadAction<number[]>) => {
      // if number is in formQuestions remove it else add it

      const newFormQuestions = action.payload.filter(
        (id) => !state.formQuestions.includes(id)
      );
      state.formQuestions = [ ...state.formQuestions.filter(
        (id) => !action.payload.includes(id)
      ), ...newFormQuestions];

      // state.formQuestions = action.payload;
    },
    updateAllInFormQuestions: (state, action: PayloadAction<UpdateAllSectionSinglesPayload>) => {
      // if number is in formQuestions remove it else add it
      const mark = action.payload.mark;
      if (mark) {
        const newFormQuestions = action.payload.singlesIdx.filter(
          (id) => !state.formQuestions.includes(id)
        );
        state.formQuestions = [ ...state.formQuestions, ...newFormQuestions];
      } else {
        state.formQuestions = state.formQuestions.filter(
          (id) => !action.payload.singlesIdx.includes(id)
        )
      }

      // state.formQuestions = action.payload;
    },
    setAllInFormSectionsQuestions: (state, action: PayloadAction<FormSection>) => {
      if (
        state.formSectionsQuestions.findIndex(
          (section) => section.name === action.payload.name
        ) === -1
      ) {
        state.formSectionsQuestions.push(action.payload);
      } else {
        //add payload singles to formSectionsQuestions and update the state.formSectionsQuestions
        const tempList = state.formSectionsQuestions;
        const section = tempList.find(
          (section) => section.name === action.payload.name
        );
        if (section) {
          //using lodash if payload singlesIds are already in section.singlesIds remove them else add them
            section.singlesIds = action.payload.singlesIds;
            section.description = action.payload.description;
            section.title = action.payload.title;
            section.hide = action.payload.hide;
        }
        state.formSectionsQuestions = tempList;
      }
    },
    setInFormSectionsQuestions: (state, action: PayloadAction<FormSection>) => {
      if (
        state.formSectionsQuestions.findIndex(
          (section) => section.name === action.payload.name
        ) === -1
      ) {
        state.formSectionsQuestions.push(action.payload);
      } else {
        //add payload singles to formSectionsQuestions and update the state.formSectionsQuestions
        const tempList = state.formSectionsQuestions;
        const section = tempList.find(
          (section) => section.name === action.payload.name
        );
        if (section) {
          //using lodash if payload singlesIds are already in section.singlesIds remove them else add them
          if (action.payload.singlesIds.length > 0) {
            const newSingles = action.payload.singlesIds.filter(
              (id) => !section.singlesIds.includes(id)
            );
            section.singlesIds = [
              ...section.singlesIds.filter(
                (id) => !action.payload.singlesIds.includes(id)
              ),
              ...newSingles,
            ];
          } else {
            section.description = action.payload.description;
            section.title = action.payload.title;
            section.hide = action.payload.hide;
          }
        }
        state.formSectionsQuestions = tempList;
      }
      state.dirtyFlag = true
    },
    setSelectedFormQuestions: (state, action: PayloadAction<number[]>) => {
      state.formQuestions = action.payload;
    },

    setFirstSingleShowen: (state, action: PayloadAction<boolean>) => {
      state.firstSingleShowen = action.payload;
    },
    setWebSiteMode: (state, action: PayloadAction<boolean>) => {
      state.webSiteMode = action.payload;
    },

    setSelectedFormSectionQuestions: (
      state,
      action: PayloadAction<FormSection[]>
    ) => {
      state.formSectionsQuestions = action.payload;
    },
    setCMSFilterMode: (state, action: PayloadAction<ContentHubFilterType>) => {
      state.cmsFilterMode = action.payload;
    },
    setBlockCopy: (state, action: PayloadAction<boolean>) => {
      state.blockCopy = action.payload;
    },
    setCMSIsPublished: (state, action: PayloadAction<boolean>) => {
      state.cmsIsPublished = action.payload;
    },
    setNewImageComment: (
      state,
      action: PayloadAction<ImageCommentPointRec | undefined>
    ) => {
      state.newImageComment = action.payload;
    },
    updateCmsValues: (state, action: PayloadAction<UpdateCmsItem>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state.cmsItems = { ...state.cmsItems, [key]: value };
      state.dirtyFlag = true;
      if (action.payload.forceRender) {
        state.forceRendering++;
      }
    },

    updateCmsItemsUndoData: (state, action: PayloadAction<UpdateCmsItem>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state.cmsItemsUndoData = { ...state.cmsItemsUndoData, [key]: value };
      if (action.payload.forceRender) {
        state.forceRendering++;
      }
    },
    
    updateCmsHiddenValues: (state, action: PayloadAction<UpdateCmsItem>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state.hiddenList = { ...state.hiddenList, [key]: value };
      state.dirtyFlag = true;
    },

    updateCmsTemplateValues: (state, action: PayloadAction<UpdateCmsItem>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state.templateValues = { ...state.templateValues, [key]: value };
    },
    initCmsValues: (state, action: PayloadAction<{ [id: string]: any }>) => {
      state.cmsItems = { ...action.payload };
      state.cmsStartItems = { ...action.payload };
    },
    setCmsStartItems: (state, action: PayloadAction<{ [id: string]: any }>) => {
      state.cmsStartItems = { ...action.payload };
    },
    // updateTextComponent: (state, action: PayloadAction<UpdateComp>) => {
    //   const pages = state.pages;
    //   const location = action.payload.location;
    //   const section = pages[location.pageIdx].sections[location.sectionIdx];
    //   const compList = section.subSections
    //     ? section.subSections[location.subSectionIdx].singles[
    //         location.singleIdx
    //       ].components
    //     : section.singles[location.singleIdx].components;
    //   compList[location.compIdx] = action.payload.comp;
    //   state.pages = pages;
    //   state.dirtyFlag = true;
    //   if (action.payload.forceRender) {
    //     state.forceRendering++;
    //   } 
    // },
    updateSingleApprove: (
      state,
      action: PayloadAction<UpdateSingleApprovedStruct>
    ) => {
      const pages = state.pages;
      const location = action.payload.location;
      const section = pages[location.pageIdx].sections[location.sectionIdx];
      const single = section.singles[location.singleIdx];
      single.approved = action.payload.approved;
      state.pages = pages;
    },
    updateSingleApproveNew: (
      state,
      action: PayloadAction<UpdateSingleApprovedInfo>
    ) => {
      const approvals = state.approvals;
      if (action.payload.approved) {
        approvals[action.payload.singleKey] = [
          {
            name: action.payload.clientName,
            date: moment.utc(new Date()).format("DD/MM/YYYY"),
          },
        ];
        state.approvals = approvals;
      } else {
        state.approvals = _.omit(approvals, action.payload.singleKey);
      }
    },
    setComments: (state, action: PayloadAction<CommentData[]>) => {
      state.comments = action.payload;
      state.needToRefreshFormComments = false;
      state.needToRefreshCMSComments = false;
    },
    setSinglesSearchList: (
      state,
      action: PayloadAction<SingleSerachItem[]>
    ) => {
      state.singlesSearchList = action.payload;
    },
    setAlreadyApproved: (
      state,
      action: PayloadAction<{ [id: string]: CMSApprovalItem[] }>
    ) => {
      state.alreadyApproved = action.payload;
      state.approvals = {};
    },
    updateForceRender: (state) => {
      state.forceRendering++;
    },
    resetDirtyFlag: (state) => {
      state.dirtyFlag = false;
    },
    setNeedToRefreshFormComments: (state, action: PayloadAction<boolean>) => {
      state.needToRefreshFormComments = action.payload;
    },
    setNeedToRefreshCMSComments: (state, action: PayloadAction<boolean>) => {
      state.needToRefreshCMSComments = action.payload;
    },
  },
});

export const {
  resetCMS,
  setSections,
  setProject,
  setPages,
  updatePages,
  updateSiteName,
  setSiteUrl,
  setMetaDataId,
  setTemlateId,
  setTypesDics,
  setCMSIsPublished,
  setOnlyFilledMode,
  setCurrentPage,
  setBlockCopy,
  setCurrentSection,
  setAnnonymousEmail,
  setWebSiteMode,
  // updateTextComponent,
  setSiteImages,
  setAccountImages,
  setComments,
  setCMSFilterMode,
  setSideBarMode,
  updateSingleApprove,
  updateSingleApproveNew,
  setAlreadyApproved,
  updateForceRender,
  setNewImageComment,
  setClientMode,
  setSinglesSearchList,
  updateCmsValues,
  updateCmsItemsUndoData,
  initCmsValues,
  setCmsStartItems,
  resetDirtyFlag,
  setInFormBuildMode,
  setInFormQuestions,
  setInFormSectionsQuestions,
  setAllInFormSectionsQuestions,
  setInEditMode,
  setNewQuestionKey,
  setTriggerAddTopic,
  setCmsSchema,
  setInFormMode,
  setMobileView,
  setSelectedFormQuestions,
  setSelectedFormSectionQuestions,
  setProjectId,
  setNeedToRefreshFormComments,
  setNeedToRefreshCMSComments,
  setFirstSingleShowen,
  updateInFormQuestions,
  updateAllInFormQuestions,
  setInEditStructureMode,
  updateCmsHiddenValues,
  updateCmsTemplateValues
} = cmsSlice.actions;
export default cmsSlice.reducer;
