import { Box, PopperPlacementType } from "@mui/material";
import React, { FC } from "react";
import { PopUpBase } from "./PopUpBase";

interface DeleteProjectPopupProps {
  onClose(): void;
  onDeleteSite(): void;
  anchorEl?: any;
  title: string;
  description: string | React.ReactNode;
  autoClose: boolean;
  placement?: PopperPlacementType;
  action?: string;
  icon?: React.ReactNode;
  hideIcon?: boolean;
  hideActions?: boolean;
}

export const DeleteConfirmationPopup: FC<DeleteProjectPopupProps> = ({
  onClose,
  onDeleteSite,
  anchorEl,
  title,
  description,
  autoClose,
  placement,
  action,
  icon,
  hideIcon,
  hideActions
}) => {
  return (
    <PopUpBase
      autoClose={autoClose}
      onClose={() => onClose()}
      onAction={() => onDeleteSite()}
      direction={placement ?? "right"}
      hideActions={hideActions}
      anchorEl={anchorEl}
      extraWidth={"300px"}
      svg={
        hideIcon
          ? null
          : icon ?? (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fillRule="nonzero" stroke="#FFF" fill="none">
                  <path d="M23.8 4.6H2.2a1.2 1.2 0 1 0 0 2.4h2.4v14.4c0 1.988 1.612 3.6 3.6 3.6h9.6c1.988 0 3.6-1.612 3.6-3.6V7h2.4a1.2 1.2 0 1 0 0-2.4zm-12 13.2a1.2 1.2 0 1 1-2.4 0v-6a1.2 1.2 0 1 1 2.4 0v6zm4.8 0a1.2 1.2 0 1 1-2.4 0v-6a1.2 1.2 0 1 1 2.4 0v6zM10.6 3.4h4.8a1.2 1.2 0 1 0 0-2.4h-4.8a1.2 1.2 0 1 0 0 2.4z" />
                </g>
              </svg>
            )
      }
      actionString={action ?? "Delete"}
    >
      <Box sx={{ fontWeight: 800 }}>{title}</Box>
      <Box sx={{ marginTop: "10px" }}>{description}</Box>
    </PopUpBase>
  );
};
