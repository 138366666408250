
import * as React from "react";

import { ReactComponent as FaceBookSvg } from "../../assets/social/facebookRound.svg";
import { ReactComponent as LinkedInSvg } from "../../assets/social/linkedinRound.svg";
import { ReactComponent as LinkedInOnSvg } from "../../assets/social/linkedin_on.svg";
import { ReactComponent as LinkedInOffSvg } from "../../assets/social/linkedin_off.svg";
import { ReactComponent as FaceBookOnSvg } from "../../assets/social/facebook_on.svg";
import { ReactComponent as FaceBookOffSvg } from "../../assets/social/facebook_off.svg";
import { ReactComponent as InstagramOnSvg } from "../../assets/social/instagram_on.svg";
import { ReactComponent as InstagramOffSvg } from "../../assets/social/instagram_off.svg";
import { ReactComponent as InstagramSvg } from "../../assets/social/instagram.svg";
import { SocialItemData } from "../../api/socialApi";
import { Box } from "@mui/material";

export interface SocialItemPostData {
  index: number;
  enabled: boolean;
  svg: any;
  svgOn: any;
  svgOff: any;
  title: string;
  message: string;
  images: string[];
}

export const AvaliableSocial: SocialItemPostData[] = [
  {
    index: 0,
    enabled: false,
    title: "Facebook",
    svg: <FaceBookSvg />,
    svgOn: <FaceBookOnSvg />,
    svgOff: <FaceBookOffSvg />,
    message: "",
    images: [],
  },
  {
    index: 1,
    enabled: false,
    title: "LinkedIn",
    svg: <LinkedInSvg />,
    message: "",
    svgOn: <LinkedInOnSvg />,
    svgOff: <LinkedInOffSvg />,
    images: []
  },
  {
    index: 2,
    enabled: false,
    title: "Instagram",
    svg: <InstagramSvg />,
    message: "",
    svgOn: <InstagramOnSvg />,
    svgOff: <InstagramOffSvg />,
    images: []
  },
];

export const LynxiInfo = {
  index: 100,
  enabled: false,
  title: "Lynxi",
  svg: <></>,
  svgOn: <FaceBookOnSvg />,
  svgOff: <LinkedInSvg />,
  message: "",
  images: [],
};


export const getPostSocialIcons = (post: SocialItemData) => {
  const svgMap = []
  if (post.facebook) {
    svgMap.push(AvaliableSocial[0].svg)
  }  
  if (post.linkedin) {
    svgMap.push(AvaliableSocial[1].svg)
  }
  return <Box sx={{display:'flex', gap:'5px'}}>{svgMap.map( (svg, idx) => (
    <Box key={`svg-social${idx}`}>{svg}</Box>
  ))}</Box>
}




export const GeneralPostInfo = {
  index: 100,
  enabled: false,
  title: "General",
  svg: <></>,
  svgOn: <FaceBookOnSvg />,
  svgOff: <LinkedInSvg />,
  message: "",
  images: [],
};
