import { useMsal } from "@azure/msal-react";
import { get } from "lodash";
import * as React from "react";
import { isMobileOnly } from "react-device-detect";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { getAccessTokenSilence } from "../auth/auth";
import { NotAllowed } from "../loginPage/NotAllowed";
import { MobileViewMessage } from "../mobile/MobileViewMessage";
import { Projects } from "../projects/ProjectMain/Projects";
import { ProjectsRouter } from "../projects/ProjectsRouter";
import {
  setAccountInfo
} from "../projects/projectsSlice";
import { TopHeader } from "../topHeader/TopHeader";
// import { useAnalytics } from "use-analytics";
import { Box } from "@mui/material";
import { IntroRouter } from "../Intro/IntroRouter";
import { SiteEditorTest } from "../SiteEditor/SiteEditorTest";
import { GetAccessCodeToSocial } from "../Social/LinkedIn/GetAccessCodeToSocial";
import { AdminRouter } from "../admin/AdminRouter";
import { backofficeApi } from "../api/backofficeApi";
import { CMSActionBar } from "../cms/CMSActionBar";
import { Page } from "../cms/cmsSlice";
import ProjectUtils from "../projects/ProjectUtils";
import { SignalR } from "../NotificationCenter/SignalIR";
import { PaymentPackages } from "../Packages/PaymentPackages";
import { UserPlansDashboard } from "../Packages/PackageItem/UsersPlansDashboard/UserPlansDashboard";

const LazyBriefCreator = React.lazy(
  () => import("../Briefs/Editor/BriefCreator")
);

const LazyBriefPicker = React.lazy(
  () => import("../projects/BriefPicker/BriefPicker")
);
const LazyCMSCollaboratorViewer = React.lazy(
  () => import("../cms/CMSCollaboratorViewer")
);

const LazyProjectDetails = React.lazy(
  () => import("../projects/ProjectForm/ProjectSettings")
);
const LazyAccountSettings = React.lazy(
  () => import("../settings/AccountSettings")
);
const LazySettingsDetails = React.lazy(
  () => import("../settings/SettingsDetails")
);

const LazyFormViewerSwitcher = React.lazy(
  () => import("../Briefs/Viewer/FormViewerSwitcher")
);


export interface CMSManagerContextInterface {
  onUpdateEditor: (page: Page, lastAddedMetaId: number) => void;
  onUpdateLynxiSections: (
    singleDic: { [id: number]: string },
    singleDescriptionDic: { [id: number]: string },
    compsDic: { [id: string]: any }
  ) => void;
  getAccountSettings: () => { [id: string]: any };
  onUpdateLastAddedMetaId: (lastAddedMetaId: number) => void;
  getLastAddedMetaId: () => number;
}

export const CMSManagerContext = React.createContext<
  CMSManagerContextInterface | undefined
>(undefined);


export interface DesignerRouterProps {
  localAccountId: string;
  role: string
}


const  DesignerRouter: React.FC<DesignerRouterProps> = ({localAccountId, role}) => {
  const dispatch = useAppDispatch();
  const instance = useMsal();
  const projectsState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const onUpdateLynxiSections = async (
    singleDic: { [id: number]: string },
    singleDescriptionDic: { [id: number]: string },
    compsDic: { [id: string]: any }
  ) => {
    if (projectsState.accountInfo && projectsState.accountInfo.editorSettings) {
      const currentEditorSettings = JSON.parse(
        projectsState.accountInfo.editorSettings
      );
      const labels = get(currentEditorSettings, "labels", {});
      const singlesLabels = {
        ...get(labels, "singlesLabels", {}),
        ...singleDic,
      };
      const singlesDescriptionLabels = {
        ...get(labels, "singlesDescriptionLabels", {}),
        ...singleDescriptionDic,
      };
      const compLabels = { ...get(labels, "compLabels", {}), ...compsDic };
     
      const updatedAccountSettings = {
        ...currentEditorSettings,
        labels: { singlesLabels, compLabels, singlesDescriptionLabels},
      };
      const token = await getAccessTokenSilence(instance);
      if (token) {
        await backofficeApi.updateAccountSettings(
          {
            ...projectsState.accountInfo,
            accountId: projectsState.accountInfo.id,
            additionalData: projectsState.accountInfo.additionalData ?? "{}",
            editorSettings: JSON.stringify(updatedAccountSettings),
          },
          token.accessToken
        );
        dispatch(
          setAccountInfo({
            ...projectsState.accountInfo,
            editorSettings: JSON.stringify(updatedAccountSettings),
          })
        );
      }
    }
  };

  const getAccountSettings = () => {
    if (projectsState.accountInfo && projectsState.accountInfo.editorSettings) {
      return JSON.parse(projectsState.accountInfo.editorSettings);
    } else {
      return {};
    }
  };

  const getLastAddedMetaId = () => {
    if (projectsState.accountInfo && projectsState.accountInfo.editorSettings) {
      return get(
        get(JSON.parse(projectsState.accountInfo.editorSettings), "editor", {}),
        "lastAddedMetaId",
        20000
      );
    } else {
      return 20000;
    }
  };

  const saveEditorChanges = async (newPage: Page, lastAddedMetaId: number) => {
    const token = await getAccessTokenSilence(instance);
    if (token) {
      if (projectsState.accountInfo) {
        const valueDic = ProjectUtils.getEditorAccountUpdate(
          projectsState.accountInfo,
          newPage,
          lastAddedMetaId
        );
        const currentEditorSettings = JSON.parse(
          projectsState.accountInfo.editorSettings
        );
        await backofficeApi.updateAccountSettings(
          {
            ...projectsState.accountInfo,
            accountId: projectsState.accountInfo.id,
            additionalData: projectsState.accountInfo.additionalData ?? "{}",
            editorSettings: JSON.stringify({...currentEditorSettings, editor: valueDic.editor, schema:[newPage]}),
          },
          token.accessToken
        );
        dispatch(
          setAccountInfo({
            ...projectsState.accountInfo,
            editorSettings: JSON.stringify({...currentEditorSettings, editor: valueDic.editor, schema:[newPage]}),
          })
        );
      }
    }
  };

  const onUpdateLastAddedMetaId = async (lastAddedMetaId: number) => {
    if (projectsState.accountInfo) {
      const currentEditorSettings = JSON.parse(
        projectsState.accountInfo.editorSettings
      );
      dispatch(
        setAccountInfo({
          ...projectsState.accountInfo,
          editorSettings: JSON.stringify({...currentEditorSettings, editor: {lastAddedMetaId: lastAddedMetaId} }),
        })
      );
    }
  }

  return (
    <>
      {isMobileOnly ? (
        <MobileViewMessage withLogout={true} />
      ) : (
        <Box sx={{ display: "flex" }}>
          {location.pathname.indexOf("/cmsddd/") > -1 && <CMSActionBar />}
          <CMSManagerContext.Provider
            value={{
              onUpdateEditor: saveEditorChanges,
              onUpdateLastAddedMetaId: onUpdateLastAddedMetaId,
              getAccountSettings: getAccountSettings,
              getLastAddedMetaId: getLastAddedMetaId,
              onUpdateLynxiSections: onUpdateLynxiSections,
            }}
          >
            <Box
              sx={{
                width:
                  location.pathname.indexOf("/cmsddd/") > -1
                    ? "calc(100% - 60px)"
                    : "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SignalR />
              {location.pathname.indexOf("/intro") === -1 && (
                location.pathname.indexOf("/websiteBuilder") >=0 ? (
                 null
                ): (
                <TopHeader userId={localAccountId} websiteMode={location.pathname.indexOf("/websiteBuilder") >=0}/>)
              )}

              <div
                className={"pageWrapper"}
                style={
                  location.pathname.indexOf("/intro") === -1 &&
                  location.pathname.indexOf("/websiteBuilder") === -1 &&
                  location.pathname.indexOf("/posts/new") === -1
                    ? {
                        margin: projectsState.drawerOpen
                          ? "0px 0px 0px 4%"
                          : location.pathname.indexOf("/cms/") >= 0
                          ? "0 2% 0 2%"
                          : "0 2%",
                      }
                    : {}
                }
              >
                <Routes>
                  <Route
                    index
                    element={<Navigate replace to={`/projects`} />}
                  />

                  <Route
                    path="/designer_signup"
                    element={<Navigate replace to={`/intro/start_project`} />}
                  />

                  <Route
                    path="/pricing"
                    element={<PaymentPackages />} 
                  />    

                  <Route
                    path="/myBilling"
                    element={<UserPlansDashboard />} 
                  />      

                  <Route path="/editor-test" element={<SiteEditorTest />} />
                  <Route
                    path="/first_project"
                    element={<Navigate replace to={`/intro/start_project`} />}
                  />
                  <Route path="/intro/*" element={<IntroRouter />} />
                  <Route path="/settings" element={<LazyAccountSettings />} />
                  <Route
                    path="/dashboard"
                    element={<Navigate replace to={`/projects`} />}
                  />
                  <Route path="/profile" element={<LazySettingsDetails />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route
                    path="/projects/new"
                    element={<LazyProjectDetails />}
                  />
                  <Route
                    path="/projects/:projectId/*"
                    element={<ProjectsRouter />}
                  />
                  <Route path="/forms" element={<LazyBriefPicker />} />
                  <Route
                    path="/briefs/editor/:questionnaireId"
                    element={
                      <LazyBriefCreator isAdmin={role === "Administrator"} />
                    }
                  />

                  <Route
                    path="/questionnaire/collaborator"
                    element={<LazyFormViewerSwitcher isClient={false} />}
                  />

                  <Route
                    path="/brief/collaborator"
                    element={<LazyFormViewerSwitcher isClient={true} />}
                  />

                  <Route
                    path="/auth/linkedin"
                    element={<GetAccessCodeToSocial />}
                  />

                  {role === "Administrator" && (
                    <>
                      <Route path="/admin/*" element={<AdminRouter />} />
                    </>
                  )}
                  <Route
                    path="/cms/collaborator/:cmsId"
                    element={<LazyCMSCollaboratorViewer />}
                  />
                  {/* <Route path= '/auth/linkedin' element={<GetAccessCodeToSocial />} /> */}
                  {/* <Route path= '/auth/:social' element={<GetAccessCodeToSocial />} /> */}
                  <Route path="*" element={<NotAllowed />} />
                </Routes>
              </div>
            </Box>
          </CMSManagerContext.Provider>
        </Box>
      )}
    </>
  );
};

export default DesignerRouter