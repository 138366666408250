import { useMsal } from "@azure/msal-react";
import { PopperPlacementType } from "@mui/material/Popper";
import React, { FC } from "react";
import useAsyncEffect from "use-async-effect";
import { getAccessTokenSilence } from "../auth/auth";
import CommentsUtils from "../Comments/CommentsUtils";
import { projectsApi } from "../api/projectsApi";
import { DesignerContext } from "../routes/AppRouter";
import { CommentData } from "../api/cmsApi";
import { CommentsViewer } from "../Comments/CommentsViewer";
import { tasksApi } from "../api/tasksApi";

export interface FilesCommentsManagerProps {
  anchorEl?: any;
  onClose(): void;
  isClient?: boolean;
  commentLocation?: PopperPlacementType;
  projectId: string
  comments: any[];
  commentId: string
  setComments(comments: any[]): void;
  isTask?: boolean
  email?: string
}

export const FilesCommentsManager: FC<FilesCommentsManagerProps> = ({
  anchorEl,
  onClose,
  isClient,
  commentLocation,
  projectId,
  comments,
  commentId,
  setComments,
  isTask,
  email
}) => {
  const msalInstance = useMsal();
  const [messageTimeoutExpired, setMessageTimeoutExpired] =
    React.useState<boolean>(true);

  const context = React.useContext(DesignerContext)

  useAsyncEffect(async () => {
    if (anchorEl) {
      if (
        CommentsUtils.checkForNewItems(
          comments,
          isClient
        )
      ) {
        const token = await getAccessTokenSilence(msalInstance);
        if (token) {
          if (isTask) {
            await tasksApi.markCommentAsRead(commentId, token.accessToken)
           } else {
             await projectsApi.markCommentAsRead(projectId, commentId, token.accessToken)
           }
          setComments(comments.map((c) => {
            if (
              c.source === (isClient ? "Designer" : "Collaborator")
            ) {
              return { ...c, viewedAt: new Date().toISOString() };
            } else return c;
          }))
        }
      }
    }
  }, [anchorEl]);


  const addComment = async (newComment: string) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
     
      if (isTask) {
        await tasksApi.addCommentToTask(
          commentId,
          [{name: context.name, comments: newComment}],
          email??'',
          token.accessToken
        );
      } else {
        await projectsApi.addCommnetToShareFile(
          projectId,
          commentId,
          [{name: context.name, description: newComment}],
          token.accessToken
        );
      }
      
      const newCommentList: CommentData[] = [
        {
          createdAt: new Date().toISOString(),
          createdBy: "",
          source: isClient ? "Collaborator" : "Designer",
          name: context.name,
          comment: newComment,
          description: newComment,
        },
        ...comments,
      ];
      setMessageTimeoutExpired(false);
      setTimeout(() => {
        setMessageTimeoutExpired(true);
        setComments(newCommentList);
        onClose();
      }, 2000);
    }
  };

  return (
    anchorEl && (
      <CommentsViewer
        position={commentLocation ?? "left-start"}
        onClose={onClose}
        comments={comments.map((c: any) => ({...c, comment: c.description??c.comment}))}
        onAddComment={addComment}
        showSendMessage={!messageTimeoutExpired}
        anchorEl={anchorEl}
        isClient={isClient}
        commentKey={commentId}
        addedOffset={60}
      />
    )
  );
};
