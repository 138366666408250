import {
  Box
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyProjectSVG } from "../../../assets/plans/emptyUserPlans.svg"

import * as React from "react";
import "./UserPlansDashboard.scss";


export const EmptUserPlansDashboard: FC = () => {

    const navigate = useNavigate()

    return (
      <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "0",
              flexGrow: 1,
              height: "calc(100vh - 400px)"
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/pricing")}
            >
              <EmptyProjectSVG/>
            </Box>
            <Box sx={{ fontSize: "22px", fontWeight: 500, marginTop: "40px" }}>
              You have no active plans
            </Box>
            <Box sx={{ marginTop: "20px" }}>
                Get started by choosing a plan that suits your needs.
            </Box>
            <Box
              sx={{
                fontSize: "22px", 
                fontWeight: 500,
                color: "#7f49c3",
                marginTop: "20px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/pricing")}
            >
              Select a plan
            </Box>
          </Box>
    )

}