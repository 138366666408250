import axios from "axios";
import { Project, BriefTemplate } from "../projects/projectsSlice";
import ApiUtils from "./ApiUtils";
import { CommentListReponse, NewCommentData } from "./cmsApi";

export interface ProjectList {
  projects: Project[];
}

export interface ProjectScema {
  created: string;
  id: string;
  name: string;
  schema: string;
  type: string;
}

export interface UserProjectInfo {
  id: string;
  name: string;
  source: string;
  status: string;
}

export interface NewProjectInfo {
  name: string;
  content: string;
  status: string;
  additionalData: string;
}

export interface ProjectUpdateRequest {
  id: string;
  name: string;
  content: string;
  additionalData: string;
  status: string;
  questionaireTemplateId?: string;
}

export interface UserProjectsList {
  projects: Project[];
}

export interface TemplateList {
  templates: BriefTemplate[];
}

export interface CollaboratorData {
  id: string;
  name: string;
}

export interface ProjectBriefSchema {
  id: string;
  name: string;
  template: string;
}

export interface GetProjectBriefsResponse {
  briefs: ProjectBriefSchema[];
}

export interface QuestionnaireData {
  content: string;
  created: string;
  collaborators?: CollaboratorData[];
  collaboratorComments: string;
  designerComments: string;
  numberOfQuestions: number;
  answeredQuestions?: number;
  id: string;
  name: string;
  owner: string;
  templateId: string;
  status: string;
  shared?: string;
  updated?: string;
  template?: string;
  designerName?: string;
  projectId: string;
  cmsId?: string;
  editorSettings?: string;
  lockByUser?: string;
}

export interface AddBriefRequest {
  projectId: string;
  templateId: string;
  status: string;
  schema?: AddBriefSchemaRequest;
}

export interface AddBriefSchemaRequest {
  name: string;
  numberOfQuestions: number;
  schema: string;
}

export interface AddBriefResponse {
  id: string;
  name: string;
  template: string;
}

export interface UpdateQuestionnaireRequest {
  id: string;
  name: string;
  content: string;
  status: string;
  answeredQuestions: number;
  templateId: string;
  schema?: string;
  dueDate?: string;
}

//files
export interface SharedFile {
  id: string;
  link: string;
  description: string;
  email: string;
  sharedAt: string;
  sharedWithUsers: string[];
  comments: any[];
}

export interface GetFilesResponse {
  files: SharedFile[];
}

export interface FileComments {
  name: string;
  description: string;
}

class ProjectsApi {
  addNewProject = async (
    data: NewProjectInfo,
    token: string
  ): Promise<Project> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/project`,
      data,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  getUserProjects = async (token: string): Promise<UserProjectsList> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/project`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getUserProjectById = async (
    projectId: string,
    token: string
  ): Promise<Project> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/project/${projectId}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  editUserProject = async (
    data: ProjectUpdateRequest,
    token: string
  ): Promise<Project> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/project`,
      data,
      ApiUtils.Put(token)
    );
    return response.data;
  };

  deleteUserProject = async (
    projectId: string,
    token: string
  ): Promise<Project> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/briefs/api/project/${projectId}`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };

  updateUserProjectStatus = async (
    projectId: string,
    status: string,
    token: string
  ): Promise<Project> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/project/${projectId}/status?value=${status}`,
      {},
      ApiUtils.Put(token)
    );
    return response.data;
  };

  addBriefToProject = async (
    addbriefRequest: AddBriefRequest,
    token: string
  ): Promise<AddBriefResponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/brief`,
      addbriefRequest,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  deleteBriefFromProject = async (
    briefId: string,
    token: string
  ): Promise<string> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/briefs/api/brief/${briefId}`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };

  getProjectBriefs = async (
    projectId: string,
    token: string
  ): Promise<GetProjectBriefsResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/project/${projectId}/briefs/schema`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getUserQuestionnairById = async (
    questionnaireId: string,
    token: string
  ): Promise<QuestionnaireData | null> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/brief/${questionnaireId}`,
      ApiUtils.Get(token)
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  };

  shareUserQuestionnairById = async (
    questionnaireId: string,
    email: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/brief/${questionnaireId}/share`,
      email,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  reopenUserBrief = async (
    questionnaireId: string,
    subject: string,
    message: string,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.put(
        `${ApiUtils.serverDomain}/briefs/api/brief/${questionnaireId}/reopen`,
        { subject, message },
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  updateUserQuestionnair = async (
    questionnaireData: UpdateQuestionnaireRequest,
    token: string
  ): Promise<string> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/brief`,
      questionnaireData,
      ApiUtils.Put(token)
    );
    return response.data;
  };

  updateUserQuestionnairStatus = async (
    questionnaireId: string,
    status: string,
    token: string
  ): Promise<string> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/brief/${questionnaireId}/status?value=${status}`,
      {},
      ApiUtils.Put(token)
    );
    return response.data;
  };

  getAllQuestioners = async (token: string): Promise<TemplateList> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/Template`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getTemplateQuestionerById = async (
    id: string,
    token: string
  ): Promise<BriefTemplate> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/Template/${id}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  deleteTemplateQuestionerById = async (
    id: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/briefs/api/Template/${id}`,
      ApiUtils.Delete(token)
    );
    return response.status === 200;
  };

  addTemplateQuestioner = async (
    name: string,
    description: string,
    data: string,
    type: string,
    numberOfQuestions: number,
    token: string
  ): Promise<string> => {
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/briefs/api/Template`,
        {
          name: name,
          type: type,
          schema: data,
          numberOfQuestions: numberOfQuestions,
          description: description,
          isPrivate: true,
        },
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return "";
      }
    } catch {
      return "";
    }
  };

  updateTemplateQuestioner = async (
    data: BriefTemplate,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.put(
        `${ApiUtils.serverDomain}/briefs/api/Template`,
        data,
        ApiUtils.Put(token)
      );
      return response.status === 200;
    } catch {
      return false;
    }
  };

  getBriefComments = async (
    briefId: string,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/brief/${briefId}/comments`,
      ApiUtils.UnsecureGet()
    );
    return response.data;
  };

  addBriefComments = async (
    briefId: string,
    data: NewCommentData,
    email: string,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/brief/${briefId}/comments`,
      { comments: [data] , email},
      ApiUtils.UnsecurePost()
    );
    return response.data;
  };

  addBriefCommentsSecure = async (
    briefId: string,
    data: NewCommentData,
    email: string,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/brief/${briefId}/comments`,
      { comments: [data] , email},
      ApiUtils.Post(token)
    );
    return response.data;
  };

  updateBriefComments = async (
    briefId: string,
    name: string,
    email: string,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/brief/${briefId}/comments`,
      { names: [name] , email},
      ApiUtils.Put(token)
    );
    return response.data;
  };

  removeCollaboratorFromBrief = async (
    briefId: string,
    UserIds: string[],
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/briefs/api/brief/${briefId}/share/remove`,
      ApiUtils.DeleteWithData(token, { UserIds: UserIds })
    );
    return response.data;
  };

  setStaringProjectTime = async (
    projectId: string,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.patch(
      `${ApiUtils.serverDomain}/briefs/api/project/${projectId}/work_time/start`,
      {  },
      ApiUtils.Patch(token)
    );
    return response.data;
  };

  setEndingProjectTime = async (
    projectId: string,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.patch(
      `${ApiUtils.serverDomain}/briefs/api/project/${projectId}/work_time/end`,
      {},
      ApiUtils.Patch(token)
    );
    return response.data;
  };

  /// file handeling
  getAllSharedFiles = async (
    projectId: string,
    token: string
  ): Promise<GetFilesResponse | undefined> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/backoffice/api/Storage/project/${projectId}/storage`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  };

  reportSharedFileInfo = async (
    projectId: string,
    description: string,
    url: string,
    token: string
  ): Promise<SharedFile> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/backoffice/api/Storage/project/${projectId}/storage/add_file`,
      {
        description: description,
        url: url,
      },
      ApiUtils.Put(token)
    );
    return response.data;
  };

  deleteSharedFile = async (
    projectId: string,
    fileId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/backoffice/api/Storage/project/${projectId}/storage/${fileId}`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };

  addCommnetToShareFile = async (
    projectId: string,
    fileId: string,
    comments: FileComments[],
    token: string
  ): Promise<boolean> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/backoffice/api/Storage/project/${projectId}/storage/${fileId}/comments`,
      { comments: comments },
      ApiUtils.Put(token)
    );
    return response.data;
  };

  markCommentAsRead = async (
    projectId: string,
    fileId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/backoffice/api/Storage/project/${projectId}/storage/${fileId}/comments/mark_viewed`,
      {},
      ApiUtils.Put(token)
    );
    return response.data;
  };

  getFileShareLink = async (
    projectId: string,
    userEmail: string,
    token: string,
    filesIds?: string[]
  ) => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/backoffice/api/Storage/project/${projectId}/storage/invitation/link`,
      { email: userEmail, filesIds: filesIds },
      ApiUtils.Put(token)
    );
    return response.data;
  };

  shareFilesWithClients = async (
    projectId: string,
    subject: string,
    message: string,
    emails: string[],
    filesIds: string[],
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.put(
        `${ApiUtils.serverDomain}/backoffice/api/Storage/project/${projectId}/storage/invitation`,
        {
          message,
          subject,
          emails,
          filesIds,
        },
        ApiUtils.Put(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };
}

export const projectsApi = new ProjectsApi();
