import * as React from "react";
import "../cms.scss";

import { Player } from "@lottiefiles/react-lottie-player";
import Carousel from "react-material-ui-carousel";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BorderLinearProgress, breakTextToLines } from "../../projects/ProjectUIHelper";


export interface SecondStageMsgProps {
  overRideTitle?: string;
}

export const SecondStageMsg: React.FC<SecondStageMsgProps> = ({overRideTitle}) => {
  const { t } = useTranslation();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        } else {
          return oldProgress + 1.8 >100 ? 100 : oldProgress + 1.8;
        }
      });
    }, 400);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const items: string = t("cms.progress_popup.tips", { joinArrays: "+" });
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          flexDirection: "column",
          fontFamily: "Nunito",
          justifyContent: "center",
          fontSize: "14px",
          width: "80%",
        }}
      >        
        <Box>
        {" "}
          <Player
            src="/images/secondStage.json"
            style={{ width: "270px", height: "170px", marginTop:'30px', marginBottom:'20px'}}
            className="player"
            loop
            autoplay
          />          
        </Box>
        <Box
          sx={{
            fontWeight: 500,
            marginTop: "20px",
            fontSize: "16px",
          }}
        >
          {overRideTitle ? overRideTitle : breakTextToLines(t("cms.progress_popup.stage2.message"))}
        </Box>
        <Box sx={{ width: '300px', margin: '20px 0' }}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Box>
      </Box>
      {!overRideTitle && (
      <Box
        sx={{
          background: "#f0f0f0",
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            textAlign: "center",
            flexDirection: "column",
            fontFamily: "Nunito",
            justifyContent: "center",
            fontSize: "14px",
            width: "80%",
          }}
        >
          <Box sx={{ marginTop: "20px", marginBottom: "20px" }}></Box>
          <Box sx={{ fontWeight: 500, marginBottom: "10px" }}>Lynxi Tip:</Box>
          <Carousel
            interval={4000}
            autoPlay={true}
            indicatorContainerProps={{
              style: {
                marginTop: "10px", // 5
                textAlign: "center", // 4
              },
            }}
            sx={{ width: "100%", height: "80px" }}
          >
            {items.split("+").map((item, i) => (
              <Box
                key={`sdd${i}`}
                sx={{
                  width: "100%",
                  fontWeight: 300,
                  height: "50px",
                }}
              >
                {item}
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
      )}
    </>
  );
};
