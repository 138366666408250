import * as React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BriefData, Project } from "../../projectsSlice";
import { useMsal } from "@azure/msal-react";
import { notificationApi } from "../../../api/notificationApi";
import { ReactComponent as ShareEmailSuccessSvg } from "../../../assets/project/shareMailSuccess.svg";
import { ReactComponent as ShareEmailFailedSvg } from "../../../assets/project/shareMailFail.svg";
import { ReactComponent as WorkingOnBriefSvg } from "../../../assets/project/drawerWorkingOnBrief.svg";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../ProjectUIHelper";
import { getAccessTokenSilence } from "../../../auth/auth";
import { ShareLinkPopup } from "./ShareLinkPopup";

export interface BriefReminderStageProps {
  project: Project;
  brief: BriefData;
  onClose(reload?: boolean): void;
}

export const BriefReminderStage: FC<BriefReminderStageProps> = ({
  project,
  brief,
  onClose,
}) => {
  const [shareResult, setShareResult] = React.useState<boolean | undefined>();
  const [onLaoding, setOnLoading] = React.useState<boolean>(false);
  const [showCopyLinkPopup, setShowCopyLinkPopup] = React.useState<boolean>(false)

  const navigate = useNavigate();
  const msalInstance = useMsal();
  const { t } = useTranslation();

  const sendEmailReminder = async () => {
    setOnLoading(true);
    const token = await getAccessTokenSilence(msalInstance)
    if (token) {
        setShareResult(await notificationApi.sendEmailReminder(brief.id, token.accessToken));
    }
    setOnLoading(false);
  };

  const renderEmailResponse = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          {shareResult ? <ShareEmailSuccessSvg /> : <ShareEmailFailedSvg />}
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {shareResult
            ? t("brief.stages.reminder.success.title")
              : t("brief.stages.reminder.failed.title")}
          </Typography>
          <br />
          <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
            {shareResult
              ? t("brief.stages.reminder.success.message")
              : t("brief.stages.reminder.failed.message")}
          </Box>
          <br />
          <Typography
            color="secondary"
            onClick={() => {
              setShareResult(undefined)
              onClose()
              }
            }
            sx={{
              cursor: "pointer",
              fontWeight: 500,
            }}
          >
            {shareResult 
            ? t("brief.stages.reminder.success.action")
            : t("brief.stages.reminder.failed.action")}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          borderTopLeftRadius: "30px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={onLaoding}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {shareResult === undefined ? (
        <>
          <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
            Help your client finish the brief by{" "}
            <Typography
              color="secondary"
              onClick={() => sendEmailReminder()}
              sx={{
                cursor: "pointer",
                display: "inline",
                fontWeight: 500,
              }}
            >
              sending a reminder
            </Typography>{" "}
            email to keep going.<br/>{" "} You can also use the <Typography
              color="secondary"
              onClick={() => setShowCopyLinkPopup(true)}
              sx={{
                cursor: "pointer",
                display: "inline",
                fontWeight: 500,
              }}
            >
              following link
            </Typography>{" "} to send it by yourself.
          </Box>

          <Divider sx={{ marginTop: "40px" }} />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              margin: "15px 0 17px",
              color: "#6c3dab",
              cursor: "pointer",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box
                onClick={() =>
                  navigate(
                    `/projects/${project.id}/questionnaire/client?q=${brief.id}`
                  )
                }
              >
                View form
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <ColorButton sx={{padding: '4px 15px' }} onClick={() => sendEmailReminder()}>Send a reminder</ColorButton>
            </Box>
          </Box>
        </>
      ) : (
        renderEmailResponse()
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        <WorkingOnBriefSvg />
      </Box>
      {showCopyLinkPopup && (
        <ShareLinkPopup
          id={brief.id}
          projectId={project.id}
          initialEmail={brief.invitations.length>0? brief.invitations[0].email : ""}
          onClose={() => setShowCopyLinkPopup(false)}
          autogenerate={true}
        />
      )}
    </>
  );
};
