import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import cmsReducer from '../cms/cmsSlice';
import projectsReducer from '../projects/projectsSlice';
import aiItemsReducer from '../admin/aiSlice'
import notificationsReducer  from '../NotificationCenter/notificationsSlice';



const reducer = combineReducers({
  cmsData: cmsReducer,
  projectsData: projectsReducer,
  aiItems: aiItemsReducer,
  notificationCenter: notificationsReducer
})

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
});

if (window.__lynxi_env__ !== 'prod') {
  window.store = store;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
