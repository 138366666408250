import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { useAppDispatch } from "../../app/hooks";
import { ShareMessagePopup } from "../../Massages/ShareMessagePopup";
import { SuccessMessagePopup } from "../../Massages/SuccessMessagePopup";
import { DESIGNER_LANG_TOKEN } from "../../utils/Constants";
import { Project, refreshActiveProject } from "../projectsSlice";
import { ReactComponent as ShareSVG } from "../../assets/cms/share.svg";

import Box from "@mui/material/Box";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cmsApi } from "../../api/cmsApi";
import { breakTextToLines } from "../ProjectUIHelper";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { getAccessTokenSilence } from "../../auth/auth";
import { notificationApi } from "../../api/notificationApi";
import { useDesginerInfo } from "../../hooks/useDesginerName";
import { Dayjs } from "dayjs";
import { useAccountSettingsData } from "../../hooks/useAccountSettingsData";

export interface ContentApprovalPopupProps {
  project: Project;
  disabled: boolean;
  children: React.ReactNode;
  inContentHub?: boolean;
  noTooltip?: boolean;
  onShared?(emails: string[]): void;
  tooltipMessage?: string;
}

export const ContentApprovalPopup: React.FC<ContentApprovalPopupProps> = ({
  project,
  disabled,
  children,
  inContentHub,
  noTooltip,
  onShared,
  tooltipMessage,
}) => {
  const [showShareSuccessMessage, setShowShareSuccessMessage] =
    React.useState<boolean>(false);

  const [showSharePopup, setShowSharePopup] = React.useState<boolean>(false);

  const msalInstance = useMsal();

  const ref = React.useRef<HTMLDivElement>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { getDesignerName } = useDesginerInfo();
  const [searchParams] = useSearchParams();
  const { getAccountDateFormat } = useAccountSettingsData();

  const dateFormat = React.useMemo(
    () => getAccountDateFormat(),
    [getAccountDateFormat]
  );

  useAsyncEffect(async () => {
    if (searchParams) {
      const shareWithClient = searchParams.get("share");
      if (shareWithClient === "true" && ref.current) {
        ref.current.click();
      }
    }
  }, []);

  const onShare = async (
    shareEmails: string[],
    description: string,
    subject: string,
    isAnonymous?: boolean,
    _dueDate?: Dayjs,
    dailyReminder?: boolean
  ): Promise<boolean> => {
    if (project.cmsDataId) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const shareResponse = await cmsApi.shareCMS(
          project.cmsDataId,
          {
            emails: shareEmails,
            message: _dueDate
              ? `${description.replace(
                  /\n/g,
                  "<br/>"
                )} <br><br><span style="font-size:14px">Please note that the due date for approving the content is ${_dueDate.format(
                  dateFormat
                )}</span>`
              : description.replace(/\n/g, "<br/>"),
            subject: subject,
            isAnonymous,
            addDailyReminder: dailyReminder,
            dueDate: _dueDate
              ? `${_dueDate.format("YYYY-MM-DD")}T00:00:00.000Z`
              : undefined,
          },
          token.accessToken
        );
        if (shareResponse) {
          if (onShared) {
            notificationApi.addUserActivite(
              "Share content",
              project.id,
              token.accessToken
            );
            onShared(shareEmails);
          }
          dispatch(refreshActiveProject(token.accessToken));
          return true;
        }
      }
    }
    return false;
  };

  const onCloseShare = (success: boolean) => {
    setShowShareSuccessMessage(success);
    setShowSharePopup(false);
  };

  return (
    <>
      <Box
        sx={{
          fontFamily: "Nunito",
          fontSize: "16px",
        }}
      >
        {noTooltip ? (
          <Box
            ref={ref}
            onClick={() => (!disabled ? setShowSharePopup(true) : {})}
          >
            {children}
          </Box>
        ) : (
          <HelpToolTipOnHover
            position={tooltipMessage ? "top-end" : "bottom"}
            message={
              tooltipMessage
                ? tooltipMessage
                : breakTextToLines(
                    disabled
                      ? inContentHub
                        ? t("cms.share_button.tooltip.not_published_cms")
                        : t("cms.share_button.tooltip.not_published")
                      : t("cms.share_button.tooltip.published")
                  )
            }
            icon={tooltipMessage ? undefined : <ShareSVG />}
            actionNode={
              <Box
                ref={ref}
                onClick={() => (!disabled ? setShowSharePopup(true) : {})}
              >
                {children}
              </Box>
            }
          >
            {disabled && !inContentHub && (
              <Box
                sx={{
                  fontWeight: 800,
                  cursor: "pointer",
                  justifyContent: "flex-end",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/projects/${project.id}/cms/${project.cmsDataId}/content`
                    )
                  }
                >
                  Content Hub
                </Box>
              </Box>
            )}
          </HelpToolTipOnHover>
        )}
      </Box>
      {showSharePopup && (
        <ShareMessagePopup
          allowAnonymous={true}
          project={project}
          onClose={onCloseShare}
          onShare={onShare}
          title={t("cms.share_popup.title")}
          header={t("cms.share_popup.header")}
          subject={t("cms.share_popup.subject")}
          action={t("cms.share_popup.action")}
          message={t("cms.share_popup.message").replace(
            DESIGNER_LANG_TOKEN,
            getDesignerName()
          )}
          onCopyLink={(emails) => {
            if (onShared) {
              onShared(emails);
            }
          }}
        />
      )}
      {showShareSuccessMessage && (
        <SuccessMessagePopup
          open={showShareSuccessMessage}
          title={t("cms.success_share.title")}
          message={t("cms.success_share.message")}
          onClose={() => setShowShareSuccessMessage(false)}
          onContinue={() => setShowShareSuccessMessage(false)}
          showAction={false}
        />
      )}
    </>
  );
};
