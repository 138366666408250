import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import {
  PublicClientApplication, EventType, EventMessage, AuthenticationResult,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
// import * as OldServey from 'survey-core';
import App from './App';

import "./i18nextConf";

import * as serviceWorker from './serviceWorker';
import { msalConfig } from './authConfig';
import { store } from './app/store';
import i18next from 'i18next';


const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.enableAccountStorageEvents();
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

i18next.changeLanguage(localStorage.getItem("i18nextLng") || "en")

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
  