import { Box, Grid, TextField } from "@mui/material";
import React, { FC } from "react";
import { ReactComponent as SmallTrashSvg } from "../../assets/project/smallTrash.svg";

export interface EditorEditMultiCheckBoxProps {
  options?: string[];
  onSetComponentsOptions(options: string[]): void;
}

export const EditorEditMultiCheckBox: FC<EditorEditMultiCheckBoxProps> = ({
  options,
  onSetComponentsOptions,
}) => {
  const [startOptions, setStartOptions] = React.useState<string[]>(options ?? []);

  const setComponentsPlaceholder = (idx: number, value: string) => {
    const newOptions = [...(options ?? [])];
    newOptions[idx] = value
    onSetComponentsOptions(newOptions);
  };

  const onAddNewOption = () => {
    const newOptions = [...(options ?? [])];
    newOptions.push("");
    onSetComponentsOptions(newOptions);
    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) { 
      // Scroll to the end of the div
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    }
  };

  const onDeleteOption = (idx: number) => {
    const newOptions = [...(options ?? [])];
    newOptions.splice(idx, 1);
    onSetComponentsOptions(newOptions);
  };

  const onReset = () => {
    onSetComponentsOptions(startOptions);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "10px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>Options:</Box>
        <Box
          sx={{ cursor: "pointer", color: "#7c48c2", fontWeight: 600 }}
          onClick={() => onAddNewOption()}
        >
          + Add
        </Box>
      </Box>
      <Box sx={{ maxHeight: "250px", overflowY: "scroll" }} id={'scrollableDiv'}>
        <Grid container rowGap={"10px"} gap={"15px"}>
          {options?.map((option: string, idx: number) => (
            <Grid item xs={5.5} key={`option-${idx}`}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => onDeleteOption(idx)}
                >
                  <SmallTrashSvg />
                </Box>
                <TextField
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    background: "#f9f9f9",
                    "& input.Mui-disabled": {
                      color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                    },
                  }}
                  inputProps={{ className: "dropDownOther" }}
                  placeholder={"Enter new item"}
                  rows={1}
                  maxRows={1}
                  value={option ?? ""}
                  onChange={(e) => {
                    setComponentsPlaceholder(idx, e.target.value);
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          widht: "100%",
          justifyContent: "flex-end",
          cursor: "pointer",
          color: "#7c48c2",
          fontSize:'14px'
        }}
        onClick={() => onReset()}
      >
        Reset all
      </Box>
    </Box>
  );
};
