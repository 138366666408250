import React, { useState, useEffect } from "react";
import moment from "moment";

export interface TimeCounterProps {
  initialSeconds: number;
}

const TimeCounter: React.FC<TimeCounterProps> = ({ initialSeconds }) => {
  const [currentSeconds, setCurrentSeconds] = useState<number>(initialSeconds);

  const [renderTrigger, setRenderTrigger] = useState<number>(0);

  useEffect(() => {
    // Function to update the current time every second
    const intervalId = setInterval(() => {
      setRenderTrigger((prev) => prev + 1);
      setCurrentSeconds((prevTime) => prevTime + 1);
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once

  return <>{moment.utc(currentSeconds * 1000).format("HH:mm:ss")}</>;
};

export default TimeCounter;
