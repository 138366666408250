import { FormControl, IconButton, TextField } from "@mui/material";
import React, { useState, FC } from "react";
import useAsyncEffect from "use-async-effect";
import isEmail from "validator/lib/isEmail";
import ClearIcon from "@mui/icons-material/Clear";

export interface InputEmailFieldProps {
  handleChange(val: string, isValid: boolean): void;
  isFullWidth: boolean;
  label?: string;
  required: boolean | undefined;
  defaultValue: string;
  disabled?: boolean;
  placeholder?: string;
  id?: string
  fieldColor?: string
}

export const InputEmailField: FC<InputEmailFieldProps> = ({
  handleChange,
  isFullWidth,
  label,
  required,
  defaultValue,
  disabled,
  placeholder,
  fieldColor,
  id
}) => {
  const [value, setValue] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  //   const [dirty, setDirty] = useState(false);

  const onHandleChange = (val: string) => {
    if (isEmail(val)) {
      setIsValid(true);
      handleChange(val, isValid);
    } else {
      setIsValid(val === "");
    }
    setValue(val);
  };

  const handleClearClick = () => {
    setValue("");
    handleChange("", true);
  };

  useAsyncEffect(async () => {
    onHandleChange(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <FormControl fullWidth={isFullWidth}>
        <TextField
          sx={{
            background: fieldColor ? fieldColor : "#f9f9f9",
            "& input.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
              WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
            },
          }}
          id={id}
          error={isValid === false}
          
          // onBlur={() => setDirty(true)}
          label={label ?? ""}
          placeholder={placeholder ?? ""}
          required={required}
          variant="outlined"
          className={value !== "" ? "inputWithVal" : ""}
          value={value}
          disabled={disabled}
          onChange={(e) => onHandleChange(e.target.value)}
          InputProps={
            disabled
              ? {}
              : {
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: value ? "visible" : "hidden" }}
                      onClick={handleClearClick}
                    >
                      <ClearIcon fontSize="small" sx={{ color: '#d3d3d3' }}/>
                    </IconButton>
                  ),
                }
          }
        />
      </FormControl>
    </>
  );
};
