import * as React from "react";
import { Box } from "@mui/material";
import { FC } from "react";
import { isMobileOnly } from "react-device-detect";

export interface ProjectPageStructProps {
  topLine?: React.ReactNode;
  whiteLine?: React.ReactNode;
  children?: React.ReactNode;
  noscroll?: boolean;
  drawer?: React.ReactNode;
}

export const ProjectPageStruct: FC<ProjectPageStructProps> = ({
  topLine,
  whiteLine,
  children,
  noscroll,
  drawer,
}) => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          display: "flex",
          height: "78px",
          width: "100%",
        }}
      >
        {topLine}
      </Box>
      {whiteLine && (
        <Box
          sx={{
            flexGrow: 1,
            background: "#fff",
            padding: "0 31px",
            alignItems: "center",
            display: "flex",
            minHeight: "60px",
            height: "auto",
          }}
        >
          {whiteLine}
        </Box>
      )}
      {drawer ? (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              margin: whiteLine ? "30px 0 0px" : "0 0",
              width: drawer ? "calc( 100% - 315px)" : "100%",
              display: "flex",
              height: noscroll ? "calc( 100vh - 300px)" : "auto",
              overflowY: 'auto'
            }}
          >
            {children}
          </Box>
          {drawer}
        </Box>
      ) : (
        <Box
          sx={{
            margin: whiteLine ? "30px 0 20px" : "0 0",
            display: "flex",
            overflowY: 'auto',
            height: noscroll
              ? isMobileOnly
                ? "auto"
                : "calc( 100vh - 300px)"
              : "auto",
          }}
          className={'projectPageStruct'}
        >
          {children}
        </Box>
      )}
    </>
  );
};
