import React from "react";
import Grid from "@mui/material/Grid";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import { Box } from "@mui/material";
import { EmptyRadio, FullRadio } from "../../projects/ProjectUIHelper";
import parse from "html-react-parser";

export interface YesNoCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const YesNoComp: React.FC<YesNoCompProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly,
}) => {
  const setYesNoValue = (value: string) => {
    const updateComp = { ...comp, value: value };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  return (
    <Grid item xs={12}>
      <Box
        style={{
          marginTop: "6px",
          fontFamily: "Nunito",
          fontWeight: "500",
          paddingBottom: "6px",
          fontSize: "14px",
        }}
        className={"compDescription"}
      >
        <div>{parse(comp.description)}</div>
      </Box>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box sx={{ display: "flex" }}>
              {comp.value === "Yes" ? (
                <FullRadio />
              ) : (
                <EmptyRadio
                  onClick={() => (readonly ? {} : setYesNoValue("Yes"))}
                />
              )}
              Yes
            </Box>
            <Box sx={{ display: "flex" }}>
              {comp.value === "No" ? (
                <FullRadio />
              ) : (
                <EmptyRadio
                  onClick={() => (readonly ? {} : setYesNoValue("No"))}
                />
              )}
              No
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
