import { Grid, Box } from "@mui/material";
import { without } from "lodash";
import * as React from "react";
import { CustomCheckBox } from "../../projects/ProjectUIHelper";
import "../cms.scss";
import {
  CMSComponent,
  CMSState,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import { ReactComponent as CheckSvg } from "../../assets/checkbox.svg";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import parse from 'html-react-parser';


export interface MultiCheckBoxCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const MultiCheckBoxComp: React.FC<MultiCheckBoxCompProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly,
}) => {
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );


  const updateCompData = (values: string[]) => {
    const updateComp = { ...comp, value: values };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  const setSelectedOption = (newValue: string) => {
    if (comp.multi) {
      if (comp.value.indexOf(newValue) > -1) {
        updateCompData(without(comp.value, newValue));
      } else {
        updateCompData([...comp.value, newValue]);
      }
    } else {
      updateCompData([newValue]);
    }
  };

  return (
    <Grid item xs={12} sx={{ fontFamily: " Nunito", fontSize: "14px" }}>
      <Grid container>
        {comp.description && comp.description !== "" && (
          <>
            <Grid item xs={11}>
              <Box sx={{ padding: "0 10px",fontWeight: "500" }}  className= {'compDescription'}><div>{parse(comp.description)}</div></Box>
            </Grid>
            <Grid item xs={1}></Grid>
          </>
        )}
        <Grid item xs={12}>
          <Grid container gap={"10px"}>
            {comp.options &&
              comp.options.map((option, idx) => (
                <Grid
                  item
                  xs={comp.options?.length === 1 || cmsState.mobileView? 12 : 3}
                  key={`radio-${idx}`}
                >
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <Box>
                      <CustomCheckBox
                        checkedIcon={<CheckSvg style={{transform: "scale(0.7)"}}/>}
                        checked={comp.value.indexOf(option) > -1}
                        onChange={() =>
                          readonly ? {} : setSelectedOption(option)
                        }
                      />
                    </Box>
                    <Box sx={{ marginLeft: "5px", marginTop: "12px" }}>
                      {option}
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
