import { Box } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { Project } from "../../projectsSlice";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../ProjectUIHelper";
import { ReactComponent as EmptyFormSVG } from "../../../assets/project/formBox.svg";
import { BasicEmptyStage } from "../../../basic/BasicEmptyStage/BasicEmptyStage";

export interface EmptyProjectBriefsProps {
  project: Project;
}

export const EmptyProjectBriefs: FC<EmptyProjectBriefsProps> = ({
  project,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BasicEmptyStage icon={<EmptyFormSVG />}>
      <>
        <Box>
          Need some info from your client? <br />
          Use a form template or create your own.
        </Box>
        <Box sx={{ marginTop: "50px" }}>
          <ColorButton
            onClick={() =>
              navigate(`/projects/${project.id}/questionnaire/pick`)
            }
          >
            {t("projects.briefs.empty_stage.action")}
          </ColorButton>
        </Box>
      </>
    </BasicEmptyStage>
  );
};
