import axios from "axios";
import ApiUtils from "./ApiUtils";
import { QuestionnaireData } from "./projectsApi";
import { ClientUpdateRequest, UrlResponse } from "./clientApi";
import { CMSResponse, CommentListReponse, NewCommentData } from "./cmsApi";
import { CMSApprovalList } from "../cms/cmsSlice";


class AnonymousApi {

  getClientQuestionnairById = async (questionnaireId:string, email:string): Promise<QuestionnaireData | null> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/briefs/api/Anonymous/${questionnaireId}?email=${encodeURIComponent(email)}`, ApiUtils.UnsecureGetWithParams({}))
    if (response.status === 200) {
       return response.data
    } else return null
  }

  updateClientQuestionnair= async (data: ClientUpdateRequest, email: string): Promise<QuestionnaireData> => {
    const response = await axios.put(`${ApiUtils.serverDomain}/briefs/api/Anonymous`, {...data, email}, ApiUtils.UnsecurePut())
    return response.data
  }

  unlockFormByClient = async (formId: string, userEmail: string): Promise<boolean> => {
    try {
      const response = await axios.post(`${ApiUtils.serverDomain}/briefs/api/Brief/${formId}/unlock?email=${encodeURIComponent(userEmail)}`, {}
    , ApiUtils.UnsecurePut())
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  getAnonymousCmsDataFromServer= async (cmsID:string, email:string): Promise<CMSResponse | null> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/briefs/api/Anonymous/${cmsID}/cms?email=${encodeURIComponent(email)}`, ApiUtils.UnsecureGetWithParams({}))
    if (response.status === 200) {
       return response.data
    } else return null
  }

  getCmsCommentsByEmail = async (
    cmsId: string,
    email: string
  ): Promise<CommentListReponse> => {
    try{
      const response = await axios.get(
        `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/comments?email=${encodeURIComponent(email)}`,
        ApiUtils.UnsecureGet()
      );
      if (response.status === 200) {
        return response.data
      } else {
        return {comments:[]}
      }
    } catch {
      return {comments:[]}
    }  
  };

  updateApprovalFieldsByEmail = async (
    cmsId: string,
    approvalList: CMSApprovalList,
    email: string
  ): Promise<string> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/cms/approve_fields`, {json: JSON.stringify(approvalList), email},
      ApiUtils.UnsecurePut()
    );
    return response.data
  };

  ApprovesCMSByEmail = async (
    cmsId: string,
    state: number,
    email: string
  ): Promise<string> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/cms/approved`, {state: state, email},
      ApiUtils.UnsecurePost()
    );
    return response.data
  };

  addCmsCommentsByEmail = async (
    cmsId: string,
    data: NewCommentData,
    email: string,    
  ): Promise<CommentListReponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/comments`, {comments: [data], email},
      ApiUtils.UnsecurePost()
    );
    return response.data
  };

  updateCmsCommentsByEmail = async (
    cmsId: string,
    name: string,
    email: string
  ): Promise<CommentListReponse> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/comments`, {names: [name], email},
      ApiUtils.UnsecurePut()
    );
    return response.data
  };


  getAccountLogo= async (projectId: string): Promise<UrlResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/Anonymous/logo?projectId=${projectId}`,
      ApiUtils.UnsecureGet()
    );
    return response.data;
  }

}

export const anonymousApi = new AnonymousApi();

