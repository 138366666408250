import { IconButton, TextField } from "@mui/material";
import React, { useState, FC } from "react";
import useAsyncEffect from "use-async-effect";
import isURL from "validator/lib/isURL";
import ClearIcon from "@mui/icons-material/Clear";

export interface InputEmailFieldProps {
  handleChange(val: string, isValid: boolean): void;
  label: string;
  required: boolean | undefined;
  defaultValue: string;
  disabled?: boolean;
  id: string
}

export const InputURLField: FC<InputEmailFieldProps> = ({
  handleChange,
  label,
  required,
  defaultValue,
  disabled,
  id
}) => {
  const [value, setValue] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  //   const [dirty, setDirty] = useState(false);

  const onHandleChange = (val: string) => {
    if (isURL(val)) {
      setIsValid(true);
      handleChange(val, isValid);
    } else {
      setIsValid(val === "");
    }
    setValue(val);
  };

  const handleClearClick = () => {
    setValue("");
    handleChange("", true);
  };

  useAsyncEffect(async () => {
    onHandleChange(defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      sx={{
        width: "100%",
        backgroundColor: "#f9f9f9",
        "& input.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
          WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
        },
      }}
      id={id}
      error={isValid === false}
      // onBlur={() => setDirty(true)}
      label={label}
      required={required}
      disabled={disabled}
      variant="outlined"
      value={value}
      className={value !=='' ? 'inputWithVal': ''}
      onChange={(e) => onHandleChange(e.target.value)}
      InputProps={
        disabled
          ? {}
          : {
              endAdornment: (
                <IconButton
                  sx={{ visibility: value ? "visible" : "hidden" }}
                  onClick={handleClearClick}
                >
                  <ClearIcon fontSize="small" sx={{ color: '#d3d3d3' }}/>
                </IconButton>
              ),
            }
      }
    />
  );
};
