import { Box } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { Editor } from "react-draft-wysiwyg";
import classnames from "classnames";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import useAsyncEffect from "use-async-effect";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./BasicRichEditor.scss";

export interface BasicRichEditorProps {
  value: string;
  onUpdate?: (message: string) => void;
  topToolBar?: boolean;
  addTextDecoration?: boolean;
  addedClass?: string;
}

export const BasicRichEditor: FC<BasicRichEditorProps> = ({
  value,
  onUpdate,
  topToolBar,
  addTextDecoration,
  addedClass,
}) => {
  const [editorState, setEditorState] = React.useState<EditorState>(
    EditorState.createEmpty()
  );
  const [init, setInit] = React.useState<boolean>(false);
  const [dirtyFlag, setDirtyFlag] = React.useState<boolean>(false);

  const [hasFocus, setHasFocus] = React.useState<boolean>(false);

  useAsyncEffect(async () => {
    if (value && !init) {
      updateEditorValue(value);
    }
  }, [value, init]);

  const updateEditorValue = (initMessage: string) => {
    const contentBlock = htmlToDraft(initMessage);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  };

  const onBlurEditor = () => {
    setHasFocus(false);
    if (onUpdate && dirtyFlag) {
      const htmlText = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      onUpdate(htmlText);
    }
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    if (
      init &&
      newEditorState.getCurrentContent() !== editorState.getCurrentContent()
    ) {
      setDirtyFlag(true);
    }
    setInit(true);
    setEditorState(newEditorState);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column" }}
        onMouseLeave={() => onBlurEditor()}
      >
        <Editor
          onFocus={() => setHasFocus(true)}
          readOnly={false}
          onBlur={() => onBlurEditor()}
          hashtag={{
            separator: " ",
            trigger: "#",
          }}
          placeholder={""}
          wrapperClassName="basicEditorWrapper"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          editorClassName={classnames(
            "basicEditor",
            addedClass ?? "",
            hasFocus ? "basicEditorFocus" : ""
          )}
          toolbarClassName={
            topToolBar ? "post-editor-toolbar-top" : "post-editor-toolbar"
          }
          toolbar={{
            options: addTextDecoration
              ? ["emoji", "inline"]
              : ["link", "emoji"],
            inline: {
              inDropdown: false,
              className: "boldtext",
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
              bold: { className: "iconButton" },
              italic: { className: "iconButton" },
              underline: { className: "iconButton" },
            },
            link: {
              inDropdown: false,
              className: "linkButtons",
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: "_self",
              options: ["link"],
              link: {
                icon: "/images/social/editorLink.svg",
                className: undefined,
              },
              unlink: { className: undefined },
              linkCallback: undefined,
            },
            emoji: {
              className: "emojiButtons",
              icon: "/images/social/emoji.svg",
              popupClassName: undefined,
              emojis: [
                "😀",
                "😁",
                "😂",
                "😃",
                "😉",
                "😋",
                "😎",
                "😍",
                "😗",
                "🤗",
                "🤔",
                "😣",
                "😫",
                "😴",
                "😌",
                "🤓",
                "😛",
                "😜",
                "😠",
                "😇",
                "😷",
                "😈",
                "👻",
                "😺",
                "😸",
                "😹",
                "😻",
                "😼",
                "😽",
                "🙀",
                "🙈",
                "🙉",
                "🙊",
                "👼",
                "👮",
                "🕵",
                "💂",
                "👳",
                "🎅",
                "👸",
                "👰",
                "👲",
                "🙍",
                "🙇",
                "🚶",
                "🏃",
                "💃",
                "⛷",
                "🏂",
                "🏌",
                "🏄",
                "🚣",
                "🏊",
                "⛹",
                "🏋",
                "🚴",
                "👫",
                "💪",
                "👈",
                "👉",
                "👉",
                "👆",
                "🖕",
                "👇",
                "🖖",
                "🤘",
                "🖐",
                "👌",
                "👍",
                "👎",
                "✊",
                "👊",
                "👏",
                "🙌",
                "🙏",
                "🐵",
                "🐶",
                "🐇",
                "🐥",
                "🐸",
                "🐌",
                "🐛",
                "🐜",
                "🐝",
                "🍉",
                "🍄",
                "🍔",
                "🍤",
                "🍨",
                "🍪",
                "🎂",
                "🍰",
                "🍾",
                "🍷",
                "🍸",
                "🍺",
                "🌍",
                "🚑",
                "⏰",
                "🌙",
                "🌝",
                "🌞",
                "⭐",
                "🌟",
                "🌠",
                "🌨",
                "🌩",
                "⛄",
                "🔥",
                "🎄",
                "🎈",
                "🎉",
                "🎊",
                "🎁",
                "🎗",
                "🏀",
                "🏈",
                "🎲",
                "🔇",
                "🔈",
                "📣",
                "🔔",
                "🎵",
                "🎷",
                "💰",
                "🖊",
                "📅",
                "✅",
                "❎",
                "💯",
              ],
            },
          }}
        />
      </Box>
    </>
  );
};
