import { Box } from "@mui/material";
import React, { FC } from "react";
import useAsyncEffect from "use-async-effect";
import { CommentData } from "../../api/cmsApi";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { SingleCMSSvg } from "../SingleCMSSvg";

export interface CmsCommenttProps {
  onShowComment(e: any, commentKey: string): void;
  noComment?: boolean;
  isClient?: boolean;
  singleKey: string;
  noText?: boolean
  mobileView?: boolean
}

export const CmsComment: FC<CmsCommenttProps> = ({
  onShowComment,
  noComment,
  singleKey,
  isClient,
  noText,
  mobileView
}) => {
  const [showComment, setShowComment] = React.useState<boolean>(false);
  const [haveNewItems, setHaveNewItems] = React.useState<boolean>(false);
  const [numOfComments, setNumOfComments] = React.useState<number>(0);

  const rootState: RootState = useAppSelector((state: RootState) => state);

  const checkForNewItems = (commentList: CommentData[]) => {
    if (isClient) {
      return (
        commentList.filter((c) => c.source !== "Collaborator" && !c.viewedAt)
          .length > 0
      );
    } else {
      return (
        commentList.filter((c) => c.source === "Collaborator" && !c.viewedAt)
          .length > 0
      );
    }
  };

  useAsyncEffect(async () => {
    const comments = rootState.cmsData.comments.filter(
      // (r: CommentData) => r.name === `${singleKey}${compIndex !== undefined ? `__${compIndex}` : ""}` || r.name.indexOf(`${singleKey}-#`) ===0
      (r: CommentData) => r.name === `${singleKey}` || r.name.indexOf(`${singleKey}-#`) ===0
    );
    const allowByStatus = true
      // rootState.projectsData.activeProject &&
      // rootState.projectsData.activeProject.cmsShareInfo.length > 0;

    if ((isClient || comments.length > 0 || allowByStatus) && !noComment) {
      setHaveNewItems(checkForNewItems(comments));
      setNumOfComments(comments.length);
      setShowComment(true);
    }
  }, [rootState.cmsData.comments]);

  return showComment ? (
    <Box
      // onClick={(e) => onShowComment(e, `${singleKey}${compIndex !== undefined ? `__${compIndex}` : ""}`)}
      onClick={(e) => onShowComment(e, `${singleKey}`)}
      sx={noText ? {
        cursor: "pointer",
        display: "flex",
        position: 'relative'
      }:{
        position: "absolute",
        top: mobileView? 5: 10,
        right: noText? 40 : -10,
        cursor: "pointer",
        display: "flex",
      }}
      id={`comment-${singleKey}-btn`}
    >
      {!noText && (
        <Box
          sx={{ fontSize: "12px", fontFamily: "Nunito", marginRight: "8px" }}
        >
          {numOfComments === 0
            ? "Add comment"
            : haveNewItems
            ? "View comment"
            : "Comment"}
        </Box>
      )}
      <SingleCMSSvg name={numOfComments > 0 ? "commentOn" : "commentNew"} />
      {haveNewItems && (
        <Box
          sx={{
            position: "absolute",
            top: "-6px",
            right: "-3px",
            width: "12px",
            height: "12px",
            borderRadius: "7px",
            background: "#d70404",
          }}
        />
      )}
    </Box>
  ) : null;
};
