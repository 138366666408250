import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ReactComponent as EmptyFilesVG } from "../assets/storage/empty_share_files.svg";
import { ColorButton } from "../projects/ProjectUIHelper";
import { BasicEmptyStage } from "../basic/BasicEmptyStage/BasicEmptyStage";

export interface EmptyFilesViewProps {
  onAddFile: () => void;
  onInvite: () => void;
  isClient?: boolean;
}

export const EmptyFilesView: FC<EmptyFilesViewProps> = ({
  onAddFile,
  onInvite,
  isClient,
}) => {
  return (
    <BasicEmptyStage icon={<EmptyFilesVG />}>
      {isClient ? (
        <>
          <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
            Upload files of any size including images and videos.
          </Box>
          <Box>
            <ColorButton onClick={() => onAddFile()} sx={{ marginTop: "30px" }}>
              Upload file
            </ColorButton>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
            {`Share and receive files`}
          </Box>
          <Box sx={{ display: "flex", marginTop: "10px" }}>
            Share relevant files with your client for your project here, like
            contracts, brand guidelines, and inspiration.
          </Box>
          <Box sx={{ display: "flex" }}>
            You can also&nbsp;
            <Box
              sx={{ color: "#6c3dab", cursor: "pointer", fontWeight: "600" }}
              onClick={() => onInvite()}
            >
              invite
            </Box>
            &nbsp;your client to share files of any size, including images and
            videos.
          </Box>
          <Box>
            <ColorButton onClick={() => onAddFile()} sx={{ marginTop: "30px" }}>
              Upload file
            </ColorButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "10px",
              fontStyle: "italic",
              fontSize: "14px",
            }}
          >
            *To get feedback on specific files like a design mockup, upload it
            to the Content Hub.
          </Box>
        </>
      )}
    </BasicEmptyStage>
  );
};
