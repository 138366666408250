import { Grid, TextField, Typography } from  "@mui/material"
import * as React from "react";
import { CMSComponent, PositionIndex, UpdateComp, UpdateCompBuilder } from '../cmsSlice';
import _ from "lodash";

export interface ButtonCompProps {
  comp: CMSComponent
	location: PositionIndex
  onUpdateComp(updateComp: UpdateComp): void
}

export const ButtonComp: React.FC<ButtonCompProps> = ({comp, location, onUpdateComp}) => {


  const onChange = (value: string) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.components[0].value = value;
    onUpdateComp(UpdateCompBuilder(location, deepClone))
  }

  return (
    <Grid item xs={comp.components[0].fullWidth? 12 : 6}>
      <Grid container>
        <Grid item xs={10}>
          <Typography>
            {comp.components[0].description}
          </Typography>
        </Grid>
      </Grid>
      <TextField
        id="standard-helperText"
        className={'actionInput'}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        value={comp.components[0].value}
        variant="outlined"
      />
    </Grid>
  )
}

