import * as React from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { common } from "@mui/material/colors";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getPrettyDate } from "../../utils/DateUtils";
import { Project } from "../projectsSlice";
import { TableCell, getProjectSvgRocket } from "../ProjectUIHelper";
import { ReactComponent as CommentSvg } from "../../assets/project/purpleComment.svg";
import { ReactComponent as BulletsIconSVG } from "../../assets/project/bullets.svg";
import { ReactComponent as SmallTrashSvg } from "../../assets/project/smallTrash.svg";
import { ReactComponent as SmallEditSvg } from "../../assets/project/smallEdit.svg";
import { ReactComponent as SettingsSmallSVG } from "../../assets/project/settingsSmall.svg";
import { ReactComponent as PlaySVG } from "../../assets/project/playIcon.svg";
import { ReactComponent as PauseSVG } from "../../assets/project/pauseIcon.svg";

import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../auth/auth";
import { projectsApi } from "../../api/projectsApi";
import { WalkThrowPopup } from "../../PopUpMessage/WalkThrowPopup";
import { useTranslation } from "react-i18next";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import moment from "moment";
import TimeCounter from "../../basic/TimeCounter";
import { StatusDropDown } from "../../basic/StatusDropDown";
import { useAccountSettingsData } from "../../hooks/useAccountSettingsData";
import { LabelItem } from "../../basic/StatusDropDown/StatusDropDown";

export interface ProjectLineProps {
  project: Project;
  selected: boolean;
  onDeleteClick(): void;
  onSetStartProjectTime(): void;
  onSetStopProjectTime(): void;
  projectLabels: LabelItem[];
  onSettingsClick(): void;
}

export const ProjectLine: FC<ProjectLineProps> = ({
  project,
  selected,
  onDeleteClick,
  onSetStartProjectTime,
  onSetStopProjectTime,
  projectLabels,
  onSettingsClick,
}) => {
  const [status, setStatus] = React.useState<string>();
  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);
  const [heplerAnchorEl, setHeplerAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const { getAccountDateFormat } = useAccountSettingsData();

  const msalInstance = useMsal();
  const { t } = useTranslation();

  const getProjectStatusColor = (status: string) => {
    return (
      projectLabels.find((label) => label.label === status)?.color ?? "#000"
    );
  };

  const checkForComments = () => {
    return (
      <Box
        sx={{ cursor: "pointer", marginLeft: "10px", position: "relative" }}
        onClick={(e) => {
          e.stopPropagation();
          project.comments.unreadCmsComments +
            project.comments.unreadBriefComments >
          0
            ? navigate(`/projects/${project.id}/details`)
            : null;
        }}
      >
        {project.comments.unreadCmsComments +
          project.comments.unreadBriefComments >
        0 ? (
          <>
            <CommentSvg />
            <div className="has-comments" />
          </>
        ) : (
          <HelpToolTipOnHover
            position="top"
            message={t("projects.project_line.comment_helper")}
            actionNode={<CommentSvg />}
          />
        )}
      </Box>
    );
  };

  const getProjectStatus = () => {
    if (status) {
      if (status === "New" || status === "ContentBeingCreated")
        return "In Progress";
      return status;
    }
    if (project.status === "ContentBeingCreated") {
      return "In Progress";
    } else return project.status;
  };

  const onStatusChange = async (value: string) => {
    setOnProcessing(true);
    const accessToken = await getAccessToken(msalInstance);
    if (accessToken) {
      await projectsApi.updateUserProjectStatus(project.id, value, accessToken);
      setStatus(value);
    }
    setOnProcessing(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "felx",
        background: selected ? "#f5effb" : common.white,
        alignItems: "center",
        height: "100px",
        margin: "25px 0px",
        "&:hover": {
          backgroundColor: "#f5effb",
          "& .p_title": {
            fontWeight: 700,
          },
        },
      }}
    >
      <Grid container spacing={1} alignItems="center" height="100%">
        <Grid
          item
          xs={2}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%" }}
        >
          <TableCell
            sx={{
              color: "#6c3dab",
              fontWeight: "500",
              height: "100%",
              cursor: "pointer",
              justifyContent: "flex-start",
            }}
            onClick={() => navigate(`/projects/${project.id}/details`)}
          >
            <Box sx={{ margin: "0 10px" }}>
              {getProjectSvgRocket(
                getProjectStatusColor(status ?? project.status)
              )}
            </Box>
            <Box
              className="p_title"
              sx={{
                cursor: "pointer",
                marginRight: "5px",
                fontWeight: selected ? 400 : 300,
              }}
              onClick={() => navigate(`/projects/${project.id}/details`)}
            >
              {project.name}
            </Box>
          </TableCell>
        </Grid>
        <Grid item xs={1.5}>
          <TableCell
            onClick={() => navigate(`/projects/${project.id}/details`)}
            sx={{ fontSize: "12px", cursor: "pointer" }}
          >
            {getPrettyDate(project.created, false, getAccountDateFormat())}
          </TableCell>
        </Grid>
        <Grid item xs={1.5}>
          <TableCell
            onClick={() => navigate(`/projects/${project.id}/details`)}
            sx={{ fontSize: "12px", cursor: "pointer" }}
          >
            {getPrettyDate(project.updated, false, getAccountDateFormat())}
          </TableCell>
        </Grid>
        <Grid item xs={1.5}>
          <Box
            sx={{
              fontSize: "12px",
              cursor: "pointer",
              display: "flex",
              gap: "20px",
              margin: "0 20px",
              justifyContent: "center",
            }}
          >
            {project.designerWorking ? (
              <Box onClick={() => onSetStopProjectTime()}>
                <PauseSVG />
              </Box>
            ) : (
              <Box onClick={() => onSetStartProjectTime()}>
                <PlaySVG />
              </Box>
            )}
            {project.designerWorking ? (
              <TimeCounter
                initialSeconds={
                  project.currentWorkingInSec
                    ? project.currentWorkingInSec +
                      (project.workTimeInSecond ?? 0)
                    : project.workTimeInSecond ?? 0
                }
              />
            ) : (
              <>
                {project.workTimeInSecond
                  ? moment
                      .utc(project.workTimeInSecond * 1000)
                      .format("HH:mm:ss")
                  : "00:00:00"}
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <TableCell
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/projects/${project.id}/details`)}
          >
            <Box
              sx={{
                marginRight: "5px",
                fontSize: "14px",
                fontWeight: 900,
                color: "#7c48c2",
                textAlign: "center",
                position: "relative",
              }}
            >
              {project.briefs.length}
              {project.activities &&
                project.activities.unreadBriefActivities > 0 && (
                  <div
                    className="has-comments-text"
                    onClick={() => navigate(`/projects/${project.id}/details`)}
                  />
                )}
            </Box>
          </TableCell>
        </Grid>
        <Grid item xs={1.5}>
          <TableCell
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/projects/${project.id}/details`)}
          >
            <Box
              sx={{
                marginRight: "5px",
                fontSize: "14px",
                fontWeight: 900,
                color: "#7c48c2",
                textAlign: "center",
                position: "relative",
              }}
            >
              {project.cmsShareCount ?? 0}
              {project.activities &&
                project.activities.unreadCmsActivities > 0 && (
                  <div
                    className="has-comments-text"
                    onClick={() => navigate(`/projects/${project.id}/details`)}
                  />
                )}
            </Box>
          </TableCell>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            {checkForComments()}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <TableCell>
            <StatusDropDown
              currentStatus={getProjectStatus()}
              onStatusChange={onStatusChange}
              statusList={projectLabels}
              onSettingsClick={() => onSettingsClick()}
            />
          </TableCell>
        </Grid>
        <Grid item xs={1}>
          <TableCell sx={{ justifyContent: "flex-end" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              <Tooltip
                placement={"bottom"}
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: "#000",
                      padding: "5px 15px 10px 15px",
                      boxShadow: "0 9px 15px 0 rgba(0, 0, 0, 0.07)",
                      bgcolor: "#f5f8f7",
                      "& .MuiTooltip-arrow": {
                        color: "#000",
                      },
                    },
                  },
                }}
                title={
                  <Box>
                    <Box
                      sx={{
                        marginTop: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() =>
                        navigate(`/projects/${project.id}/details`)
                      }
                    >
                      <Box sx={{ marginRight: "20px" }}>
                        <SmallEditSvg />
                      </Box>
                      Edit project
                    </Box>
                    <Box
                      sx={{
                        marginTop: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => navigate(`/projects/${project.id}/view`)}
                    >
                      <Box sx={{ marginRight: "20px" }}>
                        <SettingsSmallSVG />
                      </Box>
                      Edit settings
                    </Box>
                    <Box
                      sx={{
                        marginTop: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => onDeleteClick()}
                    >
                      <Box sx={{ marginRight: "20px" }}>
                        <SmallTrashSvg />
                      </Box>
                      Delete project
                    </Box>
                  </Box>
                }
                arrow={false}
              >
                <Box
                  sx={{ display: "flex", padding: "0 20px", cursor: "pointer" }}
                >
                  <BulletsIconSVG />
                </Box>
              </Tooltip>
            </Box>
          </TableCell>
        </Grid>
      </Grid>
      <WalkThrowPopup
        anchorEl={heplerAnchorEl}
        message={t("projects.project_line.comment_helper")}
        direction="left"
        autoClose={false}
        onClose={() => setHeplerAnchorEl(null)}
      />
      <BasicPageBlocker show={onProcessing} />
    </Box>
  );
};
