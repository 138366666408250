import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get, orderBy } from "lodash";
import { LynxiAccountInfo, backofficeApi } from "../api/backofficeApi";
import { ShareCmsInfo } from "../api/cmsApi";
import { projectsApi } from "../api/projectsApi";
import { ImageCategory } from "../api/templatesApi";
import { TaskInfo } from "../api/tasksApi";
import { TemplateStructure } from "../SiteEditor/Stages/PickTemplate/PickTemplate";
import { TemplateMappingObject } from "../SiteEditor/Stages/EditTemplate/EditTemplateUtils";

export interface ProjectSocial {
  facebook: FaceBookConnectedPageInfo;
  linkedin: LinkedInConnectedPageInfo;
  instagram: InstagramConnectedPageInfo;
}

export interface ElementorSite {
  blogId: string;
  template: TemplateStructure
  mapping: TemplateMappingObject
  status?: string;
  lastPublished?: string;
  created?: string;
  updated?: string;
}

export interface Project {
  id: string;
  name: string;
  status: string;
  briefs: BriefData[];
  owner?: string;
  cmsDataId?: string;
  content?: string;
  additionalData: string;
  created: string;
  updated?: string;
  comments: {
    unreadBriefComments: number;
    unreadCmsComments: number;
  };
  cmsShareInfo: ShareCmsInfo[];
  unreadCmsComments: number;
  cmsShareCount?: number;
  cmsLastPublished?: string;
  activities?: ProjectActivities;
  social?: ProjectSocial;
  workTimeInSecond?: number;
  designerWorking?: boolean;
  currentWorkingInSec?:number;
  tasks:TaskInfo[]
  site?: ElementorSite;
}

export interface LinkedInConnectedPageInfo {
  connected: boolean;
  name?: string;
  id?: string;
}

export interface FaceBookConnectedPageInfo {
  connected: boolean;
  name?: string;
  logo?: string;
  pageAccessToken?: string;
  id?: string;
  updated?: string;
  newPageExperience?: boolean;
}

export interface InstagramConnectedPageInfo {
  connected: boolean;
  name?: string;
  logo?: string;
  pageAccessToken?: string;
  id?: string;
  updated?: string;
  newPageExperience?: boolean;
}

export interface ProjectActivities {
  unreadBriefActivities: number;
  unreadCmsActivities: number;
  unreadProjectActivities: number;
}

export interface CollaboratorClientInfo {
  email: string;
  id: string;
  name: string;
}

export interface InviteInfo {
  created: string;
  email: string;
  isAnonymous?: boolean;
  validForInDays: number;
}

export interface BriefData {
  id: string;
  answeredQuestions: number;
  name: string;
  numberOfQuestions: number;
  status: string;
  updated: string;
  invitations: InviteInfo[];
  created: string;
  unreadBriefComments: number;
  templateId: string;
  emailChange?: string;
  collaborators: CollaboratorClientInfo[];
  testMode?: boolean;
  dueDate?: string;
}

export interface BriefTemplate {
  name: string;
  id: string;
  created: string;
  type: string;
  numberOfQuestions: number;
  schema?: string;
  owner?: string;
  description?: string;
}

export interface UserInfo {
  displayName?: string;
  notificationEmail?: string;
  schedulingApp?: string;
  notificationSettings?: string;
}

export interface ProjectState {
  projects: Project[];
  activeProject: Project | undefined;
  briefsList: BriefTemplate[];
  templateImages: ImageCategory[];
  userInfo: UserInfo | undefined;
  accountInfo: LynxiAccountInfo | undefined;
  drawerOpen: boolean;
  hideHeader: boolean;
  filesForceUpdate: number;
  showFillBrandingDialog: boolean;
  showAccountPopup: boolean;
}

const initialState: ProjectState = {
  projects: [],
  activeProject: undefined,
  briefsList: [],
  templateImages: [],
  userInfo: undefined,
  drawerOpen: false,
  hideHeader: false,
  accountInfo: undefined,
  filesForceUpdate: 0,
  showFillBrandingDialog: false,
  showAccountPopup: false
};

export const getUserProjects = createAsyncThunk(
  "projects/getUserProjects",
  async (token: string) => {
    const response = await projectsApi.getUserProjects(token);
    return response.projects;
  }
);
export const getQuestioners = createAsyncThunk(
  "projects/getQuestioners",
  async (token: string) => {
    const response = await projectsApi.getAllQuestioners(token);
    return orderBy(
    response.templates.filter(
      (r) => r.type === "survey" || r.type === "cms"
    ),
    ["created"],
    ["desc"])
  }
);

export const refreshActiveProject = createAsyncThunk(
  "projects/updateActiveProject",
  async (token: string, { getState }) => {
    const { projectsData } = getState() as any;
    if (projectsData.activeProject) {
      return await projectsApi.getUserProjectById(
        projectsData.activeProject.id,
        token
      );
    } else return undefined;
  }
);

export const getUserInfo = createAsyncThunk(
  "projects/getUserInfo",
  async (token: string) => {
    const response = await backofficeApi.getUserSettings(token);
    const settingsDic: { [id: string]: any } = JSON.parse(
      response.additionalData
    );
    return {
      displayName: response.displayName,
      notificationEmail: response.notificationsEmail,
      schedulingApp: get(settingsDic, "schedulingApp"),
      notificationSettings: get(settingsDic, "notificationSettings"),
    };
  }
);

export const getUserAccountInfo = createAsyncThunk(
  "projects/getAccountInfo",
  async (token: string) => {
    const response = await backofficeApi.getAccount(token);
    if (response.accounts.length > 0) {
      return response.accounts[0];
    } else {
      return undefined;
    }
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = action.payload;
    },
    addProject: (state, action: PayloadAction<Project>) => {
      state.projects = [...state.projects, action.payload];
    },
    setActiveProject: (state, action: PayloadAction<Project | undefined>) => {
      state.activeProject = action.payload;
    },
    deleteProject: (state, action: PayloadAction<Project>) => {
      state.projects = [
        ...state.projects.filter(
          (project) => project.name !== action.payload.name
        ),
      ];
    },
    addTemplate: (state, action: PayloadAction<BriefTemplate>) => {
      state.briefsList = [action.payload, ...state.briefsList];
    },
    updateTemplateQuestions: (state, action: PayloadAction<BriefTemplate>) => {
      state.briefsList = [
        action.payload,
        ...state.briefsList.filter(
          (template) => template.id !== action.payload.id
        ),
      ];
    },
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
    setHideHeader: (state, action: PayloadAction<boolean>) => {
      state.hideHeader = action.payload;
    },
    setShowBrandingDialog: (state, action: PayloadAction<boolean>) => {
      state.showFillBrandingDialog = action.payload;
    },
    setShowAccountPopup: (state, action: PayloadAction<boolean>) => {
      state.showAccountPopup = action.payload;
    },
    deleteTemlpate: (state, action: PayloadAction<BriefTemplate>) => {
      state.briefsList = [
        ...state.briefsList.filter(
          (template) => template.id !== action.payload.id
        ),
      ];
    },
    setTemplateImages: (state, action: PayloadAction<ImageCategory[]>) => {
      state.templateImages = action.payload;
    },
    setAccountInfo: (state, action: PayloadAction<LynxiAccountInfo>) => {
      state.accountInfo = action.payload;
    },
    setFilesForceUpdate: (state) => {
      state.filesForceUpdate = state.filesForceUpdate + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    }),
      builder.addCase(getQuestioners.fulfilled, (state, action) => {
        state.briefsList = action.payload;
      });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(getUserAccountInfo.fulfilled, (state, action) => {
      state.accountInfo = action.payload;
    });
    builder.addCase(refreshActiveProject.fulfilled, (state, action) => {
      state.activeProject = action.payload;
    });
  },
});

export const {
  setProjects,
  addProject,
  setActiveProject,
  deleteProject,
  addTemplate,
  deleteTemlpate,
  setTemplateImages,
  setDrawerOpen,
  updateTemplateQuestions,
  setHideHeader,
  setAccountInfo,
  setFilesForceUpdate,
  setShowBrandingDialog,
  setShowAccountPopup,
} = projectsSlice.actions;
export default projectsSlice.reducer;
