import * as React from "react";
import { Route, Routes } from "react-router-dom";
// import { BriefPicker } from "../projects/BriefPicker/BriefPicker";
// import { BriefCreator } from "./BriefCreator";
// import { BriefClientViewer } from "./BriefClientViewer";

const LazyNewAiForm = React.lazy(() => import("./NewAiForm"));
const LazyAiItems = React.lazy(() => import("./AiItems"));
const LazyAnaylzeUrlTest = React.lazy(() => import("./AnaylzeUrlTest"));
const LazyHTMLTest = React.lazy(() => import("./GetWebsiteUrl"));
const LazyUsersList = React.lazy(() => import("./UsersList"));
const LazyInvoices = React.lazy(() => import("./Paypal/AddInvoiceForAccount"));

export const AdminRouter: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/ai" element={<LazyAiItems />} />
        <Route path="/ai/:aiId" element={<LazyNewAiForm />} />
        <Route path="/website/text" element={<LazyHTMLTest />} />
        <Route path="/website" element={<LazyAnaylzeUrlTest />} />
        <Route path="/users" element={<LazyUsersList />} />
        <Route path="/invoices" element={<LazyInvoices />} />
      </Routes>
    </>
  );
};
