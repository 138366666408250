import _ from "lodash";
import { CMSState, FormSection, Single } from "./cmsSlice";

export const getSelectedSinglesStucture = (cmsState: CMSState): Single[] => {
  return cmsState.cmsSchema[0].sections.flatMap((section) => {
    return section.singles.filter((single) =>
      cmsState.formQuestions.includes(single.singleKey)
    );
  });
};

export const fixFormSectionIndex = (cmsState: CMSState) => {
  return cmsState.formSectionsQuestions.map((r) => ({
    ...r,
    sectionIndex: cmsState.pages[0].sections.findIndex(
      (s) => s.description === r.name
    ),
  }));
};

export const addSinglesOrigListOrderToSection = (cmsState: CMSState) => {
  const orderedFormSectionsQuestions = _.orderBy(
    fixFormSectionIndex(cmsState),
    ["sectionIndex"],
    ["asc"]
  );
  return orderedFormSectionsQuestions.map((sectionQuestion: FormSection) => {
    const section = cmsState.pages[0].sections[sectionQuestion.sectionIndex];
    return {
      ...sectionQuestion,
      origSinglesOrder: section.singles.map(
        (single: Single) => single.singleKey
      ),
    };
  });
};

export const getSelectedSinglesSectionStucture = (
  cmsState: CMSState
): FormSection[] => {
  const orderedFormSectionsQuestions = _.orderBy(
    fixFormSectionIndex(cmsState),
    ["sectionIndex"],
    ["asc"]
  );
  return orderedFormSectionsQuestions.map((sectionQuestion: FormSection) => {
    const section =
      cmsState.cmsSchema[0].sections[sectionQuestion.sectionIndex];
    const selectedSections = section.singles.filter((single) =>
      sectionQuestion.singlesIds.includes(single.singleKey)
    );
    return { ...sectionQuestion, singles: selectedSections };
  });
};
