import Box from "@mui/material/Box";
import { get } from "lodash";
import React, { FC } from "react";
import { CommentData } from "../api/cmsApi";
import { getPassingDays } from "../utils/DateUtils";

export interface CommentLineProps {
  comment: CommentData;
}

export const CommentLine: FC<CommentLineProps> = ({ comment }) => {
  const getCommentText = () => {
    try {
      return get(JSON.parse(comment.comment), "title", "");
    } catch (_) {
      return comment.comment;
    }
  };

  return (
    <Box
      sx={{
        background: comment.source === "Collaborator" ? "#292B28" : "#fff",
        color: comment.source === "Collaborator" ? "#fff" : "#000",
        boxShadow: "25px 39px 60px 0 rgba(0, 0, 0, 0.07)",
        padding: "8px 10px",
        marginBottom: "6px",
        position: "relative",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          fontFamily: "Nunito",
          fontSize: "12px",
          fontWeight: "normal",
        }}
      >
        {getPassingDays(comment.createdAt)}
      </Box>
      <Box
        sx={{
          fontFamily: "Nunito",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        {getCommentText()}
      </Box>
    </Box>
  );
};
