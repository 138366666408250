import { Box } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { Editor } from "react-draft-wysiwyg";
import classnames from "classnames";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import useAsyncEffect from "use-async-effect";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./BasicRichEditor.scss";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useUpdateCmsWatchedItems } from "../../hooks/useUpdateCmsWatchedItems";
import TreeUtils from "../../utils/TreeUtils";

export interface BasicRichEditorCMSProps {
  value: string;
  onUpdate?: (message: string) => void;
  readonly?: boolean;
  placeHolder?: string;
  forceRender: number;
  cmsKey: string
}

export const BasicRichEditorCMS: FC<BasicRichEditorCMSProps> = ({
  value,
  onUpdate,
  readonly,
  placeHolder,
  forceRender,
  cmsKey
}) => {
  const [editorState, setEditorState] = React.useState<EditorState>(
    EditorState.createEmpty()
  );
  const [init, setInit] = React.useState<boolean>(false);
  const [dirtyFlag, setDirtyFlag] = React.useState<boolean>(false);

  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const cmsState = useAppSelector((state: RootState) => state.cmsData);
  const {updateWatchedItems} = useUpdateCmsWatchedItems()


  useAsyncEffect(async () => {
    if (!init) {
      updateEditorValue(value);
    } 
  }, [value, init]);

  useAsyncEffect(async () => {
    if (value && init) {
      updateEditorValue(value);
    } 
  }, [forceRender, cmsState.forceRendering]);


  const updateEditorValue = (initMessage: string) => {
    const contentBlock = htmlToDraft(initMessage);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  };

  const onBlurEditor = () => {
    setHasFocus(false);
    if (onUpdate && dirtyFlag) {
      const htmlText = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      if (TreeUtils.htmlToText(value) !== TreeUtils.htmlToText(htmlText)) {
         onUpdate(htmlText);
      }
    }
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    if (
      init &&
      newEditorState.getCurrentContent() !== editorState.getCurrentContent()
    ) {
      setDirtyFlag(true);
    }
   
    setInit(true);
    setEditorState(newEditorState); 
  };

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column" }}
        onMouseLeave={() => onBlurEditor()}
      >
        <Editor
          onFocus={() => {
            if (cmsKey !== "") {
              updateWatchedItems([cmsKey])
            }
            setHasFocus(true);
          }}
          readOnly={readonly}
          onBlur={() => setHasFocus(false)} 
          placeholder={`${placeHolder}`}
          wrapperClassName={"paragrpah-wrapper"}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          editorClassName={classnames(
            "paragraphEditor",
            "parEditor",
            hasFocus ? "EditorFocus" : ""
          )}
          toolbarClassName="paragrpah-editor-toolbar"
          toolbar={{
            options: ["inline"],
            inline: {
              inDropdown: false,
              className: readonly ? "boldtexthidden" : "boldtext",
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
              bold: { className: "iconButton" },
              italic: { className: "iconButton" },
              underline: { className: "iconButton" },
            },
          }}
        />
      </Box>
    </>
  );
};
