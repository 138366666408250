import { Box } from "@mui/material";
import * as React from "react";

import "./commentPoints.scss";
import { ReactComponent as ExistCommentSVG } from "../../../assets/cms/existComment.svg";
import { ReactComponent as UnreadCommentSVG } from "../../../assets/cms/newComment.svg";


export interface ImageCommentPointRec {
  top: number;
  left: number;
  currentWidth: number;
  currentHeight: number;
  id: string;
}

export interface ImageCommentPointProps {
  imagePoint: ImageCommentPointRec;
  currentWidth: number;
  currentHeight: number;
  isNewComment?: boolean;
  onShowComment(elem: HTMLDivElement | null, addedLocation: string): void;
  hasNew: boolean;
}

export const ImageCommentPoint: React.FC<ImageCommentPointProps> = ({
  imagePoint,
  currentWidth,
  currentHeight,
  isNewComment,
  onShowComment,
  hasNew,
}) => {
  const getCalculatedHeightPoint = () => {
    return (imagePoint.top * currentHeight) / imagePoint.currentHeight;
  };

  const getCalculatedWidthPoint = () => {
    return (imagePoint.left * currentWidth) / imagePoint.currentWidth;
  };

  const onCommentClick = (event: any) => {
    event.stopPropagation();
    if (!isNewComment) {
      const newPointElem = document.getElementById(imagePoint.id);
      if (newPointElem) {
        onShowComment(newPointElem as HTMLDivElement, imagePoint.id);
      }
    }
  };

  return (
    <Box
      id={isNewComment ? "new_comment" : imagePoint.id}
      sx={{
        position: "absolute",
        marginTop: `${getCalculatedHeightPoint() - 16}px`,
        marginLeft: `${getCalculatedWidthPoint() - 16}px`,
      }}
      onClick={(e) => onCommentClick(e)}
    >
      {/* <Box className={isNewComment ? "c" : "a"}>
      {hasNew && (
        <Box
          sx={{
            position: "absolute",
            top: "-6px",
            right: "-3px",
            width: "12px",
            height: "12px",
            borderRadius: "7px",
            background: "#d70404",
          }}
        />
      )}
        </Box>
      <Box className="b" /> */}
      <Box>
      {isNewComment ? (
        <ExistCommentSVG style={{ transform: "scale(0.8)" }}/>
      ) : hasNew ? <UnreadCommentSVG style={{ transform: "scale(0.8)" }}/> : <ExistCommentSVG style={{ transform: "scale(0.8)" }}/>}
      </Box>  
      
    </Box>
  );
};
