import axios from "axios";
import ApiUtils from "./ApiUtils";

export interface UploadResponse {
  uploadLink: string;
  imageId: string;
  url: string;
}

export interface UploadCmsImageResponse {
  imageUrl: string;
  uploadLink: string;
  file: File;
}

export interface UploadUserImageResponse {
  imageUrl: string;
  uploadLink: string;
}

export interface UploadDocumentResponse {
  uploadLink: string;
  url: string;
  file: File;
}

export interface AddImageForUser {
  description: string;
  imageId: string;
  url: string;
}

class UploadApi {
  getUploadUrl = async (
    metaDataId: string,
    contentType: string,
    token: string
  ): Promise<UploadResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/sites/api/upload/${metaDataId}?contentType=${contentType}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getUploadCMSUrl = async (
    cmsId: string,
    contentType: string,
    token: string
  ): Promise<UploadCmsImageResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/images/cms/${cmsId}/link?contentType=${contentType}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getUploadUrlToUserAccount = async (
    contentType: string,
    accountId: string,
    token: string
  ): Promise<UploadUserImageResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/images/account/${accountId}/link?contentType=${contentType}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  updateUserImages = async (
    metaDataId: string,
    data: AddImageForUser,
    token: string
  ): Promise<string> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/sites/api/upload/${metaDataId}`,
      data,
      ApiUtils.Put(token)
    );
    return response.data;
  };

  uploadImageToAzure = async (
    url: string,
    file: File | Blob,
    fileType: string
  ): Promise<UploadResponse> => {
    const response = await axios.put(
      `${url}`,
      file,
      ApiUtils.PutBinary(fileType)
    );
    return response.data;
  };

  downloadImageToBlob = async (
     url: string): Promise<Blob> => {

      const response = await axios.get(
        `${url}`,
        ApiUtils.GetBlob()
      );
      const file = new Blob([response.data], {type:'image/png'})
      console.log(file.size) // !!! this line
      return file
  }

  getUploadUrlToUserProjectDocumentsAccount = async (
    projectId: string,
    contentType: string,
    token: string
  ): Promise<UploadDocumentResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/storage/project/${projectId}/shared/link?contentType=${contentType}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getUploadUrlToUserProjectDocumentsAccountByEmail = async (
    projectId: string,
    contentType: string,
    email: string
  ): Promise<UploadDocumentResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/storage/project/${projectId}/shared/link?contentType=${contentType}&email=${encodeURIComponent(email)}`,
      ApiUtils.UnsecureGet()
    );
    return response.data;
  };

  getuploadSharedFileToAzureStorage = async (
    projectId: string,
    contentType: string,
    token: string
  ): Promise<UploadDocumentResponse | undefined> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/backoffice/api/storage/project/${projectId}/storage/link?fileExtension=${contentType}`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
    
  };
}

export const uploadApi = new UploadApi();
