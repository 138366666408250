import { Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import _ from "lodash";
import { useAppSelector } from "../../app/hooks";

export interface SocialListProps {
  comp: CMSComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
  padding: number;
}

export const SocialList: React.FC<SocialListProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly,
  padding,
}) => {
  const cmsState = useAppSelector((state) => state.cmsData);

  const onChange = (value: string, idx: number) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.components[idx].value = value;
    onUpdateComp(UpdateCompBuilder(location, deepClone.components[idx]));
  };

  const getSocialValue = (socialComp: CMSComponent) => {
    return _.get(cmsState.cmsItems, socialComp.mapKey, "");
  }

  return (
    <Grid item xs={12}>
      <Typography
        style={{
          marginBottom: "10px",
          fontFamily: "Nunito",
          fontWeight: "normal",
          fontSize: "14px",
        }}
      >
        {comp.description}
      </Typography>
      <Grid container>
        {comp.components.map((socialLink, idx) => (
          <Grid item xs={padding} key={`socaillink-${idx}`}>
            <TextField
              disabled={readonly}
              sx={{
                background: "#f9f9f9",
                "& input.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                },
              }}
              label={socialLink.description.trim()}
              variant="outlined"
              className={socialLink.value !== "" ? "inputWithVal" : ""}
              onChange={(e) => onChange(e.target.value, idx)}
              // value={socialLink.value ? socialLink.value : ""}
              value = {getSocialValue(socialLink)}
              style={padding ===12 ? {width: "100%",  marginBottom: "10px"}:{
                width: "97%",
                marginBottom: "15px",
                float: idx % 2 === 0 ? "left" : "right",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
