import axios from "axios";
import ApiUtils from "./ApiUtils";


export interface StartPaypalSubscriptionResponse {
  id: string
  planId: string
  customId: string
  accountId: string
  subscriptionId: string
  created: string
  userId: string
  status: string
}

export interface PlanInfo {
  id: string
  planId: string
  name: string
  description: string
  numOfProjects: number
  numOfWebSites: number
  numOfSocial: number
  numOfDesigners: number
  active: boolean
}


export interface SubscriptionInfo 
{
  id: string
  planId: string
  accountId: string
  subscriptionId: string
  created: string
  userId: string
  status: string
  subscriptionData: any
  plan: PlanInfo
}

export interface ReplacePaypalSubscriptionLink {
  href: string
  rel: string
  method: string
}

export interface ReplacePaypalSubscriptionResponse {
  links: ReplacePaypalSubscriptionLink[]
  plan_id: string
}

export interface SubscriptionResponse {
  subscription: SubscriptionInfo[]
}


class PaymentApi  {

  getAllPlans = async (token : string): Promise<PlanInfo[]> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/backoffice/api/Paypal/plans`, ApiUtils.Get(token))
    return response.data
  }

  startSubscription = async (token : string, customId: string, planId: string): Promise<StartPaypalSubscriptionResponse> => {
    const response = await axios.put(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscription/start`, {
      planId
    }, ApiUtils.Put(token))
    return response.data
  }


  replaceSubscription = async (subscriptionId: string, planId: string, token : string): Promise<ReplacePaypalSubscriptionResponse> => {
    const response = await axios.post(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscription/${subscriptionId}/revise`, {
      planId
    }, ApiUtils.Post(token))
    return response.data
  }

  declineSubscription = async (token : string, id: string, errorData: any): Promise<StartPaypalSubscriptionResponse> => {
    const response = await axios.patch(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscription/decline`, {
      id, subscriptionId:'', subscriptionData: errorData
    }, ApiUtils.Patch(token))
    return response.data
  }

  cancelSubscription = async (token : string, id: string): Promise<StartPaypalSubscriptionResponse> => {
    const response = await axios.patch(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscription/${id}/abandon`, {
    }, ApiUtils.Patch(token))
    return response.data
  }

  subscriptionApproved = async (id: string, subscriptionId: string, data: any, token : string): Promise<SubscriptionInfo> => {
    const response = await axios.patch(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscription/approved`, {
      id, subscriptionId, transactionId: '', subscriptionData: data
    }, ApiUtils.Patch(token))
    return response.data
  }

  getMyPlans = async (token : string): Promise<SubscriptionResponse> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscriptions`, ApiUtils.Get(token))
    return response.data
  }

  getSusbcriptionData = async (subscriptionId: string, token : string): Promise<SubscriptionInfo> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscriptions/${subscriptionId}`, ApiUtils.Get(token))
    return response.data

  }

  cancelSusbcription = async (subscriptionId: string, reason: string, token : string): Promise<SubscriptionInfo> => {
    const response = await axios.post(`${ApiUtils.serverDomain}/backoffice/api/Paypal/subscriptions/${subscriptionId}/cancel`, {reason}, ApiUtils.Post(token))
    return response.data
  }

}

export const paymentApi = new PaymentApi();




