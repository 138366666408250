import {
  Autocomplete,
  Box,
  FormControl,
  Popper,
  TextField,
  createFilterOptions,
} from "@mui/material";
import * as React from "react";

export interface StaticDropDownProps {
  options: string[];
  value: string | null;
  handleChange(newValue: string | null): void;
  showError: boolean;
  placeHolder: string
  disableOutline?: boolean
  disableClear?: boolean
  background?: string
  allowSolo?: boolean
}


export const StaticDropDown: React.FC<StaticDropDownProps> = ({
  options,
  value,
  handleChange,
  showError,
  placeHolder,
  disableOutline,
  disableClear,
  background,
  allowSolo
}) => {
  const [open, setOpen] = React.useState(false);
  const filter = createFilterOptions<string>();


  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormControl fullWidth>
        <Autocomplete
          disableClearable={disableClear}
          open={open}
          sx={{
            background: "#f9f9f9",
            "& .MuiAutocomplete-listbox": {
              border: "1px solid #fff",
              fontFamily: "Nunito",
            },
            "& .MuiAutocomplete-inputRoot:hover::before": {
              borderBottomColor: "#7C48C2 !important",
            },
            "& .MuiAutocomplete-groupUl": {
              fontFamily: "Nunito",
              color: "red",
            },            
            "& .MuiAutocomplete-inputRoot.Mui-focused::after": {
              borderBottomColor: "#7C48C2",
            },
          }}
          freeSolo={allowSolo}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}          
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
  
            if (params.inputValue !== '') {
              filtered.push(
                `Add "${params.inputValue}"`
              );
            }
            return filtered;
          }}
          disablePortal={true}
          autoHighlight
          options={options}
          PopperComponent={(props) => (
            <Popper
              {...props}
              placement="bottom"
              disablePortal={true}
              modifiers={[
                {
                  name: "flip",
                  enabled: true,
                  options: {
                    altBoundary: true,
                    rootBoundary: "document",
                    padding: 8,
                  },
                },
              ]}
            >
              <Box
                className={"lynxiDropDowm"}
                sx={{ width:'100%' ,border: disableOutline? 'none': "1px solid black", fontFamily: "Nunito" , boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px'}}
              >
                {props.children}
              </Box>
            </Popper>
          )}
          value={value??undefined}
          onChange={(e, newVal) => handleChange(newVal)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{...params.InputProps}}
              autoComplete={"off"}
              placeholder={placeHolder}
              className={'dropDownText'}
              variant={ disableOutline? "standard" : "outlined"}
              sx={{
                width:'100%',
                boxShadow: disableOutline ? 'none': "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
                background: background? '#f9f9f9': '#fff',
                "& input.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                },
              }}             
            />
          )}
        />
      </FormControl>
      {showError && (
        <Box sx={{ color: "red", fontSize: "12px", marginLeft: "3px" }}>
          This field is required
        </Box>
      )}
    </Box>
  );
};
