import { Box } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { DesignerContext } from "../../routes/AppRouter";
import { ColorButton } from "../ProjectUIHelper";
import { Project } from "../projectsSlice";

export interface ChoosePathPageParams {
  project: Project
}

const ChoosePathPage: React.FC <ChoosePathPageParams>= ({project}) => {

  const navigate = useNavigate();
  const context = React.useContext(DesignerContext);  

  const navigateToContentHub = () => {
    navigate(`/projects/${project.id}/cms/${project.cmsDataId}/content?generate=true`)
  }

  const navigateToConnectBrief = () => {
    navigate(`/projects/${project.id}/questionnaire/pick`)  }


  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          display: "flex",
          height: "78px",
        }}
      >
        <Box
          sx={{
            marginLeft: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Hi {context.name}, lets get started!
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          background: "#fff",
          padding: "0 36px",
          alignItems: "center",
          display: "flex",
          height: "60px",
        }}
      ></Box>
      <Box
        sx={{ margin: "50px 0 50px", textAlign: "center", fontSize: "36px", fontWeight:700 }}
      >
        Choose how you want to start
      </Box>
      <Box className={"choosePage"}>
        <Box sx={{ display: "flex", justifyContent: "center", gap:'50px' }}>
        <Box className={"chooseItem"} onClick={() => navigateToContentHub()}>
            <Box className={"chooseBody"}>
              <Box className={"chooseTitle"}>
                Create high-quality content with AI
              </Box>
              <Box className={"chooseMessage"}>
                {
                  "Lynxi will write your client’s unique business story and fill in the missing info."
                }
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box
              className={"imageWrapper"}
              sx={{ height: "240px", position: "relative" }}
            >
              <Box
                className={"chooseImage"}
                sx={{ position: "absolute", bottom: "-7px" }}
              >
                <img
                  src={
                       "/images/choose_path1.svg"
                  }
                  style={{ width: "100%" }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            className={"chooseItem"}
            onClick={() => navigateToConnectBrief()}
          >
            <Box className={"chooseBody"}>
              <Box className={"chooseTitle"}>
                Collect business info from your client
              </Box>
              <Box className={"chooseMessage"}>
                Send a client-friendly form using a template or creating your
                own.
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box
              className={"imageWrapper"}
              sx={{ height: "240px", position: "relative" }}
            >
              <Box
                className={"chooseImage"}
                sx={{ position: "absolute", bottom: "-7px" }}
              >
                <img
                  src={
                      "/images/choose_path2.svg"
                  }
                  style={{ width: "100%" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", gap:'50px',
            marginTop: "20px",
            marginBottom: "50px",
          }}
        >
          <Box sx={{ width: "400px", textAlign: "center" }}>
            <ColorButton onClick={ () => navigateToContentHub()}>Select</ColorButton>
          </Box>
          <Box sx={{ width: "400px", textAlign: "center" }}>
            <ColorButton onClick= { () => navigateToConnectBrief()}>Select</ColorButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChoosePathPage;
