import AddIcon from '@mui/icons-material/Add';
import { Fab, Grid } from "@mui/material";
import * as React from "react";
import '../../cms.scss';
import { CMSComponent, PageSeoInfo, PositionIndex, UpdateComp, UpdateCompBuilder } from '../../cmsSlice';
import _ from 'lodash';
import { PageSeo } from './PageSeo';
import { HelpToolTipOnHover } from '../../../basic/HelpToolTipOnHover';
import { DeleteConfirmationPopup } from '../../../PopUpMessage/DeleteProjectPopup';
import useAsyncEffect from 'use-async-effect';

export interface PagesSeoManagerProps {
  comp: CMSComponent
  location: PositionIndex
	onUpdateComp(updateComp: UpdateComp): void
	readonly?:boolean
  forceUpdate: number
}

export const PagesSeoManager: React.FC<PagesSeoManagerProps> = ({comp, location, onUpdateComp, readonly, forceUpdate}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [indexToDelete, setIndexToDelete] = React.useState<number>();
	const [fakeNumber, setFakeNumber] = React.useState<number>(0);

	const onUpdateList = (item: PageSeoInfo, index: number) => {
		const deepClone = _.cloneDeep(comp);
		deepClone.value[index] = item
		onUpdateComp(UpdateCompBuilder(location, deepClone))   
	}	

	const addNewService = () => {
		const deepClone = _.cloneDeep(comp);
		deepClone.value = [...deepClone.value ,  { title:'', page:'', metaData:'', keywords:''}]
		onUpdateComp(UpdateCompBuilder(location, deepClone))
	}

	const onDeleteItem = (anchor: HTMLDivElement, index: number) => {
    setAnchorEl(anchor);
    setIndexToDelete(index);
  };

  const onCancelDelete = () => {
    setIndexToDelete(undefined);
    setAnchorEl(null);
  };

  useAsyncEffect(async () => {
    setFakeNumber(fakeNumber + 1);
  }, [forceUpdate])

	const onDeleteApproved = async () => {
    if (indexToDelete !== undefined) {
      const deepClone = _.cloneDeep(comp);
      deepClone.value.splice(indexToDelete, 1);
      onUpdateComp(UpdateCompBuilder(location, deepClone));
      setAnchorEl(null);
      setIndexToDelete(undefined);
      setTimeout(() => {
        setFakeNumber(fakeNumber + 1);
      }, 200);
    }
  };

  const onAfterAiGenerated = (item: PageSeoInfo, index:number) => {
    onUpdateList(item, index)
    setTimeout(() => {
      setFakeNumber(fakeNumber + 1);
    }, 200);
  }

  const fixValue = () => {
    if (typeof comp.value === 'string') {
      const value = [{keywords :  "",
      metaData
      : 
      "",
      page
      : 
      "",
      title
      : 
      ""}]
      return value
    } else return comp.value ?? []
  }

  return (
    <div style={{ width: "100%", marginTop: "25px" }} className={"serviceList"} key={`key-${forceUpdate}`}>
      {(fixValue()).map((item:PageSeoInfo, idx: number) => (
        <PageSeo
          key={`service-${idx}-${fakeNumber}`}
          item={item}
          index={idx}
          updateList={onUpdateList}
          readOnly={readonly}
          isAi={comp.isAI}
          showContentPopup={comp.showContentPopup}
          compMapKey={comp.mapKey}
          hideList={comp.hide ?? []}
          onDeleteItem={onDeleteItem}
          onAfterAiGenerated={(item: PageSeoInfo) => onAfterAiGenerated(item, idx)}
        />
      ))}
      {!readonly && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <HelpToolTipOnHover
            position="bottom-end"
            message={comp.action}
            actionNode={
              <Fab
                size="small"
                sx={{
                  backgroundImage:
                    "linear-gradient(143deg, #3023ae -60%, #c86dd7 102%)",
                  color: "#fff",
                  "&:hover": { color: "#000" },
                  width: "35px",
                  height: "35px",
                }}
                onClick={() => addNewService()}
              >
                <AddIcon fontSize="medium" sx={{ width: "18px" }} />
              </Fab>
            }
          ></HelpToolTipOnHover>
        </Grid>
      )}
      <DeleteConfirmationPopup
        title={`Are you sure you want to delete this item ?`}
        description={""}
        placement="left"
        onDeleteSite={() => onDeleteApproved()}
        onClose={() => onCancelDelete()}
        anchorEl={anchorEl}
        autoClose={true}
      />
    </div>
  );
};
