import { Box, Divider, Modal } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ReactComponent as CLoseDialogSvg } from "../assets/closeDialog.svg";

export interface BasicPopupDialogProps {
  header?: React.ReactNode | string;
  show: boolean;
  onClose: () => void;
  width: string;
  children?: React.ReactNode;
  withDivider?: boolean;
  withCloseIcon?: boolean;
  zIndex?: number;
}

const BasicPopupDialog: FC<BasicPopupDialogProps> = ({
  show,
  header,
  onClose,
  width,
  children,
  withDivider,
  withCloseIcon,
  zIndex,
}) => {
  const handleClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        sx={{ zIndex: zIndex ? zIndex : 2400 }}
        hideBackdrop={false}
        disableEnforceFocus
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            outline: "none",            
            width: width,
          }}
        >
          {withCloseIcon && (
            <Box
              sx={{
                position: "absolute",
                cursor: "pointer",
                display: "flex",
                right: "20px",
                top: "10px",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              onClick={() => onClose()}
            >
              <CLoseDialogSvg style={{ transform: "scale(0.8)" }} />
            </Box>
          )}
          <Box
            sx={{
              // borderTopLeftRadius: "30px",
              // borderTopRightRadius: "30px",
              borderRadius: "30px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f4f8f7",
              flexDirection: "column",
              paddingBottom: "10px",
              height: "95vh",
            }}
          >
            {header && (
              <Box
                sx={{
                  paddingTop: "30px",
                  borderTopLeftRadius: "30px",
                  borderTopRightRadius: "30px",
                  width: "100%",
                  display: "flex",
                  paddingBottom: "20px",
                  backgroundColor: "#f4f8f7",
                }}
              >
                {header}
              </Box>
            )}
            {withDivider && (<Divider sx={{ margin: "0 40px 20px" }} flexItem />)}
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BasicPopupDialog;
