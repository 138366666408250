import { useMsal } from "@azure/msal-react";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { ClientUrl } from "../authConfig";

export const LogoutPage = () => {
  const { instance } = useMsal();

  useAsyncEffect(async () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: ClientUrl,
    });
  }, []);

  return <></>;
};
