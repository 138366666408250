import axios from "axios";
import { ProjectActivity } from "../NotificationCenter/notificationsSlice";
import ApiUtils from "./ApiUtils";


export interface ActivitiesResponse {
  activities: ProjectActivity[]
}

export interface ShareEmailInfo {
  emails: string[]
  name: string
  message: string
  subject: string
  isAnonymous?: boolean
  validForInDays: number
}



export interface ScheduleMettingInfo {
    email: string
    link: string
    message: string
    subject: string
}

export interface GetShareLinkResponse {
  link: string
}


class NotificationApi  {
  

  sendScheduleMetting = async (questionnaireId:string, scheduleMettingInfo:ScheduleMettingInfo, token : string): Promise<boolean> => {
    try{
      const response = await axios.post(`${ApiUtils.serverDomain}/briefs/api/notifications/${questionnaireId}/schedule_meeting`,scheduleMettingInfo, ApiUtils.Post(token))
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }

  shareQuestionnaire = async (questionnaireId:string, shareData:ShareEmailInfo, token : string): Promise<boolean> => {
    try{
      const response = await axios.post(`${ApiUtils.serverDomain}/briefs/api/notifications/${questionnaireId}/share_brief`,shareData, ApiUtils.Post(token))
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }


  updateFormReminder = async (formId:string, isOn:boolean, email:string, token : string): Promise<boolean> => {
    try{
      const response = await axios.put(`${ApiUtils.serverDomain}/briefs/api/brief/${formId}/invitation`,{
        validForInDays: isOn ?100:0,
        email
      }, ApiUtils.Put(token))
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }

  
  sendApprovedMail = async (cmsId:string, token : string): Promise<boolean> => {
    try{
      const response = await axios.post(`${ApiUtils.serverDomain}/briefs/api/notifications/${cmsId}/approve_content`, {},ApiUtils.Post(token))
      if (response.status === 200) {
        return response.data
      } else {
        return false
      }
    } catch {
      return false
    }  
  }  

  sendEmailReminder = async (questionnaireId:string, token : string): Promise<boolean> => {
    try{
      const response = await axios.post(`${ApiUtils.serverDomain}/briefs/api/notifications/${questionnaireId}/brief_reminder`, {}, ApiUtils.Post(token))
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }  
  }  


  

  getShareLink = async (questionnaireId:string, email: string, isAnonymous: boolean, validForInDays: number, token : string): Promise<GetShareLinkResponse> => {
    const response = await axios.post(`${ApiUtils.serverDomain}/briefs/api/notifications/${questionnaireId}/share_brief_link`, 
     {email: email, message:'sdsds', subject:'sdsd',isAnonymous, validForInDays}, ApiUtils.Post(token))
    return response.data
  }

  getUserActivities = async (startDate: string, token : string): Promise<ActivitiesResponse> => {
    const response = await axios.post(`${ApiUtils.serverDomain}/backoffice/api/activities`, {
      fromDate: startDate,
      take: 100
    }, ApiUtils.Post(token))
    if (response.status === 200) {
      return response.data
    }
    return {activities: []}
  }

  deleteUserActivitie = async (activityId: string, token : string): Promise<boolean> => {
    const response = await axios.delete(`${ApiUtils.serverDomain}/backoffice/api/activities?id=${activityId}`, ApiUtils.Delete(token))
    if (response.status === 200) {
      return true
    }
    return false
  }

  deleteAllActivitie = async (token : string): Promise<boolean> => {
    const response = await axios.delete(`${ApiUtils.serverDomain}/backoffice/api/activities/all`, ApiUtils.Delete(token))
    if (response.status === 200) {
      return true
    }
    return false
  }

  addUserActivite = async (content: string, projectId: string, token : string): Promise<boolean> => {
    try{
      const response = await axios.post(`${ApiUtils.serverDomain}/backoffice/api/activities/${projectId}/add`, {
        content: content,
      }, ApiUtils.Post(token))
      if (response.status === 200) {
        return true
      }
      return false
    } catch {
      return false
    }  
  }

}

export const notificationApi = new NotificationApi();




