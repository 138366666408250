import * as React from "react";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";

const LazySelectProjectName = React.lazy(
  () => import("./SelectProjectName")
);

export const IntroRouter: FC = () => {

  return (
    <>
      <Box sx={{height: '100vh'}}>
        <Routes>
           <Route
            path="/start_project"
            element={<LazySelectProjectName />}
          />
          <Route
            path="/new_project"
            element={<LazySelectProjectName isNew={true}/>}
          />
        </Routes>
        </Box>
    </>
  );
};
