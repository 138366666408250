import { useMsal } from "@azure/msal-react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { FC } from "react";
import useAsyncEffect from "use-async-effect";
import "./UserPlansDashboard.scss";
import * as React from "react";
import { EmptUserPlansDashboard } from "./EmptUserPlansDashboard";
import { getAccessToken, getAccessTokenSilence } from "../../../auth/auth";
import { SubscriptionInfo, paymentApi } from "../../../api/paymentApi";
import { PaymentHistoryLine } from "./PaymentHistoryLine/PaymentHistoryLine";
import { SubscriptionView } from "./SubscriptionView/SubscriptionView";
import _ from "lodash";
import { InvoiceData, backofficeApi } from "../../../api/backofficeApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { ProjectState } from "../../../projects/projectsSlice";

export const UserPlansDashboard: FC = () => {
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const [subscriptionsList, setSubscriptionList] = React.useState<
    SubscriptionInfo[]
  >([]);
  const [payPalSubscriptionData, setPayPalSubscriptionData] =
    React.useState<any>();
  const [paymentsDetail, setPaymentsDetail] = React.useState<any>([]);
  const [lastSubscription, setLastSubscription] =
    React.useState<SubscriptionInfo>();
  const [invociesList, setInvociesList] = React.useState<InvoiceData[]>([]);
  const projectState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const msalInstance = useMsal();

  const fetchUsePlans = async () => {
    setOnLoading(true);
    const accessToken = await getAccessTokenSilence(msalInstance);
    if (accessToken) {
      paymentApi.getMyPlans(accessToken.accessToken).then((res: any) => {
        setOnLoading(false);
        const _list = (_.orderBy(res.subscription.filter((r:SubscriptionInfo) => r.status === 'Approved' ||r.status === 'Canceled'), ['created'], ['desc']))
        setSubscriptionList(_list);
        if (_list.length > 0) {
          setLastSubscription(_list[0]);
        } else {
          setLastSubscription(undefined);
        }
      });
    }
  };

  const getInvoicesList = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token && projectState.accountInfo?.id) {
      const invoicelistRes = await backofficeApi.getAccountInvoices(
        projectState.accountInfo.id,
        token.accessToken
      );
      if (invoicelistRes && invoicelistRes.invoices) {
        setInvociesList(invoicelistRes.invoices)
      }
    }
  };


  React.useEffect(() => {
    if (lastSubscription) {
      getInvoicesList()
      fetchSubscriptionData(lastSubscription.subscriptionId);
      setTimeout(() => {
        fetchSubscriptionData(lastSubscription.subscriptionId, true);
      }, 5000);
    } 
  }, [lastSubscription]);

  const fetchSubscriptionData = async (
    subscriptionId: string,
    withoutLoading?: boolean
  ) => {
    setOnLoading(!withoutLoading);
    getAccessTokenSilence(msalInstance).then((res: any) => {
      paymentApi
        .getSusbcriptionData(subscriptionId, res.accessToken)
        .then((resSub: any) => {
          setPayPalSubscriptionData(resSub);
          setOnLoading(false);
          setPaymentsDetail(
            _.get(_.get(resSub, "transactions", {}), "transactions", [])
          );
        });
    });
  };

  const onDeleteApproved = async (id: string, reason: string) => {
    setOnLoading(true);
    const accessToken = await getAccessToken(msalInstance);
    if (accessToken) {
      paymentApi.cancelSusbcription(id, reason, accessToken).then(async () => {
        await fetchUsePlans();
        setOnLoading(false);
      });
    }
  };

  useAsyncEffect(fetchUsePlans, []);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          display: "flex",
          height: "78px",
        }}
      >
        <Box
          sx={{
            marginLeft: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          My Billing
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          background: "#fff",
          padding: "0 36px",
          alignItems: "center",
          display: "flex",
          height: "60px",
        }}
      >
        <Box>
          {/* <ProjectsSearch
            startOpen={true}
            onSelectedSearchRes={(val: string) => setSearchQuery(val)}
          /> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}></Box>
      </Box>
      <Box sx={{ margin: "30px 0", display: "flex" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={onLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {!lastSubscription && !onLoading ? (
          <EmptUserPlansDashboard />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              gap: "20px",
              display: "flex",
              justifyContent: "space-between",
              height: "calc(100vh - 340px)",
              margin: "0 5%",
            }}
          >
            {lastSubscription && (
              <Box
                sx={{ display: "felx", flexDirection: "column", width: "40%" }}
              >
                <Box sx={{ marginBottom: "30px" }}>Subscriptions</Box>
                <SubscriptionView
                  subscription={lastSubscription}
                  subscriptionData={payPalSubscriptionData}
                  onDeleteApproved={(reason: string) =>
                    onDeleteApproved(
                      subscriptionsList[0].subscriptionId,
                      reason
                    )
                  }
                />
              </Box>
            )}
            <Box
              sx={{ display: "felx", flexDirection: "column", width: "40%" }}
            >
              <Box sx={{ marginBottom: "30px" }}>Payment history</Box>
              {paymentsDetail.map((item: any, idx: number) => (
                <PaymentHistoryLine key={`history-${idx}`} paymentInfo={item} invoiceList={invociesList}/>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
