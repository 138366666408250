import * as React from "react";
import { get } from "lodash";
import Measure, { BoundingRect } from "react-measure";
import { ImageCommentPoint, ImageCommentPointRec } from "./ImageCommentPoint";
import useAsyncEffect from "use-async-effect";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CMSState, setNewImageComment } from "../../cmsSlice";
import { RootState } from "../../../app/store";
import { CommentData } from "../../../api/cmsApi";

export interface ImageCommentReviewProps {
  imgSrc: string;
  isClient?: boolean;
  onShowComment(elem: HTMLDivElement | null, addedLocation: string): void;
  commentId: string;
}

export const ImageCommentReview: React.FC<ImageCommentReviewProps> = ({
  imgSrc,
  onShowComment,
  isClient,
  commentId,
}) => {
  const [dimensions, setDimensions] = React.useState<
    BoundingRect | undefined
  >();
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const dispatch = useAppDispatch();

  const onNewComment = () => {
    if (mousePosition && dimensions) {
      const newPoint: ImageCommentPointRec = {
        top: mousePosition.y ?? 0,
        left: mousePosition.x ?? 0,
        currentWidth: dimensions ? dimensions.width : 0,
        currentHeight: dimensions ? dimensions.height : 0,
        id: `${new Date().getTime()}`,
      };
      dispatch(setNewImageComment(newPoint));
    }
  };

  useAsyncEffect(async () => {
    if (cmsState.newImageComment) {
      const newPointElem = document.getElementById("new_comment");
      if (newPointElem) {
        onShowComment(
          newPointElem as HTMLDivElement,
          cmsState.newImageComment.id
        );
      }
    }
  }, [cmsState.newImageComment]);

  React.useEffect(() => {
    const imageObj = document.getElementById("myImage");
    if (imageObj) {
      const updateMousePosition = (ev: any) => {
        const bbox_rect = imageObj.getBoundingClientRect();
        const x: number = ev.clientX - bbox_rect.left;
        const y: number = ev.clientY - bbox_rect.top;
        setMousePosition({ x: x ?? 0, y: y ?? 0 });
      };
      imageObj.addEventListener("mousemove", updateMousePosition);
      return () => {
        imageObj.removeEventListener("mousemove", updateMousePosition);
      };
    }
  }, []);

  const updateDimensions = (bounds: BoundingRect | undefined) => {
    setDimensions(bounds);
  };

  const checkForNewItems = (comment: CommentData) => {
    const currentComments = cmsState.comments.filter(
      (c: CommentData) => c.name === comment.name
    );
    if (isClient) {
      return (
        currentComments.filter(
          (c) => c.source !== "Collaborator" && !c.viewedAt
        ).length > 0
      );
    } else {
      return (
        currentComments.filter(
          (c) => c.source === "Collaborator" && !c.viewedAt
        ).length > 0
      );
    }
  };

  const tryJsonParse = (json: string) => {
    try {
      return JSON.parse(json);
    } catch (_) {
      return {};
    }
  };

  return (
    <div style={{ height: dimensions?.height, width: "100%" }}>
      <Measure bounds onResize={(measure) => updateDimensions(measure.bounds)}>
        {({ measureRef }) => (
          <div>
            <div style={{ position: "relative" }} onClick={onNewComment}>
              <div style={{ position: "absolute" }}>
                <img
                  style={{ maxWidth: "100%" }}
                  alt="#"
                  ref={measureRef}
                  src={imgSrc}
                  id={"myImage"}
                />
              </div>
              <div style={{ position: "absolute" }}>
                {cmsState.comments
                  .filter((c) => c.name.indexOf(`${commentId}-`) === 0)
                  .map((comment, idx) => (
                    <ImageCommentPoint
                      key={`point-${idx}`}
                      currentWidth={dimensions ? dimensions.width : 0}
                      currentHeight={dimensions ? dimensions.height : 0}
                      imagePoint={get(tryJsonParse(comment.comment), "info")}
                      onShowComment={onShowComment}
                      hasNew={checkForNewItems(comment)}
                    />
                  ))}
              </div>
              {cmsState.newImageComment && (
                <ImageCommentPoint
                  isNewComment={true}
                  currentWidth={dimensions ? dimensions.width : 0}
                  currentHeight={dimensions ? dimensions.height : 0}
                  imagePoint={cmsState.newImageComment}
                  onShowComment={onShowComment}
                  hasNew={false}
                />
              )}
            </div>
          </div>
        )}
      </Measure>
    </div>
  );
};
