import {
  Box
} from "@mui/material";
import * as React from "react";
import { ReactComponent as SearchCMSSVG } from "../../assets/cms/searchCMS.svg";
import useAsyncEffect from "use-async-effect";
import { ProjectState } from "../projectsSlice";
import { StaticDropDown } from "../../Intro/StaticDropDown";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

export interface ProjectsSearchProps{
  startOpen?: boolean;
  background?: string;
  onSelectedSearchRes(value: string): void;
}

export const ProjectsSearch: React.FC<ProjectsSearchProps> = ({startOpen, background, onSelectedSearchRes}) => {
  const [inSearchMode, setInSearchMode] = React.useState<boolean>(false)
  const projectsState: ProjectState = useAppSelector((state: RootState) => state.projectsData);
  const [selectedText, setSelectedText] = React.useState<string | null>(null)

  const onUpdateSelectedText = (val: string | null) => {
    setSelectedText(val)
    onSelectedSearchRes(val??'')
  }

  useAsyncEffect(async () => {
    if (startOpen) {
      setInSearchMode(true)
    }
  }, []);

  return (
    <Box sx={{display: 'flex', alignItems:'center'}}>
      <SearchCMSSVG style={{cursor:'pointer'}} onClick= { () => setInSearchMode(!inSearchMode)}/>
        <Box className={inSearchMode? 'showSearch': 'hideSearch'} sx={{marginLeft: "10px"}}>
          <StaticDropDown
            disableOutline={true}
            handleChange={onUpdateSelectedText}
            value={selectedText}
            showError={false}
            options={projectsState.projects.map( r=> r.name)}
            placeHolder={"Search project"}
            background={background}
          />
         </Box>
      {!inSearchMode && (
        <Box sx={{marginLeft: "10px", cursor:'pointer'}} onClick= { () => setInSearchMode(!inSearchMode)}>Search</Box>
      )}
    </Box>
  );
};