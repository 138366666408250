import { Badge, Box, Grid } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { ReactComponent as ViewSvg } from "../assets/project/eye.svg";
import { ReactComponent as CommentSVG } from "../assets/project/purpleComment.svg";
import { ReactComponent as TrashSvg } from "../assets/project/trash.svg";
import { ReactComponent as DownloadSVG } from "../assets/storage/direct-download.svg";
import { ReactComponent as ForwardSvg } from "../assets/storage/forward.svg";
import { ReactComponent as TrashDisableSvg } from "../assets/storage/trash_disabled.svg";
import { ReactComponent as CommentAddSvg } from "../assets/storage/commentAdd.svg";

import { SharedFile } from "../api/projectsApi";
import { BasicPageBlocker } from "../basic/BasicPageBlocker";
import { HelpToolTipOnHover } from "../basic/HelpToolTipOnHover";
import { DesignerContext } from "../routes/AppRouter";
import { getPrettyDate } from "../utils/DateUtils";
import { breakTextToLines } from "../projects/ProjectUIHelper";
import CommentsUtils from "../Comments/CommentsUtils";
import { useAccountSettingsData } from "../hooks/useAccountSettingsData";

export interface FileSharedLineProps {
  fileInfo: SharedFile;
  selected: boolean;
  onDeleteFile(fileInfo: SharedFile, anchor: HTMLDivElement): void;
  onShareFile(fileInfo: SharedFile): void;
  onCommentClick(fileInfo: SharedFile, anchor: HTMLDivElement): void;
  isClient?: boolean;
  dateFormat?: string;
}

export const FileSharedLine: FC<FileSharedLineProps> = ({
  fileInfo,
  selected,
  onDeleteFile,
  onShareFile,
  onCommentClick,
  isClient,
  dateFormat,
}) => {
  const designerContext = React.useContext(DesignerContext);
  const [onProcessing, setOnProcessing] = React.useState(false);
  const { getAccountDateFormat } = useAccountSettingsData();

  const onDownloadFile = () => {
    setOnProcessing(true);
    fetch(fileInfo.link).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        setOnProcessing(false);
        a.href = url;
        a.download = `${fileInfo.description}.${fileInfo.link
          .split(".")
          .pop()}`;
        a.click();
      });
    });
  };

  const renderComments = () => {
    const newCount = CommentsUtils.checkForNewItemsCount(
      fileInfo.comments,
      isClient
    );
    return (
      <HelpToolTipOnHover
        position="top"
        message={
          fileInfo.comments.length === 0 ? "Add comment" : "View comments"
        }
        actionNode={
          newCount > 0 ? (
            <Badge badgeContent={newCount} color="error">
              <CommentSVG />
            </Badge>
          ) : fileInfo.comments.length > 0 ? (
            <CommentSVG />
          ) : (
            <CommentAddSvg />
          )
        }
      />
    );
  };

  const onViewFile = () => {
    window.open(fileInfo.link, "_blank");
  };

  return (
    <Box
      className="briefline"
      sx={{
        flexGrow: 1,
        display: "flex",
        background: selected ? "#f5effb" : "#f9f9f9",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
        height: "70px",
        margin: "10px 0px",
        fontSize: "14px",
      }}
    >
      <Grid container spacing={0} alignItems="center" height="100%">
        <Grid
          item
          xs={2}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%" }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              fontWeight: "500",
              height: "100%",
              justifyContent: "flex-start",
              margin: "0 5px 0 10px",
              alignItems: "center",
              wordBreak: "break-all",
            }}
          >
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/files-type/no-file-type.svg";
              }}
              src={`/images/files-type/${fileInfo.link
                .split(".")
                .pop()
                ?.replace(" ", "_")}-file.svg`}
            />
            {fileInfo.description}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ textAlign: "center" }}>
            {designerContext.email.toLowerCase() ===
            fileInfo.email.toLowerCase()
              ? "Me"
              : fileInfo.email.toLowerCase()}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ textAlign: "center" }}>
            {getPrettyDate(
              fileInfo.sharedAt,
              false,
              dateFormat ?? getAccountDateFormat()
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ textAlign: "center" }}>
            {breakTextToLines(
              fileInfo.sharedWithUsers.map((r) => r.toLowerCase()).join("\n")
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ textAlign: "center" }}>
            {fileInfo.sharedWithUsers.filter((r) => r !== "").length > 0
              ? "Shared"
              : "Uploaded"}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingTop: "5px",
              gap: "25px",
              marginRight: "20px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                alignItems: "center",
                position: "relative",
              }}
              onClick={(e) => onCommentClick(fileInfo, e.currentTarget)}
            >
              {renderComments()}
            </Box>
            {!isClient && (
              <Box
                sx={{ cursor: "pointer", alignItems: "center" }}
                onClick={() => onShareFile(fileInfo)}
              >
                <HelpToolTipOnHover
                  position="top"
                  message={"Share file"}
                  actionNode={<ForwardSvg />}
                />
              </Box>
            )}
            <Box
              sx={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => onDownloadFile()}
            >
              <HelpToolTipOnHover
                position="top"
                message={"Download file"}
                actionNode={<DownloadSVG />}
              />
            </Box>
            <Box
              sx={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => onViewFile()}
            >
              <HelpToolTipOnHover
                position="top"
                message={"View file"}
                actionNode={<ViewSvg />}
              />
            </Box>
            {designerContext.email.toLowerCase() ===
            fileInfo.email.toLowerCase() ? (
              <Box
                sx={{ cursor: "pointer", alignItems: "center" }}
                onClick={(e) => onDeleteFile(fileInfo, e.currentTarget)}
              >
                <HelpToolTipOnHover
                  position="top"
                  message={"Delete file"}
                  actionNode={<TrashSvg />}
                />
              </Box>
            ) : (
              <Box sx={{ cursor: "pointer", alignItems: "center" }}>
                <HelpToolTipOnHover
                  position="top"
                  message={"Available for delete only by owner"}
                  actionNode={<TrashDisableSvg />}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <BasicPageBlocker show={onProcessing} />
    </Box>
  );
};
