import { Fab, Grid, Typography, Box } from "@mui/material";
import * as React from "react";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useAsyncEffect from "use-async-effect";
import { InputURLField } from "../../basic/InputURLField";

export interface VideoListCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const VideoListComp: React.FC<VideoListCompProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly,
}) => {
  useAsyncEffect(async () => {
    if (comp.value.length === 0) {
      onChange("", -1);
    }
  }, [comp]);

  const onChange = (value: string, idx: number) => {
    const deepClone = _.cloneDeep(comp);
    if (idx === -1) {
      deepClone.value = [...deepClone.value, value];
    } else {
      deepClone.value[idx] = value;
    }
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const onRemoveValue = (idx: number) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.value.splice(idx, 1);
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const renderPlayer = (value: string) => {
    if (value.toLowerCase().indexOf("youtube.") > -1)
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          width="48px"
          height="48px"
        >
          <path
            fill="#FF3D00"
            d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
          />
          <path fill="#FFF" d="M20 31L20 17 32 24z" />
        </svg>
      );
    if (value.toLowerCase().indexOf("vimeo.") > -1)
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          height="20"
          width="70"
          style={{ fill: "#1ab7ea" }}
          id="svg2"
        >
          <path
            d="m 15.72,9.431 c -0.069,1.514 -1.127,3.588 -3.172,6.22 -2.114,2.749 -3.903,4.124 -5.367,4.124 -0.906,0 -1.673,-0.837 -2.3,-2.512 C 4.463,15.728 4.044,14.194 3.626,12.659 3.161,10.985 2.662,10.147 2.128,10.147 c -0.116,0 -0.524,0.245 -1.221,0.733 L 0.176,9.937 C 0.943,9.263 1.7,8.588 2.445,7.912 3.469,7.028 4.237,6.562 4.75,6.515 5.96,6.399 6.705,7.227 6.985,8.998 c 0.302,1.912 0.511,3.101 0.628,3.566 0.349,1.586 0.733,2.378 1.152,2.378 0.326,0 0.815,-0.515 1.467,-1.543 0.651,-1.029 1,-1.812 1.047,-2.349 0.093,-0.888 -0.256,-1.333 -1.047,-1.333 -0.373,0 -0.757,0.085 -1.151,0.255 0.764,-2.504 2.224,-3.721 4.38,-3.652 1.598,0.047 2.351,1.084 2.259,3.111"
            id="path4"
          />
          <path
            d="m 22.281,1.918 c -0.023,0.58 -0.314,1.136 -0.874,1.669 -0.628,0.602 -1.373,0.903 -2.234,0.903 -1.327,0 -1.968,-0.579 -1.921,-1.737 0.022,-0.602 0.378,-1.182 1.064,-1.738 0.687,-0.555 1.449,-0.834 2.288,-0.834 0.489,0 0.896,0.192 1.223,0.574 0.325,0.382 0.477,0.77 0.454,1.163 z m 3.038,12.419 c -0.652,1.232 -1.548,2.349 -2.689,3.349 -1.56,1.349 -3.119,2.024 -4.679,2.024 -0.723,0 -1.275,-0.233 -1.659,-0.699 -0.384,-0.465 -0.565,-1.069 -0.541,-1.814 0.022,-0.767 0.261,-1.954 0.715,-3.56 0.454,-1.605 0.682,-2.466 0.682,-2.582 0,-0.605 -0.21,-0.908 -0.629,-0.908 -0.139,0 -0.536,0.245 -1.188,0.733 L 14.528,9.937 c 0.745,-0.674 1.49,-1.349 2.235,-2.025 1.001,-0.884 1.746,-1.35 2.236,-1.397 0.768,-0.069 1.332,0.157 1.693,0.679 0.36,0.523 0.494,1.2 0.402,2.035 -0.303,1.415 -0.629,3.212 -0.978,5.392 -0.024,0.998 0.337,1.496 1.082,1.496 0.326,0 0.908,-0.344 1.746,-1.033 0.699,-0.574 1.269,-1.114 1.712,-1.62 l 0.663,0.873"
            id="path6"
          />
          <path
            d="m 47.127,14.336 c -0.652,1.233 -1.548,2.349 -2.689,3.349 -1.56,1.349 -3.12,2.024 -4.679,2.024 -1.514,0 -2.247,-0.837 -2.2,-2.513 0.022,-0.745 0.168,-1.639 0.436,-2.686 0.267,-1.048 0.413,-1.862 0.436,-2.444 0.024,-0.883 -0.245,-1.326 -0.806,-1.326 -0.607,0 -1.331,0.722 -2.172,2.165 -0.887,1.514 -1.367,2.98 -1.436,4.4 -0.05,1.002 0.05,1.77 0.293,2.305 -1.624,0.047 -2.762,-0.221 -3.411,-0.803 -0.582,-0.512 -0.848,-1.361 -0.801,-2.549 0.02,-0.745 0.136,-1.49 0.343,-2.235 0.205,-0.745 0.319,-1.408 0.342,-1.991 0.05,-0.861 -0.268,-1.292 -0.944,-1.292 -0.583,0 -1.213,0.664 -1.888,1.991 -0.676,1.326 -1.049,2.712 -1.119,4.155 -0.05,1.305 0.04,2.212 0.25,2.724 -1.598,0.047 -2.733,-0.29 -3.404,-1.01 -0.558,-0.603 -0.812,-1.52 -0.765,-2.751 0.02,-0.603 0.129,-1.445 0.321,-2.524 0.192,-1.08 0.299,-1.921 0.321,-2.525 0.05,-0.417 -0.06,-0.627 -0.314,-0.627 -0.14,0 -0.536,0.236 -1.188,0.707 L 21.215,9.937 c 0.117,-0.092 0.849,-0.768 2.2,-2.025 0.978,-0.907 1.641,-1.373 1.99,-1.396 0.606,-0.047 1.094,0.203 1.467,0.75 0.372,0.547 0.559,1.182 0.559,1.903 0,0.233 -0.02,0.454 -0.07,0.664 0.349,-0.535 0.756,-1.002 1.222,-1.398 1.071,-0.93 2.27,-1.455 3.597,-1.571 1.141,-0.093 1.955,0.174 2.445,0.803 0.395,0.512 0.581,1.246 0.558,2.2 0.163,-0.139 0.338,-0.291 0.525,-0.454 0.534,-0.628 1.058,-1.128 1.57,-1.501 0.861,-0.629 1.759,-0.978 2.689,-1.048 1.118,-0.093 1.921,0.173 2.41,0.8 0.418,0.51 0.605,1.241 0.559,2.191 -0.024,0.65 -0.181,1.595 -0.472,2.836 -0.292,1.241 -0.436,1.953 -0.436,2.139 -0.024,0.488 0.023,0.824 0.139,1.009 0.117,0.186 0.395,0.278 0.838,0.278 0.326,0 0.907,-0.344 1.746,-1.034 0.698,-0.573 1.269,-1.113 1.712,-1.619 l 0.664,0.872"
            id="path8"
          />
          <path
            d="m 52.295,10.654 c 0.022,-0.625 -0.233,-0.938 -0.767,-0.938 -0.698,0 -1.407,0.481 -2.127,1.442 -0.721,0.961 -1.093,1.882 -1.116,2.762 -0.013,0 -0.013,0.151 0,0.452 1.139,-0.417 2.127,-1.053 2.964,-1.911 0.674,-0.741 1.022,-1.344 1.046,-1.807 z m 7.927,3.646 c -0.675,1.117 -2.002,2.232 -3.981,3.348 -2.467,1.418 -4.971,2.126 -7.508,2.126 -1.885,0 -3.237,-0.628 -4.051,-1.885 -0.582,-0.861 -0.861,-1.885 -0.838,-3.072 0.023,-1.885 0.862,-3.677 2.515,-5.377 1.815,-1.862 3.957,-2.794 6.425,-2.794 2.282,0 3.492,0.93 3.632,2.787 0.093,1.184 -0.559,2.404 -1.956,3.658 -1.49,1.371 -3.365,2.241 -5.622,2.612 0.418,0.581 1.046,0.871 1.885,0.871 1.676,0 3.504,-0.426 5.483,-1.279 1.42,-0.599 2.538,-1.221 3.353,-1.866 l 0.663,0.871"
            id="path10"
          />
          <path
            d="m 65.755,11.828 c 0.023,-0.63 -0.064,-1.207 -0.262,-1.732 -0.198,-0.524 -0.484,-0.788 -0.855,-0.788 -1.188,0 -2.166,0.642 -2.933,1.925 -0.653,1.05 -1.003,2.17 -1.048,3.358 -0.024,0.584 0.081,1.098 0.314,1.54 0.255,0.514 0.616,0.77 1.083,0.77 1.047,0 1.944,-0.617 2.689,-1.854 0.628,-1.027 0.965,-2.1 1.012,-3.219 z m 3.946,0.132 c -0.093,2.139 -0.884,3.987 -2.374,5.544 -1.49,1.557 -3.342,2.336 -5.553,2.336 -1.839,0 -3.236,-0.593 -4.19,-1.779 -0.698,-0.883 -1.083,-1.987 -1.152,-3.311 -0.118,-2 0.604,-3.836 2.165,-5.51 1.676,-1.859 3.782,-2.789 6.32,-2.789 1.629,0 2.863,0.547 3.702,1.639 0.792,1 1.152,2.29 1.082,3.87"
            id="path12"
          />
        </svg>
      );
  };

  // const calcColumns = () => {
  //  if (comp.value.length % 2 === 0) {
  //     return 2;
  //   } else return 7;
  // };

  return (
    <Grid item xs={12}>
      <Typography
        style={{
          paddingBottom: "10px",
          fontFamily: "Nunito",
          fontWeight: "normal",
          fontSize: "14px",
        }}
      >
        {comp.description}
      </Typography>
      <Grid container>
        {comp.value.map((link: string, idx: number) => (
          <Grid item xs={10} key={`video-${idx}`}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              {!readonly && (
                <Box sx={{ display: "flex", width: "56px" }}>
                  {(link !== "" || idx < comp.value.length) && (
                    <Fab
                      sx={{
                        background: "#000",
                        color: "#fff",
                        transform: "scale(0.4)",
                        "&:hover": { color: "#000" },
                      }}
                      onClick={() => onRemoveValue(idx)}
                    >
                      <RemoveIcon />
                    </Fab>
                  )}
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <InputURLField
                  handleChange={(value) => onChange(value, idx)}
                  label={""}
                  required={false}
                  defaultValue={link}
                  disabled={readonly}
                  id={`video-${idx}-url`}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "10px",
                  width: "70px",
                  height: "48px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {renderPlayer(link)}
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid item xs={2} sx={{ alignSelf: "center !important" }}>
          {!readonly && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Fab
                sx={{
                  background: "#000",
                  color: "#fff",
                  transform: "scale(0.6)",
                  "&:hover": { color: "#000" },
                }}
                onClick={() => onChange("", -1)}
              >
                <AddIcon />
              </Fab>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
