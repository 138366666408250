import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useState } from "react";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../cms.scss";
import { ReactComponent as SmallTrashSvg } from "../../../assets/project/smallTrash.svg";
import {
  CMSComponentBuilder,
  CMSState,
  PageSeoInfo,
  PositionIndexBuilder,
} from "../../cmsSlice";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { SimpleTextComp } from "../SimpleTextComp";
import { ParagraphComp } from "../ParagraphComp";
import { HelpToolTipOnHover } from "../../../basic/HelpToolTipOnHover";
import { PurpleButton } from "../../../projects/ProjectUIHelper";
import { getAccessTokenSilence } from "../../../auth/auth";
import { useMsal } from "@azure/msal-react";
import { adminApi } from "../../../api/adminApi";
import { get} from "lodash";
import AiDialogForPageSeo from "../../../Social/Posts/NewPostSwitcher/AiDialogForPageSeo";
import _ from "lodash";

export interface PageSeoProps {
  item: PageSeoInfo;
  index: number;
  updateList(item: PageSeoInfo, index: number): void;
  onAfterAiGenerated(item: PageSeoInfo, index: number): void;
  onDeleteItem(anchor: HTMLDivElement, index: number): void;
  readOnly?: boolean;
  showContentPopup?: boolean;
  compMapKey?: string;
  hideList: string[];
  isAi?: boolean;
}

export const PageSeo: React.FC<PageSeoProps> = ({
  item,
  index,
  updateList,
  onDeleteItem,
  readOnly,
  showContentPopup,
  onAfterAiGenerated,
  compMapKey,
  hideList,
  isAi,
}) => {
  const [title, setTitle] = useState<string>(item.title);
  const [pageName, setPageName] = useState<string>(item.page);
  const [metaData, setMetaData] = useState<string>(item.metaData);
  const [keyWords, setKeyWords] = useState<string>(item.keywords);
  const [showAiContentPopup, setShowAiContentPopup] =
    React.useState<boolean>(false);
  const [fake, setFake] = useState<number>(0);
  const msalInstance = useMsal();
  const [onProcessingAi, setOnProcessingAi] = useState<boolean>(false);
  // const [dirtyFlag, setDirtyFlag] = useState<boolean>(false);

  const fakePosition = PositionIndexBuilder(index, index, index, index, index);

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  const getNewSuggestionFromAi = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const aiItemList = await adminApi.getAllAiItems(token.accessToken);
      const aiItemsForCMS = aiItemList.items.filter(
        (item) =>
          item.cmsIds.length > 0 &&
          item.cmsIds.indexOf(compMapKey??"") > -1 &&
          item.tokens.length > 0
      );
      // SUB_CATEGORY_NAME|#|COMPANY_NAME|#|COMPANY_ADDRESS|#|COMPANY_WEBSITE|#|PAGE_NAME
      if (aiItemsForCMS.length === 1) {
        const dic = cmsState.cmsItems; //TreeUtils.extractDicFromCms(cmsState.pages);
        const subCategory = get(get(dic, "40", ""), "value", "");
        const businessName = get(dic, "42", "");
        const companyAddress = get(dic, "1", "");
        const companyWebsite = get(dic, "5", "");
        const usedAnswers: { [id: string]: string } = {
          SUB_CATEGORY_NAME: subCategory,
          COMPANY_NAME: businessName,
          COMPANY_ADDRESS: companyAddress,
          COMPANY_WEBSITE: companyWebsite,
          PAGE_NAME: pageName,
        };
        const aiResponse = await adminApi.processAiTemaplate(
          cmsState.project ? cmsState.project.id :'',
          { id: aiItemsForCMS[0].id, data: usedAnswers, cmsIds: [] },
          token.accessToken
        )
        const data = (aiResponse??[]).join(" ").trim();
        return data;
      } else {
        return "";
      }
    }
    return "";
  }

  const onAiFinish = (postText: string, aiFailed?: boolean) => {
    setOnProcessingAi(false);
    if (postText !== "") {
      const aiPageSeo = JSON.parse(postText);
      onAfterAiGenerated(
        {
          title: _.get(aiPageSeo, 'titleTag', title),
          page: pageName,
          metaData: _.get(aiPageSeo, 'metaDescription', metaData),
          keywords: _.get(aiPageSeo, 'seoKeyWords', keyWords),
        },
        index
      );
    }
  };

  // useAsyncEffect(() => {
  //   if (dirtyFlag) {
  //     updateList(
  //       {
  //         title: title,
  //         page: pageName,
  //         metaData: metaData,
  //         keywords: keyWords,
  //       },
  //       index
  //     );
  //   }
  // }, [title, pageName, metaData, keyWords]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        outline: "1px solid #d9dedc",
        borderBottomLeftRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: "20px 40px",
          position: "relative",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <SimpleTextComp
              comp={CMSComponentBuilder(
                [],
                "Page",
                "",
                pageName !== undefined ? pageName : "",
                "",
                ""
              )}
              onUpdateComp={(item) => {
                setPageName(item.comp.value)
                updateList(
                  {
                    title: title,
                    page: item.comp.value,
                    metaData: metaData,
                    keywords: keyWords,
                  },
                  index
                );
              }}
              location={fakePosition}
              readonly={readOnly}
              padding={12}
            />
          </Box>
          {pageName && pageName !== "" && (
            <PurpleButton
              onClick={() => setOnProcessingAi(true)}
              sx={{ padding: "0px 15px" }}
            >
              Lynxi AI
            </PurpleButton>
          )}
        </Box>
        <SimpleTextComp
          comp={CMSComponentBuilder(
            [],
            "Title",
            "",
            title !== undefined ? title : "",
            "",
            ""
          )}
          onUpdateComp={(item) => {
            setTitle(item.comp.value)
            updateList(
              {
                title: item.comp.value,
                page: pageName,
                metaData: metaData,
                keywords: keyWords,
              },
              index
            );
          }}
          location={fakePosition}
          readonly={readOnly}
          padding={12}
        />

        <Grid container spacing={3}>
          <ParagraphComp
            comp={CMSComponentBuilder(
              [],
              "Meta Description",
              "",
              metaData,
              "",
              "paragraph",
              true,
              ""
            )}
            key={`seo-meta-${index}-${fake}`}
            onUpdateComp={(item) => {
              setMetaData(item.comp.value)
              updateList(
                {
                  title: title,
                  page: pageName,
                  metaData: item.comp.value,
                  keywords: keyWords,
                },
                index
              );
            }}
            location={fakePosition}
            forceUpdate={0}
            padding={
              cmsState.mobileView || hideList.indexOf("Image") !== -1 ? 12 : 6
            }
            readonly={readOnly}
            showContentPopup={false}
            onShowContentPopupDialog={() => setShowAiContentPopup(true)}
          />
          <ParagraphComp
            comp={CMSComponentBuilder(
              [],
              "Keywords",
              "",
              keyWords,
              "",
              "paragraph",
              true,
              ""
            )}
            key={`seo-keywords-${index}-${fake}`}
            onUpdateComp={(item) => {
              setKeyWords(item.comp.value)
              updateList(
                {
                  title: title,
                  page: pageName,
                  metaData: metaData,
                  keywords: item.comp.value,
                },
                index
              );
            }}
            location={fakePosition}
            forceUpdate={0}
            padding={
              cmsState.mobileView || hideList.indexOf("Image") !== -1 ? 12 : 6
            }
            readonly={readOnly}
            showContentPopup={false}
            onShowContentPopupDialog={() => setShowAiContentPopup(true)}
          />
        </Grid>

        {!readOnly && (
          <Box
            sx={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={(e) => onDeleteItem(e.currentTarget, index)}
          >
            <HelpToolTipOnHover
              position="left"
              message={"Delete item"}
              actionNode={<SmallTrashSvg />}
            />
          </Box>
        )}
        <AiDialogForPageSeo
        projectId={cmsState.projectId ?? ""}
        pageName={pageName}
        mapKey={compMapKey??''}
        startAiProcess={onProcessingAi}
        onFinish={onAiFinish}
        timeOut={5000}
        overRideTitle={
          "Your page's SEO text is being generated by Lynxi AI..."
        }
      />
      </Box>
    </Box>
  );
};
