import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
} from "@mui/material";
import React, { FC } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export interface BasicContextMenuProps {
  title: React.ReactNode | string;
  menuItems: ContextMenuItem[];
  icon?: React.ReactNode;
}

export interface ContextMenuItem {
  label: string;
  icon: React.ReactNode;
  func(e:any): void;
}

export const BasicContextMenu: FC<BasicContextMenuProps> = ({
  title,
  menuItems,
  icon
}) => {
  const [popupAnchorEl, setPopupAnchorEl] =
    React.useState<HTMLDivElement | null>(null);
  const [showProjectOption, setShowProjectOption] =
    React.useState<boolean>(false);

  const handleClickAway = () => {
    setPopupAnchorEl(null);
    setShowProjectOption(false);
  };

  const setPopoverAnchor = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setPopupAnchorEl(event.currentTarget);
    setShowProjectOption(true);
  };

  const handleMenuItemClick = (e: any, func: any) => {
    e.stopPropagation();
    e.preventDefault();
    setPopupAnchorEl(null);
    func(e);
  }

  return (
    <Box>
      <ClickAwayListener onClickAway={() => handleClickAway()}>
        <Box sx={{display:'flex', fontWeight:'normal'}}>
          <Box>{title ?? ""}</Box>
        <Box
          onClick={(e) => setPopoverAnchor(e)}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            paddingLeft: "0px",
          }}
        >          
          {icon ?? <KeyboardArrowDownIcon fontSize="small" sx={{ marginLeft: "5px" }} />}
        </Box>
        </Box>
      </ClickAwayListener>
      <Popper
        open={showProjectOption}
        anchorEl={popupAnchorEl}
        placement={"bottom-start"}
        transition
        style={{ zIndex: 10000 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={2}>
              <Box
                sx={{
                  // boxShadow: "0 9px 15px 0 rgba(0, 0, 0, 0.07)",
                  background: "#f5f8f7",
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: 400,
                  padding: "10px 15px",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  {menuItems.map((item, index) => (
                    <Box
                      key={`contexc-menu-item-${index}`}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={ (e) => handleMenuItemClick(e, item.func)}
                    >
                      <Box sx={{ marginRight: "20px" }}>{item.icon}</Box>
                      {item.label}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};
