import { FC } from "react";
import * as React from "react";
import { BlackMessageDialog } from "./BlackMessageDialog";
import { Box } from "@mui/material";

export interface DeleteMessageDialogProps {
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  onClose(): void;
  onSecondAction?(): void;
  onDelete(): void;
  actionText?: string;
  secondActionText?: string;
  noSecondAction?: boolean;
}

export const DeleteMessageDialog: FC<DeleteMessageDialogProps> = ({
  title,
  message,
  onClose,
  onDelete,
  onSecondAction,
  actionText,
  secondActionText,
  noSecondAction,
}) => {
  return (
    <BlackMessageDialog
      onClose={() => onClose()}
      open={true}
      hideDialogClose={true}
      extraWidth={"450px"}
      icon={
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fillRule="nonzero" stroke="#FFF" fill="none">
            <path d="M23.8 4.6H2.2a1.2 1.2 0 1 0 0 2.4h2.4v14.4c0 1.988 1.612 3.6 3.6 3.6h9.6c1.988 0 3.6-1.612 3.6-3.6V7h2.4a1.2 1.2 0 1 0 0-2.4zm-12 13.2a1.2 1.2 0 1 1-2.4 0v-6a1.2 1.2 0 1 1 2.4 0v6zm4.8 0a1.2 1.2 0 1 1-2.4 0v-6a1.2 1.2 0 1 1 2.4 0v6zM10.6 3.4h4.8a1.2 1.2 0 1 0 0-2.4h-4.8a1.2 1.2 0 1 0 0 2.4z" />
          </g>
        </svg>
      }
    >
      <Box
        sx={{
          dispaly: "flex",
          flexDirection: "column",
          position: "relative",
          color: "#fff",
        }}
      >
        <Box sx={{ fontWeight: 800 }}>{title}</Box>
        <Box sx={{ marginTop: "10px" }}>{message}</Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: noSecondAction ? "flex-end":"space-between",
            marginTop: "20px",
          }}
        >
          {!noSecondAction && (
            <>
              {onSecondAction ? (
                <Box
                  sx={{ color: "#fff", cursor: "pointer" }}
                  onClick={() => onSecondAction()}
                >
                  {secondActionText ? secondActionText : "Cancel"}
                </Box>
              ) : (
                <Box
                  sx={{ color: "#fff", cursor: "pointer" }}
                  onClick={() => onClose()}
                >
                  Cancel
                </Box>
              )}
            </>
          )}

          <Box
            sx={{ color: "#fff", cursor: "pointer" }}
            onClick={() => onDelete()}
          >
            {actionText ? actionText : "Delete"}
          </Box>
        </Box>
      </Box>
    </BlackMessageDialog>
  );
};
