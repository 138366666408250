import { Box, TextField } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { CMSState } from "../cmsSlice";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { BasicDraggableDialog } from "../../basic/BasicDraggableDialog";

export interface EditorNewSectionPopupProps {
  onClose(): void;
  onAddSection(sectionName: string): void;
  initialSectionName?: string;
  sectionIndex: number;
}

export const EditorNewSectionPopup: FC<EditorNewSectionPopupProps> = ({
  onClose,
  onAddSection,
  initialSectionName,
  sectionIndex,
}) => {
  const [sectionName, setSectionName] = React.useState<string>(
    initialSectionName ?? ""
  );
  const [showError, setShowError] = React.useState<boolean>(false);
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  const onAddSectionClick = () => {
    if (cmsState.pages.length > 0) {
      if (
        cmsState.pages[0].sections.find(
          (section, index) =>
            section.description.toLowerCase() === sectionName.toLowerCase() &&
            sectionIndex !== index
        )
      ) {
        setShowError(true);
      } else {
        onAddSection(sectionName);
      }
    }
  };

  return (
    <BasicDraggableDialog
      onClose={onClose}
      title={initialSectionName ? "Update menu item" : "New menu item"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "0 auto 20px",
        }}
      >
        <Box sx={{ fontWeight: 400, marginTop: "40px", marginBottom: "35px" }}>
            <Box
              sx={{
                fontFamily: "Nunito",
                fontSize: "16px",
                fontWeight: "normal",
                marginBottom: "10px",
              }}
            >
              {initialSectionName? 'Change menu item': 'Add a new menu item to your Content Hub.'}
            </Box>
          <TextField
            sx={{
              flexGrow: 1,
              width: "100%",
              background: "#f9f9f9",
              "& input.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
              },
            }}
            inputProps={{ className: "dropDownOther" }}
            placeholder={"Enter a title"}
            maxRows={1}
            value={sectionName}
            onChange={(e) => {
              setShowError(false);
              setSectionName(e.target.value);
            }}
          />
          {showError && (
            <Box sx={{ color: "red", fontSize: "12px", marginLeft: "3px" }}>
              Menu item already exists
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
            Cancel
          </Box>
          <ColorButton
            onClick={() => onAddSectionClick()}
            disabled={sectionName === ""}
          >
            {initialSectionName ? "Update" : "Add"}
          </ColorButton>
        </Box>
      </Box>
    </BasicDraggableDialog>
  );
};
