import React, { FC } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useAsyncEffect from "use-async-effect";
import { getAccessTokenSilence } from "../auth/auth";
import { useMsal } from "@azure/msal-react";
import { PlanInfo, SubscriptionInfo, paymentApi } from "../api/paymentApi";
import { Box } from "@mui/material";
import { PackageItemViewer } from "./PackageItem/PackageItemViewer";
import {
  AValiablePackages,
  PlanPakageInfo,
  TestPackage,
} from "./PackagesConfig";
import { BasicPageBlocker } from "../basic/BasicPageBlocker";
import { useAccountSubscriptions } from "../hooks/useAccountSubscriptions";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

export const PaymentPackages: FC = () => {
  const [planList, setPlanList] = React.useState<PlanInfo[]>([]);
  const [userExistingPlan, setUserExistingPlan] = React.useState<PlanInfo>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentCycleName, setCurrentCycleName] =
    React.useState<string>("month");
  const [payPalSubscriptionData, setPayPalSubscriptionData] =
    React.useState<any>();
  const msalInstance = useMsal();
  const { getActiveSubscription, getPaypalSubscription } =
    useAccountSubscriptions();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    window.dataLayer.push({ event: "packages_page" });

    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      paymentApi.getAllPlans(token.accessToken).then(async (res: any) => {
        setPlanList(res);
        const payPalInfo = await getPaypalSubscription();
        setPayPalSubscriptionData(payPalInfo);
        const planId = _.get(
          _.get(payPalInfo, "subscriptionDetails", {}),
          "plan_id",
          ""
        );
        if (planId) {
          const usedPlan = res.find((plan: PlanInfo) => plan.planId === planId);
          if (usedPlan) {
            setUserExistingPlan(usedPlan);
          }
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <BasicPageBlocker show={loading} />
      ) : (
        planList &&
        planList.length > 0 && (
          <>
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center",
                display: "flex",
                height: "78px",
              }}
            >
              <Box
                sx={{
                  marginLeft: "50px",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  color: "#666",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => navigate("/projects")}
              >
                My Projects
              </Box>
              <Box
                sx={{
                  marginLeft: "50px",
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#666",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/forms")}
              >
                Forms
              </Box>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                background: "#fff",
                padding: "0 36px",
                alignItems: "center",
                display: "flex",
                height: "60px",
              }}
            ></Box>
            <PayPalScriptProvider
              options={{
                // debug:true,
                clientId:
                  "AYHnLI74XgLl84ugABYLv6HsguyJBmgV1chBP24F22npoPv-KZTGXF1GpWWEpEnF-kL7npdmEddYIudx",
                vault: true,
                intent: "subscription",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "calc(100vh - 270px)",
                  overflowY: "scroll",
                }}
              >
                <Box
                  sx={{
                    margin: "30px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#1E1C1C",
                        fontWeight: 700,
                        fontSize: "36px",
                      }}
                    >
                      Grow your business with Lynxi
                    </Box>
                    <Box>
                      Get started for free and choose a paid plan when you’re
                      ready to gather client content
                      <br /> faster and move projects forward with ease.
                    </Box>
                    <Box
                      sx={{
                        color: "#1e1c1c",
                        fontSize: "18px",
                        marginTop: "10px",
                        fontWeight: 700,
                      }}
                    >
                      Save 15% with annual billing.
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div className="switch_menu">
                    <ul
                      className="nav nav-pills priceSwitch titleFonts"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        {" "}
                        <button
                          onClick={() => setCurrentCycleName("month")}
                          className={
                            currentCycleName === "month" ? "active" : "nav-link"
                          }
                          id="monthly-tab"
                        >
                          Monthly
                        </button>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <button
                          onClick={() => setCurrentCycleName("year")}
                          className={
                            currentCycleName !== "month" ? "active" : "nav-link"
                          }
                          id="yearly-tab"
                        >
                          Yearly
                        </button>
                      </li>
                    </ul>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "12px",
                    maxWidth: "1460px",
                    margin: "0 auto",
                    marginTop: "40px",
                  }}
                >
                  {(window.__lynxi_env__ !== "prod"
                    ? [...AValiablePackages]
                    : AValiablePackages
                  ).map((plan: PlanPakageInfo, idx: number) => (
                    <PackageItemViewer
                      key={`plan-${idx}`}
                      planPackage={plan}
                      userExistingPlan={userExistingPlan}
                      userCurrentPlanId={userExistingPlan?.planId}
                      currentCycleName={currentCycleName}
                      payPalSubscriptionData={payPalSubscriptionData}
                    />
                  ))}
                </Box>
              </Box>
            </PayPalScriptProvider>
          </>
        )
      )}
    </div>
  );
};
