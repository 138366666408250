import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";

export interface BasicEmptyStageProps {
  icon: React.ReactNode;
  children: React.ReactNode;
}

export const BasicEmptyStage: FC<BasicEmptyStageProps> = ({
  icon,
  children,
}) => {
  return (
    <>
      <Box sx={{ padding: "20px 50px 20px", display: "flex" }}>
        <Box
          sx={{
            marginRight: "30px",
            width: "210px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingBottom: "20px",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
