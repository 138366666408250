import { Grid, TextField } from "@mui/material";
import * as React from "react";
import { AccountMember } from "../../../api/backofficeApi";

export interface MemberLineProps {
  memberInfo: AccountMember
}

export const MemberLine: React.FC<MemberLineProps> = ({
  memberInfo
}) => {
  return (
    <Grid item xs={12}>
      <Grid container gap={"10px"}>
        <Grid item xs={3}>
           <TextField
              sx={{ width: "100%", background: "#f9f9f9", "& input.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important"
              } }}
              className={'inputWithValDisabled'}
              label={'Name'}
              variant="outlined"
              value={memberInfo.name}
              disabled={true}
              placeholder={'Member name'}
            />
        </Grid>
        <Grid item xs={5}>
           <TextField
              sx={{ width: "100%", background: "#f9f9f9", "& input.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important"
              } }}
              className={'inputWithValDisabled'}
              label={'Email'}
              variant="outlined"
              value={memberInfo.email.toLowerCase()}
              disabled={true}
              placeholder={'Member email'}
            />
        </Grid>
        <Grid item xs={2}>
           <TextField
              sx={{ width: "100%", background: "#f9f9f9", "& input.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important"
              } }}
              className={'inputWithValDisabled'}
              label={'Role'}
              variant="outlined"
              value={memberInfo.role}
              disabled={true}
              placeholder={'Member email'}
            />
        </Grid>
      </Grid>
    </Grid>
  );
};
