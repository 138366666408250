export default class ApiUtils {
  static domain =
    window.__lynxi_env__ === "local"
      ? "localhost:5002"
      : window.__lynxi_env__ === "prod"
      ? "lynxi-gateway-prod-us.azurewebsites.net"
      : window.__lynxi_env__ === "staging"
      ? "lynxi-staging-gateway.azurewebsites.net"
      : "affilisens-dev-gateway.azurewebsites.net";
  static serverDomain = `https://${ApiUtils.domain}`;

  static Get = (token: string): object => ({
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function () {
      return true;
    },
  });

  static GetWithParams = (token: string, params: any): object => ({
    method: "GET",
    mode: "cors",
    credentials: "include",
    params: params,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function () {
      return true;
    },
  });

  static Put = (token: string): object => ({
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function () {
      return true;
    },
  });

  static Patch = (token: string): object => ({
    method: "PATCH",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function () {
      return true;
    },
  });

  static Post = (token: string): object => ({
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function () {
      return true;
    },
  });

  static Delete = (token: string): object => ({
    method: "DELETE",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function () {
      return true;
    },
  });

  static DeleteWithData = (token: string, data: any): object => ({
    method: "DELETE",
    mode: "cors",
    data: data,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function () {
      return true;
    },
  });

  static PutBinary = (type: string): object => ({
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: {
      "x-ms-blob-content-type": `${type}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/octet-stream",
      "x-ms-blob-type": "BlockBlob",
    },
    validateStatus: function () {
      return true;
    },
  });


  static GetBlob = (): object => ({
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    validateStatus: function () {
      return true
    }
  })

  static UnsecurePut = (): object => ({
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    validateStatus: function () {
      return true;
    },
  });

  static UnsecurePost = (): object => ({
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    validateStatus: function () {
      return true;
    },
  });


  static UnsecureGet = (): object => ({
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    validateStatus: function () {
      return true;
    },
  });

  static UnsecureGetWithParams = (params: any): object => ({
    method: "GET",
    mode: "cors",
    credentials: "include",
    params: params,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    validateStatus: function () {
      return true;
    },
  });
}
