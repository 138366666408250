import { Box } from "@mui/material";
import * as React from "react";
import "./cms.scss";
import { ReactComponent as EditorFlowSvg } from "../assets/siteEditor/openSiteFlow.svg";

export const CMSActionBar: React.FC = () => {
  const [showEditorFlow, setShowEditorFlow] = React.useState<boolean>(false);

  return (
    <Box
      sx={{
        background: "#6c3dab",
        height: "100vh",
        width: "60px",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          cursor: "pointer",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <EditorFlowSvg onClick={() => setShowEditorFlow(true)} />
      </Box>
      {showEditorFlow && (
        <StagesManager
          show={showEditorFlow}
          onClose={() => setShowEditorFlow(false)}
        />
      )} */}
    </Box>
  );
};
