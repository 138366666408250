import { Box, Grid } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import { RenderImage } from "./RenderImage";
import parse from "html-react-parser";

export interface SingleImageCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const SingleImageComp: React.FC<SingleImageCompProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly,
}) => {
  const updateImage = (index: number, img: string) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.value = img;
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  return (
    <>
      <Grid item xs={comp.colSize ? comp.colSize : comp.fullWidth ? 12 : 6}>
        <Box paddingTop={"7px"} className={"compDescription"}>
          <Box
            sx={{
              paddingBottom: "10px",
              fontFamily: "Nunito",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {comp.title && (
              <>
                {comp.title}
                <br />
              </>
            )}
            <div>{parse(comp.description)}</div>
          </Box>
          <RenderImage
            value={comp.value}
            fullWidth={comp.fullWidth}
            index={0}
            center={false}
            updateImage={updateImage}
            removeImage={() => updateImage(0, "")}
            readonly={readonly}
            onlyUpload={comp.onlyUpload}
            size={comp.size}
            newHeight={comp.height ? comp.height : undefined}
            display={comp.display}
            allfiles={false}
            singleFile={true}
            isAccount={comp.mapKey==='logo'}
          />
        </Box>
      </Grid>
    </>
  );
};
