import {
  Box, Divider, Typography
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Project } from "../projectsSlice";
import * as React from "react";
import { ColorButton } from "../ProjectUIHelper";


export interface CMSApprovedStageProps {
  project?: Project;
}

export const CMSApprovedStage: FC<CMSApprovedStageProps> = ({
  project,
}) => {

  const navigate = useNavigate();
  
  const renderSvg = () => {
    return (
      <svg width="163" height="112" viewBox="0 0 163 112" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M142.468 23.613h-.023l-13.18 5.628-18.035 7.701a.582.582 0 0 1-.445.002l-18.606-7.719-13.505-5.603-.02-.009h-.023a.804.804 0 0 0-.804.804V59.32c0 .444.36.803.804.804h63.837c.444 0 .804-.36.804-.804V24.417a.804.804 0 0 0-.804-.804" fill="#FFF"/>
        <path d="M142.526 23.843a.114.114 0 0 1-.066-.02L110.707 1.707a.576.576 0 0 0-.658.001L78.524 23.822a.115.115 0 1 1-.131-.188L109.917 1.52a.806.806 0 0 1 .921-.001l31.753 22.114a.115.115 0 0 1-.065.209" fill="#3F3D56"/>
        <path fill="#E6E6E6" d="M80.326 24.627 110.409 2.33l30.311 23.882-28.761 17.05-15.615-3.559z"/>
        <path d="M97.662 53.794H82.8a.694.694 0 0 1-.002-1.388h14.864a.694.694 0 0 1 .002 1.388h-.002zM88.132 50.81H82.8a.694.694 0 0 1-.002-1.39h5.334a.694.694 0 0 1 .002 1.39h-.002z" fill="#F9A826"/>
        <path d="M110.832 37.045a.87.87 0 0 1-.33-.065L91.95 29.282V5.185c.001-.443.36-.803.804-.804h35.593c.444.001.803.36.804.804V29.3l-.035.015-17.944 7.663a.876.876 0 0 1-.339.068" fill="#FFF"/>
        <path d="m92.122 29.167 18.446 7.654c.172.07.365.069.536-.003l17.874-7.633v-24a.632.632 0 0 0-.632-.631H92.753a.632.632 0 0 0-.631.631v23.982zm18.71 7.935a.93.93 0 0 1-.351-.069L91.892 29.32V5.185c0-.475.386-.86.861-.861h35.593c.475 0 .86.386.861.861v24.152l-18.013 7.692a.934.934 0 0 1-.362.073z" fill="#3F3D56"/>
        <path d="M142.87 59.32a.574.574 0 0 1-.574.575H78.458a.574.574 0 0 1-.574-.574V24.417c0-.309.244-.562.553-.574l13.57 5.63 18.517 7.684c.2.081.425.08.625-.004l17.943-7.662 13.227-5.648c.308.013.55.266.551.574V59.32zm-.574-35.707h-.023l-13.18 5.628-18.035 7.701a.581.581 0 0 1-.445.002l-18.606-7.719-13.505-5.603-.02-.009h-.024a.804.804 0 0 0-.803.804V59.32c0 .443.36.803.803.804h63.838c.444-.001.804-.36.804-.804V24.417a.804.804 0 0 0-.804-.804z" fill="#3F3D56"/>
        <path d="M107.944 13.389h-12.63a.918.918 0 0 1 0-1.837h12.63a.919.919 0 0 1 0 1.837M101.4 9.485h-6.086a.919.919 0 1 1 0-1.837h6.085a.919.919 0 0 1 0 1.837" fill="#F9A826"/>
        <path d="M119.996 22.574h-19.29a.919.919 0 0 1 0-1.837h19.29a.919.919 0 0 1 0 1.837M119.996 26.822h-19.29a.919.919 0 1 1 0-1.837h19.29a.919.919 0 0 1 0 1.837" fill="#CCC"/>
        <path d="M141.94 15.212a8.734 8.734 0 1 1 0 17.468 8.734 8.734 0 0 1 0-17.468" fill="#F9A826"/>
        <path fill="#FFF" d="m141.108 27.633-2.614-3.361 1.52-1.183 1.238 1.592 4.181-4.414 1.398 1.324z"/>
        <path d="M44.206 75.566a1.854 1.854 0 0 1 .777-2.735l-.568-6.564 3.01 1.631.078 5.984a1.864 1.864 0 0 1-3.297 1.684" fill="#9F616A"/>
        <path d="m44.777 71.413-.025-.062-3.32-8.39-.528-15.902.089-.006a4.88 4.88 0 0 1 5.221 4.63l.487 8.55 1.381 10.997-3.305.183z" fill="#E5E5E5"/>
        <path fill="#9F616A" d="m23.563 106.476 1.909 1.21 5.577-6.787-2.818-1.786z"/>
        <path d="m23.471 105.544 3.76 2.384a2.837 2.837 0 0 1 .877 3.915l-.05.078-6.155-3.903 1.568-2.474z" fill="#2F2E41"/>
        <path fill="#9F616A" d="M41.612 109.73h2.26l1.075-8.72-3.336.001z"/>
        <path d="M41.035 108.992h4.451a2.837 2.837 0 0 1 2.837 2.836v.092h-7.288v-2.928zM35.216 73.2l5.321 32.007s-1.046 2.617.524 2.966c1.57.349 5.06-.35 5.06-.35s1.046-2.616-.524-2.965l-2.966-33.443-7.415 1.784z" fill="#2F2E41"/>
        <path d="M38.356 73.77 34.67 88.736l-9.4 13.648s-3.489.349-1.57 2.268c1.92 1.919 2.792 2.093 2.792 2.093s2.966-.349 2.792-1.744l11.555-13.602 5.369-18.153-7.852.524z" fill="#2F2E41"/>
        <path d="m35.14 75.088-.891-5.081c-1.34-1.401 1.192-4.866 1.475-5.242l-.166-2.155a.646.646 0 0 1-.367-.2c-.832-.848-.776-4.194-.773-4.336l-.918-9.548 2.283-1.332 5.775-.187 2.82 2.445 2.717 24.955-.097.005-11.859.676z" fill="#E5E5E5"/>
        <path d="M37.138 76.87a1.854 1.854 0 0 1-.45-2.807l-3.287-5.71 3.417.207 2.597 5.391a1.864 1.864 0 0 1-2.277 2.919z" fill="#9F616A"/>
        <path d="m34.227 71.53-2.835-6.572-.473-13.424a2.414 2.414 0 0 1 1.218-2.187l3.27-1.999.706 14.113 2.11 8.435-3.996 1.634z" fill="#E5E5E5"/>
        <path d="M39.315 37.619a4.058 4.058 0 1 1 0 8.115 4.058 4.058 0 0 1 0-8.115" fill="#9F616A"/>
        <path d="M40.783 35.685c-4.087-1.179-5.247 2.209-5.247 2.209-1.97 1.306-.736 2.945-.736 2.945-.498.11.496 3.019.828 2.945.177-.039.631.504 1.017 1.018.132 0 .264.013.394.038l-.748-2.768s2.521-.386 3.295-1.6c.74-1.163 3.175-1.53 3.868-.754.04-.223.125-.436.247-.627.095-.146.219-.27.363-.365-.612-2.604-3.281-3.041-3.281-3.041" fill="#2F2E41"/>
        <path fill="#000" d="m36.178 56.32-.226 8.077 2.81 5.923-2.584-14.215"/>
        <path d="M70.43 112H.185a.184.184 0 0 1 0-.369h70.247a.184.184 0 0 1 0 .369" fill="#3F3D56"/>
        <path d="M60.314 9.064a.222.222 0 0 1 .332-.22l9.003 5.342.12.07a.221.221 0 0 1-.034.397l-8.593 3.287a.22.22 0 0 1-.3-.204l-.044-4.102c0-.04-.007-.081-.021-.12l8.749 1.006a.147.147 0 0 0 .065-.286l-8.99-3.001a.366.366 0 0 0-.003-.081l-.284-2.088zm.407-.347a.369.369 0 0 0-.553.367l.285 2.088a.238.238 0 0 1-.007.092.223.223 0 0 1-.043.08l-1.595 1.889a.36.36 0 0 0-.023.445l.002.002a.36.36 0 0 0 .42.139l1.152-.391a.222.222 0 0 1 .291.208l.045 4.102a.366.366 0 0 0 .5.34l8.592-3.287a.368.368 0 0 0 .057-.661l-.117-.07-9.006-5.343z" fill="#F1F1F1"/>
        <path d="M60.314 9.064a.222.222 0 0 1 .332-.22l9.003 5.342.12.07a.221.221 0 0 1-.034.397l-8.593 3.287a.22.22 0 0 1-.3-.204l-.044-4.102a.354.354 0 0 0-.115-.263l-.019-.017a.367.367 0 0 0-.352-.065l-1.152.39a.214.214 0 0 1-.252-.083l-.001-.002a.214.214 0 0 1 .014-.266l1.594-1.889a.35.35 0 0 0 .086-.206.366.366 0 0 0-.003-.081l-.284-2.088zm.407-.347a.369.369 0 0 0-.553.367l.285 2.088a.238.238 0 0 1-.007.092.223.223 0 0 1-.043.08l-1.595 1.889a.36.36 0 0 0-.023.445l.002.002a.36.36 0 0 0 .42.139l1.152-.391a.222.222 0 0 1 .291.208l.045 4.102a.366.366 0 0 0 .5.34l8.592-3.287a.368.368 0 0 0 .057-.661l-.117-.07-9.006-5.343z" fill="#E5E5E5"/>
        <path d="m60.6 11.233 8.991 3a.148.148 0 0 1-.065.286l-8.749-1.004-.154-.018.017-.146.024.002 8.878 1.02-8.982-2.999-.04-.013.047-.14.034.012z" fill="#E5E5E5"/>
        <path d="m161.386.398-.285 2.088a.365.365 0 0 0-.003.081l-8.99 3.002a.148.148 0 0 0 .065.286l8.75-1.006a.354.354 0 0 0-.021.12l-.045 4.101a.22.22 0 0 1-.3.204l-8.593-3.286a.221.221 0 0 1-.034-.397l.12-.07 9.003-5.343a.221.221 0 0 1 .333.22zm-.408-.347-9.006 5.344-.117.07a.368.368 0 0 0 .057.66l8.593 3.287a.37.37 0 0 0 .499-.34l.045-4.102a.222.222 0 0 1 .291-.208l1.152.391a.36.36 0 0 0 .42-.138l.002-.003a.36.36 0 0 0-.023-.445l-1.595-1.889a.226.226 0 0 1-.043-.08.242.242 0 0 1-.007-.092l.285-2.088a.369.369 0 0 0-.552-.367z" fill="#F1F1F1"/>
        <path d="m161.386.398-.285 2.088a.365.365 0 0 0 .039.223.35.35 0 0 0 .044.065l1.594 1.888a.214.214 0 0 1 .015.267l-.001.001a.214.214 0 0 1-.253.084l-1.151-.391a.368.368 0 0 0-.352.065l-.02.017a.358.358 0 0 0-.114.263l-.045 4.102a.22.22 0 0 1-.3.204l-8.593-3.286a.222.222 0 0 1-.034-.397l.12-.07 9.003-5.343a.221.221 0 0 1 .333.22zm-.408-.347-9.006 5.344-.117.07a.368.368 0 0 0 .057.66l8.593 3.287a.37.37 0 0 0 .499-.34l.045-4.102a.222.222 0 0 1 .291-.208l1.152.391a.36.36 0 0 0 .42-.138l.002-.003a.36.36 0 0 0-.023-.445l-1.595-1.889a.226.226 0 0 1-.043-.08.242.242 0 0 1-.007-.092l.285-2.088a.369.369 0 0 0-.552-.367z" fill="#E5E5E5"/>
        <path d="m161.132 2.556.047.14-.039.013-8.983 2.999 8.878-1.02.024-.002.018.146-.155.017-8.748 1.005a.148.148 0 0 1-.066-.286l8.99-3 .034-.012z" fill="#E5E5E5"/>
        <path d="M83.989 75.242a.221.221 0 0 1-.009-.399l9.327-4.755.124-.063a.221.221 0 0 1 .317.24l-1.814 9.02a.22.22 0 0 1-.333.144l-3.49-2.154a.351.351 0 0 0-.113-.046l5.525-6.858a.147.147 0 0 0-.206-.208l-7.341 5.995a.358.358 0 0 0-.07-.041l-1.917-.875zm-.076-.53a.369.369 0 0 0 .015.663l1.917.875a.236.236 0 0 1 .074.055c.02.023.035.05.045.08l.744 2.357a.36.36 0 0 0 .364.257h.003a.36.36 0 0 0 .342-.282l.285-1.181a.222.222 0 0 1 .33-.136l3.492 2.154a.366.366 0 0 0 .554-.24l1.814-9.02a.368.368 0 0 0-.528-.4l-.121.061-9.33 4.757z" fill="#F1F1F1"/>
        <path d="M83.989 75.242a.221.221 0 0 1-.009-.399l9.327-4.755.124-.063a.221.221 0 0 1 .317.24l-1.814 9.02a.22.22 0 0 1-.333.144l-3.49-2.154a.357.357 0 0 0-.284-.044l-.025.007a.367.367 0 0 0-.243.263l-.285 1.183a.214.214 0 0 1-.206.168h-.002a.214.214 0 0 1-.218-.155l-.744-2.356a.363.363 0 0 0-.199-.224l-1.916-.875zm-.076-.53a.369.369 0 0 0 .015.663l1.917.875a.236.236 0 0 1 .074.055c.02.023.035.05.045.08l.744 2.357a.36.36 0 0 0 .364.257h.003a.36.36 0 0 0 .342-.282l.285-1.181a.222.222 0 0 1 .33-.136l3.492 2.154a.366.366 0 0 0 .554-.24l1.814-9.02a.368.368 0 0 0-.528-.4l-.121.061-9.33 4.757z" fill="#E5E5E5"/>
        <path d="m85.976 76.158 7.34-5.995a.147.147 0 0 1 .207.208l-5.525 6.857-.097.122-.114-.093.014-.019h.001l5.606-6.96-7.335 5.99v.001l-.032.026-.093-.114.028-.023z" fill="#E5E5E5"/>
    </g>
</svg>


    );
  };

  return project ? (
    <>
      <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
        {`The client approved the content. You can start working on their project using ${project.name} `}
        <Typography
            color="secondary"
            onClick = { () => navigate(`/projects/${project.id}/cms/${project.cmsDataId}/content`)}
            sx={{
              cursor: "pointer",
              display: "inline",
              fontWeight: 500,            
            }}
          >
            Content Hub
          </Typography>.{" "}
      </Box>

      <Divider sx={{marginTop: '40px'}}/>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                margin: "15px 0 17px",
                color: "#6c3dab",
                cursor: "pointer",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
              
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <ColorButton sx={{padding: '4px 15px' }} 
                 onClick={() =>
                  navigate(
                    `/projects/${project.id}/cms/${project.cmsDataId}/content`
                  )
                }
                >
                  View Content Hub
                </ColorButton>
              </Box>
            </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        {renderSvg()}
      </Box>
    </>
  ) : null;
};
