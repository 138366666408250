import { Single } from "../cms/cmsSlice";
import TreeUtils from "../utils/TreeUtils";
import _ from "lodash";


export const checkRequiredFields = (singles: Single[]) => {
  const requiredList: { [id: string]: string } = {};
  TreeUtils.extractFromSection(singles, requiredList, true);
  const values = _.values(requiredList);
  const filledValuse = values.filter((v) => {
    if (typeof v === "object") {
      const val = _.get(v, "value", "").toLowerCase();
      return (
        (val !== "" && val !== "other") || _.get(v, "other", "") !== ""
      );
    } else {
      return v !== "";
    }
  });
  if (values.length === filledValuse.length) {
    return true
  } else {
    return false
  }
}

export const convertNotificationStringToDays = (param: string) => {
  const options = ["3 Days", "5 Days", "Week", "2 Weeks", "Month"];
  const days = [3, 5, 7, 14, 30];
  const optionIndex = options.indexOf(param);
  if (optionIndex > -1) {
    return days[optionIndex];
  } else return 7;
};