import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "./TopHeader.scss";
import { Divider, Menu, MenuItem, styled } from "@mui/material";
import useAsyncEffect from "use-async-effect";
import { useAccount, useMsal } from "@azure/msal-react";
import { getInitialsFromFullName } from "../utils/DateUtils";
import { NotificatiionCenter } from "../NotificationCenter/NotificatiionCenter";
import { get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LynxiLogo } from "../assets/logoNoText.svg";
import ProjectUtils from "../projects/ProjectUtils";
import { ReactComponent as SettingsSvg } from "../assets/settings.svg";
import { ReactComponent as UserGuideSVG } from "../assets/userGuide.svg";

// import LanguageSelect from "../settings/LanguageSelect";
import { HelpToolTipOnHover } from "../basic/HelpToolTipOnHover";
import { DeleteConfirmationPopup } from "../PopUpMessage/DeleteProjectPopup";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { CMSState } from "../cms/cmsSlice";
import { clientApi } from "../api/clientApi";
import { getAccessTokenSilence } from "../auth/auth";
import AccountSettingsPopup from "../settings/AccountSettingsPopup";
import UserSettingsPopup from "../settings/UserSettingsPopup";
import { ProjectState, setShowAccountPopup } from "../projects/projectsSlice";

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "center",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  "@media all": {
    minHeight: 80,
  },
}));

const MatchListForUserGuide = [
  { id: 72773, path: "\\/projects\\/(.*)\\/cms\\/(.*?)\\/content\\?mode=ai" },
  {
    id: 72726,
    path: "\\/projects\\/(.*?)\\/questionnaire\\/pick",
    title: "Lynxi | Select Form",
  },
  {
    id: 72723,
    path: "\\/projects\\/(.*)\\/questionnaire\\/client\\?q=(.*)",
    title: "Lynxi | Form Review",
  },
  {
    id: 76764,
    path: "(?<!convert.)(?:\\/projects\\/(.*?)\\/cms\\/(.*?)\\/content)(?!.*convert)",
    title: "Lynxi | Content Hub",
  },
  {
    id: 72178,
    path: "\\/projects\\/(.*?)\\/details",
    title: "Lynxi | Project Dashboard",
  },
  { id: 72190, path: "\\/briefs\\/editor\\/new", title: "Lynxi | Form Editor" },
  { id: 71991, path: "\\/projects\\/new" },
  { id: -1, path: "\\/projects", title: "Lynxi | My Projects" },
  { id: -1, path: "\\/first_project", title: "Lynxi | First Project" },
  { id: -1, path: "\\/profile", title: "Lynxi | My Profile" },
  { id: -1, path: "\\/settings", title: "Lynxi | Account Settings" },
  { id: 72724, path: "\\/forms", title: "Lynxi | Forms Dashboard" },
];

export interface TopHeaderProps {
  userId: string;
  websiteMode?: boolean;
}

export const TopHeader: React.FC<TopHeaderProps> = ({ userId, websiteMode }) => {
  const account = useAccount();
  const [userInitials, setUserInitials] = React.useState<string>("");
  const [email, setUserEmail] = React.useState<string>();
  const [isNewProjectView, setIsNewProjectView] =
    React.useState<boolean>(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [userType, setUserType] = React.useState<string>("guest");
  const [gobackTo, setGoBackTo] = React.useState<string>("");
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(undefined);
  const [matchGuideId, setMatchGuidId] = React.useState<number | undefined>();
  const [closeAnchorEl, setCloseAnchorEl] =
    React.useState<HTMLDivElement | null>(null);
  const [showAccountSettingsPopup, setShowAccountSettingsPopup] =
    React.useState<boolean>(false);
  const [showUserSettingsPopup, setShowUserSettingsPopup] = React.useState<boolean>(false); 
  const instance = useMsal();
  const navigate = useNavigate();
  const locationRoute = useLocation();
  const cmsData: CMSState = useAppSelector((state: RootState) => state.cmsData);
  const projectsState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const dispatch = useAppDispatch();

  const continueNext = () => {
    setCloseAnchorEl(null);
    handleCloseUserMenu();
    navigate(gobackTo);
  };

  const checkIfCanNavigate = (e: any, navigateTo: string) => {
    if (location.pathname.indexOf("/cms/") > 0 && cmsData.dirtyFlag) {
      setCloseAnchorEl(e.currentTarget);
      setGoBackTo(navigateTo);
    } else {
      handleCloseUserMenu();
      setCloseAnchorEl(null);
      navigate(navigateTo);
    }
  };


  useAsyncEffect(async () => {
    if (account) {
      if (account.idTokenClaims) {
        const role: string = get(
          account.idTokenClaims,
          "extension_Role",
          "guest"
        ) as string;
        setUserEmail(account.username);
        setUserType(role);
        if (role !== "Designer" && role !== "Administrator") {
          const token = await getAccessTokenSilence(instance);
          if (token) {
            const designerLogo = await clientApi.getAccountLogo(
              token.accessToken
            );
            if (designerLogo) {
              const logo = designerLogo.url;
              if (logo && logo.indexOf("staticflickr") === -1) {
                setLogoUrl(logo);
              } else {
                setLogoUrl("");
              }
            }
          }
        } else {
          setLogoUrl("");
        }
      }
      setUserInitials(
        getInitialsFromFullName(ProjectUtils.getUserNameFromAccount(account))
      );
    }
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const matchGuideToUrl = () => {
    const currentPath = `${locationRoute.pathname}${locationRoute.search}`;
    const matchFound = MatchListForUserGuide.find((r) =>
      currentPath.match(r.path)
    );
    if (matchFound && matchFound.title) {
      document.title = matchFound.title;
    } else {
      document.title = "Lynxi | Content Hub Manager";
    }
    setMatchGuidId(
      matchFound && matchFound.id !== -1 ? matchFound.id : undefined
    );
    if (
      locationRoute.pathname.indexOf("/first_project") === 0 ||
      locationRoute.pathname.indexOf("/choose_path") > 0
    ) {
      setIsNewProjectView(true);
    } else {
      setIsNewProjectView(false);
    }
  };

  useAsyncEffect(async () => {
    matchGuideToUrl();
  }, [locationRoute]);

  const showGuide = async () => {
    window.userGuiding.previewGuide(matchGuideId);
  };

  const getLogo = () => {
    if (logoUrl !== undefined) {
      if (logoUrl === "") {
        return <LynxiLogo />;
      } else {
        return (
          <div
            // src={logoUrl}
            style={{
              width: "142px",
              height: "71px",
              backgroundImage: `url("${logoUrl}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          />
        );
      }
    } else {
      return null;
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        background:
          location.pathname.indexOf("/posts/new") > -1 ? "#f4f8f7" : "#fff",
      }}
    >
      <AppBar position="static" elevation={0}>
        <StyledToolbar
          variant="dense"
          sx={{
            height: "95px",
            background:
              location.pathname.indexOf("/posts/new") > -1 ? "#f4f8f7" : "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: 300,
              cursor: isNewProjectView ? "auto" : "pointer",
              alignItems: "flex-end",
              position: "relative",
            }}
          >
            <Box
              sx={{ marginRight: "5px", marginTop: "8px", width: "200px"}}
              onClick={(e) =>
                isNewProjectView
                  ? {}
                  : userType === "Designer" || userType === "Administrator"
                  ? checkIfCanNavigate(e, "/")
                  : navigate("/dashborad")
              }
            >
              {getLogo()}
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {(userType === "Designer" || userType === "Administrator") &&
            matchGuideId && (
              <Box
                onClick={() => showGuide()}
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                  marginRight: "35px",
                }}
              >
                <HelpToolTipOnHover
                  position="bottom"
                  message={"Walkthrough guide"}
                  actionNode={<UserGuideSVG />}
                />
              </Box>
            )}
          {/* <LanguageSelect /> */}
          {(userType === "Designer" || userType === "Administrator") &&
            !isNewProjectView && (
              <>
              <Box
                sx={{
                  // marginLeft: "50px",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#000",
                  cursor: "pointer",
                  marginRight:'20px'
                }}
                onClick={() => navigate("/pricing")}
              >
                Upgrade
              </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "35px",
                  }}
                  className={"helpCenter"}
                  // onClick={(e) => checkIfCanNavigate(e, "/settings")}
                  onClick={() => setShowAccountSettingsPopup(true)}
                >
                  <HelpToolTipOnHover
                    position="bottom"
                    message={"Account Settings"}
                    actionNode={<SettingsSvg />}
                  />
                </Box>
                <NotificatiionCenter userType={userType} userId={userId} />
                <Box sx={{ width: "40px" }} />
              </>
            )}
          <Box>
            {userType === "Designer" ||
            userType === "Administrator" ||
            !email ? (
              <div className="oval" onClick={handleOpenUserMenu}>
                <span className="gn">{userInitials}</span>
              </div>
            ) : (
              <HelpToolTipOnHover
                position="right"
                message={email}
                actionNode={
                  <div className="oval" onClick={handleOpenUserMenu}>
                    <span className="gn">{userInitials}</span>
                  </div>
                }
              />
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {(userType === "Designer" || userType === "Administrator") && (
                <>
                  <MenuItem
                    onClick={() => {
                      setShowUserSettingsPopup(true);
                      setAnchorElUser(null);
                      // checkIfCanNavigate(e, "/profile");
                    }}
                  >
                    <Typography textAlign="center">My Profile</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorElUser(null);
                      navigate("/myBilling");
                    }}
                  >
                    <Typography textAlign="center">My Billing</Typography>
                  </MenuItem>
                  <Divider />
                </>
              )}
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  instance.instance.logoutRedirect({
                    postLogoutRedirectUri: location.href,
                  });
                }}
              >
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </StyledToolbar>
      </AppBar>
      <DeleteConfirmationPopup
        title={`Your changes are not saved. Are you sure you want to exit?`}
        description={""}
        onDeleteSite={() => continueNext()}
        action={"Yes"}
        onClose={() => {
          handleCloseUserMenu();
          setCloseAnchorEl(null);
        }}
        anchorEl={closeAnchorEl}
        autoClose={false}
        placement={"right"}
      />
      {(showAccountSettingsPopup || (projectsState && projectsState.showAccountPopup)) && (
        <AccountSettingsPopup
          show={true}
          onClose={() => {
            setShowAccountSettingsPopup(false)
            dispatch(setShowAccountPopup(false))
          }}
        />
      )}
       {showUserSettingsPopup && (
        <UserSettingsPopup
          show={showUserSettingsPopup}
          onClose={() => setShowUserSettingsPopup(false)}
        />
      )}
    </Box>
  );
};
