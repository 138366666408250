import { Box, Typography } from "@mui/material";
import * as React from "react";

export const NotAllowed = () => {

  return (
      <Box sx={{width:'100%', alignItems: 'center', height: '100vh', display:'flex', flexDirection:'column'}}>
        <Box sx={{width:'500px', textAlign:'center', height: '100vh', alignItems: 'center', marginTop:'400px'}}>
          <Typography
          >
              Not Allowed to get here
          </Typography>
          </Box>
      </Box>
  )
};