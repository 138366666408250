import { Box } from "@mui/material";
import React from "react";
import { TaskInfo } from "../../api/tasksApi";
import { BasicDraggableDialog } from "../../basic/BasicDraggableDialog";
import parse from "html-react-parser";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { GalleryComp } from "../../cms/components/GalleryComp";
import ProjectUtils from "../../projects/ProjectUtils";

interface TaskFileDescription {
  url: string;
  description: string;
}
interface TaskViewPopupProps {
  onClose(): void;
  task: TaskInfo;
}

export const TaskViewPopup: React.FC<TaskViewPopupProps> = ({
  onClose,
  task,
}) => {
  const [imageFiles, setImageFiles] = React.useState<TaskFileDescription[]>([]);
  const [otherFiles, setOtherFiles] = React.useState<TaskFileDescription[]>([]);

  React.useEffect(() => {
    if (task.images) {
      const images: TaskFileDescription[] = [];
      const otherFiles: TaskFileDescription[] = [];
      task.images.forEach((image) => {
        const imgObj = JSON.parse(image);
        //get the file type from the description
        const fileType = imgObj.description.split(".").pop()?.replace(" ", "_");
        if (fileType === "jpg" || fileType === "jpeg" || fileType === "png") {
          images.push(imgObj);
        } else {
          otherFiles.push(imgObj);
        }
      });
      setImageFiles(images);
      setOtherFiles(otherFiles);
    }
  }, [task]);

  return (
    <BasicDraggableDialog
      title={"Task description"}
      onClose={onClose}
      zIndex={1000}
      extraWidth="900px"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          marginTop: "30px",
          height: imageFiles.length > 0 ? "70vh" : "50vh",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            gap: "30px",
            position: "relative",
          }}
        >
          {task.description && task.description.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box sx={{ fontSize: "16px" }}>Task name</Box>
              <Box
                sx={{
                  fontSize: "16px",
                  background: "#f9f9f9",
                  padding: "10px",
                }}
              >
                {task.description}
              </Box>
            </Box>
          )}
          {task.name && task.name.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box sx={{ fontSize: "16px" }}>Task description</Box>
              <Box
                sx={{
                  fontSize: "16px",
                  background: "#f9f9f9",
                  padding: "10px",
                }}
              >
                {parse(task.name)}
              </Box>
            </Box>
          )}

          {(imageFiles.length > 0 || otherFiles.length > 0) && (
            <>
              <Box sx={{ fontSize: "16px" }}>Task attachments</Box>
              {imageFiles.length > 0 && (
                <Box>
                  <GalleryComp
                    comp={{
                      fullWidth: false,
                      value: imageFiles.map((r) => ({ src: r.url })),
                      listType: "",
                      mapKey: "",
                      components: [],
                      description: "",
                      type: "",
                    }}
                    readonly={true}
                    onlyImages={true}
                    location={ProjectUtils.GetFakePosition(0)}
                    onUpdateComp={() => {
                      //do nothing
                    }}
                    singleKey={0}
                  />
                </Box>
              )}

              {otherFiles.length > 0 && (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      alignItems: "flex-start",
                      background: "#f9f9f9",
                      padding: "10px 20px",
                      marginTop: "10px",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  >
                    {otherFiles.map((image, idx) => (
                      <Box
                        key={`file-${idx}`}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "10px",
                        }}
                        onClick={() => window.open(image.url, "_blank")}
                      >
                        <img
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/images/files-type/no-file-type.svg";
                          }}
                          style={{ width: "20px" }}
                          src={`/images/files-type/${image.url
                            .split(".")
                            .pop()
                            ?.replace(" ", "_")}-file.svg`}
                        />
                        {image.description}
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          <ColorButton sx={{ cursor: "pointer" }} onClick={() => onClose()}>
            Close
          </ColorButton>
        </Box>
      </Box>
    </BasicDraggableDialog>
  );
};
