import { Box, Divider, Grid } from "@mui/material";
import * as React from "react";
import { get, has } from "lodash";
import {
  CMSApprovalItem,
  CMSComponent,
  CMSState,
  PositionIndexBuilder,
  Single,
  UpdateComp,
  UpdateCompBuilder,
  setFirstSingleShowen,
  setInFormQuestions,
  setInFormSectionsQuestions,
  setTypesDics,
} from "../cmsSlice";
import { ParagraphComp } from "./ParagraphComp";
import { ImageListComp } from "./ImageListComp";
import { SingleImageComp } from "./SingleImageComp";
import { ListComp } from "./ListComp";
import { SocialList } from "./SocialList";
import { DropDownComp } from "./DropDownComp";
import { ButtonComp } from "./ButtonComp";
import { OpenHoursComp } from "./OpenHours/OpenHoursComp";
import "../cms.scss";
import { ServiceListComp } from "./ServiceListComp";
import { GalleryComp } from "./GalleryComp";
import { SimpleTextComp } from "./SimpleTextComp";
import { RadioSelectComp } from "./RadioSelectComp";
import { MultiCheckBoxComp } from "./MultiCheckBoxComp";
import { VideoListComp } from "./VideoListComp";
import {
  ContentPickerPopup,
  MapKeyList,
} from "../ContentPicker/ContentPickerPopup";
import { CmsCommentsManager } from "../CmsComments/CmsCommentsManager";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { MultiDropDown } from "./MultiDropDown";
import {
  CustomCheckBox,
  EmptyCheck,
  FullCheck,
  PurpleButton,
} from "../../projects/ProjectUIHelper";
import { MembersComp } from "./Members/MembersComp";
import { ReactComponent as LockSVG } from "../../assets/cms/lock.svg";
import { ReactComponent as UnLockSVG } from "../../assets/cms/unlock.svg";
import { ReactComponent as SettingsSmallSVG } from "../../assets/project/cogwheel.svg";
import parse from "html-react-parser";

import useAsyncEffect from "use-async-effect";
import { DeleteConfirmationPopup } from "../../PopUpMessage/DeleteProjectPopup";
import { useTranslation } from "react-i18next";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { CmsComment } from "../CmsComments/CmsComment";
import { DesignImagesReview } from "./DesignImagesReview";
import { RatingComp } from "./RatingComp";
import { ReactComponent as CheckSvg } from "../../assets/checkbox.svg";
import { CMSWorkingMode, ContentHubFilterType } from "../../utils/Constants";
import { EditorEditTopicPopup } from "../Editor/EditorEditTopicPopup";
import { PagesSeoManager } from "./PagesSeo/PagesSeoManager";
import { SideContentMenu } from "../../basic/SideMenuContext/SideContentMenu";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";
import _ from "lodash";
import { YesNoComp } from "./YesNoComp";

export interface SingleCompProps {
  single: Single;
  singleIdx: number;
  subSectionIndex?: number;
  sectionIndex: number;
  showIcon?: boolean;
  onUpdateComp(updateComp: UpdateComp): void;
  onUpdateSingleApproved?(singleKey: string, approve: boolean): void;
  readonly?: boolean;
  noComment?: boolean;
  compactMode?: boolean;
  previewMode?: boolean;
  removeSingle?(singleIdx: number): void;
  mode: CMSWorkingMode;
  changeIdForSearch?: boolean;
  disableFormEditing?: boolean;
  extranalComponent?: React.ReactNode;
  noHeader?: boolean;
  sectionName?: string;
}

export const SingleComp: React.FC<SingleCompProps> = ({
  single,
  singleIdx,
  subSectionIndex,
  sectionIndex,
  onUpdateComp,
  onUpdateSingleApproved,
  showIcon,
  readonly,
  noComment,
  compactMode,
  previewMode,
  removeSingle,
  changeIdForSearch,
  mode,
  disableFormEditing,
  extranalComponent,
  noHeader,
  sectionName,
}) => {
  const padding = single.description === "Open Hours" ? 4 : 6;
  const [forceIndex, setForceIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [lockAnchorEl, setLockAnchorEl] = React.useState<HTMLDivElement | null>(
    null
  );
  const [singelToEdit, setSingleToEdit] = React.useState<Single | undefined>();
  const [approvedInfo, setApprovedInfo] = React.useState<CMSApprovalItem[]>([]);
  const [questionName, setQuestionName] = React.useState<string>("");
  const [showContentPopup, setShowContentPopup] =
    React.useState<boolean>(false);
  const [singleForDelete, setSingleForDelete] = React.useState<
    number | undefined
  >();

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useAsyncEffect(async () => {
    const approvedInfo: CMSApprovalItem[] = get(
      cmsState.alreadyApproved,
      `${single.singleKey}`,
      []
    );
    setApprovedInfo(approvedInfo);
  }, [single, cmsState.alreadyApproved]);

  const onCloseComments = () => {
    setAnchorEl(null);
    setQuestionName("");
  };

  React.useEffect(() => {
    dispatch(setFirstSingleShowen(true));
  }, []);

  const onShowComment = (e: any, commentKey: string) => {
    setQuestionName(commentKey);
    setAnchorEl(e.currentTarget);
  };

  const buildLocationObj = (compIdx: number) => {
    return PositionIndexBuilder(
      compIdx,
      singleIdx,
      sectionIndex,
      subSectionIndex !== undefined ? subSectionIndex : -1,
      0
    );
  };

  const onUnlockpproved = () => {
    if (onUpdateSingleApproved) {
      setApprovedInfo([]);
      onUpdateSingleApproved(`${single.singleKey}`, false);
    }
    setLockAnchorEl(null);
  };

  const showUnlockMessage = (elememt: HTMLElement) => {
    setLockAnchorEl(elememt as HTMLDivElement);
  };

  const isLocked = () => {
    return approvedInfo.length > 0;
  };

  const getAiMapKey = () => {
    const aiList = single.components.map((c, idx) => ({
      key: c.mapKey,
      index: idx,
    }));
    return aiList.length === 1 ? aiList[0] : undefined;
  };

  const singleHasOneParagraph = () => {
    return (
      single.components.length === 1 &&
      single.components[0].type === "paragraph"
    );
  };

  const renderContentOptions = () => {
    return single.showContentPopup || single.isAI || singleHasOneParagraph()
      ? !readonly && !cmsState.isClientMode && (
          <Box
            onClick={() => setShowContentPopup(true)}
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              marginBottom: cmsState.sideBarMode ? "0" : "20px",
            }}
          >
            <HelpToolTipOnHover
              position="right"
              message={"Click here for content options"}
              actionNode={
                <PurpleButton sx={{ padding: "6px 12px" }}>
                  Content options
                </PurpleButton>
              }
            />
          </Box>
        )
      : null;
  };

  const updateAiComponent = (
    mapKey: MapKeyList,
    content: string,
    type: string
  ) => {
    const location = buildLocationObj(0);
    const contentComp = single.components[mapKey.index];
    if (type === "ai") {
      if (cmsState.cmsValuesByTypes) {
        const updateDic = { ...cmsState.cmsValuesByTypes.expert };
        updateDic[contentComp.mapKey] = contentComp.value;
        dispatch(
          setTypesDics({
            ...cmsState.cmsValuesByTypes,
            expert: { ...updateDic },
          })
        );
      }
    }
    const updateComp = { ...contentComp, value: content };
    const updateLocation = { ...location, compIdx: mapKey.index };
    onUpdateComp(UpdateCompBuilder(updateLocation, updateComp));
  };

  const setSingleApproved = (approve: boolean) => {
    //
    if (onUpdateSingleApproved) {
      onUpdateSingleApproved(`${single.singleKey}`, approve);
    }
  };

  const onCancelDeleteSection = () => {
    setSingleForDelete(undefined);
  };

  const removeQuestion = () => {
    setSingleForDelete(singleIdx);
    // if (removeSingle) {
    //   removeSingle(singleIdx);
    // }
  };

  const onRemoveSingle = () => {
    if (singleForDelete !== undefined && removeSingle) {
      removeSingle(singleForDelete);
      onCancelDeleteSection();
    }
  };

  const renderComponent = (_comp: CMSComponent, idx: number) => {
    const comp = {
      ..._comp,
      value: _.get(cmsState.cmsItems, _comp.mapKey, _comp.value),
    };
    switch (comp.type) {
      case "title":
      case "subtitle":
      case "paragraph": {
        return (
          <ParagraphComp
            key={`comp-${idx}`}
            comp={comp}
            location={buildLocationObj(idx)}
            padding={get(comp, "fullWidth", false) ? 12 : padding}
            onUpdateComp={onUpdateComp}
            forceUpdate={forceIndex}
            readonly={readonly || isLocked()}
          />
        );
      }
      case "accountMemebers": {
        return <MembersComp key={`comp-${idx}`} />;
      }
      case "divider":
        return (
          <Grid item xs={12}>
            <Divider sx={{ bgcolor: "#e9ebea" }} />
          </Grid>
        );
      case "simpleText":
        return (
          <SimpleTextComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            padding={
              get(comp, "fullWidth", false) || cmsState.mobileView
                ? 12
                : padding
            }
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      case "rating":
        return (
          <RatingComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      case "yesno":
        return (
          <YesNoComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      case "radioSelect":
        return (
          <RadioSelectComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      case "multiCheckBox":
        return (
          <MultiCheckBoxComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      case "media": {
        return mode === CMSWorkingMode.ContentReview &&
          (comp.value === "" || !comp.value) ? null : (
          <SingleImageComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      }
      // case "imagereview":
      case "gallery": {
        return (
          <GalleryComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
            singleKey={single.singleKey}
            onShowComment={onShowComment}
          />
        );
      }
      case "imagereview": {
        return (
          <DesignImagesReview
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
            singleKey={single.singleKey}
            onShowComment={onShowComment}
          />
        );
      }
      case "videos": {
        return (
          <VideoListComp
            location={buildLocationObj(idx)}
            key={`comp-${idx}`}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      }
      case "openHours": {
        return (
          <OpenHoursComp
            key={`comp-${idx}`}
            comp={comp}
            location={buildLocationObj(idx)}
            readonly={readonly || isLocked()}
            onUpdateComp={onUpdateComp}
          />
        );
      }
      case "serviceList": {
        return (
          <ServiceListComp
            key={`comp-${idx}`}
            comp={comp}
            location={buildLocationObj(idx)}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      }
      case "seoPages": {
        return (
          <PagesSeoManager
            key={`comp-${idx}`}
            comp={comp}
            location={buildLocationObj(idx)}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
            forceUpdate={forceIndex}
          />
        );
      }
      case "dropdown":
        return (
          <DropDownComp
            key={`comp-${idx}`}
            padding={comp.fullWidth || cmsState.mobileView ? 12 : padding}
            location={buildLocationObj(idx)}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      case "multidropdown":
        return (
          <MultiDropDown
            key={`comp-${idx}`}
            padding={comp.fullWidth || cmsState.mobileView ? 12 : padding}
            location={buildLocationObj(idx)}
            comp={comp}
            onUpdateComp={onUpdateComp}
            readonly={readonly || isLocked()}
          />
        );
      case "list": {
        switch (comp.listType) {
          case "openhours": {
            return (
              <OpenHoursComp
                key={`comp-${idx}`}
                comp={comp}
                location={buildLocationObj(idx)}
                readonly={readonly || isLocked()}
                onUpdateComp={onUpdateComp}
              />
            );
          }
          case "background":
          case "media": {
            return comp.components.length > 1 ? (
              <ImageListComp
                location={buildLocationObj(idx)}
                key={`comp-${idx}`}
                comp={comp}
                onUpdateComp={onUpdateComp}
              />
            ) : (
              <SingleImageComp
                location={buildLocationObj(idx)}
                key={`comp-${idx}`}
                comp={comp}
                onUpdateComp={onUpdateComp}
              />
            );
          }
          case "textlist":
            return <ListComp key={`comp-${idx}`} />;
          case "sociallink":
            return (
              <SocialList
                padding={cmsState.mobileView ? 12 : 6}
                comp={comp}
                location={buildLocationObj(idx)}
                key={`comp-${idx}`}
                onUpdateComp={onUpdateComp}
                readonly={readonly || isLocked()}
              />
            );
          case "actions":
            return (
              <ButtonComp
                key={`comp-${idx}`}
                comp={comp}
                location={buildLocationObj(idx)}
                onUpdateComp={onUpdateComp}
              />
            );
          default:
            return null;
        }
      }
      default:
        return null;
    }
  };

  const renderWasApproved = () => {
    if (approvedInfo.length > 0) {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{ cursor: readonly ? "auto" : "pointer" }}
            onClick={(e) =>
              readonly ? {} : showUnlockMessage(e.currentTarget)
            }
          >
            <LockSVG />
          </Box>
          <Box
            sx={{
              marginLeft: "10px",
              fontSize: cmsState.sideBarMode ? "12px" : "14px",
              color: "#555",
            }}
          >
            Approved by {approvedInfo[0].name} | {approvedInfo[0].date}
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: compactMode ? "auto" : "1050px",
        width: "100%",
        margin: compactMode ? 0 : singleIdx === 0 ? "25px 0 25px" : "25px 0",
      }}
      className={
        cmsState.sideBarMode
          ? "singleSideBar"
          : cmsState.mobileView
          ? "singleMobile"
          : "singleWeb"
      }
    >
      {cmsState.inFormBuildMode && !disableFormEditing && !compactMode && (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "70px",
              marginTop: "15px",
              marginRight: "20px",
            }}
          >
            <CustomCheckBox
              disabled={cmsState.inEditStructureMode}
              checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
              color="primary"
              checked={cmsState.formQuestions.includes(single.singleKey)}
              onChange={() => {
                dispatch(
                  setInFormSectionsQuestions({
                    singlesIds: [single.singleKey],
                    sectionIndex: sectionIndex,
                    name: sectionName ?? "",
                  })
                );
                dispatch(setInFormQuestions(single.singleKey));
              }}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            minHeight: compactMode ? undefined : "130px",
            alignItems: "center",
            flexDirection: "column",
            background: "#fff",
            zIndex: 3,
            height: compactMode && !previewMode ? "100%" : "auto",
          }}
          className={compactMode ? "single-shadow" : "singleWrapper"}
          id={
            cmsState.inFormBuildMode && changeIdForSearch
              ? `build-${single.singleKey}`
              : `comment-${single.singleKey}`
          }
        >
          <Grid
            container
            spacing={0}
            height="100%"
            sx={{
              display: "flex",
              alignItems: "stretch",
              position: "relative",
            }}
          >
            {!compactMode && (
              <>
                {!cmsState.mobileView &&
                  cmsState.inEditMode &&
                  cmsState.cmsFilterMode !==
                    ContentHubFilterType.SelectedQuestions && (
                    <Box sx={{ zIndex: 2 }}>
                      <SideContentMenu
                        menuItems={
                          single.isNew && cmsState.inEditStructureMode
                            ? [
                                {
                                  label: "Edit",
                                  icon: <SettingsSmallSVG />,
                                  func: () => setSingleToEdit(single),
                                },
                                {
                                  label: "Delete",
                                  icon: (
                                    <img src="/images/singles/smallTrash.svg" />
                                  ),
                                  func: () => removeQuestion(),
                                },
                              ]
                            : [
                                {
                                  label: "Edit",
                                  icon: <SettingsSmallSVG />,
                                  func: () => setSingleToEdit(single),
                                },
                              ]
                        }
                      />
                    </Box>
                  )}
                <Grid
                  className={"singleLeft"}
                  item
                  xs={cmsState.mobileView ? 12 : 2.5}
                  sx={{
                    display: noHeader ? "none" : "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "stretch",
                    alignContent: "center",
                  }}
                >
                  <Box
                    className="singleLeftBox"
                    sx={{
                      borderRight: "solid 1px #e9ebea",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      alignContent: "center",
                      position: "relative",
                      padding: "0 16px",
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        marginTop: "18px",
                        justifyContent: "center",
                        width: "90%",
                      }}
                      className="singleDesc"
                    >
                      <Box
                        sx={{
                          fontSize: "16px",
                          fontFamily: "Nunito",
                          textAlign: "center",
                        }}
                      >
                        {single.description}
                      </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, flexDirection: "column" }} />
                    {showIcon && single.icon !== "" && (
                      <Box
                        sx={{
                          justifyContent: "flex-end",
                          display: "flex",
                          marginBottom: "20px",
                          paddingTop: "20px",
                        }}
                        className="singleImageBox"
                      >
                        <img
                          className="singleImage"
                          src={`/images/cms/${single.icon}.svg`}
                        />
                      </Box>
                    )}

                    {!cmsState.sideBarMode && renderContentOptions()}
                  </Box>
                </Grid>
              </>
            )}
            <Grid
              item
              xs={compactMode || cmsState.mobileView ? 12 : 9.5}
              sx={{ position: "relative" }}
              className="singleRightBox"
            >
              <Box
                sx={{
                  margin: cmsState.sideBarMode
                    ? "10px"
                    : compactMode
                    ? "20px 36px 40px"
                    : parse(single.header ?? "")
                    ? "20px 0px 20px 36px"
                    : "40px 0px 20px 36px",
                }}
              >
                {compactMode && (
                  <Box
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Nunito",
                      fontWeight: 500,
                      marginBottom: "30px",
                    }}
                  >
                    {single.description}
                  </Box>
                )}
                {single.header && (
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Nunito",
                      marginBottom: "20px",
                    }}
                    className="singleHeader"
                  >
                    {parse(single.header ?? "")}
                  </Box>
                )}
                <Box
                  sx={
                    compactMode
                      ? { display: "flex", alignItems: "center" }
                      : { display: "flex", flexGrow: 1 }
                  }
                >
                  <Grid container spacing={"20px"}>
                    {extranalComponent ? (
                      <>{extranalComponent}</>
                    ) : (
                      single.components.map((comp, idx) =>
                        cmsState.mobileView ? (
                          <React.Fragment key={`comp-wrap-${idx}`}>
                            {renderComponent(comp, idx)}
                            <Grid
                              item
                              xs={12}
                              sx={{ paddingTop: "5px !important" }}
                            />
                          </React.Fragment>
                        ) : (
                          renderComponent(comp, idx)
                        )
                      )
                    )}
                  </Grid>
                  {!cmsState.inFormBuildMode && (
                    <CmsComment
                      onShowComment={onShowComment}
                      singleKey={`${single.singleKey}`}
                      isClient={cmsState.isClientMode}
                      noComment={noComment}
                      mobileView={cmsState.mobileView}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            {cmsState.sideBarMode &&
              (single.showContentPopup || single.isAI) &&
              noHeader && (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    position: isLocked() ? "relative" : "absolute",
                    bottom: isLocked() ? "auto" : "-5px",
                  }}
                >
                  {renderContentOptions()}
                </Box>
              )}
          </Grid>

          {singelToEdit && (
            <EditorEditTopicPopup
              onClose={() => setSingleToEdit(undefined)}
              currentSingle={singelToEdit}
            />
          )}

          {showContentPopup && (
            <ContentPickerPopup
              mapKey={getAiMapKey()}
              onUpdateComp={updateAiComponent}
              description={single.description}
              readonly={readonly}
              onClose={() => {
                setShowContentPopup(false), setForceIndex(forceIndex + 1);
              }}
            />
          )}
          <CmsCommentsManager
            cmsKey={questionName}
            onClose={onCloseComments}
            anchorEl={anchorEl}
            isClient={cmsState.isClientMode}
            isFormStage={cmsState.inFormMode}
          />
          <DeleteConfirmationPopup
            title={t("cms.unlock_approve.title")}
            description={t("cms.unlock_approve.message")}
            onDeleteSite={() => onUnlockpproved()}
            onClose={() => setLockAnchorEl(null)}
            anchorEl={lockAnchorEl}
            placement={"left"}
            action={"Unlock"}
            autoClose={true}
            icon={<UnLockSVG />}
          />

          {singleForDelete !== undefined && (
            <DeleteMessageDialog
              onClose={() => onCancelDeleteSection()}
              onDelete={() => onRemoveSingle()}
              title={`Are you sure you want to delete this section ?`}
              message={""}
            />
          )}
        </Box>
        {!compactMode &&
          !noHeader &&
          !cmsState.inFormBuildMode &&
          mode !== CMSWorkingMode.View &&
          (approvedInfo.length > 0 ||
            mode === CMSWorkingMode.ContentReview) && (
            <Box
              sx={{
                width: "100%",
                background: "#F5F8F7",
                height: cmsState.sideBarMode ? "50px" : "40px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: cmsState.sideBarMode ? "30px" : "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  margin: "10px 20px",
                  fontSize: cmsState.sideBarMode ? "12px" : "14px",
                  background: "#F5F8F7",
                  justifyContent: "flex-end",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {mode === CMSWorkingMode.ContentReview ? (
                  <>
                    {has(cmsState.alreadyApproved, `${single.singleKey}`) ? (
                      renderWasApproved()
                    ) : (
                      <>
                        {has(cmsState.approvals, `${single.singleKey}`) ? (
                          <FullCheck onClick={() => setSingleApproved(false)}>
                            <svg
                              width="13"
                              height="10"
                              viewBox="0 0 13 10"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.789.19a.649.649 0 0 0-.918 0L4.096 7.965 1.108 4.976a.649.649 0 0 0-.918.918l3.447 3.447a.65.65 0 0 0 .918 0l8.234-8.233a.649.649 0 0 0 0-.918z"
                                fill="#FFF"
                                fillRule="nonzero"
                              />
                            </svg>
                          </FullCheck>
                        ) : (
                          <EmptyCheck onClick={() => setSingleApproved(true)} />
                        )}
                        <Box sx={{ marginLeft: "8px" }}>
                          I approve this content
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  renderWasApproved()
                )}
              </Box>
            </Box>
          )}
      </Box>
    </Box>
  );
};
