import { Box } from "@mui/material";
import React from "react";
import { ReactComponent as SelectedFolderSVG } from "../../assets/tasks/selectedFolder.svg";
import { ReactComponent as FolderSVG } from "../../assets/tasks/folder.svg";

interface FoldersListProps {
  folderList: string[];
  selectedFolder: string[];
  onFolderSelected(folders: string[]): void;
}

export const FoldersList: React.FC<FoldersListProps> = ({
  folderList,
  selectedFolder,
  onFolderSelected,
}) => {
  // Define any state or variables needed for the component here

  const onSelectFolder = (tag: string) => {
    //update the selected folders list
    const newSelectedFolders = [...selectedFolder];
    const index = newSelectedFolders.indexOf(tag);
    if (index > -1) {
      newSelectedFolders.splice(index, 1);
    } else {
      newSelectedFolders.push(tag);
    }
    onFolderSelected(newSelectedFolders);
  };

  return folderList.length > 0 ? (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
      }}
    >
      {folderList.map((tag, idx) => (
        <Box
          key={`task-tag-${idx}`}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            height: "42px",
            width: "fit-content",
            padding: "5px 15px",
            borderRadius: "10px",
            border: "solid 0.5px #6c3dab",
            backgroundColor: "#f9f9f9",
            color: selectedFolder.indexOf(tag) !==-1 ? "#fff" : "#000",
            backgroundImage:
            selectedFolder.indexOf(tag) !==-1
                ? "linear-gradient(107deg, #3023ae -36%, #c86dd7 104%)"
                : "none",
          }}
          onClick={() => onSelectFolder(tag)}
        >
          {selectedFolder.indexOf(tag) !==-1 ? <SelectedFolderSVG /> : <FolderSVG />}
          {tag}
        </Box>
      ))}
    </Box>
  ) : null;
};
