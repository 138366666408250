import { CommentData } from "../api/cmsApi"

export default class CommentsUtils {

  static checkForNewItems = (commentList: CommentData[], isClient?: boolean, ) => {
    if (isClient) {
      return commentList.filter( c=> c.source !== 'Collaborator' && !c.viewedAt).length>0
    } else {
      return commentList.filter( c=> c.source === 'Collaborator' && !c.viewedAt).length>0
    }
  }

  static checkForNewItemsCount = (commentList: CommentData[], isClient?: boolean, ) => {
    if (isClient) {
      return commentList.filter( c=> c.source !== 'Collaborator' && !c.viewedAt).length
    } else {
      return commentList.filter( c=> c.source === 'Collaborator' && !c.viewedAt).length
    }
  }

}