
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Box } from "@mui/material";
import { common } from "@mui/material/colors";
import { FC } from "react";
import { Project } from "../projectsSlice";
import * as React from "react";
import { ShareCmsInfo } from "../../api/cmsApi";
import { CMSShareStatusStages } from "../../utils/Constants";
import { CMSFillStage } from "./CMSFillStage";
import { CMSWaitingStage } from "./CMSWaitingStage";
import { CMSApprovedStage } from "./CMSApprovedStage";

export interface CMSStageStatusSwitcherProps {
  project: Project;
  shareInfo: ShareCmsInfo;
  onClose(reload?:boolean): void;
}

export const CMSStageStatusSwitcher: FC<CMSStageStatusSwitcherProps> = ({
  project,
  onClose,
  shareInfo,
}) => {

  const renderByStage = () => {
      switch (shareInfo.state) {
        case CMSShareStatusStages.Shared:
          return <CMSFillStage project={project} shareInfo={shareInfo} onClose={onClose}/>;
        case CMSShareStatusStages.Viewed:
        case CMSShareStatusStages.InProgress:
        case CMSShareStatusStages.Reopened:
          return <CMSWaitingStage project={project} shareInfo={shareInfo} onClose={onClose}/>;
        case CMSShareStatusStages.Approved:
        case CMSShareStatusStages.PartiallyApproved:
          return <CMSApprovedStage project={project} />;
      }
  };

  return (
    <>
        <Box
          sx={{
            width: project ? "314px" : "0px",
            display: "felx",
            transitionTimingFunction: "ease-in-out",
            transition: "width 2s, transform 2s",
            position: "sticky",
            right: '0px',
            top: '250px',
            zIndex: 10000,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            <CloseSharpIcon onClick={() => onClose()} />
          </Box>
          <Box
            sx={{
              height: "120px",
              borderTopLeftRadius: "30px",
              display: "flex",
              justifyContent: "center",
              backgroundImage:
                "linear-gradient(124deg, #fb8332 -127%, #f8c332 86%)",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "260px",
                alignSelf: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
                paddingRight: "10px",
              }}
            >
              {project.name}
            </Box>
            <Box
              sx={{ width: "260px", alignSelf: "center", fontWeight: "bold" }}
            >
            </Box>
          </Box>
          <Box
            sx={{
              background: common.white,
              height: "60vh",
              padding: "46px 27px 0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {renderByStage()}           
          </Box>
        </Box>
    </>
  );
};
