import * as React from "react";
import BasicPopupDialog from "../basic/BasicPopupDialog";
import SettingsDetails from "./SettingsDetails";

export interface UserSettingsPopupProps {
  show: boolean;
  onClose: () => void;
}

const UserSettingsPopup: React.FC<UserSettingsPopupProps> = ({
  show,
  onClose,
}) => {
  return (
    <BasicPopupDialog show={show} onClose={() => onClose()} width={"80vw"}>
      <SettingsDetails onExternalClose={() => onClose()} />
    </BasicPopupDialog>
  );
};

export default UserSettingsPopup;
