import { useMsal } from "@azure/msal-react";
import { Box, TextField } from "@mui/material";
import _, { cloneDeep, get } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { LynxiAccountInfo, backofficeApi } from "../api/backofficeApi";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { getAccessTokenSilence } from "../auth/auth";
import {
  CMSState,
  Section,
  UpdateComp,
  setAccountImages,
} from "../cms/cmsSlice";
import { SingleComp } from "../cms/components/SingleComp";
import { getUserInfo, setAccountInfo } from "../projects/projectsSlice";
import { DesignerContext } from "../routes/AppRouter";
import TreeUtils from "../utils/TreeUtils";
import SettingsPage from "./SettingsPage";
import { checkRequiredFields } from "./SettingsUtils";
import { CMSWorkingMode } from "../utils/Constants";
import { ClientUrl } from "../authConfig";
import { ShareLinkCopy } from "../basic/ShareLinkCopy";

export interface AccountSettingsProps {
  onExternalClose?(): void;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({
  onExternalClose,
}) => {
  const [settingsDetails, setSettingsDetails] = useState<Section>();
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [accountInfo, setCurrentAccountInfo] = useState<
    LynxiAccountInfo | undefined
  >(undefined);
  const [dirtyFlag, setDirtyFlag] = React.useState<boolean>(false);

  const [showSaveMessage, setShowSaveMessage] = React.useState<boolean>(false);
  const msalInstance = useMsal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const context = React.useContext(DesignerContext);
  const cmsData: CMSState = useAppSelector((state: RootState) => state.cmsData);

  const refreshAccountInfo = async (token: string) => {
    const accountResponse = await backofficeApi.getAccount(token);
    if (accountResponse) {
      setCurrentAccountInfo(accountResponse.accounts[0]);
    }
  };

  useAsyncEffect(async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      refreshAccountInfo(token.accessToken);
    }
  }, [cmsData.forceRendering]);

  useAsyncEffect(async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnLoading(true);
      const settingsToken = await backofficeApi.getParamFromServer(
        "settings",
        token.accessToken
      );
      const settingsObj = JSON.parse(settingsToken.value);
      const accountResponse = await backofficeApi.getAccount(token.accessToken);

      setCurrentAccountInfo(accountResponse.accounts[0]);
      if (accountResponse.accounts[0].additionalData) {
        const settingsDic: { [id: string]: any } = {
          ...JSON.parse(accountResponse.accounts[0].additionalData),
          logo: accountResponse.accounts[0].logoUrl ?? "",
        };
        const notificationSettings = get(
          settingsDic,
          "notificationSettings",
          []
        );
        if (
          notificationSettings.length > 0 &&
          notificationSettings[0] === "Week"
        ) {
          TreeUtils.fillCMSWithContentFormDic(settingsObj, {
            ...settingsDic,
            notificationSettings: ["1 week"],
          });
        } else {
          TreeUtils.fillCMSWithContentFormDic(settingsObj, settingsDic);
        }
      }
      setOnLoading(false);
      setSettingsDetails(settingsObj);
      if (context.accountId !== "") {
        dispatch(
          setAccountImages(
            await backofficeApi.getAccountImages(
              context.accountId,
              token.accessToken
            )
          )
        );
      }
    }
  }, []);

  useAsyncEffect(async () => {
    if (settingsDetails) {
      setEnableSave(checkRequiredFields(settingsDetails.singles));
    }
  }, [settingsDetails]);

  const saveSettingsData = async () => {
    if (settingsDetails && accountInfo) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const settingsDic = TreeUtils.getDicFromProjectCms(settingsDetails);
        const data = {
          ...accountInfo,
          accountId: accountInfo.id,
          logoUrl: _.get(settingsDic, "logo"),
          UseAsLogo: _.get(settingsDic, "setAsLogo", []).length > 0,
          additionalData: JSON.stringify({
            ...JSON.parse(accountInfo.additionalData ?? "{}"),
            ...settingsDic,
          }),
          editorSettings: accountInfo.editorSettings,
        };
        await backofficeApi.updateAccountSettings(data, token.accessToken);
        dispatch(setAccountInfo(data));
        setShowSaveMessage(true);
        dispatch(getUserInfo(token.accessToken));
        setDirtyFlag(false);
      }
    }
  };

  const onUpdateComp = (item: UpdateComp) => {
    if (settingsDetails) {
      setDirtyFlag(true);
      const tempProjectDetails = cloneDeep(settingsDetails);
      const compList =
        tempProjectDetails.singles[item.location.singleIdx].components;
      compList[item.location.compIdx] = item.comp;
      setSettingsDetails(tempProjectDetails);
    }
  };

  const renderSingleByIndex = (section: Section, singleIndex: number) => {
    return (
      <SingleComp
        key={`single-${singleIndex}`}
        single={section.singles[singleIndex]}
        singleIdx={singleIndex}
        subSectionIndex={-1}
        sectionIndex={0}
        showIcon={false}
        onUpdateComp={onUpdateComp}
        noComment={true}
        compactMode={true}
        disableFormEditing={true}
        mode={CMSWorkingMode.Edit}
      />
    );
  };

  const onCloseSuccessMessage = (cont?: boolean) => {
    setShowSaveMessage(false);
    if (cont) {
      if (onExternalClose) {
        onExternalClose();
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <SettingsPage
      title={"Account Settings"}
      dirtyFlag={dirtyFlag}
      enableSave={enableSave}
      onSaveSettingsData={saveSettingsData}
      onLoading={onLoading}
      accountInfo={accountInfo}
      showSaveMessage={showSaveMessage}
      onCloseSuccessMessage={onCloseSuccessMessage}
      onClose={ () => onCloseSuccessMessage(true)}
      asPopup={onExternalClose !== undefined}      
    >
      {settingsDetails && (
        <Box
          sx={{
            display: "flex",
            width: "85%",
            flexDirection: "column",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              height: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
                height: "auto",
              }}
            >
              <Box sx={{ marginBottom: "36px" }}>
                {renderSingleByIndex(settingsDetails, 0)}
              </Box>
              {renderSingleByIndex(settingsDetails, 3)}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex", background: "#fff", width: "100%" }}>
                {renderSingleByIndex(settingsDetails, 1)}
              </Box>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box
                sx={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  background: "#fff",
                  padding: "10px 30px 20px",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ fontSize: "16px", fontWeight: "500" }}>
                Lynxi Founders Club referral link
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "middle", gap: "10px" }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      background: "#f9f9f9",
                      "& input.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                        WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                      },
                    }}
                    className={"inputWithValDisabled"}
                    label={""}
                    variant="outlined"
                    value={`${ClientUrl}/designer_signup?referral=${accountInfo?.id}`}
                    disabled={true}
                    placeholder={"Member name"}
                  />
                  <ShareLinkCopy
                    linkToShare={`${ClientUrl}/designer_signup?referral=${accountInfo?.id}`}
                  >
                    <svg
                      style={{ width: "25px", height: "27px" }}
                      width="15"
                      height="18"
                      viewBox="0 0 15 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill={"#7C48C2"} fillRule="nonzero">
                        <path d="M12.955 17.727H4.773a2.045 2.045 0 0 1-2.046-2.045V4.772c0-1.129.916-2.045 2.046-2.045h8.182c1.13 0 2.045.916 2.045 2.046v10.909c0 1.13-.916 2.045-2.045 2.045zM4.773 4.091a.682.682 0 0 0-.682.682v10.909c0 .376.305.682.682.682h8.182a.682.682 0 0 0 .681-.682V4.772a.682.682 0 0 0-.681-.681H4.773z" />
                        <path d="M.682 10.91A.682.682 0 0 1 0 10.226V2.045C0 .915.916 0 2.045 0h6.819a.682.682 0 1 1 0 1.364H2.045a.682.682 0 0 0-.681.681v8.182a.682.682 0 0 1-.682.682z" />
                      </g>
                    </svg>
                  </ShareLinkCopy>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", marginTop: "36px" }}>
                {renderSingleByIndex(settingsDetails, 4)}
              </Box>
          <Box sx={{ display: "flex", width: "100%", marginTop: "36px" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {renderSingleByIndex(settingsDetails, 2)}
            </Box>
          </Box>

        </Box>
      )}
    </SettingsPage>
  );
};

export default AccountSettings;
