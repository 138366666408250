import * as React from "react";

import {
  Box,
  Fab,
  // Fab,
  TextField,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  BriefData,
  Project,
  ProjectState,
  refreshActiveProject,
} from "../../projectsSlice";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useMsal } from "@azure/msal-react";
import useAsyncEffect from "use-async-effect";
import { notificationApi } from "../../../api/notificationApi";
import { projectsApi, ProjectUpdateRequest } from "../../../api/projectsApi";
import "../../ProjectMain/Project.scss";
import { get } from "lodash";
import { ReactComponent as ShareEmailSuccessSvg } from "../../../assets/project/shareMailSuccess.svg";
import { ReactComponent as ShareEmailFailedSvg } from "../../../assets/project/shareMailFail.svg";
import { ReactComponent as CheckSvg } from "../../../assets/checkbox.svg";

import { ReactComponent as CopyLinkSVG } from "../../../assets/link.svg";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DesignerContext } from "../../../routes/AppRouter";
import { useTranslation } from "react-i18next";
import { ShareLinkPopup } from "./ShareLinkPopup";
import { getAccessTokenSilence } from "../../../auth/auth";
import { CUSTOMER_EMAIL_CMS_ID } from "../../../utils/Constants";
import { useDesginerInfo } from "../../../hooks/useDesginerName";
import { ShareBtnWithBrandingCheck } from "../../../settings/ShareBtnWithBrandigCheck";
import { RootState } from "../../../app/store";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { CustomCheckBox } from "../../ProjectUIHelper";
import InfoIcon from "@mui/icons-material/Info";
import { HelpToolTipOnHover } from "../../../basic/HelpToolTipOnHover";
import dayjs, { Dayjs } from "dayjs";
import { useAccountSettingsData } from "../../../hooks/useAccountSettingsData";
import ProjectUtils from "../../ProjectUtils";

export interface BriefShareStageProps {
  project: Project;
  brief: BriefData;
  onClose(reload?: boolean): void;
}

export const BriefShareStage: FC<BriefShareStageProps> = ({
  project,
  brief,
  onClose,
}) => {
  const [userEmails, setUserEmails] = React.useState<string[]>([""]);
  const [missingEmail, setMissingEmail] = React.useState<boolean>(false);
  const [shareResult, setShareResult] = React.useState<boolean | undefined>();
  const [emailMessage, setEmailMessage] = React.useState<string>("");
  const [emailSubject, setEmailSubject] = React.useState<string>("");
  const [onLaoding, setOnLoading] = React.useState<boolean>(false);
  const [linkForAll, setLinkForAll] = React.useState<boolean>(false);
  const [validForInDays, setValidForInDays] = React.useState<number>(0);
  const [showCopyLinkPopup, setShowCopyLinkPopup] =
    React.useState<boolean>(false);
  const [enableDueDate, setEnableDueDate] = React.useState<boolean>(false);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const { getAccountDateFormat } = useAccountSettingsData();

  const dateFormat = React.useMemo(
    () => getAccountDateFormat(),
    [getAccountDateFormat]
  );

  const msalInstance = useMsal();
  const dispatch = useAppDispatch();
  const context = React.useContext(DesignerContext);
  const { t } = useTranslation();
  const projectState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const formRef = React.useRef<HTMLInputElement>(null);
  const { getDesignerName } = useDesginerInfo();

  const getEmails = () => {
    if (brief.emailChange && brief.emailChange === "AddEmail") {
      setUserEmails([""]);
    } else {
      const email = brief.testMode
        ? context.email
        : get(JSON.parse(project.additionalData), "8000", "");
      if (email !== "") {
        setUserEmails([email]);
      } else {
        setUserEmails([""]);
        setMissingEmail(true);
      }
    }
  };

  const onAfterGenerate = async (linkWasGenerated: boolean) => {
    setShowCopyLinkPopup(false);
    if (linkWasGenerated) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        await projectsApi.updateUserQuestionnairStatus(
          brief.id,
          "BriefSent",
          token.accessToken
        );
        dispatch(refreshActiveProject(token.accessToken));
      }
    }
  };

  useAsyncEffect(async () => {
    if (project) {
      getEmails();
      setEmailMessage(
        `${getDesignerName()} invited you to fill out the "${
          brief.name
        }" form.\nPlease fill it out to move your project forward.`
      );
      setEmailSubject(
        `${getDesignerName()} invited you to fill out the form, "${brief.name}"`
      );
      if (brief.dueDate) {
        setDueDate(dayjs(brief.dueDate));
      }
    }
  }, [project, projectState.accountInfo]);

  const onEmailChange = (e: any, idx: number) => {
    const emails = [...userEmails];
    emails[idx] = e.target.value;
    setUserEmails(emails);
  };

  const updateMissingEmail = async (email: string, token: string) => {
    const projectAdded = JSON.parse(project.additionalData);
    const projectContent = JSON.parse(project.content ?? "{}");
    const data: ProjectUpdateRequest = {
      id: project.id,
      name: project.name,
      content: JSON.stringify({
        ...projectContent,
        [CUSTOMER_EMAIL_CMS_ID]: email,
      }),
      additionalData: JSON.stringify({ ...projectAdded, ["8000"]: email }),
      status: project.status,
    };
    await projectsApi.editUserProject(data, token);
    dispatch(refreshActiveProject(token));
  };

  const sendByEmail = async () => {
    if (userEmails.length > 0) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        setOnLoading(true);
        if (missingEmail) {
          await updateMissingEmail(userEmails[0], token.accessToken);
        }
        const shareData = {
          emails: userEmails,
          name: getDesignerName(),
          message:
            dueDate && enableDueDate
              ? `${emailMessage.replace(
                  /\n/g,
                  "<br/>"
                )} <br><br><span style="font-size:14px">Please note that the due date for submitting this form is ${dueDate.format(
                  dateFormat
                )}</span>`
              : emailMessage.replace(/\n/g, "<br/>"),
          subject: emailSubject,
          isAnonymous: linkForAll,
          validForInDays,
        };
        const mailResult = await notificationApi.shareQuestionnaire(
          brief.id,
          shareData,
          token.accessToken
        );
        if (mailResult) {
          if (brief.invitations.length === 0) {
            await ProjectUtils.fillFormWithContentHubData(
              project.cmsDataId ?? "",
              token.accessToken,
              { id: brief.id, name: brief.name, template: "" },
              brief.templateId,
              dueDate && enableDueDate
                ? `${dueDate.format("YYYY-MM-DD")}T00:00:00.000Z`
                : undefined
            );
          } else {
            await updateDueDate();
          }
          dispatch(refreshActiveProject(token.accessToken));
        }
        setShareResult(mailResult);
        setOnLoading(false);
      } else {
        setShareResult(false);
      }
    }
  };

  const updateDueDate = async () => {
    if (dueDate && enableDueDate) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const briefData = await projectsApi.getUserQuestionnairById(
          brief.id,
          token.accessToken
        );
        if (briefData) {
          const data = {
            id: brief.id,
            name: brief.name,
            content: briefData.content,
            answeredQuestions: briefData.answeredQuestions ?? 0,
            status: briefData.status,
            templateId: briefData.templateId,
            dueDate: `${dueDate.format("YYYY-MM-DD")}T00:00:00.000Z`,
          };
          await projectsApi.updateUserQuestionnair(data, token.accessToken);
        }
      }
    }
  };

  const onMailShared = async () => {
    if (shareResult) {
      onClose(true);
    } else {
      setShareResult(undefined);
    }
  };

  const removeEmail = (index: number) => {
    const array = [...userEmails];
    array.splice(index, 1);
    setUserEmails(array);
  };

  const renderAddEmailButton = () => {
    return (
      <Fab
        size="small"
        sx={{
          background: "#8B4FC6",
          color: "#fff",
          transform: "scale(0.6)",
          "&:hover": { color: "#000" },
        }}
        onClick={() => setUserEmails([...userEmails, ""])}
      >
        <AddIcon fontSize="small" />
      </Fab>
    );
  };

  const renderEmailResponse = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          {shareResult ? <ShareEmailSuccessSvg /> : <ShareEmailFailedSvg />}
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {shareResult
              ? t("brief.stages.share.success.title")
              : t("brief.stages.share.failed.title")}
          </Typography>
          <br />
          <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
            {shareResult
              ? t("brief.stages.share.success.message")
              : t("brief.stages.share.failed.message")}
          </Box>
          <br />
          <Typography
            color="secondary"
            onClick={() => onMailShared()}
            sx={{
              cursor: "pointer",
              fontWeight: 500,
            }}
          >
            {shareResult
              ? t("brief.stages.share.success.action")
              : t("brief.stages.share.failed.action")}
          </Typography>
        </Box>
      </Box>
    );
  };

  return project ? (
    <>
      {shareResult === undefined ? (
        <ValidatorForm
          onSubmit={() => sendByEmail()}
          onError={(errors) => console.log(errors)}
          style={{ width: "100%", position: "relative" }}
        >
          <Box className="emailShareBox">
            <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
              {t("brief.stages.share.title")}
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {userEmails.map((email: string, idx: number) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  key={`email-${idx}`}
                >
                  <Box sx={{ width: "80%", flexGrow: 1 }}>
                    <TextValidator
                      label="Client Email"
                      onChange={(e) => onEmailChange(e, idx)}
                      name={`email${idx}`}
                      sx={{ width: "100%", background: "#f9f9f9" }}
                      // variant="standard"
                      value={email}
                      className={email !== "" ? "inputWithVal" : ""}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "4px",
                    }}
                  >
                    {userEmails.length === 1 && renderAddEmailButton()}
                    {idx > 0 && (
                      <>
                        <Box
                          sx={{
                            cursor: "pointer",
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <Fab
                            size="small"
                            sx={{
                              background: "#cbcbcb",
                              color: "#fff",
                              transform: "scale(0.6)",
                              marginRight: "-8px",
                              "&:hover": { color: "#000" },
                            }}
                            onClick={() => removeEmail(idx)}
                          >
                            <CloseIcon fontSize="small" />
                          </Fab>
                        </Box>
                        {userEmails.length > 1 &&
                          idx === userEmails.length - 1 && (
                            <Box
                              sx={{
                                display: "flex",
                                cursor: "pointer",
                                justifyContent: "center",
                              }}
                            >
                              {renderAddEmailButton()}
                            </Box>
                          )}
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box sx={{ width: "100%", marginTop: "28px" }}>
              <TextField
                sx={{ width: "100%", background: "#f9f9f9" }}
                label="Subject"
                required={true}
                className={emailSubject !== "" ? "inputWithVal" : ""}
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <TextField
                sx={{ width: "100%", background: "#f9f9f9" }}
                multiline
                maxRows={4}
                label="Message"
                required={true}
                rows={4}
                className={emailMessage !== "" ? "inputWithVal" : ""}
                value={emailMessage}
                onChange={(e) => setEmailMessage(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "5px",
                alignItems: "center",
              }}
            >
              <CustomCheckBox
                checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
                checked={linkForAll}
                onChange={() => setLinkForAll(!linkForAll)}
              />
              <Box sx={{ fontSize: "14px" }}>Anyone with a link can access</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <CustomCheckBox
                checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
                checked={validForInDays !== 0}
                onChange={() =>
                  setValidForInDays(validForInDays === 0 ? 100 : 0)
                }
              />
              <Box
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Send a daily reminder
                <HelpToolTipOnHover
                  position="top-start"
                  size={250}
                  message={
                    "Lynxi will send a daily email reminder with the number of unanswered questions until the form is submitted."
                  }
                  actionNode={<InfoIcon fontSize="small" />}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <CustomCheckBox
                checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
                checked={enableDueDate}
                onChange={() => setEnableDueDate(!enableDueDate)}
              />
              <DatePicker
                label="Due date"
                className="datePicker"
                value={dueDate}
                format={dateFormat}
                disabled={!enableDueDate || brief.dueDate !== null}
                onChange={(newValue: any) => setDueDate(newValue)}
              />
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                margin: "22px 0 17px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => setShowCopyLinkPopup(true)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CopyLinkSVG style={{ marginRight: "5px" }} /> Copy link
                </Box>
              </Box>
              <Box>
                <ShareBtnWithBrandingCheck
                  btnText={t("brief.stages.share.action")}
                  disabled={
                    emailMessage === "" || emailSubject === "" || onLaoding
                  }
                  onContinue={() => formRef.current?.click()}
                  onProcessing={onLaoding}
                />
                <input
                  type="submit"
                  style={{ display: "none" }}
                  ref={formRef}
                />
              </Box>
            </Box>
          </Box>
        </ValidatorForm>
      ) : (
        renderEmailResponse()
      )}
      <Box sx={{ flexGrow: 1 }} />
      {/* <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        <ShareEmailSvg />
      </Box> */}
      {showCopyLinkPopup && (
        <ShareLinkPopup
          id={brief.id}
          projectId={project.id}
          initialEmail={userEmails[0]}
          allowAnonymous={true}
          defaultValidForInDays={validForInDays}
          defaultLinkForAll={linkForAll}
          allowTimeLimit={true}
          onClose={(linkWasGenerated: boolean) =>
            onAfterGenerate(linkWasGenerated)
          }
        />
      )}
    </>
  ) : null;
};
