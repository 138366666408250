import React from "react";
import { BasicMessagePopup } from "../../Massages/BasicMessagePopup";
import { Box, Divider, Fab, TextField } from "@mui/material";
import { ColorButton } from "../ProjectUIHelper";
import { useAccountSettingsData } from "../../hooks/useAccountSettingsData";
import {
  LabelItem,
  LabelItemForUpdate,
} from "../../basic/StatusDropDown/StatusDropDown";
import { ReactComponent as SmallTrashSvg } from "../../assets/project/smallTrash.svg";
import { ReactComponent as SmallTrashDisabledSvg } from "../../assets/project/smallTrashDisabled.svg";

import { Color, ColorPicker } from "material-ui-color";
import AddIcon from "@mui/icons-material/Add";
import { ProjectState, setAccountInfo } from "../projectsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import _ from "lodash";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { getAccessTokenSilence } from "../../auth/auth";
import { useMsal } from "@azure/msal-react";
import { backofficeApi } from "../../api/backofficeApi";
import { projectsApi } from "../../api/projectsApi";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";

export interface StatusEditorPopupProps {
  onClose: (updateList?: LabelItem[], reloadProjects?: boolean) => void;
}

export const StatusEditorPopup: React.FC<StatusEditorPopupProps> = ({
  onClose,
}) => {
  const [currentStatusList, setCurrentStatusList] = React.useState<
    LabelItemForUpdate[]
  >([]);
  const { getAccountProjectsLabels } = useAccountSettingsData();
  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);
  const projectsState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const [duplicateValues, setDuplicateValues] = React.useState<string[]>([]);
  const msalInstance = useMsal();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const usedLabeled = _.uniq(
      projectsState.projects.map((project) => project.status)
    );
    const labels = getAccountProjectsLabels();
    setCurrentStatusList(
      labels.map((label: LabelItem) => ({
        ...label,
        blocked: usedLabeled.includes(label.label),
        origLabel: label.label,
      }))
    );
  }, []);

  const onUpdateColor = (index: number, color: string) => {
    const newStatusList = [...currentStatusList];
    newStatusList[index].color = color;
    setCurrentStatusList(newStatusList);
  };

  const checkForDuplicate = () => {
    const allLabels = currentStatusList.map((r) => r.label);
    const grouped = _.groupBy(allLabels);
    const duplicates = _.pickBy(grouped, (value, key) => value.length > 1);
    if (Object.keys(duplicates).length > 0) {
      setDuplicateValues(Object.keys(duplicates));
      return true;
    }
    return false;
  };

  const setLabelName = (label: string, index: number) => {
    setDuplicateValues([]);
    const newStatusList = [...currentStatusList];
    newStatusList[index].label = label;
    setCurrentStatusList(newStatusList);
  };

  const deleteStatus = (index: number) => {
    const newStatusList = [...currentStatusList];
    newStatusList.splice(index, 1);
    setCurrentStatusList(newStatusList);
  };

  const addNewLabel = () => {
    const newStatusList = [...currentStatusList];
    newStatusList.push({
      label: "",
      color: "#fff",
      blocked: false,
      origLabel: "",
    });
    setCurrentStatusList(newStatusList);
  };

  const onUpdateLabels = async () => {
    if (checkForDuplicate()) {
      return;
    }
    const token = await getAccessTokenSilence(msalInstance);
    if (token && projectsState.accountInfo) {
      let needToReload = false;
      setOnProcessing(true);
      const labelsThatNeedsUpdate = currentStatusList.filter(
        (r) => r.origLabel !== "" && r.origLabel !== r.label
      );
      for (let i = 0; i < projectsState.projects.length; i++) {
        try {
          const label = labelsThatNeedsUpdate.find(
            (r) => r.origLabel === projectsState.projects[i].status
          );
          if (label) {
            needToReload = true;
            await projectsApi.updateUserProjectStatus(
              projectsState.projects[i].id,
              label.label,
              token.accessToken
            );
          }
        } catch (error) {
          console.error(`Error updating project: ${error}`);
        }
      }
      const additionalDataJson = JSON.parse(
        projectsState.accountInfo.additionalData ?? "{}"
      );
      const updatedAccountSettings = {
        ...projectsState.accountInfo,
        additionalData: JSON.stringify({
          ...additionalDataJson,
          labelsList: currentStatusList.map((r) => ({
            label: r.label,
            color: r.color,
          })),
        }),
        accountId: projectsState.accountInfo.id,
      };
      await backofficeApi.updateAccountSettings(
        updatedAccountSettings,
        token.accessToken
      );
      dispatch(setAccountInfo(updatedAccountSettings));
      setOnProcessing(false);
      onClose(
        currentStatusList.map((r) => ({
          label: r.label,
          color: r.color,
        })),
        needToReload
      );
    }
  };

  return (
    <BasicMessagePopup
      extraWidth="750px"
      onClose={onClose}
      open={true}
      title="Edit Project Labels"
      zIndex={1000}
      disableAutoClose={true}
    >
      <Box
        sx={{
          margin: "40px 70px 0px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          position: "relative",
        }}
      >
        <BasicPageBlocker show={onProcessing} useAbsolute={true} />
        <Box
          sx={{
            maxHeight: "50vh",
            overflowY: "auto",
            paddingRight: "20px",
            display: "flex",
            flexWrap: "wrap",
            rowGap: "10px",
            padding: "5px",
          }}
        >
          {currentStatusList.map(
            (labelInfo: LabelItemForUpdate, index: number) => (
              <Box
                key={`label-list-${index}`}
                width={"50%"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box sx={{ fontSize: "16px" }}>
                  {" "}
                  <ColorPicker
                    hideTextfield={true}
                    value={labelInfo.color}
                    onChange={(newColor: Color) =>
                      onUpdateColor(index, `#${newColor.hex}`)
                    }
                  />
                </Box>
                <Box>
                  {" "}
                  <TextField
                    sx={{
                      width: "100%",
                      background: duplicateValues.includes(labelInfo.label)
                        ? "#AA2D07"
                        : "#f9f9f9",
                      "& input.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                        WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                      },
                    }}
                    className={labelInfo.label !== "" ? "inputWithVal" : ""}
                    variant="outlined"
                    required={true}
                    value={labelInfo.label}
                    onChange={(e) => {
                      setLabelName(e.target.value, index);
                    }}
                    inputProps={{ className: "dropDownOther" }}
                  />
                </Box>
                {labelInfo.blocked ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HelpToolTipOnHover
                      position="top"
                      message={
                        "This label is currently in use and cannot be deleted"
                      }
                      actionNode={<SmallTrashDisabledSvg />}
                    />
                  </Box>
                ) : (
                  <Box
                    onClick={() => deleteStatus(index)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <SmallTrashSvg />
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            gap: "10px",
          }}
        >
          <Fab
            size="small"
            sx={{
              boxShadow: "none",
              background: "#000",
              color: "#fff",
              width: "30px",
              height: "30px",
              minHeight: "20px",
              "&:hover": { color: "#000" },
            }}
            onClick={() => addNewLabel()}
          >
            <AddIcon fontSize="small" sx={{ width: "18px" }} />
          </Fab>
          <Box sx={{ fontSize: "14px" }}>Add new label</Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            marginTop: "20px",
          }}
        >
          {duplicateValues.length > 0 && (
            <Box
              sx={{
                color: "red",
                fontSize: "14px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Labels cannot be duplicated
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
              Cancel
            </Box>
            <ColorButton onClick={() => onUpdateLabels()}>Update</ColorButton>
          </Box>
        </Box>
      </Box>
    </BasicMessagePopup>
  );
};
