import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ColorButton } from "../projects/ProjectUIHelper";
import { BasicMessagePopup } from "./BasicMessagePopup";
import { ReactComponent as SuccessSVG } from "../assets/cms/publishSuccess.svg";

export interface SelectPathMessagePopupProps {
  message?: React.ReactNode | string;
  onRightBtn(): void;
  onLeftBtn(): void;
  onClose(): void;
  rightAction: string;
  leftAction: string;
  title: React.ReactNode | string;
  icon?: React.ReactNode;
  open: boolean;
  extraWidth?: string;
  disableAutoClose?: boolean;
  size?: string;
}

export const SelectPathMessagePopup: FC<SelectPathMessagePopupProps> = ({
  message,
  onRightBtn,
  onLeftBtn,
  onClose,
  rightAction,
  leftAction,
  title,
  icon,
  open,
  extraWidth,
  disableAutoClose,
  size,
}) => {
  return (
    <BasicMessagePopup
      icon={icon ?? <SuccessSVG />}
      onClose={() => onClose()}
      open={open}
      disableAutoClose={disableAutoClose}
      extraWidth={extraWidth}
      size={size}
      bottomPadding="20px"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          flexDirection: "column",
          fontFamily: "Nunito",
          justifyContent: "center",
          fontSize: "14px",
          width: "70%",
        }}
      >
        <Box sx={{ fontWeight: 500, marginTop: "32px" }}>{title}</Box>
        <Box sx={{ fontWeight: 300, marginTop: "20px" }}>{message}</Box>
      </Box>
      <Box sx={{ display: "flex", flexGrow: 1 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
          margin: "30px auto 10px",
          alignItems: "center",

        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#6c3dab",
          }}
          onClick={() => onLeftBtn()}
        >
          {leftAction}
        </Box>
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#6c3dab",
          }}
          onClick={() => onRightBtn()}
        >
          <ColorButton>{rightAction}</ColorButton>
        </Box>
      </Box>
    </BasicMessagePopup>
  );
};
