import { Box, Divider } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ColorButton } from "../projects/ProjectUIHelper";
import { BasicMessagePopup } from "./BasicMessagePopup";
import { ReactComponent as SuccessSVG } from "../assets/cms/publishSuccess.svg";

export interface SuccessMessagePopupProps {
  message?: React.ReactNode | string;
  onClose?(): void;
  onContinue?(): void;
  title?: string;
  icon?: React.ReactNode;
  showAction: boolean;
  open: boolean;
  extraWidth?: string;
  projectId?: string;
  continueAction?:string
  nextStepText?:string
  disableAutoClose?:boolean
  size?:string
  subTitle?:React.ReactNode | string;
}

export const SuccessMessagePopup: FC<SuccessMessagePopupProps> = ({
  message,
  onClose,
  onContinue,
  title,
  icon,
  showAction,
  open,
  extraWidth,
  projectId,
  continueAction,
  nextStepText,
  disableAutoClose,
  size,
  subTitle
}) => {
  const navigate = useNavigate();

  return (
    <BasicMessagePopup
      icon={icon??<SuccessSVG/>}
      onClose={onClose}
      open={open}
      disableAutoClose={disableAutoClose}
      extraWidth={extraWidth}
      size={size}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          flexDirection: "column",
          fontFamily: "Nunito",
          justifyContent: "center",
          fontSize: "14px",
          width: "70%",
        }}
      >
        <Box sx={{ fontWeight: 500, marginTop: "32px" }}>
          {title ? title : "Good Job!"}
        </Box>
        {nextStepText && (
          <Divider sx={{width: '100%', marginTop:'20px' }}>{nextStepText}</Divider>
        )}
        {subTitle && (
          <Box sx={{ fontWeight: 500, marginTop: "20px"}}>{subTitle}</Box>
        )}
        <Box sx={{ fontWeight: 300, marginTop: "20px" }}>{message}</Box>
      </Box>
      {showAction && projectId && (
        <>
          <Box sx={{ display: "flex", flexGrow: 1 }} />
          <Box
            sx={{
              cursor: "pointer",
              alignSelf: "flex-end",
              color: "#6c3dab",
              padding: "35px 40px 10px 0px",
              textDecoration: "underline",
            }}
            onClick={() => navigate(`/projects/${projectId}/details`)}
          >
            Back to Project
          </Box>
        </>
      )}
      {onContinue && (
        <>
          <Box sx={{ display: "flex", flexGrow: 1 }} />
          <Box
            sx={{
              cursor: "pointer",
              alignSelf: "center",
              justifyContent: "center",
              color: "#6c3dab",
              padding: "35px 0px 0px",
            }}
            onClick={() => onContinue()}
          >
            <ColorButton>{continueAction ??'Continue'}</ColorButton>
          </Box>
        </>
      )}
    </BasicMessagePopup>
  );
};
