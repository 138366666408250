import { Box, TextField } from "@mui/material";
import * as React from "react";
import { ReactComponent as SearchCMSSVG } from "../../assets/cms/searchCMS.svg";
import useAsyncEffect from "use-async-effect";

export interface TaskSearchProps {
  startOpen?: boolean;
  background?: string;
  onSelectedSearchRes(value: string): void;
}

export const TaskSearch: React.FC<TaskSearchProps> = ({
  startOpen,
  background,
  onSelectedSearchRes,
}) => {
  const [inSearchMode, setInSearchMode] = React.useState<boolean>(false);
  const [selectedText, setSelectedText] = React.useState<string | null>(null);

  const onUpdateSelectedText = (val: string | null) => {
    setSelectedText(val);
    onSelectedSearchRes(val ?? "");
  };

  useAsyncEffect(async () => {
    if (startOpen) {
      setInSearchMode(true);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <SearchCMSSVG
        style={{ cursor: "pointer" }}
        onClick={() => setInSearchMode(!inSearchMode)}
      />
      <Box
        className={inSearchMode ? "showSearch" : "hideSearch"}
        sx={{ marginLeft: "10px" }}
      >
        <TextField
          sx={{
            flexGrow: 1,
            width: "100%",
            background: "#fff",
            boxShadow: "none !important",
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#7C48C2 !important",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#7C48C2 !important",
            },
            "& .MuiInput-underline:focus:after": {
              borderBottomColor: "#7C48C2 !important",
            },
            "& input.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
              WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
            },
          }}
          inputProps={{ className: "dropDownOther" }}
          placeholder={"Search tasks"}
          maxRows={1}
          variant="standard"
          value={selectedText ?? ""}
          onChange={(e) => {
            onUpdateSelectedText(e.target.value);
          }}
        />
      </Box>
      {!inSearchMode && (
        <Box
          sx={{ marginLeft: "10px", cursor: "pointer" }}
          onClick={() => setInSearchMode(!inSearchMode)}
        >
          Search
        </Box>
      )}
    </Box>
  );
};
