import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../cms.scss";
import {
  CMSComponentBuilder,
  CMSState,
  ListValues,
  PositionIndexBuilder,
} from "../cmsSlice";
import {
  ContentPickerPopup,
  MapKeyList,
} from "../ContentPicker/ContentPickerPopup";
import { ParagraphComp } from "./ParagraphComp";
import { SimpleTextComp } from "./SimpleTextComp";
import { SingleImageComp } from "./SingleImageComp";
import { PurpleButton } from "../../projects/ProjectUIHelper";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { ReactComponent as SmallTrashSvg } from "../../assets/project/smallTrash.svg";
import { ReactComponent as EyeSvg } from "../../assets/project/eye.svg";
import { ReactComponent as EyeHiddenSvg } from "../../assets/project/eyeHidden.svg";

export interface ServiceCompProps {
  item: ListValues;
  index: number;
  desctiption: string;
  labels?: string[];
  updateList(item: ListValues, index: number): void;
  onHideItem(index: number, show: boolean): void;
  onDeleteItem(anchor: HTMLDivElement, index: number): void;
  readOnly?: boolean;
  showContentPopup?: boolean;
  compMapKey?: string;
  hideList: string[];
  isAi?: boolean;
}

export const ServiceComp: React.FC<ServiceCompProps> = ({
  item,
  index,
  labels,
  updateList,
  onDeleteItem,
  onHideItem,
  readOnly,
  showContentPopup,
  compMapKey,
  hideList,
  isAi,
}) => {
  const [title, setTitle] = useState<string>(item.title);
  const [name, setName] = useState<string | undefined>(item.name);
  const [subTitle, setSubTitle] = useState<string>(item.subTitle);
  const [paragraph, setParagraph] = useState<string>(item.paragraph);
  const [image, setImage] = useState<string | any>(item.image);
  const [isTemplate, setIsTemplate] = useState<boolean>(
    item.fromTemplate ?? false
  );

  const [showAiContentPopup, setShowAiContentPopup] =
    React.useState<boolean>(false);
  const [fake, setFake] = useState<number>(0);

  const fakePosition = PositionIndexBuilder(index, index, index, index, index);

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  useAsyncEffect(() => {
    if (
      item.title !== title ||
      item.subTitle !== subTitle ||
      item.paragraph !== paragraph ||
      item.image !== image ||
      item.name !== name
    ) {
      updateList(
        {
          title: title,
          subTitle: subTitle,
          paragraph: paragraph,
          image: image,
          name: name,
          fromTemplate: isTemplate,
        },
        index
      );
    }
  }, [title, subTitle, paragraph, image, name]);

  const getItemLabel = (idx: number) => {
    if (labels && idx < labels.length) {
      return labels[idx];
    }
    return "";
  };

  const updateAiComponent = (mapKey: MapKeyList, content: string) => {
    setParagraph(content);
    setIsTemplate(false);
    setFake(fake + 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        outline: "1px solid #d9dedc",
        borderBottomLeftRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: cmsState.sideBarMode
            ? "20px 5px 20px 15px"
            : "20px 20px 20px 30px",
          position: "relative",
          gap: "10px",
        }}
      >
        <Grid container rowGap={"20px"}>
          {getItemLabel(4) !== "" && (
            <>
              <SimpleTextComp
                comp={CMSComponentBuilder(
                  [],
                  getItemLabel(4),
                  "",
                  name !== undefined ? name : "",
                  "",
                  ""
                )}
                onUpdateComp={(item) => {
                  setName(item.comp.value);
                  setIsTemplate(false);
                }}
                location={fakePosition}
                readonly={readOnly}
                padding={12}
              />
            </>
          )}
          {hideList.indexOf("title") === -1 && (
            <SimpleTextComp
              comp={CMSComponentBuilder([], getItemLabel(0), "", title, "", "")}
              onUpdateComp={(item) => {
                setTitle(item.comp.value);
                setIsTemplate(false);
              }}
              location={fakePosition}
              readonly={readOnly}
              padding={
                cmsState.mobileView || hideList.indexOf("Image") !== -1 ? 12 : 6
              }
            />
          )}
          {hideList.indexOf("SubTitle") === -1 &&
          hideList.indexOf("subTitle") === -1 ? (
            <SimpleTextComp
              comp={CMSComponentBuilder(
                [],
                getItemLabel(1),
                "",
                subTitle,
                "",
                ""
              )}
              onUpdateComp={(item) => {
                setSubTitle(item.comp.value);
                setIsTemplate(false);
              }}
              location={fakePosition}
              padding={cmsState.mobileView ? 12 : 6}
              readonly={readOnly}
            />
          ) : (
            <Grid item xs={cmsState.mobileView ? 12 : 6}>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: 1 }}></Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={cmsState.sideBarMode ? 0 : 3}>
          {hideList.indexOf("paragraph") === -1 && (
            <ParagraphComp
              comp={CMSComponentBuilder(
                [],
                getItemLabel(2),
                "",
                paragraph,
                compMapKey??'',
                "paragraph",
                true,
                ""
              )}
              key={`service-parag-${index}-${fake}`}
              onUpdateComp={(item) => {
                setParagraph(item.comp.value);
                setIsTemplate(false);
              }}
              location={fakePosition}
              forceUpdate={0}
              padding={
                cmsState.mobileView || hideList.indexOf("Image") !== -1 ? 12 : 6
              }
              readonly={readOnly}
              showContentPopup={false}
              onShowContentPopupDialog={() => setShowAiContentPopup(true)}
            />
          )}
          {hideList.indexOf("Image") === -1 &&
            hideList.indexOf("image") === -1 && (
              <SingleImageComp
                location={fakePosition}
                comp={CMSComponentBuilder(
                  [],
                  getItemLabel(3),
                  "",
                  image,
                  "",
                  "media"
                )}
                onUpdateComp={(item) => {
                  setImage(item.comp.value);
                  setIsTemplate(false);
                }}
                readonly={readOnly}
              />
            )}
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent:
              showContentPopup || (isAi && title !== "")
                ? "space-between"
                : "flex-end",
            marginTop:
              showContentPopup || (isAi && title !== "") ? "20px" : "0px",
            alignItems: "center",
          }}
        >
          {(showContentPopup || (isAi && title !== "")) &&
            !cmsState.isClientMode && (
              <Box sx={{ marginTop: "5px" }}>
                {" "}
                <PurpleButton
                  onClick={() => setShowAiContentPopup(true)}
                  sx={{ padding: "3px 10px" }}
                >
                  Content Options
                </PurpleButton>
              </Box>
            )}
          {!readOnly &&
            (cmsState.webSiteMode ? (
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => onHideItem(index, !(item.isHidden ?? false))}
              >
                <HelpToolTipOnHover
                  position="left"
                  message={`${item.isHidden ? "Show" : "Hide"} item from site`}
                  actionNode={item.isHidden ? <EyeHiddenSvg /> : <EyeSvg />}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  // position: "absolute",
                  // bottom: "-10px",
                  // right: "10px",
                  cursor: "pointer",
                }}
                onClick={(e) => onDeleteItem(e.currentTarget, index)}
              >
                <HelpToolTipOnHover
                  position="left"
                  message={"Delete item"}
                  actionNode={<SmallTrashSvg />}
                />
              </Box>
            ))}
        </Box>
      </Box>
      {showAiContentPopup && compMapKey && compMapKey !== "" && (
        <ContentPickerPopup
          mapKey={{ key: compMapKey, index: index }}
          description={title}
          onUpdateComp={updateAiComponent}
          readonly={readOnly}
          noClient={true}
          onClose={() => {
            setShowAiContentPopup(false);
          }}
        />
      )}
    </Box>
  );
};
