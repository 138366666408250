import axios from "axios";
import ApiUtils from "./ApiUtils";

export interface FaceBookLongTokenResponse {
  access_token: string;
  expires_in: number;
}

export interface UserFaceBookInfo {
  accessKey: string;
  pageName: string;
  pageId: string;
  pageLogo: string;
  pageAccessKey: string;
}

export interface UserLinkedInInfo {
  access_token: string;
  organization_id: string;
  organization_logo: string;
  organization_name: string;
}

export interface FaceBookPage {
  name: string;
  category: string;
}

export interface LinkedInOrganization {
  organization_id: string;
  organization_logo: string;
  organization_name: string;
}

export interface FaceBookPageList {
  pages: FaceBookPageInfo[];
}

export interface FaceBookPageInfo {
  accessToken: string;
  category: string;
  facebookId: string;
  logo: string;
  name: string;
}

export interface FaceBookOpenHours {
  mon_1_open?: string;
  mon_1_close?: string;
  tue_1_open?: string;
  tue_1_close?: string;
  wed_1_open?: string;
  wed_1_close?: string;
  thu_1_open?: string;
  thu_1_close?: string;
  fri_1_open?: string;
  fri_1_close?: string;
  sun_1_open?: string;
  sun_1_close?: string;
  sat_1_open?: string;
  sat_1_close?: string;
}

export interface FaceBookLocation {
  location?: string;
  city?: string;
  country?: string;
  latitude?: string;
  longitude?: string;
  state?: string;
  street?: string;
  zip?: string;
}
export interface FaceBookGraphImg {
  source?: string;
}

export interface FaceBookGraphCoverImg {
  data?: { url: string };
}

export interface FaceBookPageAttributes {
  about?: string;
  // category?: string
  description?: string;
  emails?: string[];
  hours?: FaceBookOpenHours;
  impressum?: string;
  phone?: string;
  website?: string;
  location?: FaceBookLocation;
  single_line_address?: string;
  picture?: FaceBookGraphImg;
  cover?: FaceBookGraphCoverImg;
}

export interface FaceBookImages {
  cover?: string;
  picture?: string;
}

export interface LinkedInDetails {
  localizedName: string;
}

export interface LinkedInOrganizationInfo {
  id: string;
  name: string;
}

export interface LinkedInOrganizationResponse {
  organizations: LinkedInOrganizationInfo[];
  loginIssue?: boolean;
  unknownIssue?: boolean;
}

export interface PostItemData {
  id : string,
  scheduled?: string
  published?: string
  content: string
  images: string[]
}

export interface SocialItemData {
  id : string,
  facebook?: PostItemData
  linkedin?: PostItemData
  instagram?: PostItemData
  created: string
}

export interface PostsResponse {
  itemsInPage: number
  itemsPerPage: number
  totalPages: number
  pageNumber: number
  items: SocialItemData[]
}

export interface PostInsiteItemData {
  id: string,
  likes?: number,
  comments?: number
}

export interface PostInsightsData {
  id: string,
  facebook?: PostInsiteItemData,
  linkedin?: PostInsiteItemData
}

export interface PostInsitesResponse {
  insights: PostInsightsData[]
  success: boolean
}

export interface LinkInAddressStruct {
  country?: string
  city?:string
  line2?: string
  line1?: string,
  postalCode?: string,
  geographicArea?: string
}
export interface LinkedInPhoneStruct {
  number?: string
}

export interface LinkedInLocation {
  locationType: string
  address?: LinkInAddressStruct
  phoneNumber1?: LinkedInPhoneStruct
}

export interface LinkedInFounded {
  year?: number
}

export interface LinkedInResponseData {
  localizedDescription?: string;
  localizedWebsite?: string
  locations?: LinkedInLocation[]
  staffCountRange?: string
  foundedOn?: LinkedInFounded
  localizedName?:string
}

export interface DeletePostInfo {
  facebook?: boolean
  linkedin?: boolean
}



export interface InstagramUser {
  instagramBusinessAccountId : string
  linkedFacebookAccountId: string
  name: string
  userName: string
  webSite: string
  profilePictureUrl: string
  biography: string
  followersCount : number
  followsCount: number
  mediaCount: number
}

export interface InstagramUserResponse {
  user: InstagramUser
}

export interface InstagramUsersList {
  users: InstagramUser[]
}

class SocialApi {
  /// FACEBOOK ///

  getFaceBookInfo = async (
    projectId: string,
    token: string
  ): Promise<FaceBookPageAttributes | undefined> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        console.log(response.data);
        return undefined;
      }
    } catch {
      return undefined;
    }
  };

  getLongFaceBookAccessCode = async (
    accessCode: string,
    projectId: string,
    token: string
  ): Promise<FaceBookLongTokenResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/connect?facebookShortToken=${accessCode}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };


  getLongInstagramAccessCode = async (
    accessCode: string,
    projectId: string,
    token: string
  ): Promise<FaceBookLongTokenResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/instagram/${projectId}/connect?instagramShortToken=${accessCode}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  disconnectFromFacebook = async (
    projectId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/disconnect`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };


  getFaceBookPageList = async (
    projectId: string,
    token: string
  ): Promise<FaceBookPageList> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/pages`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getFaceBookPageImages = async (
    projectId: string,
    token: string
  ): Promise<FaceBookImages> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page/images`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  updateFaceBookPageImages = async (
    projectId: string,
    data: any,
    token: string
  ): Promise<FaceBookImages> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page/images`,
      data,
      ApiUtils.Put(token)
    );
    return response.data;
  };

  setFaceBookPage = async (
    projectId: string,
    pageId: string,
    token: string
  ): Promise<FaceBookPageInfo | undefined> => {
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page?facebookId=${pageId}`,
        {},
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        console.log(response.data);
        return undefined;
      }
    } catch {
      return undefined;
    }
  };

  deleteFacebookPage = async (
    projectId: string,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.delete(
        `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page`,
        ApiUtils.Delete(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        console.log(response.data);
        return false;
      }
    } catch {
      return false;
    }
  };

  updateFaceBookPageInfo = async (
    projectId: string,
    data: { [id: string]: any },
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.put(
        `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page`,
        data,
        ApiUtils.Put(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        console.log(response.data);
        return false;
      }
    } catch {
      return false;
    }
  };

  saveFaceBookInfo = async (
    data: UserFaceBookInfo,
    projectId: string,
    token: string
  ): Promise<FaceBookLongTokenResponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page`,
      data,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  deleteFaceBookInfo = async (
    projectId: string,
    token: string
  ): Promise<FaceBookLongTokenResponse> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/social/api/facebook/${projectId}/page`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };

  getSocialPosts = async (
    projectId: string,
    token: string,
    preview?: boolean
  ): Promise<PostsResponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/social/api/posts/${projectId}/pagination`, {pageNumber: 0,  itemsPerPage: preview? 3: 10},
      ApiUtils.Post(token)
    );
    return response.data;
  };

  getSocislPostsInsights = async (
    projectId: string,
    postList: string[],
    token: string
  ): Promise<PostInsitesResponse> => {
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/social/api/posts/${projectId}/insights`, {posts: postList},
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return {...response.data, success: true};
      } else {
        return {insights:[], success: false};
      }
    } catch {
      return {insights:[], success: false};
    }
    
  };  

  getSocialPostsById = async (
    postId: string,
    token: string
  ): Promise<SocialItemData> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/posts/${postId}?postId=${postId}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };


  deletePost = async (
    post: SocialItemData,
    projectId : string,
    data: DeletePostInfo,
    token: string
  ): Promise<PostsResponse> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/social/api/posts/${projectId}?postId=${post.id}`,
      ApiUtils.DeleteWithData(token, data)
    );
    return response.data;
  };


  postToSocial = async (
    projectId: string,
    postData: any,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/social/api/posts/${projectId}`, 
        postData,
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        console.log(response.data);
        return false;
      }
    } catch {
      return false;
    }
  };

  /// LINKEDIN ///

  connectToLinkedIn = async (
    code: string,
    projectId: string,
    token: string
  ): Promise<FaceBookLongTokenResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/linkedin/${projectId}/connect?code=${code}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  disconnectFromLinkedIn = async (
    projectId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/social/api/linkedin/${projectId}/disconnect`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };

  updateLinkedInOrganization = async (
    data: LinkedInOrganizationInfo,
    projectId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/social/api/linkedin/${projectId}/organization`,
      data,
      ApiUtils.Put(token)
    );
    return response.data;
  };

  getLinkedInOrganizationData = async (
    projectId: string,
    token: string
  ): Promise<LinkedInResponseData> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/social/api/linkedin/${projectId}/organization`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        const loginIssue = response.data?.detail??''.indexOf("refreshing linkedin token")> -1
        if (loginIssue) {
          throw Error("Reconnecting");
        } else {
          throw Error("UnknownError");
        }
      }      
    } catch {
      throw Error("UnknownError");
    }      
  };

  deleteLinkedInPage = async (
    projectId: string,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.delete(
        `${ApiUtils.serverDomain}/social/api/linkedin/${projectId}/organization`,
        ApiUtils.Delete(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        console.log(response.data);
        return false;
      }
    } catch {
      return false;
    }
  };

  getLinkedInOrganizationsNames = async (
    projectId: string,
    token: string
  ): Promise<LinkedInOrganizationResponse| undefined> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/social/api/linkedin/${projectId}/organizations`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        const loginIssue = response.data?.detail??''.indexOf("refreshing linkedin token")> -1
        return {organizations: [], loginIssue: loginIssue, unknownIssue: !loginIssue  };
      }      
    } catch {
      return {organizations: [], unknownIssue: true};
      
    }  
  };

  
  sendClientRequuestToConnectToSocial = async (
    projectId: string,
    socialNetwork: string,
    url: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/social/api/notifications/${projectId}/connect_social`, {socialNetwork, url},
      ApiUtils.Post(token)
    );
    return response.data;
  };

  
 //connect instagram page
   getInstagramPageList= async (
    projectId: string,
    token: string
  ): Promise<InstagramUsersList> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/instagram/${projectId}/users`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  //connect instagram page
  getInstagramConnectUserInfo= async (
    projectId: string,
    token: string
  ): Promise<InstagramUserResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/social/api/instagram/${projectId}/user`,
      ApiUtils.Get(token)
    );
    return response.data;
  };


  setInstagramUser = async (
    projectId: string,
    instagramAccountId: string,
    token: string
  ): Promise<InstagramUsersList> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/social/api/instagram/${projectId}/user?instagramAccountId=${instagramAccountId}`, {}, 
      ApiUtils.Post(token)
    );
    return response.data;
  };

  disconnectFromInstagram = async (
    projectId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.delete(
      `${ApiUtils.serverDomain}/social/api/instagram/${projectId}/disconnect`,
      ApiUtils.Delete(token)
    );
    return response.data;
  };
      


}

export const socialApi = new SocialApi();
