import * as React from "react";

export interface SingleCMSSvgProps {
  name: string;
}

export const SingleCMSSvg: React.FC<SingleCMSSvgProps> = ({ name }) => {
  const getSvgByName = () => {
    switch (name) {
      case "commentNew":
        return (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="24.967%"
                y1="-9.676%"
                x2="75.372%"
                y2="125.105%"
                id="hf2n4kwzta"
              >
                <stop stopColor="#FB8332" offset="0%" />
                <stop stopColor="#F8C332" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="24.967%"
                y1="-9.676%"
                x2="75.372%"
                y2="125.105%"
                id="yvns741mpd"
              >
                <stop stopColor="#FB8332" offset="0%" />
                <stop stopColor="#F8C332" offset="100%" />
              </linearGradient>
              <path id="vb8d5dtb7b" d="M0 11h11V0H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <path
                d="M15.455 0H4.545A4.55 4.55 0 0 0 0 4.545v7.273a4.554 4.554 0 0 0 3.636 4.455v2.818a.91.91 0 0 0 1.414.756l5.223-3.483h5.182A4.55 4.55 0 0 0 20 11.818V4.545A4.55 4.55 0 0 0 15.455 0z"
                fillRule="nonzero"
                stroke="url(#hf2n4kwzta)"
                transform="translate(1 1)"
              />
              <g transform="matrix(1 0 0 -1 5 15)">
                <mask id="yq5q23d86c" fill="#fff">
                  <use xlinkHref="#vb8d5dtb7b" />
                </mask>
                <g
                  mask="url(#yq5q23d86c)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    d="M9.389 6.145H6.145v3.244a.967.967 0 1 1-1.934 0V6.145H.967a.967.967 0 1 1 0-1.934H4.21V.967a.967.967 0 1 1 1.934 0V4.21h3.244a.967.967 0 1 1 0 1.934z"
                    stroke="url(#yvns741mpd)"
                    transform="translate(.322 .322)"
                  />
                </g>
              </g>
            </g>
          </svg>
        );

      case "noContent":
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="-32.756%"
                y1="-80.833%"
                x2="104.364%"
                y2="100%"
                id="ag1a82slna"
              >
                <stop stopColor="#3023AE" offset="0%" />
                <stop stopColor="#C86DD7" offset="100%" />
              </linearGradient>
            </defs>
            <g fillRule="nonzero" fill="none">
              <path
                d="M15.646 2.684c.81-.81 1.012-1.418 1.417-1.62 1.62-1.418 4.05-1.418 5.874 0 .405.202.81.81 1.417 1.62.405.405.608.607.608.81.81.81 2.025 1.215 3.24 1.215h1.013c1.215-.203 1.823-.203 2.228-.203 2.228.203 3.848 2.026 4.05 4.051 0 .405 0 1.013-.202 2.228v1.012c0 1.216.405 2.228 1.215 3.241.203.203.405.405.81.608.81.81 1.418 1.012 1.62 1.417 1.418 1.62 1.418 4.05 0 5.874-.202.405-.81.81-1.62 1.417-.405.405-.607.608-.81.608-.81.81-1.215 2.025-1.215 3.24v1.013c.203 1.215.203 1.823.203 2.228-.203 2.228-2.026 3.848-4.051 4.05-.405 0-1.013 0-2.228-.202h-1.012c-1.216 0-2.228.405-3.241 1.215-.203.203-.405.405-.608.81-.81.81-1.012 1.418-1.417 1.62-1.62 1.418-4.05 1.418-5.874 0-.405-.202-.81-.81-1.417-1.62-.405-.405-.608-.607-.608-.81-.81-.81-2.025-1.215-3.24-1.215h-1.013c-1.215.203-1.823.203-2.228.203-2.228-.203-3.848-2.026-4.05-4.051 0-.405 0-1.013.202-2.228v-1.012c0-1.216-.405-2.228-1.215-3.241-.203-.203-.405-.405-.81-.608-.81-.81-1.418-1.012-1.62-1.417-1.418-1.62-1.418-4.05 0-5.874.202-.405.81-.81 1.62-1.417.405-.405.607-.608.81-.608.81-.81 1.215-2.025 1.215-3.24v-1.013c-.203-1.013-.203-1.62-.203-2.026.203-2.227 2.026-3.848 4.051-4.05.405 0 1.013 0 2.228.202h1.012c1.216 0 2.228-.405 3.241-1.215.203-.405.405-.607.608-1.012z"
                fill="url(#ag1a82slna)"
              />
              <path
                d="M20.101 28.608c-1.215 0-2.025-.81-2.025-2.026 0-1.215.81-2.025 2.025-2.025 1.215 0 2.026.81 2.026 2.025 0 1.215-.81 2.026-2.026 2.026zM20.101 22.937c-.81 0-1.62-.608-1.62-1.62v-9.114c0-.81.608-1.62 1.62-1.62 1.013 0 1.62.607 1.62 1.62v9.113c-.202 1.013-.81 1.62-1.62 1.62z"
                fill="#FFF"
              />
            </g>
          </svg>
        );

      case "expertIcon":
        return (
          <svg
            width="31"
            height="24"
            viewBox="0 0 31 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#FFF" fillRule="nonzero">
              <path d="M15.064 10.274c.935 0 1.823-.112 2.501-.316l11.006-3.301c1.286-.387 1.556-1.04 1.556-1.52s-.27-1.134-1.556-1.52L17.565.315C16.887.112 15.999 0 15.064 0c-.936 0-1.824.112-2.502.315L1.556 3.617C.27 4.003 0 4.657 0 5.137s.27 1.133 1.556 1.52l11.006 3.301c.678.204 1.566.316 2.502.316z" />
              <path d="M15.064 12.039c-1.119 0-2.16-.135-3.01-.39L5.673 9.734v2.493c0 .826.537 1.99 3.096 2.843 1.695.565 3.93.876 6.295.876 2.366 0 4.601-.311 6.296-.876 2.559-.853 3.096-2.017 3.096-2.843V9.734l-6.383 1.915c-.85.255-1.89.39-3.008.39zM28.236 14.816V8.6l-1.765.53v5.686a2.778 2.778 0 0 0-1.89 2.63v3.78A2.777 2.777 0 0 0 27.353 24a2.776 2.776 0 0 0 2.773-2.773v-3.782c0-1.22-.793-2.259-1.89-2.629z" />
            </g>
          </svg>
        );
      case "designerIcon":
        return (
          <svg
            width="22"
            height="25"
            viewBox="0 0 22 25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#FFF" fillRule="nonzero">
              <path d="M11.908 0v10.083a2.532 2.532 0 1 1-1.816 0V0L0 12.316l3.24 6.571h15.52L22 12.316 11.908 0zM5.609 20.702H3.794v4.13h14.412v-4.13h-1.815z" />
            </g>
          </svg>
        );

      case "commentOff":
        return (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="24.967%"
                y1="-9.676%"
                x2="75.372%"
                y2="125.105%"
                id="v10e4aqg6a"
              >
                <stop stopColor="#FB8332" offset="0%" />
                <stop stopColor="#F8C332" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d="M15.455 0H4.545A4.55 4.55 0 0 0 0 4.545v7.273a4.554 4.554 0 0 0 3.636 4.455v2.818a.91.91 0 0 0 1.414.756l5.223-3.483h5.182A4.55 4.55 0 0 0 20 11.818V4.545A4.55 4.55 0 0 0 15.455 0zm-1.819 10.91H6.364a.91.91 0 0 1 0-1.82h7.272a.91.91 0 1 1 0 1.82zm1.819-3.637H4.545a.91.91 0 1 1 0-1.818h10.91a.91.91 0 0 1 0 1.818z"
              transform="translate(1 1)"
              fillRule="nonzero"
              stroke="url(#v10e4aqg6a)"
              fill="none"
            />
          </svg>
        );
      case "commentOn":
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="24.967%"
                y1="-9.676%"
                x2="75.372%"
                y2="125.105%"
                id="wzf1cszuua"
              >
                <stop stopColor="#FB8332" offset="0%" />
                <stop stopColor="#F8C332" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d="M15.455 0H4.545A4.55 4.55 0 0 0 0 4.545v7.273a4.554 4.554 0 0 0 3.636 4.455v2.818a.91.91 0 0 0 1.414.756l5.223-3.483h5.182A4.55 4.55 0 0 0 20 11.818V4.545A4.55 4.55 0 0 0 15.455 0zm-1.819 10.91H6.364a.91.91 0 0 1 0-1.82h7.272a.91.91 0 1 1 0 1.82zm1.819-3.637H4.545a.91.91 0 1 1 0-1.818h10.91a.91.91 0 0 1 0 1.818z"
              fill="url(#wzf1cszuua)"
              fillRule="nonzero"
            />
          </svg>
        );
      case "clientIcon":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#FFF" fillRule="nonzero">
              <path d="M8.216 11.695A6.396 6.396 0 0 0 12 12.923c3.454 0 6.462-2.99 6.462-6.461A6.469 6.469 0 0 0 12 0 6.469 6.469 0 0 0 5.54 6.462a6.442 6.442 0 0 0 2.677 5.233z" />
              <path d="M17.686 12.508A8.26 8.26 0 0 1 12 14.769a8.26 8.26 0 0 1-5.686-2.261C2.557 14.538 0 18.517 0 23.077c0 .508.416.923.923.923h22.154a.926.926 0 0 0 .923-.923c0-4.56-2.557-8.539-6.314-10.57z" />
            </g>
          </svg>
        );
      case "aiIcon":
        return (
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#FFF" fillRule="nonzero">
              <path d="M24.453 13A3.794 3.794 0 0 0 26 9.953a3.828 3.828 0 0 0-2.3-3.495c.22-2.24-1.74-4.398-4.157-4.157A3.828 3.828 0 0 0 16.047 0a3.77 3.77 0 0 0-2.285.786V9.19h1.523V6.906a.761.761 0 1 1 1.524 0v2.285h2.285a.761.761 0 1 1 0 1.524h-2.285v1.523h2.285a.761.761 0 1 1 0 1.524h-2.285v1.523h2.285a.761.761 0 1 1 0 1.524h-2.285v2.285a.761.761 0 1 1-1.524 0v-2.285h-1.523v8.405a3.77 3.77 0 0 0 2.285.786 3.828 3.828 0 0 0 3.496-2.3c2.409.243 4.378-1.91 4.156-4.158A3.828 3.828 0 0 0 26 16.047c0-1.25-.613-2.352-1.547-3.047z" />
              <path d="M10.715 10.715v4.57h4.57v-4.57h-4.57zM13 13.762a.762.762 0 1 1 0-1.524.762.762 0 0 1 0 1.524z" />
              <path d="M12.238 9.191V.786A3.77 3.77 0 0 0 9.953 0a3.828 3.828 0 0 0-3.495 2.3C4.072 2.065 2.078 4.189 2.3 6.459a3.828 3.828 0 0 0-2.3 3.495c0 1.25.613 2.352 1.546 3.047A3.794 3.794 0 0 0 0 16.047c0 1.533.933 2.902 2.301 3.495-.22 2.239 1.742 4.401 4.157 4.157A3.828 3.828 0 0 0 9.953 26a3.77 3.77 0 0 0 2.285-.786V16.81h-1.523v2.285a.761.761 0 1 1-1.523 0v-2.285H6.906a.761.761 0 1 1 0-1.524h2.286v-1.523H6.906a.761.761 0 1 1 0-1.524h2.286v-1.523H6.906a.761.761 0 1 1 0-1.524h2.286V6.906a.761.761 0 1 1 1.523 0v2.285h1.523z" />
            </g>
          </svg>
        );
      case "cmsContentPopup":
        return (
          <svg
            width="62"
            height="62"
            viewBox="0 0 62 62"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="-32.756%"
                y1="-76.654%"
                x2="104.364%"
                y2="98.403%"
                id="f4ekhw3isa"
              >
                <stop stopColor="#3023AE" offset="0%" />
                <stop stopColor="#C86DD7" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <path
                d="m0 0 62 61.002H10c-5.523 0-10-4.477-10-10V0z"
                fill="url(#f4ekhw3isa)"
                transform="translate(0 .5)"
              />
              <path
                d="M27 38.604v1.054c0 .582-.472 1.054-1.054 1.054h-4.743a.527.527 0 0 0-.526.527v2.18a.843.843 0 0 1-1.44.596l-4.288-4.287a.843.843 0 0 1 0-1.193l4.288-4.288a.843.843 0 0 1 1.44.596v2.18c0 .292.235.527.526.527h4.743c.582 0 1.054.472 1.054 1.054zm-13.237 6.378a.843.843 0 0 0-1.44.596v2.18a.527.527 0 0 1-.526.527H7.054c-.582 0-1.054.472-1.054 1.054v1.054c0 .582.472 1.054 1.054 1.054h4.743c.29 0 .526.236.526.527v2.18a.843.843 0 0 0 1.44.596l4.288-4.288a.843.843 0 0 0 0-1.192l-4.288-4.288z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </g>
          </svg>
        );
      case "cmsContentPopupSmall":
        return (
          <svg
            width="30"
            height="30"
            viewBox="0 0 62 62"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="-32.756%"
                y1="-76.654%"
                x2="104.364%"
                y2="98.403%"
                id="f4ekhw3isa"
              >
                <stop stopColor="#3023AE" offset="0%" />
                <stop stopColor="#C86DD7" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <path
                d="m0 0 62 61.002H10c-5.523 0-10-4.477-10-10V0z"
                fill="url(#f4ekhw3isa)"
                transform="translate(0 .5)"
              />
              <path
                d="M27 38.604v1.054c0 .582-.472 1.054-1.054 1.054h-4.743a.527.527 0 0 0-.526.527v2.18a.843.843 0 0 1-1.44.596l-4.288-4.287a.843.843 0 0 1 0-1.193l4.288-4.288a.843.843 0 0 1 1.44.596v2.18c0 .292.235.527.526.527h4.743c.582 0 1.054.472 1.054 1.054zm-13.237 6.378a.843.843 0 0 0-1.44.596v2.18a.527.527 0 0 1-.526.527H7.054c-.582 0-1.054.472-1.054 1.054v1.054c0 .582.472 1.054 1.054 1.054h4.743c.29 0 .526.236.526.527v2.18a.843.843 0 0 0 1.44.596l4.288-4.288a.843.843 0 0 0 0-1.192l-4.288-4.288z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </g>
          </svg>
        );
      case "contentPopup":
        return (
          <svg
            width="72px"
            height="72px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="-32.756%"
                y1="-77.231%"
                x2="104.364%"
                y2="98.623%"
                id="0sqiorftdc"
              >
                <stop stopColor="#3023AE" offset="0%" />
                <stop stopColor="#C86DD7" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d="M 0 71 L 71 71 L 0 0 z"
              stroke="colourname"
              fill="url(#0sqiorftdc)"
            />
          </svg>
        );
      case "project":
        return null;

      default: {
        return <></>;
      }
    }
  };

  return getSvgByName();
};
