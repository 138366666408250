import * as React from "react";
import { Box, Tooltip, TooltipProps } from "@mui/material";

export interface HelpToolTipOnHoverProps {
  position: TooltipProps["placement"];
  actionNode?: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  message: string | React.ReactNode;
  size?: number;
  inverted?: boolean;
}

export const HelpToolTipOnHover: React.FC<HelpToolTipOnHoverProps> = ({
  actionNode,
  children,
  icon,
  message,
  position,
  size,
  inverted,
}) => {
  return (
    <>
      <Tooltip
        enterDelay={300}
        sx={{ zIndex: 5100 }}
        placement={position ?? "bottom"}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: size ? size : 300,
              padding: icon
                ? "24px 26px 18px 26px"
                : children
                ? "8px 26px 5px 26px"
                : "2px 26px 5px 26px",
              bgcolor: inverted ? "#fff" : "#292B28",
              "& .MuiTooltip-arrow": {
                color: inverted ? "#fff" : "#292B28",
              },
              color: inverted ? "#292B28" : "#fff",
            },
          },
        }}
        PopperProps={{
          onPointerDown: (e) => {
            if (
              e.target instanceof Element &&
              !e.target.classList.contains("MuiTooltip-tooltip")
            ) {
              e.preventDefault();
            }
          },
        }}
        title={
          <Box>
            {icon && <Box>{icon}</Box>}
            <Box
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: "normal",
                marginTop: icon ? "10px" : "4px",
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  fontWeight: 600,
                }}
              >
                {message}
              </Box>

              {children && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "27px",
                  }}
                >
                  {children}
                </Box>
              )}
            </Box>
          </Box>
        }
        arrow={true}
      >
        <Box sx={{ display: "flex", width: "max-content" }}>{actionNode}</Box>
      </Tooltip>
    </>
  );
};
