import { Box, Button } from "@mui/material";
import * as React from "react";
import { ColorButton } from "../projects/ProjectUIHelper";

export const SiteEditorTest: React.FC = () => {


  const injectToIframe = () => {
   
    const iframe:any = document.getElementById('site_editor');
    const iframeWin = iframe?.contentWindow
    if (iframeWin) {
      iframeWin.postMessage({command:'mark'}, "https://mylynxisite.com");
    }   
  }

  const ScrollTo = () => {
   
    const iframe:any = document.getElementById('site_editor');
    const iframeWin = iframe?.contentWindow
    if (iframeWin) {
      iframeWin.postMessage({command:'scroll', sectionId:3}, "https://mylynxisite.com");
    }   
  }

  return (
    <Box sx={{display:'flex', height:'90vh'}}>
      <Box sx={{width:'20%'}}>
          <ColorButton onClick={() => injectToIframe()}>Inject</ColorButton>
          <ColorButton onClick={() => ScrollTo()}>ScrollTo</ColorButton>
      </Box>
      <Box sx={{width:'80%'}}>
        <iframe id='site_editor' src="https://mylynxisite.com/elementor-385/" width={'100%'} height={'100%'}></iframe>
      </Box>
    </Box>
  )

}