import { useMsal } from "@azure/msal-react";
import { Box, Divider, Typography } from "@mui/material";
import { cloneDeep, difference, get, keys } from "lodash";
import * as React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { backofficeApi } from "../../api/backofficeApi";
import { QuestionnaireData, projectsApi } from "../../api/projectsApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getAccessTokenSilence } from "../../auth/auth";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { Project, ProjectState } from "../../projects/projectsSlice";
import { ContentHubFilterType } from "../../utils/Constants";
import TreeUtils from "../../utils/TreeUtils";
import {
  CMSState,
  setCMSFilterMode,
  setCmsSchema,
  setCurrentSection,
  setInFormBuildMode,
  setPages,
  setSelectedFormQuestions,
  setSelectedFormSectionQuestions,
} from "../cmsSlice";
import ContentHubPopupBase from "./ContentHubPopupBase";
import {addSinglesOrigListOrderToSection, getSelectedSinglesStucture } from "../cmsUtils";

export interface CHFormUpdatePopupProps {
  show: boolean;
  onClose: () => void;
  userFormData: QuestionnaireData;
  project: Project;
}

const CHFormUpdatePopup: FC<CHFormUpdatePopupProps> = ({
  userFormData,
  show,
  onClose,
  project,
}) => {
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const [selectedSingles, setSelectedSingles] = React.useState<number[]>([]);
  const [noSideBar, setNoSideBar] = React.useState<boolean>(true);
  const [saveMode, setSaveMode] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const msalInstance = useMsal();
  const { t } = useTranslation();

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  const projectState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  useAsyncEffect(async () => {
    setOnLoading(true);
    dispatch(setInFormBuildMode(false));
    dispatch(setCurrentSection(0));
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnLoading(true);
      const formSchema = JSON.parse(userFormData.template ?? "{}");
      dispatch(setCMSFilterMode(ContentHubFilterType.None));
      setSelectedSingles(get(formSchema, "singlesKeys", []));
      dispatch(setSelectedFormQuestions(get(formSchema, "singlesKeys", [])));
      const cmsSections = get(formSchema, "sectionSchema", []); 
      dispatch(setSelectedFormSectionQuestions(cmsSections));
      setNoSideBar(false);
      dispatch(setInFormBuildMode(true));
      const cmsFullSchema = await backofficeApi.getParamFromServer(
        "cms",
        token.accessToken
      );
      const cmsJsonObj = JSON.parse(cmsFullSchema.value);
      let pages = get(cmsJsonObj, "pages", []);
      if (projectState.accountInfo && projectState.accountInfo.editorSettings) {
        const editorSettings = JSON.parse(
          projectState.accountInfo.editorSettings
        );
        if (get(editorSettings, "schema")) {
          pages = get(editorSettings, "schema");
        } else {
          if (get(editorSettings, "editor")) {
            TreeUtils.updateCmsWithEditor(pages, get(editorSettings, "editor"));
          }
        }
        if ( get(editorSettings, "labels")) {
          TreeUtils.updateCmsWithLabels(pages,get(editorSettings, "labels"))
        }
      }
      dispatch(setCmsSchema(cloneDeep(pages)));
      dispatch(setPages(pages));
      setOnLoading(false);
    }
  }, [userFormData]);

  useAsyncEffect(async () => {
    setSaveMode(
      difference(selectedSingles, cmsState.formQuestions).length > 0 ||
        difference(cmsState.formQuestions, selectedSingles).length > 0
    );
  }, [cmsState.formQuestions]);

  const onCloseClick = () => {
    dispatch(setInFormBuildMode(false));
    onClose();
  };


  const onUpdate = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnLoading(true);
      const singlesList = cmsState.formQuestions;
      const singlesQuestions = TreeUtils.extractDataFromSelectedFormQuestions(
        cmsState.pages,
        singlesList
      );

      const data = {
        id: userFormData.id,
        name: userFormData.name,
        content: userFormData.content,
        numberOfQuestions: TreeUtils.extractTotalFormQuestionsFromSingles(
          cmsState.pages,
          singlesList
        ),
        answeredQuestions: userFormData.answeredQuestions ?? 0,
        status: userFormData.status,
        templateId: userFormData.templateId,
        schema: JSON.stringify({
          schema: getSelectedSinglesStucture(cmsState),
          sectionSchema: addSinglesOrigListOrderToSection(cmsState),
          singlesKeys: singlesList,
          cmsKeys: keys(singlesQuestions)
        }),
      };
      await projectsApi.updateUserQuestionnair(data, token.accessToken);
      dispatch(setInFormBuildMode(false));
      setOnLoading(false);
      onClose();
    }
  };

  const renderHeader = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          color: "rgb(108, 61, 171)",
        }}
      >
        <Box>
          <Typography
            sx={{
              padding: "0px 66px",
              fontFamily: "Nunito",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {userFormData.name}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {cmsState.cmsFilterMode !== ContentHubFilterType.SelectedQuestions ? (
          <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                onCloseClick();
              }}
            >
              Cancel
            </Box>
            <Divider sx={{ margin: "0 0px" }} orientation="vertical" flexItem />
            <Box
              sx={{ cursor: "pointer",color: "rgb(108, 61, 171)"}}
              onClick={() =>
                dispatch(
                  setCMSFilterMode(ContentHubFilterType.SelectedQuestions)
                )
              }
            >
              Preview
            </Box>
            <Divider sx={{ margin: "0 0px" }} orientation="vertical" flexItem />
            <Box sx={{ marginRight: "80px" }}>
              <ColorButton onClick={(e) => onUpdate()} disabled={!saveMode}>
                Share
              </ColorButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{ cursor: "pointer", marginRight: "80px" }}
            onClick={() =>
              dispatch(setCMSFilterMode(ContentHubFilterType.None))
            }
          >
            Exit Preview
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {onLoading ? (
        <BasicPageBlocker show={onLoading} />
      ) : (
        <>
          <ContentHubPopupBase
            show={!onLoading}
            onClose={onClose}
            header={renderHeader()}
            noSideBar={noSideBar}
          />
        </>
      )}
    </>
  );
};

export default CHFormUpdatePopup;
