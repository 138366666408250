import { useMsal } from "@azure/msal-react";
import _, { get, pick } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { ErrorMessagePopup } from "../../Massages/ErrorMessagePopup";
import { backofficeApi } from "../../api/backofficeApi";
import {
  NewProjectInfo,
  ProjectUpdateRequest,
  projectsApi,
} from "../../api/projectsApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAccessTokenSilence } from "../../auth/auth";
import {
  Section,
  Single,
  UpdateComp,
  initCmsValues,
  setAlreadyApproved,
  setBlockCopy,
  setInEditMode,
  setPages,
  updateCmsValues,
} from "../../cms/cmsSlice";
import { SingleComp } from "../../cms/components/SingleComp";
import SettingsPage from "../../settings/SettingsPage";
import { convertNotificationStringToDays } from "../../settings/SettingsUtils";
import {
  BUSINESS_CATEGORY_CMS_ID,
  BUSINESS_SUB_CATEGORY_CMS_ID,
  CMSWorkingMode,
  PROJECT_NAME_CMS_ID,
} from "../../utils/Constants";
import TreeUtils from "../../utils/TreeUtils";
import ProjectUtils from "../ProjectUtils";
import { Project } from "../projectsSlice";
import { Box } from "@mui/material";

export const ProjectDetalisContext = React.createContext({ errors: [""] });

const ProjectSettings: React.FC = () => {
  const [projectDetails, setProjectDetails] = useState<Section>();
  const [project, setProject] = useState<Project>();
  const [updateMode, setUpdateMode] = React.useState<boolean>(false);
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const [showMissingRequired, setShowMissingRequired] =
    React.useState<boolean>(false);
  const [formErros, setFormErrors] = React.useState<string[]>([]);
  const [dirtyFlag, setDirtyFlag] = React.useState<boolean>(false);

  const cmsState = useAppSelector((state) => state.cmsData);
  const [showSaveMessage, setShowSaveMessage] = React.useState<boolean>(false);

  const msalInstance = useMsal();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useAsyncEffect(async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnLoading(true);

      const projectToken = await backofficeApi.getParamFromServer(
        "project",
        token.accessToken
      );
      const projectObj = JSON.parse(projectToken.value);

      if (params.projectId) {
        const projectInfoResponse = await projectsApi.getUserProjectById(
          params.projectId,
          token.accessToken
        );
        setProject(projectInfoResponse);
        setUpdateMode(true);
        if (projectInfoResponse.content) {
          const projectDic: { [id: string]: string } = JSON.parse(
            projectInfoResponse.content
          );
          dispatch(initCmsValues(projectDic));
          // TreeUtils.fillCMSWithContentFormDic(projectObj, projectDic);
        }
      } else {
        const userDetails = await backofficeApi.getUserSettings(
          token.accessToken
        );
        const userDic: { [id: string]: string } = JSON.parse(
          userDetails.additionalData
        );
        const notification = get(userDic, "notificationSettings");
        if (notification) {
          TreeUtils.fillCMSWithContentFormDic(projectObj, {
            "1002": notification,
          });
        }
      }
      if (searchParams.get("param")) {
        setTimeout(() => {
          const id = `compKey-${searchParams.get("param")}`;
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            element.focus();
          }
        }, 700);
      }
      setOnLoading(false);
      setProjectDetails(projectObj);
      dispatch(setBlockCopy(true));
      dispatch(setInEditMode(false));
      dispatch(setAlreadyApproved({}));
      dispatch(
        setPages([{ sections: [projectObj], icon: "", description: "" }])
      );

      window.scrollTo(0, 0);
    }
  }, []);

  const validateForm = () => {
    if (projectDetails) {
      const requiredList: { [id: string]: string } = {};
      TreeUtils.extractFromSection(projectDetails.singles, requiredList, true);
      let missingKeys: string[] = [];
      _.mapKeys(requiredList, function (value1, key) {
        const value = _.get(cmsState.cmsItems, key, "");
        if (typeof value === "object") {
          const val = _.get(value, "value", "").toLowerCase();
          if (
            (val !== "" && val !== "other") ||
            _.get(value, "other", "") !== ""
          ) {
            //
          } else {
            missingKeys.push(key);
          }
        } else {
          if (value === "") {
            missingKeys.push(key);
          }
        }
      });
      if (missingKeys.indexOf(BUSINESS_SUB_CATEGORY_CMS_ID) >= 0) {
        const catValue = get(requiredList, BUSINESS_CATEGORY_CMS_ID, "");
        if (_.get(catValue, "other", "") !== "") {
          missingKeys = missingKeys.filter(
            (r) => r !== BUSINESS_SUB_CATEGORY_CMS_ID
          );
        }
      }
      setFormErrors(missingKeys);
      if (missingKeys.length === 0) {
        saveProjectData();
      } else {
        setTimeout(() => {
          const id = `compKey-${missingKeys[0]}`;
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            element.focus();
          }
        }, 700);
        setShowMissingRequired(true);
      }
    }
  };

  const setAdditionalData = (projectDic: { [id: string]: any }) => {
    return {
      ...pick(projectDic, ["8000", "8001", "44"]),
      reminders: {
        intervals: [
          convertNotificationStringToDays(get(projectDic, "1002", "Week")),
        ],
      },
    };
  };

  const saveProjectData = async () => {
    if (projectDetails) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        setOnLoading(true);
        const projectDic = cmsState.cmsItems; // TreeUtils.getDicFromProjectCms(projectDetails);
        const projectName = _.get(projectDic, PROJECT_NAME_CMS_ID, "");
        let cmsId: string | undefined = undefined;
        if (project) {
          cmsId = project.cmsDataId;
          const data: ProjectUpdateRequest = {
            id: project.id,
            name: projectName,
            content: JSON.stringify(projectDic),
            additionalData: JSON.stringify(setAdditionalData(projectDic)),
            status: project.status,
          };
          await projectsApi.editUserProject(data, token.accessToken);
        } else {
          const data: NewProjectInfo = {
            name: projectName,
            content: JSON.stringify(projectDic),
            additionalData: JSON.stringify(setAdditionalData(projectDic)),
            status: "In Progress",
          };
          const newProjectDetailes = await projectsApi.addNewProject(
            data,
            token.accessToken
          );
          setProject(newProjectDetailes);
          cmsId = newProjectDetailes.cmsDataId;
        }
        if (cmsId) {
          await ProjectUtils.fillCMSFromProject(
            projectDic,
            cmsId,
            token.accessToken
          );
        }
        setOnLoading(false);
        setDirtyFlag(false);
        setShowSaveMessage(true);
      }
    }
  };

  const onUpdateComp = (item: UpdateComp) => {
    setDirtyFlag(true);
    dispatch(
      updateCmsValues({ key: item.comp.mapKey, value: item.comp.value })
    );

    if (formErros.indexOf(item.comp.mapKey) >= 0) {
      setFormErrors(formErros.filter((r) => r !== item.comp.mapKey));
      setShowMissingRequired(false);
    }
  };

  const renderSingle = (
    single: Single,
    singleIndex: number,
    subSectionIndex?: number
  ) => {
    return (
      <SingleComp
        key={`single-${singleIndex}`}
        single={single}
        singleIdx={singleIndex}
        subSectionIndex={subSectionIndex}
        sectionIndex={0}
        showIcon={true}
        onUpdateComp={onUpdateComp}
        noComment={true}
        mode={CMSWorkingMode.Edit}
      />
    );
  };

  const onCloseSuccessMessage = (cont?: boolean) => {
    setShowSaveMessage(false);
    if (cont) {
      project
        ? navigate(
            `/projects/${project.id}/details?update=true${
              updateMode ? "" : "&showWelcome=true"
            }`
          )
        : navigate("/projects");
    }
  };

  return (
    <ProjectDetalisContext.Provider value={{ errors: formErros }}>
      <SettingsPage
        title={"My Projects"}
        dirtyFlag={dirtyFlag}
        enableSave={true}
        onSaveSettingsData={validateForm}
        onLoading={onLoading}
        showSaveMessage={showSaveMessage}
        onCloseSuccessMessage={onCloseSuccessMessage}
        successTitle={
          updateMode
            ? t("projects.edit.success_update.title")
            : t("projects.edit.success_save.title")
        }
        successMessage={
          updateMode
            ? t("projects.edit.success_update.message")
            : t("projects.edit.success_save.message")
        }
        onClose={() => navigate(-1)}
      >
        {showMissingRequired && (
          <ErrorMessagePopup
            message="Please fill all required (*) fields"
            onClose={() => setShowMissingRequired(false)}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            widht: "100%",
            alignItems: "center",
          }}
        >
          {projectDetails &&
            projectDetails.singles.map((single, i) => renderSingle(single, i))}
        </Box>
      </SettingsPage>
    </ProjectDetalisContext.Provider>
  );
};

export default ProjectSettings;
