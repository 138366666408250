import { Box, Modal, Typography } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ReactComponent as CLoseDialogSvg } from "../assets/closeDialog.svg";

export interface BasicMessagePopupProps {
  children?: React.ReactNode;
  onClose?(): void;
  title?: string;
  icon?: React.ReactNode;
  open: boolean;
  extraWidth?: string;
  disableAutoClose?: boolean;
  size?: string;
  bottomPadding?: string;
  zIndex?: number;
}

export const BasicMessagePopup: FC<BasicMessagePopupProps> = ({
  children,
  onClose,
  title,
  icon,
  open,
  extraWidth,
  disableAutoClose,
  size,
  bottomPadding,
  zIndex
}) => {
  const getWindowSize = () => {
    if (extraWidth) {
      return extraWidth;
    } else if (size) {
      switch (size) {
        case "compact":
          return "500px";
        default:
          return "600px";
      }
    } else return "600px";
  };

  const getTopHeaderBG = () => {
    return title
      ? {
          backgroundImage:
            "linear-gradient(148deg, #fb8332 -39%, #f8c332 102%)",
        }
      : { background: "#fff" };
  };

  return (
    <Modal
      open={open}
      onClose={() => (disableAutoClose ? {} : onClose ? onClose() : {})}
      closeAfterTransition
      sx={{ zIndex: zIndex ? zIndex : 4000 }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "none",
          outline: "none",
          width: "90%",
          borderRadius: "30px",
          background: "#fff",
          maxWidth: getWindowSize(),
        }}
      >
        <Box
          sx={{
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "30px",
            display: "flex",
            alignItems: "center",
            height: "66px",
            ...getTopHeaderBG(),
          }}
        >
          <Box sx={{ width: "100%", display: "flex" }}>
            {title && (
              <Typography
                sx={{
                  padding: "0px 66px",
                  fontFamily: "Nunito",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {title}
              </Typography>
            )}
            <Box sx={{ display: "flex", flexGrow: 1 }} />
            {onClose && (
              <Box
                sx={{
                  marginRight: "25px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                onClick={() => onClose()}
              >
                <CLoseDialogSvg width='20px'/>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            // minHeight: "340px",
            width: "100%",
            bgcolor: "#fff",
            // paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "30px",
            paddingBottom: bottomPadding? bottomPadding :"46px",
          }}
        >
          {icon && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>{icon}</Box>
          )}
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
