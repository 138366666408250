import { Box, ClickAwayListener, Fade, Paper, Popper, PopperPlacementType } from "@mui/material";
import React, { FC } from "react";
import './PopUpBase.scss'

interface PopUpBaseProps {
  onClose(): void;
  anchorEl?: any;
  direction: PopperPlacementType;
  children?: React.ReactNode;
  svg?: React.ReactNode;
  actionString: string;
  disableAction? : boolean
  cancelString?: string
  onAction?(): void;
  hideActions?: boolean
  autoClose?:boolean
  extraWidth?:string
}

export const PopUpBase: FC<PopUpBaseProps> = ({
  onClose,
  onAction,
  anchorEl,
  direction,
  children,
  actionString,
  svg,
  disableAction,
  cancelString,
  hideActions,
  autoClose,
  extraWidth
}) => {

  const onBeforeClose = () =>{
    if (autoClose === undefined || autoClose) {
      onClose()
    }    
  }

  return anchorEl ? (
    <ClickAwayListener onClickAway={() => onBeforeClose()}>
    <Popper
      open={anchorEl !== undefined}
      className={'basePopper'}
      style={{zIndex: 5000}}
      anchorEl={anchorEl}
      placement={direction}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            className={'basePopperPage'}
            sx={{
              width: extraWidth??"260px",
              background: "#292B28",
              color: "#fff",
              padding: "24px 37px 18px 26px",
            }}
          >
             <Box
                className={'basePopperArrowComment'}
                sx={{
                  height: "16px",
                  width: "16px",
                  backgroundColor: "#292B28",
                  position: "absolute",                 
                  borderTop: "1px solid rgba(0, 0, 0, 0.3)",
                  transform: "translate(50%, -50%) rotate(45deg) !important",
                }}
              />
            <Box>{svg}</Box>
            <Box
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: "normal",
                marginTop: svg ? "20px": "0px",
              }}
            >
              {children}             
              {!hideActions && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "27px",
                }}
              >
                <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
                  {cancelString ? cancelString :'Cancel'}
                </Box>
                {!disableAction && (
                <Box
                  sx={{ fontWeight: 800, cursor: "pointer" }}
                  onClick={onAction}
                >
                    {actionString}
                </Box>
                )}
              </Box>
              )}
            </Box>
          </Paper>
          
        </Fade>
      )}
    </Popper>
    </ClickAwayListener>
  ) : null;
};
