import React from "react";
import * as signalR from "@microsoft/signalr";
import { useMsal } from "@azure/msal-react";
import { getAccessTokenSilence } from "../auth/auth";
import useAsyncEffect from "use-async-effect";
import { useAppDispatch } from "../app/hooks";
import {
  setNeedToRefreshCMSComments,
  setNeedToRefreshFormComments,
} from "../cms/cmsSlice";

const singlrDomain =
  window.__lynxi_env__ === "dev"
    ? "affilisens-dev-signalr.azurewebsites.net"
    : window.__lynxi_env__ === "prod"
    ? "lynxi-notifications-prod-us.azurewebsites.net"
    : "lynxi-staging-notifications.azurewebsites.net";

export interface SinglrInfo {
  ProjectId: string;
  UserId: string;
  QuestionnaireId?: string;
  TemplateId?: string;
  CmsId?: string;
  Action?: number;
}

const SignalR = () => {
  const [token, setToken] = React.useState<string | null>();
  const instance = useMsal();
  const dispatch = useAppDispatch();

  const updateToken = async () => {
    const token = await getAccessTokenSilence(instance);
    if (token) {
      return token.accessToken;
    }
    return "";
  };

  useAsyncEffect(async () => {
    const token = await getAccessTokenSilence(instance);
    if (token) {
      setToken(token.accessToken);
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`https://${singlrDomain}/notifications`, {
          withCredentials: true,
          accessTokenFactory: async () => await updateToken(),
        })
        .configureLogging(signalR.LogLevel.Error)
        .withAutomaticReconnect()
        .build();

      connection.start();
      connection.on("ProjectNotifications", (data) => {
        const projectInfo: SinglrInfo = JSON.parse(data);
        const projectId = projectInfo.ProjectId;
        if (
          projectInfo.Action &&
          projectInfo.Action === 1 &&
          projectInfo.QuestionnaireId
        ) {
          // brief comment
          const res = document.location.href.indexOf(
            `/projects/${projectId}/questionnaire/client?q=${projectInfo.QuestionnaireId}`
          );
          if (res >= 0) {
            dispatch(setNeedToRefreshFormComments(true));
          }
        }
        if (
          projectInfo.Action &&
          projectInfo.Action === 2 &&
          projectInfo.CmsId
        ) {
          // cms comment
          const res = document.location.href.indexOf(
            `/projects/${projectId}/cms/${projectInfo.CmsId}/content`
          );
          if (res >= 0) {
            dispatch(setNeedToRefreshCMSComments(true));
          }
        }
        // console.log(projectInfo);
      });
    }
  }, []);

  return <></>;
};

export { SignalR };
