import { useMsal } from "@azure/msal-react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Grid } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AccountInvite, backofficeApi } from "../../../api/backofficeApi";
import { getAccessToken } from "../../../auth/auth";
import { BasicPageBlocker } from "../../../basic/BasicPageBlocker";
import { SuccessMessagePopup } from "../../../Massages/SuccessMessagePopup";
import { AccountContext } from "../../../settings/SettingsDetails";
import { getPrettyDate } from "../../../utils/DateUtils";
import { MemberAddPopup } from "./MemberAddPopup";
import { MemberLine } from "./MemberLine";
import { useAccountSettingsData } from "../../../hooks/useAccountSettingsData";

export const MembersComp: React.FC = () => {
  const [showAddPopup, setShowAddPopup] = React.useState<boolean>(false);
  const [showInviteSuccessMessage, setShowInviteSuccessMessage] =
    React.useState<boolean>(false);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const instance = useMsal();
  const {getAccountDateFormat} = useAccountSettingsData()


  const context = React.useContext(AccountContext);
  const { t } = useTranslation();

  const sendInviteReminder = async (
    accountId: string,
    invite: AccountInvite
  ) => {
    setInProgress(true);
    const token = await getAccessToken(instance);
    if (token) {
      await backofficeApi.sendTeamMemberReminder(
        accountId,
        invite.email,
        token
      );
      setShowInviteSuccessMessage(true);
    }
    setInProgress(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container rowGap={"15px"}>
          {context &&
            context.accountInfo &&
            context.accountInfo.members.map((member, idx) => (
              <MemberLine key={`members-${idx}`} memberInfo={member} />
            ))}
        </Grid>
        {context &&
          context.accountInfo &&
          context.accountInfo.invitations.length > 0 && (
            <Box sx={{ marginTop: "20px" }}>
              <Box sx={{ fontWeight: 500 }}>Pending members:</Box>
              <Grid container>
                {context.accountInfo.invitations.map((invite, idx) => (
                  <React.Fragment key={`invite-${idx}`}>
                    <Grid item xs={3} sx={{ fontSize: "14px" }}>
                      {invite.email.toLowerCase()}
                    </Grid>
                    <Grid item xs={2} sx={{ fontSize: "12px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        {getPrettyDate(invite.created, false, getAccountDateFormat())}
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Box
                        sx={{
                          cursor: "pointer",
                          color: "#6c3dab",
                          fontSize: "14px",
                        }}
                        onClick={() =>
                          sendInviteReminder(context.accountInfo.id, invite)
                        }
                      >
                        Send reminder
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          )}

        <Box
          sx={{
            marginTop: "15px",
            display: "flex",
            padding: "0 20px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ fontSize: "14px", marginRight: "10px" }}>
            Add co-workers
          </Box>
          <Fab
            size="small"
            sx={{
              backgroundImage:
                "linear-gradient(143deg, #3023ae -60%, #c86dd7 102%)",
              color: "#fff",
              "&:hover": { color: "#000" },
            }}
            onClick={() => setShowAddPopup(true)}
          >
            <AddIcon fontSize="medium" />
          </Fab>
        </Box>
        {showAddPopup && (
          <MemberAddPopup
            onClose={() => setShowAddPopup(false)}
            accountId={
              context && context.accountInfo ? context.accountInfo.id : ""
            }
          />
        )}
      </Grid>
     
      {showInviteSuccessMessage && (
        <SuccessMessagePopup
          open={showInviteSuccessMessage}
          title={t("account.success_reminder.title")}
          message={t("account.success_reminder.message")}
          onClose={() => setShowInviteSuccessMessage(false)}
          showAction={false}
        />
      )}

      <BasicPageBlocker show={inProgress} />
    </>
  );
};
