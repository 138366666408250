import { useMsal } from "@azure/msal-react";
import { Box, Popper, TextField, styled } from "@mui/material";
import React, { FC } from "react";
import { cmsApi } from "../../../api/cmsApi";
import { notificationApi } from "../../../api/notificationApi";
import { ReactComponent as InvitationIconSVG } from "../../../assets/project/invitation.svg";
import { ReactComponent as CheckSvg } from "../../../assets/checkbox.svg";

import { getAccessTokenSilence } from "../../../auth/auth";
import { BasicPageBlocker } from "../../../basic/BasicPageBlocker";
import { CMSFieldCopyBtn } from "../../../basic/CMSFieldCopyBtn";
import { InputEmailField } from "../../../basic/InputEmailField";
import { BlackMessageDialog } from "../../../Massages/BlackMessageDialog";
import useAsyncEffect from "use-async-effect";
import { projectsApi } from "../../../api/projectsApi";
import { ColorButton, CustomCheckBox } from "../../ProjectUIHelper";
import InfoIcon from "@mui/icons-material/Info";
import { HelpToolTipOnHover } from "../../../basic/HelpToolTipOnHover";
import { useAccountSettingsData } from "../../../hooks/useAccountSettingsData";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import _ from "lodash";

const PickersPopperRoot = styled(Popper, {
  name: "MuiPickersPopper",
  slot: "Root",
  overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
  zIndex: 4000,
}));

interface ShareLinkPopupProps {
  onClose(linkWasGenerated: boolean, userEmail?: string): void;
  initialEmail: string;
  id: string;
  isContentHub?: boolean;
  isFileShare?: boolean;
  projectId: string;
  autogenerate?: boolean;
  allowAnonymous?: boolean;
  defaultValidForInDays?: number;
  defaultLinkForAll?: boolean;
  allowTimeLimit?: boolean;
  allowDueDate?: boolean;
  getShareIdFunction?(): Promise<string>;
}

export const ShareLinkPopup: FC<ShareLinkPopupProps> = ({
  onClose,
  initialEmail,
  id,
  isContentHub,
  isFileShare,
  projectId,
  autogenerate,
  allowAnonymous,
  defaultValidForInDays,
  defaultLinkForAll,
  allowTimeLimit,
  allowDueDate,
  getShareIdFunction,
}) => {
  const [userEmail, setUserEmail] = React.useState<string>(initialEmail);
  const [shareLink, setShareLink] = React.useState<string>("");
  const [linkForAll, setLinkForAll] = React.useState<boolean>(
    defaultLinkForAll ?? false
  );
  const [validForInDays, setValidForInDays] = React.useState<number>(
    defaultValidForInDays ?? 0
  );

  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);
  const [enableDueDate, setEnableDueDate] = React.useState<boolean>(false);
  const [linkWasGenerated, setLinkWasGenerated] =
    React.useState<boolean>(false);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const { getAccountDateFormat } = useAccountSettingsData();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const dateFormat = React.useMemo(
    () => getAccountDateFormat(),
    [getAccountDateFormat]
  );

  const msalInstance = useMsal();

  const setEmail = (value: string) => {
    setUserEmail(value);
  };

  useAsyncEffect(async () => {
    if (autogenerate) {
      await getCopyLink();
    }
  }, [autogenerate]);

  const getShareLink = async (token: string) => {
    let idTouse = id;
    if (_.isEmpty(id)) {
      if (getShareIdFunction) {
        idTouse = await getShareIdFunction();
      }
    }
    if (isContentHub) {
      return await cmsApi.getShareLink(
        idTouse,
        userEmail,
        linkForAll,
        token,
        validForInDays !== 0,
        dueDate ? `${dueDate.format("YYYY-MM-DD")}T00:00:00.000Z` : undefined
      );
    } else if (isFileShare) {
      return await projectsApi.getFileShareLink(idTouse, userEmail, token);
    }
    return await notificationApi.getShareLink(
      idTouse,
      userEmail,
      linkForAll,
      allowAnonymous ? validForInDays : 0,
      token
    );
  };

  const getCopyLink = async () => {
    setShareLink("");
    setErrorMessage(undefined);
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setLinkWasGenerated(true);
      setOnProcessing(true);
      try {
        const shareLink = await getShareLink(token.accessToken);
        if (shareLink) {
          if (shareLink.link) {
            setShareLink(shareLink.link);
          }
          notificationApi.addUserActivite(
            `Copy Link - ${isContentHub ? "CMS" : "Breif"}`,
            projectId,
            token.accessToken
          );
        } else {
          setErrorMessage("Error generating link. Please try again.");
        }
      } catch (e) {
        setErrorMessage("Error generating link. Please try again.");
      }
      setOnProcessing(false);
    }
  };

  return (
    <BlackMessageDialog
      onClose={() => onClose(linkWasGenerated, userEmail)}
      open={true}
      icon={<InvitationIconSVG />}
      hideDialogClose={true}
      zIndex={3050}
    >
      <Box
        sx={{ dispaly: "flex", flexDirection: "column", position: "relative" }}
      >
        <BasicPageBlocker show={onProcessing} />
        {!autogenerate && (
          <>
            <Box
              sx={{
                fontSize: "14px",
                color: "#fff",
                marginBottom: "10px",
                fontWeight: 600,
              }}
            >
              In order to grant access to the client, please enter the client
              email.
            </Box>

            <Box sx={{ width: "100%" }}>
              <InputEmailField
                handleChange={(val) => setEmail(val)}
                defaultValue={userEmail}
                placeholder={"Email"}
                isFullWidth={true}
                required={false}
              />
            </Box>
            {allowAnonymous && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "10px",
                    alignItems: "center",
                  }}
                >
                  <CustomCheckBox
                    checkedIcon={
                      <CheckSvg style={{ transform: "scale(0.7)" }} />
                    }
                    checked={linkForAll}
                    onChange={() => setLinkForAll(!linkForAll)}
                  />
                  <Box sx={{ fontSize: "16px", color: "#fff" }}>
                    Anyone with a link can access
                  </Box>
                </Box>
                {allowTimeLimit && (
                  <Box sx={{ color: "#fff" }}>
                    <Box
                      sx={{
                        display: "flex",
                        marginBottom: "5px",
                        alignItems: "center",
                      }}
                    >
                      <CustomCheckBox
                        checkedIcon={
                          <CheckSvg style={{ transform: "scale(0.7)" }} />
                        }
                        checked={validForInDays !== 0}
                        onChange={() =>
                          setValidForInDays(validForInDays === 0 ? 100 : 0)
                        }
                      />
                      <Box
                        sx={{
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Send a daily reminder
                        <HelpToolTipOnHover
                          position="top-start"
                          size={250}
                          message={
                            "Lynxi will send a daily email reminder with the number of unanswered questions until the form is submitted."
                          }
                          actionNode={<InfoIcon fontSize="small" />}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {allowDueDate && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CustomCheckBox
                      checkedIcon={
                        <CheckSvg style={{ transform: "scale(0.7)" }} />
                      }
                      checked={enableDueDate}
                      onChange={() => setEnableDueDate(!enableDueDate)}
                    />
                    <DatePicker
                      slots={{
                        popper: PickersPopperRoot,
                      }}
                      label="Due date"
                      className="datePicker"
                      sx={{ background: "#fff" }}
                      value={dueDate}
                      format={dateFormat}
                      disabled={!enableDueDate}
                      onChange={(newValue: any) => setDueDate(newValue)}
                    />
                  </Box>
                )}
              </>
            )}
            <Box sx={{ display: "flex", flexGrow: 1 }} />

            <Box
              sx={{
                color: "#fff",
                cursor: "pointer",
                margin: "20px 0",
                textDecoration: "underline",
              }}
            >
              <ColorButton onClick={() => (userEmail ? getCopyLink() : null)}>
                Generate link
              </ColorButton>
            </Box>
          </>
        )}
        {shareLink !== "" && (
          <>
            <Box sx={{ display: "flex" }}>
              <TextField
                sx={{
                  width: "100%",
                  background: "#f9f9f9",
                  "& input.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                    WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                  },
                }}
                label={"Link to form"}
                variant="outlined"
                disabled={true}
                value={shareLink}
              />
              <CMSFieldCopyBtn
                value={shareLink}
                invert={true}
                allowCopy={true}
              />
            </Box>
            {autogenerate && (
              <Box
                sx={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "#fff",
                  marginBottom: "10px",
                  fontWeight: 400,
                }}
              >
                * Please note the following link is valid only with your current
                client ({userEmail}).
              </Box>
            )}
          </>
        )}
        {errorMessage && (
          <Box sx={{ color: "red", fontSize: "14px", marginTop: "10px" }}>
            {errorMessage}
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{ color: "#fff", cursor: "pointer" }}
            onClick={() => onClose(linkWasGenerated)}
          >
            Close
          </Box>
        </Box>
      </Box>
    </BlackMessageDialog>
  );
};
