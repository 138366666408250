import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ReactComponent as EmptyTasksSVG } from "../../assets/tasks/emptyTasks.svg";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { BasicEmptyStage } from "../../basic/BasicEmptyStage/BasicEmptyStage";

export interface EmptyTaskListProps {
  onCreateNewTask: () => void;
  onImportTaskList: () => void;
  isClient?: boolean;
}

export const EmptyTaskList: FC<EmptyTaskListProps> = ({
  onCreateNewTask,
  onImportTaskList,
  isClient,
}) => {
  return (
    <BasicEmptyStage icon={<EmptyTasksSVG />}>
      {isClient ? (
        <>
          <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
            Create a project checklist and assign tasks to ensure efficient
            completion.
          </Box>
          <Box sx={{ display: "flex", marginTop: "20px" }}></Box>
          <Box>
            <ColorButton
              onClick={() => onCreateNewTask()}
              sx={{ marginTop: "30px" }}
            >
              Add a new task
            </ColorButton>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
            Create a checklist for the project, assign tasks to your colleagues
            or clients, and request their approvals.
            <br />
            In addition to using a checklist for your current project, you can
            save it for future projects.
          </Box>
          <Box sx={{ display: "flex", marginTop: "20px" }}></Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "30px",
              marginBottom: "20px",
              gap: "20px",
            }}
          >
            <>
              <Box sx={{}}>
                <ColorButton onClick={() => onImportTaskList()}>
                  Select a checklist
                </ColorButton>
              </Box>
              <Box>or</Box>
            </>
            {/* )} */}
            <Box sx={{}}>
              <ColorButton onClick={() => onCreateNewTask()}>
                Add a new task
              </ColorButton>
            </Box>
          </Box>
        </>
      )}
    </BasicEmptyStage>
  );
};
