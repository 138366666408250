import * as React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BriefData, Project } from "../../projectsSlice";
import { get } from "lodash";
import { useMsal } from "@azure/msal-react";
import { ReactComponent as WorkingOnBriefSvg } from "../../../assets/project/drawerWorkingOnBrief.svg";
import { notificationApi } from "../../../api/notificationApi";
import { ReminderHandle } from "./ReminderHandle";
import { ColorButton } from "../../ProjectUIHelper";
import { getAccessTokenSilence } from "../../../auth/auth";

export interface BriefFillStageProps {
  project: Project;
  showCommnets: boolean;
  brief: BriefData;
  onClose(reload?: boolean): void;
}

export const BriefFillStage: FC<BriefFillStageProps> = ({
  project,
  showCommnets,
  brief,
  onClose,
}) => {
  const [shareResult, setShareResult] = React.useState<boolean | undefined>();
  const [onLaoding, setOnLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const msalInstance = useMsal();

  const sendEmailReminder = async () => {
    setOnLoading(true);
    const token = await getAccessTokenSilence(msalInstance)
    if (token) {
        setShareResult(await notificationApi.sendEmailReminder(brief.id, token.accessToken));
    }
    setOnLoading(false);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          borderTopLeftRadius: "30px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={onLaoding}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {shareResult === undefined ? (
        <>
          {showCommnets ? (
            <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
              {get(JSON.parse(project.additionalData), "1", "Your client")} has
              submitted a comment.
              <br />
              <br />
              <Typography
                onClick={() =>
                  navigate(
                    `/projects/${project.id}/questionnaire/client?q=${brief.id}&comment=true`
                  )
                }
                color="secondary"
                sx={{
                  cursor: "pointer",
                  fontWeight: 500,
                  display: "inline",
                }}
              >
                {" "}
                View{" "}
                {get(
                  JSON.parse(project.additionalData),
                  "1",
                  "Your client"
                )}{" "}
                comment{" "}
              </Typography>
              and help him finish the Brief.
            </Box>
          ) : (
            <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
              {"Review your client's progress in"} <br/>the{" "}
              <Typography
                color="secondary"
                onClick={() =>
                  navigate(
                    `/projects/${project.id}/questionnaire/client?q=${brief.id}`
                  )
                }
                sx={{
                  cursor: "pointer",
                  display: "inline",
                  fontWeight: 500,
                }}
              >
                form
              </Typography>{" "}
              , or send a{" "}
              <Typography
                color="secondary"
                onClick={() => sendEmailReminder()}
                sx={{
                  cursor: "pointer",
                  display: "inline",
                  fontWeight: 500,
                }}
              >
                friendly reminder
              </Typography>{" "}
              to keep going.               
            </Box>
          )}

          <Divider sx={{ marginTop: "40px" }} />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              margin: "15px 0 17px",
              color: "#6c3dab",
              cursor: "pointer",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box onClick={() => sendEmailReminder()}>Send reminder</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <ColorButton sx={{padding: '4px 15px' }}
                onClick={() =>
                  navigate(
                    `/projects/${project.id}/questionnaire/client?q=${brief.id}`
                  )
                }
              >
                Review form
              </ColorButton>
            </Box>
          </Box>
        </>
      ) : (
        <ReminderHandle
          shareResult={shareResult}
          successMessage={'Review the form progress and leave comments to help your client along.'}
          onCloseShare={(success) => {
            setShareResult(undefined)
            if (success) {
              onClose()
            }
          }}
        />
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        <WorkingOnBriefSvg />
      </Box>
    </>
  );
};
