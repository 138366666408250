import React, { Suspense } from "react";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CircularProgress, CssBaseline, ThemeProvider, Box } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter";
import { LoginPage } from "./loginPage/LoginPage";
import { createMyTheme } from "./theme";
import s from "./App.scss";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const LazyAnonymousFormViewerSwitcher = React.lazy(
  () => import("../src/Briefs/Viewer/AnonymousFormView")
);
const LazyAnonymousCSVViewer = React.lazy(
  () => import("../src/cms/CMSAnonymousViewer")
);

function App() {  
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Suspense fallback= {<Box sx={{display: 'flex', width: '100%', height:'100vh', alignItems:'center', justifyContent:'center'}}><CircularProgress color="inherit" /></Box>}>
    <ThemeProvider theme={createMyTheme("light")}>
        {/* <AnalyticsProvider instance={analyticsInstance}> */}
      <div className={s.App}>
        <CssBaseline />
        <AuthenticatedTemplate>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <BrowserRouter>
              <Routes>
                <Route path="/anonymous/brief/collaborator" element={<LazyAnonymousFormViewerSwitcher />} />
                <Route path="/anonymous/cms/collaborator/:cmsId" element={<LazyAnonymousCSVViewer />} />
                <Route path="*" element={<LoginPage />} />
              </Routes>
            </BrowserRouter>
        </UnauthenticatedTemplate>
      </div>
      {/* </AnalyticsProvider> */}
    </ThemeProvider>
    </Suspense>
    </LocalizationProvider>
  );
}

export default App;
