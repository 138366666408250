import * as React from "react";
import {
  Paper,
  Box,
  Popper,
  Fade,
  ClickAwayListener,
  PopperPlacementType,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import useAsyncEffect from "use-async-effect";

export interface HelpPopplerOnHoverProps {
  position: PopperPlacementType;
  actionNode?: React.ReactNode;
  children?: React.ReactNode;
  disabled: boolean;
  icon: React.ReactNode;
  message: string | React.ReactNode;
  useClick?: boolean;
  autoClose?: boolean;
  extraStyle?: any;
  cancelText?: string;
  onCancel?(): void;
  overrideWidth?: string;
  title?: string;
  closeOnAction?: boolean
  startOpened?: boolean
  showCloseBtn?: boolean
  forceClose?: boolean
}

export const HelpPopplerOnHover: React.FC<HelpPopplerOnHoverProps> = ({
  actionNode,
  children,
  disabled,
  icon,
  message,
  useClick,
  autoClose,
  position,
  extraStyle,
  cancelText,
  onCancel,
  overrideWidth,
  title,
  startOpened,
  closeOnAction,
  showCloseBtn,
  forceClose
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const actionRef = React.useRef<HTMLDivElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    if (onCancel) {
      onCancel();
    }
  };

  const onBeforeClose = () => {
    if (autoClose) {
      setAnchorEl(null);
    }
  };

  useAsyncEffect ( async () => {
    if (forceClose) {
      setAnchorEl(null)
    }
  }, [forceClose])

  React.useEffect ( () => {
    if (startOpened && actionRef) {
      setAnchorEl(actionRef.current)
    }
  }, [startOpened, actionRef])

  return (
    <ClickAwayListener onClickAway={() => onBeforeClose()}>
      <>
        <div
          style={extraStyle ? { ...extraStyle } : {}}
          aria-haspopup="true"
          onMouseEnter={useClick ? undefined : handlePopoverOpen}
          onMouseOut={useClick ? undefined : handlePopoverClose}
          onClick={useClick ? handlePopoverOpen : undefined}
          ref={actionRef}
        >
          {actionNode}
        </div>

        <Popper
          id="mouse-over-popover"
          open={anchorEl !== null && disabled}
          className={"helperPopper"}
          anchorEl={anchorEl}
          placement={position}
          style={{ zIndex: 10000 }}
          // disablePortal
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                className={"helperPopperPage"}
                sx={{
                  width: overrideWidth ?? "300px",
                  marginTop: "15px",
                  background: "#292B28",
                  color: "#fff",
                  padding: "24px 26px 18px 26px",
                  position: "relative",
                }}
              >
                <Box
                  className={"helperArrowComment"}
                  sx={{
                    height: "16px",
                    width: "16px",
                    backgroundColor: "#292B28",
                    position: "absolute",
                    borderTop: "1px solid rgba(0, 0, 0, 0.3)",
                    transform: "translate(50%, -50%) rotate(45deg) !important",
                  }}
                />
                {showCloseBtn && (
                  <Box
                    sx={{
                      cursor: "pointer",
                      position: " absolute",
                      top: "10px",
                      right: "10px",
                    }}
                    onClick={() => onBeforeClose()}
                  >
                    <CloseIcon />
                  </Box>
                )}
                <Box sx={{ display: "flex" }}>
                  <Box>{icon}</Box>
                  <Box
                    sx={{
                      fontFamily: "Nunito",
                      marginLeft: "20px",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {title}
                  </Box>
                </Box>
                <Box
                  sx={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "normal",
                    marginTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Nunito",
                      fontWeight: 600,
                    }}
                  >
                    {message}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "27px",
                    }}
                    onClick={() => (closeOnAction ? handlePopoverClose() : {})}
                  >
                    {useClick && (
                      <Box
                        sx={{ cursor: "pointer", fontWeight: "normal" }}
                        onClick={() => handlePopoverClose()}
                      >
                        {cancelText ?? "Cancel"}
                      </Box>
                    )}

                    {children}
                  </Box>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      </>
    </ClickAwayListener>
  );
};
