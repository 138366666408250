import React from 'react';
import { BasicDraggableDialog } from '../../basic/BasicDraggableDialog';
import { Box, TextField } from '@mui/material';
import { ColorButton } from '../../projects/ProjectUIHelper';

interface SaveTaskListPopupProps {
  // Define props here
  onClose(): void;
  onSaveTaskList(taskListName: string): void;
}

const SaveTaskListPopup: React.FC<SaveTaskListPopupProps> = ({onClose, onSaveTaskList}) => {
  const [taskListName, setTaskListName] = React.useState<string>("");
  const [showError, setShowError] = React.useState<boolean>(false);


  return (
    <BasicDraggableDialog title={"Save a checklist"} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: "normal",
            marginBottom: "10px",
          }}
        >
          Save the current checklist so that it can be used in future projects.
        </Box>
        <TextField
          sx={{
            flexGrow: 1,
            width: "100%",
            background: "#f9f9f9",
            "& input.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
              WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
            },
          }}
          inputProps={{ className: "dropDownOther" }}
          placeholder={"Enter task name"}
          maxRows={1}
          value={taskListName}
          onChange={(e) => {
            setShowError(false);
            setTaskListName(e.target.value);
          }}
        />
         <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
            Cancel
          </Box>
          <ColorButton
            onClick={() => onSaveTaskList(taskListName)}
            disabled={taskListName === ""}
          >
            Create
          </ColorButton>
        </Box>
      </Box>
    </BasicDraggableDialog>
  );
};

export default SaveTaskListPopup;
