import { Backdrop, Box, CircularProgress, Tooltip } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SuccessMessagePopup } from "../Massages/SuccessMessagePopup";
import { DeleteConfirmationPopup } from "../PopUpMessage/DeleteProjectPopup";
import { LynxiAccountInfo } from "../api/backofficeApi";
import { ColorButton } from "../projects/ProjectUIHelper";
import { AccountContext } from "./SettingsDetails";

export interface SettingsPageProps {
  title: string;
  children: React.ReactNode;
  dirtyFlag: boolean;
  enableSave: boolean;
  onSaveSettingsData(): void
  accountInfo?: LynxiAccountInfo
  onLoading: boolean
  showSaveMessage: boolean
  onCloseSuccessMessage(cont?: boolean): void
  successTitle?: string
  successMessage?: string
  onClose(): void
  asPopup?: boolean
}

const SettingsPage: React.FC<SettingsPageProps> = ({
  onSaveSettingsData,
  title,
  children,
  dirtyFlag,
  enableSave,
  accountInfo,
  onLoading,
  showSaveMessage,
  onCloseSuccessMessage,
  successTitle,
  successMessage,
  onClose,
  asPopup
}) => {
  const [closeAnchorEl, setCloseAnchorEl] =
    React.useState<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          display: "flex",
          height: "78px",
        }}
      >
        <Box
          sx={{
            marginLeft: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {title}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{ marginRight: "52px", cursor: "pointer" }}
          onClick={(e) => {
            if (dirtyFlag) {
              setCloseAnchorEl(e.currentTarget);
            } else {
              onClose()
            }
          }}
        >
          Close
        </Box>
        <Box sx={{ marginRight: "32px" }}>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  textAlign: "center",
                  bgcolor: "#6c3dab",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                },
              },
            }}
            title={
              enableSave ? (
                ""
              ) : (
                <Box
                  style={{
                    fontSize: "16px",
                    width: "200px",
                  }}
                >
                  Please fill all required (*) fields to enable
                </Box>
              )
            }
            followCursor
            arrow={true}
          >
            <span>
              <ColorButton
                disabled={!enableSave}
                onClick={() => onSaveSettingsData()}
                sx={{ fontSize: "16px" }}
              >
                Save
              </ColorButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
      {!asPopup && (
      <Box
        sx={{
          flexGrow: 1,
          background: "#fff",
          padding: "0 36px",
          alignItems: "center",
          display: "flex",
          height: "60px",
        }}
      ></Box>
      )}
      <AccountContext.Provider
        value={accountInfo ? { accountInfo: accountInfo } : undefined}
      >
        <Box
          sx={{
            margin: "0px 5%",
            marginTop: "20px",
            alignSelf: "center",
            position: "relative",
            justifyContent: "center",
            height: `calc(100vh - ${asPopup? '160px':'270px'})`,
            overflowY: "auto",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={onLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box sx={{ marginBottom: "20px" }}>
          </Box>
          {children}
        </Box>
      </AccountContext.Provider>
      {showSaveMessage && (
        <SuccessMessagePopup
          open={showSaveMessage}
          title={successTitle? successTitle : t("settings.edit.success_save.title")}
          message={successMessage? successMessage : t("settings.edit.success_save.message")}
          onClose={() => {
            onCloseSuccessMessage(false);
          }}
          onContinue={() => {
            onCloseSuccessMessage(true)
          }}
          showAction={false}
          size={"compact"}
        />
      )}
      <DeleteConfirmationPopup
        title={`Your changes are not updated. Are you sure you want to exit?`}
        description={""}
        onDeleteSite={() => onClose()}
        action={"Yes"}
        onClose={() => setCloseAnchorEl(null)}
        anchorEl={closeAnchorEl}
        autoClose={false}
        placement={"left"}
      />
    </>
  );
};

export default SettingsPage;
