import {
  Box
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyProjectSVG } from "../../assets/project/emptyProject.svg"

import * as React from "react";
import "./Project.scss";


export const EmptyProject: FC = () => {

    const navigate = useNavigate()

    return (
      <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "40px",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/intro/new_project")}
            >
              <EmptyProjectSVG/>
            </Box>
            <Box sx={{ fontSize: "22px", fontWeight: 500, marginTop: "40px" }}>
              Start your first client project
            </Box>
            <Box sx={{ marginTop: "20px" }}>
                Create a project by entering the client details you know. <br /> Then send them a brief to start gathering their business info.
            </Box>
            <Box sx={{ marginTop: "20px" }}>You got this!</Box>
            <Box
              sx={{
                fontSize: "22px", 
                fontWeight: 500,
                color: "#7f49c3",
                marginTop: "20px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/intro/new_project")}
            >
              Create new project
            </Box>
          </Box>
    )

}