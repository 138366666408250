import { useMsal } from "@azure/msal-react";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { LynxiAccountInfo, backofficeApi, UserSettings } from "../api/backofficeApi";
import { useAppDispatch } from "../app/hooks";
import {
  Section,
  Single,
  UpdateComp,
} from "../cms/cmsSlice";
import { SingleComp } from "../cms/components/SingleComp";
import TreeUtils from "../utils/TreeUtils";
import _ from "lodash";
import { getUserInfo } from "../projects/projectsSlice";
import { getAccessTokenSilence } from "../auth/auth";
import SettingsPage from "./SettingsPage";
import { checkRequiredFields } from "./SettingsUtils";
import { CMSWorkingMode } from "../utils/Constants";

export interface MembersContextType {
  accountInfo: LynxiAccountInfo
}

export const AccountContext = React.createContext<MembersContextType | undefined>(undefined)


export interface SettingsDetailsProps {
  onExternalClose?(): void;
}

const SettingsDetails: React.FC<SettingsDetailsProps> = ({onExternalClose}) => {
  const [settingsDetails, setSettingsDetails] = useState<Section>();
  const [settings, setSettings] = useState<UserSettings>();
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [dirtyFlag, setDirtyFlag] = React.useState<boolean>(false);

  const [showSaveMessage, setShowSaveMessage] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams()
  const msalInstance = useMsal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useAsyncEffect(async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnLoading(true);
      const settingsToken = await backofficeApi.getParamFromServer("user_profile", token.accessToken);
      const settingsObj = JSON.parse(settingsToken.value);

      const settingsInfoResponse = await backofficeApi.getUserSettings(token.accessToken);

      setSettings(settingsInfoResponse);
      if (settingsInfoResponse.additionalData) {
        const settingsDic: { [id: string]: string } = {
          ...JSON.parse(settingsInfoResponse.additionalData),
          name: settingsInfoResponse.displayName,
          email: settingsInfoResponse.notificationsEmail?.toLowerCase(),
        };
        TreeUtils.fillCMSWithContentFormDic(settingsObj, settingsDic);
      }
      if (searchParams.get("param")) {
        setTimeout(() => {
          const id = `compKey-${searchParams.get("param") === 'city'? 'schedulingApp': 'schedulingApp'}`;
          const element = document.getElementById(id);       
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });         
            element.focus()
          }
        }, 700);
      }
      setOnLoading(false);
      setSettingsDetails(settingsObj);
    }
  }, []);

  useAsyncEffect(async () => {
    if (settingsDetails) {
      setEnableSave(checkRequiredFields(settingsDetails.singles));
    }
  }, [settingsDetails]);

  const saveSettingsData = async () => {
    if (settingsDetails) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const settingsDic = TreeUtils.getDicFromProjectCms(settingsDetails);
        const data = {
          displayName: _.get(settingsDic, "name"),
          notificationsEmail: _.get(settingsDic, "email"),
          address: settings?.address,
          additionalData: JSON.stringify(settingsDic),
        };
        await backofficeApi.updateUserSettings(data, token.accessToken);
        setShowSaveMessage(true);
        dispatch(getUserInfo(token.accessToken))
        setDirtyFlag(false);
      }
    }
  };

  const onUpdateComp = (item: UpdateComp) => {
    if (settingsDetails) {
      setDirtyFlag(true);
      const tempProjectDetails = cloneDeep(settingsDetails);
      const compList =
        tempProjectDetails.singles[item.location.singleIdx].components;
      compList[item.location.compIdx] = item.comp;
      setSettingsDetails(tempProjectDetails);
    }
  };

  const renderSingle = (
    single: Single,
    singleIndex: number,
    subSectionIndex?: number
  ) => {
    return (
      <SingleComp
        key={`single-${singleIndex}`}
        single={single}
        singleIdx={singleIndex}
        subSectionIndex={subSectionIndex}
        sectionIndex={0}
        showIcon={true}
        onUpdateComp={onUpdateComp}
        noComment={true}
        // compactMode={true}
        mode={CMSWorkingMode.Edit}
      />
    );
  };

  
  const onCloseSuccessMessage = (cont?: boolean) => {
    setShowSaveMessage(false);
    if (cont) {
      if (onExternalClose) {
        onExternalClose()
      } else {
         navigate(-1);
      }
    }
  };

  return (
    <SettingsPage
        title={"My Profile"}
        dirtyFlag={dirtyFlag}
        enableSave={enableSave}
        onSaveSettingsData={saveSettingsData}
        onLoading={onLoading}
        showSaveMessage={showSaveMessage}
        onCloseSuccessMessage={onCloseSuccessMessage}
        onClose={ () => onCloseSuccessMessage(true)}    
        asPopup={onExternalClose !== undefined}      
      >
        {settingsDetails &&
          settingsDetails.singles.map((single, i) => renderSingle(single, i))}
      </SettingsPage>
  );
};

export default SettingsDetails
