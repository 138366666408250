import { useMsal } from "@azure/msal-react";
import { PopperPlacementType } from "@mui/material/Popper";
import { get } from "lodash";
import React, { FC } from "react";
import useAsyncEffect from "use-async-effect";
import { cmsApi, CommentData } from "../../api/cmsApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getAccessTokenSilence } from "../../auth/auth";
import CommentsUtils from "../../Comments/CommentsUtils";
import { CommentsViewer } from "../../Comments/CommentsViewer";
import { refreshActiveProject } from "../../projects/projectsSlice";
import { CMSState, setComments } from "../cmsSlice";
import { projectsApi } from "../../api/projectsApi";
import { DesignerContext } from "../../routes/AppRouter";
import { anonymousApi } from "../../api/anonymousApi";

export interface CmsCommnetManagerProps {
  anchorEl?: any;
  cmsKey: string;
  onClose(): void;
  isClient?: boolean;
  commentLocation?: PopperPlacementType;
  isFormStage?: boolean;
}

export const CmsCommentsManager: FC<CmsCommnetManagerProps> = ({
  anchorEl,
  cmsKey,
  onClose,
  isClient,
  commentLocation,
  isFormStage,
}) => {
  const msalInstance = useMsal();
  const dispatch = useAppDispatch();
  const [messageTimeoutExpired, setMessageTimeoutExpired] =
    React.useState<boolean>(true);

  const cmsData: CMSState = useAppSelector((state: RootState) => state.cmsData);
  const desingerContext = React.useContext(DesignerContext);

  useAsyncEffect(async () => {
    if (anchorEl) {
      if (
        CommentsUtils.checkForNewItems(
          cmsData.comments.filter((c: CommentData) => c.name === cmsKey),
          isClient
        )
      ) {
        if (isFormStage) {
          if (isClient) {
            await projectsApi.updateBriefComments(
              cmsData.metaDataId,
              cmsKey,
              cmsData.anonymousEmail ?? desingerContext.email,
              "token.accessToken"
            )
          } else {
            const token = await getAccessTokenSilence(msalInstance);
            if (token) {
              await projectsApi.updateBriefComments(
                cmsData.metaDataId,
                cmsKey,
                '',
                token.accessToken
              );
            }
          }
        } else {
          if (cmsData.anonymousEmail) {
            await anonymousApi.updateCmsCommentsByEmail(
              cmsData.metaDataId,
              cmsKey,
              cmsData.anonymousEmail ?? ''
            );
          } else {
            const token = await getAccessTokenSilence(msalInstance);
            if (token) {
              await cmsApi.updateCmsComments(
                cmsData.metaDataId,
                cmsKey,
                token.accessToken
              );

              if (!isClient) {
                const token = await getAccessTokenSilence(msalInstance);
                if (token) {
                    dispatch(refreshActiveProject(token.accessToken));
                }
              }
            }
          }
        }

        

        dispatch(
          setComments(
            cmsData.comments.map((c) => {
              if (
                c.name === cmsKey &&
                c.source === (isClient ? "Designer" : "Collaborator")
              ) {
                return { ...c, viewedAt: new Date().toISOString() };
              } else return c;
            })
          )
        );
      }
    }
  }, [anchorEl]);

  const getExtendedCommentText = (newComment: string) => {
    //Check For image comment
    if (cmsData.newImageComment) {
      const extendedComment = {
        title: newComment,
        info: cmsData.newImageComment,
      };
      return JSON.stringify(extendedComment);
    } else {
      const currentComments = cmsData.comments.filter(
        (c: CommentData) => c.name === cmsKey
      );
      if (currentComments.length > 0) {
        const firstComment = currentComments[0];
        try {
          const jsonComment = JSON.parse(firstComment.comment);
          const extendedComment = {
            title: newComment,
            info: get(jsonComment, "info"),
          };
          return JSON.stringify(extendedComment);
        } catch (_) {
          return newComment;
        }
      }
    }
    return newComment;
  };

  const addComment = async (newComment: string) => {
    const newCommentInfo = {
      name: cmsKey,
      comments: getExtendedCommentText(newComment),
    };

    if (isFormStage) {
      if (isClient) {
        await projectsApi.addBriefComments(
          cmsData.metaDataId,
          newCommentInfo,
          cmsData.anonymousEmail ?? desingerContext.email,
          "token.accessToken"
        );
      } else {
        const token = await getAccessTokenSilence(msalInstance);
        if (token) {
          await projectsApi.addBriefCommentsSecure(
            cmsData.metaDataId,
            newCommentInfo,
            '',
            token.accessToken
          );
        }
      }
    } else {
      if (cmsData.anonymousEmail) {
        await anonymousApi.addCmsCommentsByEmail(
          cmsData.metaDataId,
          newCommentInfo,
          cmsData.anonymousEmail ?? desingerContext.email,
        );
      } else {
        const token = await getAccessTokenSilence(msalInstance);
        if (token) {
          await cmsApi.addCmsComments(
            cmsData.metaDataId,
            newCommentInfo,
            token.accessToken
          );
        }
      }
        
    }
    const newCommentList: CommentData[] = [
      {
        createdAt: new Date().toISOString(),
        createdBy: "",
        source: isClient ? "Collaborator" : "Designer",
        name: newCommentInfo.name,
        comment: newCommentInfo.comments,
      },
      ...cmsData.comments,
    ];
    setMessageTimeoutExpired(false);
    setTimeout(() => {
      setMessageTimeoutExpired(true);
      dispatch(setComments(newCommentList));
      onClose();
    }, 2000);
  };

  return (
    anchorEl && (
      <CommentsViewer
        position={commentLocation ?? "left-start"}
        onClose={onClose}
        comments={cmsData.comments.filter(
          (c: CommentData) => c.name === cmsKey
        )}
        onAddComment={addComment}
        showSendMessage={!messageTimeoutExpired}
        anchorEl={anchorEl}
        isClient={isClient}
        commentKey={cmsKey}
      />
    )
  );
};
