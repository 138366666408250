import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { ProjectState } from "../projects/projectsSlice";
import * as React from "react";
import { DesignerContext } from "../routes/AppRouter";
import _ from "lodash";

export const useDesginerInfo = () => {
  const projectState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const designerContext = React.useContext(DesignerContext);

  const getDesignerName = () => {
    let businessName = "";
    if (projectState.accountInfo) {
      const accountInfoAdditionalData = JSON.parse(
        projectState.accountInfo.additionalData ?? "{}"
      );
      businessName = _.get(accountInfoAdditionalData, "bussinessName", "");
    }

    if (businessName !== "") {
      return businessName;
    } else if (projectState.userInfo && projectState.userInfo.displayName) {
      return projectState.userInfo.displayName;
    } else return designerContext.name;
  };

  const isBrandingExists = () => {
    const brandingWasShown = localStorage.getItem("showBranding");
    if (brandingWasShown === "true") {
      return true;
    } else if (projectState.accountInfo) {
      const accountInfoAdditionalData = JSON.parse(
        projectState.accountInfo.additionalData ?? "{}"
      );
      const businessName = _.get(accountInfoAdditionalData, "bussinessName", "").trim() !== "";
      const logoUrl = projectState.accountInfo.logoUrl && projectState.accountInfo.logoUrl !== "";
      return businessName || logoUrl;
    } else return false;
  }

  return { getDesignerName, isBrandingExists };
};
