import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationHEB from "./assets/locales/he/translation.json";

const fallbackLng = ["en"];
// const availableLanguages = ["en", "he", "fr"];

const resources = {
  en: {
    translation: translationEN
  },
  he: {
    translation: translationHEB
  },
};

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources,
    fallbackLng,
    keySeparator: '.',

    // detection: {
    //   checkWhitelist: true
    // },

    debug: false,

    // whitelist: availableLanguages,

    // interpolation: {
    //   escapeValue: false
    // }
  });

export default i18n;
