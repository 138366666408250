import {
  Box
} from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

export interface EmptySectionProps {
  onAddNewTopic():void
}

export const EmptySection: FC<EmptySectionProps> = ({onAddNewTopic}) => {
  const {t} = useTranslation()

  const cmsState = useAppSelector((state: RootState) => state.cmsData);

    return (
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign:'center',
        marginTop: "40px",     
        backgroundColor: cmsState.inFormBuildMode ? "#fff": 'transparent' ,
        padding:'40px 0px',       
      }}
    >
      <Box sx={{ cursor: "pointer" }} onClick={() => onAddNewTopic()}>
        <img src="/images/cms/editor/emptySection.svg" />
      </Box>
      <Box sx={{ fontSize: "22px", fontWeight: 500, marginTop: "40px" }}>
          {t("cms.editor.empty_section.title")}
        </Box>
        <Box sx={{ marginTop: "20px" }}>
        {t("cms.editor.empty_section.message")}
        </Box>
      <Box
        sx={{
          fontSize: "22px",
          fontWeight: 500,
          color: "#7f49c3",
          marginTop: "20px",
          cursor: "pointer",
        }}
        onClick={() => onAddNewTopic()}
      >
         {t("cms.editor.empty_section.action")}
      </Box>
    </Box>
    )

}