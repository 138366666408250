import { Box, Dialog, Typography } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ReactComponent as CLoseDialogSvg } from "../assets/closeDialog.svg";

export interface BlackMessageDialogProps {
  children?: React.ReactNode;
  onClose(): void;
  title?: string;
  icon?: React.ReactNode;
  open: boolean;
  extraWidth?: string;
  hideDialogClose?: boolean
  zIndex?: number;
}

export const BlackMessageDialog: FC<BlackMessageDialogProps> = ({
  children,
  onClose,
  title,
  icon,
  open,
  extraWidth,
  hideDialogClose,
  zIndex
}) => {

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      sx={{ zIndex: zIndex??4000 }}
    >
      <Box
        sx={{
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          // border: "none",
          // outline: "none",
          width: extraWidth ? extraWidth : "600px",
          background: '#292B28',
         display: 'flex',
         flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "20px",
          }}
        >
          <Box
            sx={{ width: "100%", display: "flex" }}
          >
            {title && (
              <Typography
                sx={{
                  padding: "0px 66px",
                  fontFamily: "Nunito",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {title}
              </Typography>
            )}
            <Box sx={{display: 'flex', flexGrow:1}}/>
            {!hideDialogClose && (
            <Box
              sx={{
                marginRight: "25px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
              onClick={() => onClose()}
            >
              <CLoseDialogSvg />
            </Box>)}
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: "#292B28",
            paddingTop: "20px",
            display: "flex",
            margin: '0 40px',
            flexDirection: "column",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            paddingBottom: '30px',
          }}
        >
            {icon && <Box sx={{display:'flex', justifyContent:'left', paddingTop: "10px", marginBottom:'30px' }}>{icon}</Box>}
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:"center", alignContent:'center', width: '100%'}}>
               {children}
            </Box>
           
        </Box>
      </Box>
    </Dialog>
  );
};
