import { Box, Divider, Fab } from "@mui/material";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { ContentHubFilterType } from "../../utils/Constants";
import "../cms.scss";
import "./SideBar.scss";
import {
  CMSState,
  Section,
  Single,
  setCurrentSection,
  setInEditStructureMode,
  setNewQuestionKey,
  setTriggerAddTopic,
  updatePages,
} from "../cmsSlice";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SectionSvg } from "../../assets/cms/editor/sections.svg";
import { ReactComponent as SaveSvg } from "../../assets/brief/saveBrief.svg";

import { ReactComponent as ItemSvg } from "../../assets/cms/editor/list.svg";
import { ReactComponent as SmallEditSvg } from "../../assets/project/smallEdit.svg";

import { EditorNewSectionPopup } from "../Editor/EditorNewSectionPopup";
import {
  AddNewTopicInfo,
  EditorNewTopicPopup,
} from "../Editor/EditorNewTopicPopup";
import useAsyncEffect from "use-async-effect";
import { BasicToolTipContextMenu } from "../../basic/BasicToolTipContextMenu";
import { CMSManagerContext } from "../../routes/DesignerRouter";
import { reorder } from "./DragHelper";
import { Drop } from "./DragDrop";
import { DragDropContext as DragAndDrop } from "react-beautiful-dnd";
import { SideBarMenuItem } from "./SideBarMenuItem";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { CloseRounded } from "@mui/icons-material";
import { DeleteConfirmationPopup } from "../../PopUpMessage/DeleteProjectPopup";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";

export interface CMSSideBarProps {
  background?: string;
}

export const CMSSideBar: React.FC<CMSSideBarProps> = ({ background }) => {
  const [showAddNewSectionDialog, setShowAddNewSectionDialog] =
    React.useState<boolean>(false);
  const [showAddNewTopicDialog, setShowAddNewTopicDialog] =
    React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const [editSectionIndex, setEditSectionIndex] = React.useState<number>(-1);
  const cmsContext = React.useContext(CMSManagerContext);
  const [menuItems, setMenuItems] = React.useState<Section[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [saveAnchorEl, setSaveAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [sectionIdForDeletation, setSectionIdForDeletation] =
    React.useState<number>();
  const [sectionDeletationWarning, setSectionDeletationWarning] =
    React.useState<boolean>(false);

  const addNewSection = (sectionName: string) => {
    const pages = [...cmsState.pages];
    const page = pages[cmsState.currentPage];
    const newpage = {
      ...page,
      sections: [
        ...page.sections,
        { description: sectionName, singles: [], type: "new" },
      ],
    };
    setShowAddNewSectionDialog(false);
    dispatch(updatePages([newpage]));
    setTimeout(() => {
      dispatch(setCurrentSection(newpage.sections.length - 1));
      const scroll_to_bottom = document.getElementById("side-menu-bar");
      if (scroll_to_bottom) {
        scroll_to_bottom.scroll({
          top: scroll_to_bottom.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 300);
  };

  const editMenuItem = (sectionName: string) => {
    const pages = cloneDeep(cmsState.pages);
    const page = pages[cmsState.currentPage];
    const section = page.sections;
    section[editSectionIndex].description = sectionName;
    dispatch(updatePages(pages));
    setTimeout(() => {
      dispatch(setCurrentSection(editSectionIndex));
      setEditSectionIndex(-1);
    }, 300);
  };

  const deleteSection = (e: any, idx: number) => {
    e.stopPropagation();
    e.preventDefault();
    const origComponents = cmsState.pages[0].sections[idx].singles.filter(
      (single) => !single.isNew
    );
    if (origComponents.length > 0) {
      setSectionDeletationWarning(true);
    } else {
      setSectionIdForDeletation(idx);
    }
  };

  const onDeleteApproved = () => {
    setSectionIdForDeletation(undefined);
    const pages = [...cmsState.pages];
    const page = pages[cmsState.currentPage];
    const newPage = {
      ...page,
      sections: page.sections.filter(
        (_, index) => index !== sectionIdForDeletation
      ),
    };
    dispatch(setCurrentSection(0));
    dispatch(updatePages([newPage]));
  };

  useAsyncEffect(async () => {
    if (cmsState.triggerAddTopic) {
      setShowAddNewTopicDialog(true);
      dispatch(setTriggerAddTopic(false));
    }
  }, [cmsState.triggerAddTopic]);

  const getComponentByType = (
    topicInfo: AddNewTopicInfo,
    lastAddedMetaId: number
  ) => {
    switch (topicInfo.type) {
      case "Gallery":
        return {
          type: "gallery",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "media",
          value: [],
          components: [],
        };
      case "Image":
        return {
          type: "media",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          value: "",
          components: [],
        };
      case "Dropdown":
        return {
          type: "dropdown",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          options: topicInfo.options,
          value: [],
          components: [],
          fullWidth: true,
        };
      case "Checkbox":
        return {
          type: "multiCheckBox",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          options: topicInfo.options,
          value: [],
          components: [],
        };
      case "Multi select dropdown":
        return {
          type: "multidropdown",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          options: topicInfo.options,
          value: [],
          fullWidth: true,
          components: [],
        };
      case "Rating":
        return {
          type: "rating",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          value: -1,
          fullWidth: true,
          components: [],
        };
      case "Yes/No":
        return {
          type: "yesno",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          value: -1,
          fullWidth: true,
          components: [],
        };
      case "ContentSeperator":
        return {
          type: "contentSeperator",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          value: -1,
          fullWidth: true,
          components: [],
        };
      case "Short answer":
        return {
          type: "simpleText",
          description: topicInfo.placeholder,
          title: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          value: "",
          fullWidth: true,
          components: [],
        };
      default:
        return {
          type: "paragraph",
          description: topicInfo.title,
          mapKey: `${lastAddedMetaId}`,
          listType: "none",
          placeHolder: topicInfo.placeholder,
          value: "",
          fullWidth: true,
          components: [],
        };
    }
  };

  const addNewTopic = async (topicInfo: AddNewTopicInfo) => {
    const lastAddedMetaId =
      (cmsContext ? cmsContext.getLastAddedMetaId() : 20000) + 1;
    const pages = [...cmsState.pages];
    const page = pages[cmsState.currentPage];
    const selectedSectionId = page.sections.findIndex(
      (s) => s.description === topicInfo.sectionName
    );
    if (selectedSectionId !== -1) {
      dispatch(setCurrentSection(selectedSectionId));
    }
    const sectionLastSingleKey = page.sections[cmsState.currentSection].singles
      .map((s) => s.singleKey)
      .sort((a, b) => b - a)[0];
    const newSingle = {
      description: topicInfo.sectionTitle,
      icon: "",
      singleKey: sectionLastSingleKey
        ? parseInt(`${sectionLastSingleKey}`, 10) + 1
        : cmsState.currentSection * 100,
      isNew: true,
      isGlobal: topicInfo.global,
      isContentSeperator: topicInfo.type === "ContentSeperator",
      components: [{ ...getComponentByType(topicInfo, lastAddedMetaId) }],
    };
    const newPage = {
      ...page,
      sections: [
        ...page.sections.map((section) => ({
          ...section,
          singles:
            section.description === topicInfo.sectionName
              ? [...section.singles, newSingle]
              : [...section.singles],
        })),
      ],
    };
    setTimeout(() => {
      const scroll_to_bottom = document.getElementById(
        background ? "containerDivPopup" : "containerDiv"
      );
      if (scroll_to_bottom) {
        scroll_to_bottom.scroll({
          top: scroll_to_bottom.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 500);

    if (cmsContext) {
      cmsContext.onUpdateLastAddedMetaId(lastAddedMetaId);
    }
    dispatch(updatePages([newPage]));
    dispatch(setNewQuestionKey());
    setShowAddNewTopicDialog(false);
  };

  const getMenuItmes = () => {
    const menu = [
      {
        label: "Add menu item",
        icon: <SectionSvg />,
        func: () => setShowAddNewSectionDialog(true),
      },
      {
        label: "Add section",
        icon: <ItemSvg />,
        func: () => setShowAddNewTopicDialog(true),
      },
    ];
    return menu;
  };

  const handleDragEnd = async (result: any) => {
    const { type, source, destination } = result;
    if (!destination) return;

    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;
    const sections: Section[] = cmsState.pages[0].sections ?? [];
    // Reordering items
    if (type === "droppable-item") {
      // If drag and dropping within the same category
      if (sourceCategoryId === destinationCategoryId) {
        const updatedOrder: Single[] = reorder(
          sections.find((section) => section.description === sourceCategoryId)
            ?.singles ?? [],
          source.index,
          destination.index
        );
        const updatedCategories: Section[] = sections.map((section) =>
          section.description !== sourceCategoryId
            ? section
            : { ...section, singles: updatedOrder }
        );

        const pages = [{ ...cmsState.pages[0], sections: updatedCategories }];
        // await updateEditorStructure(pages);
        dispatch(updatePages(pages));
      } else {
        const sourceOrder =
          sections.find((section) => section.description === sourceCategoryId)
            ?.singles ?? [];
        const destinationOrder =
          sections.find(
            (section) => section.description === destinationCategoryId
          )?.singles ?? [];

        //move the item from the source to the destination type script
        const sourceIndex = source.index;
        const updatedSource = [...sourceOrder];
        const removedArray = updatedSource.splice(sourceIndex, 1);
        const removed = removedArray[0];
        const updatedDestanation = [...destinationOrder];
        updatedDestanation.splice(destination.index, 0, removed);

        const updatedCategories: Section[] = sections.map((section) =>
          section.description === sourceCategoryId
            ? { ...section, singles: updatedSource }
            : section.description === destinationCategoryId
            ? { ...section, singles: updatedDestanation }
            : section
        );

        const pages = [{ ...cmsState.pages[0], sections: updatedCategories }];
        // await updateEditorStructure(pages);
        dispatch(updatePages(pages));
      }
    }

    // Reordering categories
    if (type === "droppable-category") {
      const updatedCategories = reorder(
        sections,
        source.index,
        destination.index
      );
      const pages = [{ ...cmsState.pages[0], sections: updatedCategories }];
      // await updateEditorStructure(pages);
      dispatch(updatePages(pages));
    }
  };

  React.useEffect(() => {
    if (cmsState.pages.length > 0) {
      setMenuItems(cmsState.pages[0].sections);
    }
  }, [cmsState.pages]);

  return (
    <Box
      className={
        cmsState.cmsFilterMode !== ContentHubFilterType.None
          ? "drawZeroWidth"
          : "drawFullWidth"
      }
      sx={{
        background: background ? background : "#fff",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {cmsState.inEditStructureMode && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                paddingLeft: "20px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  fontWeight: 600,
                  color: "#6c3dab",
                }}
              >
                Add new
              </Box>
              <BasicToolTipContextMenu menuItems={getMenuItmes()}>
                <Box
                  sx={{
                    display: "flex",
                    padding: "0 20px",
                    cursor: "pointer",
                  }}
                >
                  <Fab
                    size="small"
                    sx={{
                      transform: "scale(0.6)",
                      background: "#000",
                      color: "#fff",
                      "&:hover": { color: "#000" },
                    }}
                  >
                    <AddIcon fontSize="medium" />
                  </Fab>
                </Box>
              </BasicToolTipContextMenu>
            </Box>
            <Box sx={{ width: "95%", margin: "0 auto", paddingRight: "20px" }}>
              <Divider sx={{ color: "#ececec" }} />
            </Box>
          </>
        )}
        <Box
          id="side-menu-bar"
          sx={{
            background: background ? background : "#fff",
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
            maxHeight: background
              ? `calc(100vh - ${
                  cmsState.inEditStructureMode ? "270px" : "230px"
                })`
              : `calc(100vh - ${
                  cmsState.inEditStructureMode ? "230px" : "190px"
                })`,
            overflowY: "auto",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <DragAndDrop onDragEnd={handleDragEnd}>
              <Drop id="droppable" type="droppable-category">
                {menuItems
                  .filter((section) => section.description !== "Marketing")
                  .map((section, idx) => (
                    <SideBarMenuItem
                      key={section.description}
                      section={section}
                      sectionIndex={idx}
                      pageIndex={0}
                      onEditSection={(idx: number) => setEditSectionIndex(idx)}
                      onDeleteSection={(e: any, idx: number) =>
                        deleteSection(e, idx)
                      }
                      pageName={"sdsd"}
                      background={background}
                    />
                  ))}
              </Drop>
            </DragAndDrop>
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          {showAddNewSectionDialog && (
            <EditorNewSectionPopup
              onClose={() => setShowAddNewSectionDialog(false)}
              sectionIndex={-11}
              onAddSection={addNewSection}
            />
          )}
          {editSectionIndex !== -1 && (
            <EditorNewSectionPopup
              onClose={() => setEditSectionIndex(-1)}
              onAddSection={editMenuItem}
              sectionIndex={editSectionIndex}
              initialSectionName={
                cmsState.pages[0].sections[editSectionIndex].description
              }
            />
          )}
          {showAddNewTopicDialog && (
            <EditorNewTopicPopup
              onClose={() => setShowAddNewTopicDialog(false)}
              onAddTopic={addNewTopic}
            />
          )}
        </Box>
      </Box>
      {cmsState.pages && cmsState.pages.length > 0 && cmsState.inEditMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
            marginTop: "10px",
            padding: "10px 0px 20px 0px",
            gap: "5px",
            width: "90%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              minHeight: "40px",
              justifyContent: cmsState.inEditStructureMode
                ? "space-between"
                : "left",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>Customize Content Hub</Box>
            {cmsState.inEditStructureMode ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "3px",
                  alignItems: "center",
                }}
              >
                <ColorButton
                  onClick={(e) => {
                    setSaveAnchorEl(e.currentTarget);
                  }}
                  sx={{ padding: "1px", height: "26px" }}
                >
                  Save
                </ColorButton>
                <Fab
                  size="small"
                  sx={{
                    transform: "scale(0.6)",
                    background: "#000",
                    color: "#fff",
                    "&:hover": { color: "#000" },
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <CloseRounded fontSize="medium" />
                </Fab>
              </Box>
            ) : (
              <>
                <Box
                  onClick={() => dispatch(setInEditStructureMode(true))}
                  sx={{
                    alignItems: "center",
                    cursor: "pointer",
                    display: "flex",
                  }}
                >
                  <HelpToolTipOnHover
                    position="top"
                    size={250}
                    message={
                      "Personalize the content hub's structure to fit your needs."
                    }
                    actionNode={<SmallEditSvg />}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}

      {sectionIdForDeletation !== undefined && (
        <DeleteMessageDialog
          onClose={() => setSectionIdForDeletation(undefined)}
          onDelete={() => onDeleteApproved()}
          title={`Attention!`}
          message={`Deleting a menu item will result in the removal of all the sections that are nested within it. To prevent the loss of important sections, consider moving them to different menu items before deleting them.`}
        />
      )}

      {sectionDeletationWarning && (
        <DeleteMessageDialog
          onClose={() => setSectionDeletationWarning(false)}
          onDelete={() => setSectionDeletationWarning(false)}
          title={`It is not possible to delete a default section`}
          actionText={"Got it"}
          noSecondAction={true}
          message={`It is not possible to delete a menu item that has a default section, like About Us. You should first consider moving such sections to other menu items.`}
        />
      )}

      <DeleteConfirmationPopup
        title={`Discard changes`}
        description={
          "Do you want to exit edit mode and lose all the changes made?"
        }
        onDeleteSite={() => {
          dispatch(updatePages(cmsState.cmsSchema));
          setAnchorEl(null);
          dispatch(setInEditStructureMode(false));
        }}
        placement={"top"}
        action={"Discard"}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        autoClose={true}
      />

      <DeleteConfirmationPopup
        title={`The changes that have been made will have an impact on the structure of the Content Hub across all projects.
        `}
        description={"Would you like to proceed?"}
        onDeleteSite={() => {
          setSaveAnchorEl(null);
          dispatch(setInEditStructureMode(false));
          if (cmsContext) {
            cmsContext.onUpdateEditor(
              cmsState.pages[0],
              cmsContext.getLastAddedMetaId()
            );
          }
        }}
        icon={<SaveSvg />}
        placement={"top"}
        action={"Save"}
        onClose={() => setSaveAnchorEl(null)}
        anchorEl={saveAnchorEl}
        autoClose={true}
      />
    </Box>
  );
};
