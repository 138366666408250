import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { renderDot } from "../../projects/ProjectUIHelper";
import SettingsIcon from "@mui/icons-material/Settings";

export interface LabelItem {
  label: string;
  color: string;
  isSystem?: boolean;
}

export interface LabelItemForUpdate {
  label: string;
  color: string;
  blocked: boolean;
  origLabel: string;
  isSystem?: boolean;
}

interface StatusDropDownProps {
  currentStatus: string;
  onStatusChange(value: string): void;
  statusList: LabelItem[];
  onSettingsClick?: () => void;
  // Define any props needed for the component
}

export const StatusDropDown: React.FC<StatusDropDownProps> = ({
  currentStatus,
  onStatusChange,
  statusList,
  onSettingsClick,
}) => {
  const [open, setOpen] = React.useState(false);
  const onChange = async (event: SelectChangeEvent) => {
    if (event.target.value !== "__settings__") {
      event.stopPropagation();
      event.preventDefault();
      onStatusChange(event.target.value);
    }
  };

  const getSelectedStatusColor = (status: string) => {
    const statusItem = statusList.find((item) => item.label === status);
    return statusItem ? statusItem.color : "#000";
  };

  return (
    <FormControl sx={{ borderRadius: "20px" }}>
      <Select
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        sx={{
          fontFamily: "Nunito",
          fontSize: "14px",
          fontWeight: "700",
          background: getSelectedStatusColor(currentStatus),
          color: "#fff",
          borderRadius: "20px",
          border: "none",
          width: "140px",
          textAlign: "left",

          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderRadius: "20px",
          },
          " > *": {
            borderRadius: "20px",
          },
          "&:focus": {
            borderRadius: "20px !important",
          },
          "&:hover": {
            "&& fieldset": {
              border: "none",
              borderRadius: "20px",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
        displayEmpty
        inputProps={{
          sx: {
            color: "#fff",
            background: getSelectedStatusColor(currentStatus),
            borderRadius: "20px",
            border: "none",
            fontFamily: "Nunito",
            padding: "6px 6px",
            margin: "0px 0px 0 8px",
            "& .dot2": { display: "none" },
            "&:hover": { border: "none" },
            "&:focus": {
              borderRadius: "20px !important",
            },
          },
        }}
        MenuProps={{
          disablePortal: true,
          sx: {
            "& .MuiMenuItem-root.Mui-selected": {
              display: "none",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#000",
              color: "#fff",
              border: "1px solid #000",
            },
          },
        }}
        onChange={onChange}
        value={currentStatus}
      >
        {statusList.map((statusItem, index) => (
          <MenuItem
            key={`menu-status-${index}`}
            sx={{ color: "#000", fontFamily: "Nunito" }}
            value={statusItem.label}
          >
            {renderDot(statusItem.color)}
            {statusItem.label}
          </MenuItem>
        ))}
        {onSettingsClick && (
          <>
            <Divider />
            <MenuItem
              value="__settings__"
              onClick={() => {
                setOpen(false);
                onSettingsClick();
              }}
            >
              <SettingsIcon
                style={{ color: "#000", marginRight: "5px" }}
                fontSize="small"
              />
              Settings
            </MenuItem>
          </>
        )}
      </Select>
    </FormControl>
  );
};
