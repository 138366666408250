import { Box, Divider, TextField, Tooltip } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { BasicRichEditor } from "../../basic/BasicRichEditor/BasicRichEditor";
import { FormSection, Section, setAllInFormSectionsQuestions, setInFormSectionsQuestions, updateAllInFormQuestions, updateInFormQuestions } from "../cmsSlice";
import { ReactComponent as EyeSvg } from "../../assets/project/eye.svg";
import { ReactComponent as EditPencilSVG } from "../../assets/project/purplePen.svg";
import { ReactComponent as EyeHiddenSvg } from "../../assets/project/eyeHidden.svg";
import { ReactComponent as CheckSvg } from "../../assets/checkbox.svg";



import parse from "html-react-parser";
import { CustomCheckBox } from "../../projects/ProjectUIHelper";

export interface UpdateTopicInfo {
  title: string;
  placeholder: string;
  sectionTitle: string;
  
}

export interface SectionHelperBoxProps {
  formSection?: FormSection;
  sectionName: string;
  sectionIdx: number;
  section: Section
}

export const SectionHelperBox: FC<SectionHelperBoxProps> = ({
  formSection,
  sectionName,
  sectionIdx,
  section
}) => {
  const [title, setTitle] = React.useState<string>("");
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const cmsState = useAppSelector((state) => state.cmsData);

  const getFormSectionData = (): FormSection => {
    return (
      formSection ?? {
        singlesIds: [],
        name: sectionName,
        sectionIndex: sectionIdx,
      }
    );
  };

  const setSectionTitle = (title: string) => {
    dispatch(
      setInFormSectionsQuestions({
        ...getFormSectionData(),
        title: title,
        singlesIds: [],
      })
    );
  };

  const setAsHidden = () => {
    dispatch(
      setInFormSectionsQuestions({
        ...getFormSectionData(),
        hide: !formSection?.hide,
        singlesIds: [],
      })
    );
  };

  const setDescription = (description: string) => {
    dispatch(
      setInFormSectionsQuestions({
        ...getFormSectionData(),
        description: description,
        singlesIds: [],
      })
    );
  };

  React.useEffect(() => {
    setTitle(formSection?.title ?? sectionName);
    console.log("formSection?.title", formSection);
  }, [formSection?.title, sectionName]);


  const checkIfAllSinglesAreMarked = () => {
    const allSinglesIdx = section.singles.filter(
      (single) => !cmsState.formQuestions.includes(single.singleKey)
    );
    return allSinglesIdx.length === 0;
  };

  const onSelectAllQuestions = () => {
    const allSinglesIdx = section.singles.map((single) => single.singleKey);
    dispatch(
      setAllInFormSectionsQuestions({
        singlesIds: checkIfAllSinglesAreMarked() ? [] : allSinglesIdx,
        sectionIndex: sectionIdx,
        name: section.description,
      })
    );
    dispatch(
      updateAllInFormQuestions({
        singlesIdx: allSinglesIdx,
        mark: !checkIfAllSinglesAreMarked(),
      })
    );
  };

  // const checkIfAllSinglesAreMarked = () => {
  //   const allSinglesIdx = section.singles.filter((single) => !cmsState.formQuestions.includes(single.singleKey))
  //   return allSinglesIdx.length === 0
  // }  

  // const onSelectAllQuestions = () => {
  //   const allSinglesIdx = section.singles.map((single) => single.singleKey)
  //     dispatch(
  //       setInFormSectionsQuestions({
  //         singlesIds: allSinglesIdx,
  //         sectionIndex: sectionIdx,
  //         name: section.description,
  //       })
  //     );
  //     dispatch(updateInFormQuestions(allSinglesIdx));
  // }


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "0px",
        // gap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          gap: "10px",
        }}
      >
         <Box
            sx={{
              display: "flex",
            }}
          >
            <CustomCheckBox
              disabled={cmsState.inEditStructureMode}
              checkedIcon={<CheckSvg style={{ transform: "scale(0.7)" }} />}
              color="primary"
              checked={checkIfAllSinglesAreMarked()}
              onChange={() => {
                onSelectAllQuestions();
              }}
            />
          </Box>
        <Box sx={{ fontWeight: 600, fontSize: "18px",color: formSection?.hide?'#d4d1d1':'#000' }}>{title}</Box>
        <Box sx={{display:'flex', alignItems:'center'}} onClick={() => setAsHidden()}>
          {formSection?.hide ? (
            <Tooltip
            title={'Present title and description'}
            componentsProps={{
              tooltip: {
                sx: {
                  background: "#292B28",
                  "& .MuiTooltip-arrow": {
                    color: "#292B28",
                  },
                },
              },
            }}
            arrow={true}
            placement={"top"}
          >
            <EyeHiddenSvg
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
          />
          </Tooltip>
            
          ) : (
            <Tooltip
            title={'Hide title and description'}
            componentsProps={{
              tooltip: {
                sx: {
                  background: "#292B28",
                  "& .MuiTooltip-arrow": {
                    color: "#292B28",
                  },
                },
              },
            }}
            arrow={true}
            placement={"top"}
          >
            <EyeSvg
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
            />
          </Tooltip>
            
          )}
        </Box>
        <Tooltip
            title={'Edit title and description'}
            componentsProps={{
              tooltip: {
                sx: {
                  background: "#292B28",
                  "& .MuiTooltip-arrow": {
                    color: "#292B28",
                  },
                },
              },
            }}
            arrow={true}
            placement={"top"}
          >
            <EditPencilSVG
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={() => setEditMode(!editMode)}
        />
          </Tooltip>
        
      </Box>
      {!editMode && (
        <Box className={"compDescription"} sx={{ color: formSection?.hide?'#d4d1d1':'#000', marginTop:'5px'}}>
          <div>{parse(formSection?.description ?? "" ?? "")}</div>
        </Box>
      )}
      {editMode && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            marginTop: "10px",
            padding: "20px",
            marginBottom: "20px",
            gap: "15px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <Box>Title</Box>
            <TextField
              onBlur={() => setSectionTitle(title)}
              sx={{
                flexGrow: 1,
                width: "100%",
                background: "#f9f9f9",
                "& input.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                },
              }}
              inputProps={{ className: "dropDownOther" }}
              placeholder={"Enter section title"}
              maxRows={1}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              position: "relative",
            }}
          >
            <Box>Description</Box>
            <BasicRichEditor
              topToolBar={true}
              value={formSection?.description ?? ""}
              onUpdate={(value: string) => setDescription(value)}
            />
          </Box>
          <Box
            sx={{ display: "flex", widht: "100%", justifyContent: "flex-end" }}
          >
            <Box sx={{ cursor: "pointer" }} onClick={() => setEditMode(false)}>
              Close
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{display:'flex', width:'100%',marginTop:'10px'}}>
      <Divider sx={{ width: "100%", margin: "0 auto" }} />
      </Box>
    </Box>
  );
};
