import { createTheme, ThemeOptions } from '@mui/material/styles';
import type { PaletteMode } from '@mui/material';
import { common } from '@mui/material/colors';


export const muiTheme: ThemeOptions = {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: common.white,
        },
        colorDefault: {
          background: '#fff',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body2',
        color: common.black,
        fontFamily: 'Nunito',
      },
      styleOverrides: {
        root: {
          color: common.black,
        },
        h1: {
          fontSize: '32px',
          fontWeight: 500,
          lineHeight: '42px',
        },
        h2: {
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '30px',
        },
        h3: {
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '24px',
        },
        h4: {
          color: common.black,
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '24px',
        },
        h5: {
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '24px',
        },
        h6: {
          fontSize: '10px',
          fontWeight: 500,
          lineHeight: '18px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: { alignSelf: 'center' },
      },
      defaultProps: {
        titleTypographyProps: { variant: 'h4' },
        subheaderTypographyProps: { variant: 'subtitle2' },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // overrides tooltip
        },
        popper: {
         // overrides the popper
          zIndex: 3100 + '!important',
        }
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',        
      },
      styleOverrides: {
        root: ({
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            borderRadius: '6px',
            
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7C48C2",
            borderRadius: '6px',
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7C48C2",
            borderRadius: '6px',
          },
          "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            borderRadius: '6px',
          },      
          boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: '6px',
          
          // "& .MuiOutlinedInput-root": {
          //   "& > fieldset": {
          //     borderColor: "transparent",
          //   },
          // },
          // "& .MuiOutlinedInput-root:hover": {
          //   "& > fieldset": {
          //     borderColor: "#7C48C2"
          //   }
          // },
          // "& .MuiOutlinedInput-root.Mui-focused": {
          //   "& > fieldset": {
          //     borderColor: "#7C48C2"
          //   }
          // },
          // "& .MuiOutlinedInput-root.Mui-disabled": {
          //   "& > fieldset": {
          //     borderColor: "transparent",
          //   },
          // },
        }),
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '14px',
          fontFamily: 'Nunito',   
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '14px',
          fontFamily: 'Nunito',
        },
        
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },      
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '24px',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },
};

export const createMyTheme = (mode: PaletteMode) => createTheme({
  ...muiTheme,
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: {
          main: '#000',
        },
        secondary: {
          main: '#6c3dab',
        },
      }
      : {
        primary: {
          main: '#00E5FF',
        },
        secondary: {
          main: '#FFFF00',
        },
      }),
  },
});
