import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Project } from "../projects/projectsSlice";
// import { BriefPicker } from "../projects/BriefPicker/BriefPicker";
// import { BriefCreator } from "./BriefCreator";
// import { BriefClientViewer } from "./BriefClientViewer";

const LazyBriefCreator = React.lazy(() => import("./Editor/BriefCreator"));
const LazyBriefClientViewer = React.lazy(() => import("./Viewer/BriefClientViewer"));
const LazyBriefPicker = React.lazy(() => import("../projects/BriefPicker/BriefPicker"));

const LazyFormViewerSwitcher = React.lazy(
  () => import("../Briefs/Viewer/FormViewerSwitcher")
);



export interface BriefsRouterProps {
  project: Project
}

export const BriefsRouter: React.FC<BriefsRouterProps> = ({project}) => {

  return (
    <>
      <Routes>
        <Route index element={<LazyBriefClientViewer project={project} isClient={false}/>} />
        <Route path="/client" element={<LazyFormViewerSwitcher isClient={false} project={project}/>} />
        <Route path={'/pick'} element={<LazyBriefPicker project={project}/>} />
        <Route path={'/editor/:questionnaireId'} element={<LazyBriefCreator projectInfo={project}/>} />
        <Route path={'/editor/edit/:questionnaireId'} element={<LazyBriefCreator projectInfo={project} isUpdateProjectBrief={true}/>} />
      </Routes>
    </>   
  )



}