import { useMsal } from "@azure/msal-react";
import { Modal, Box, Typography, Grid } from "@mui/material";
import { cloneDeep, get, has } from "lodash";
import * as React from "react";
import "survey-core/defaultV2.min.css";
import useAsyncEffect from "use-async-effect";
import { adminApi } from "../../api/adminApi";
import { cmsApi } from "../../api/cmsApi";
import { notificationApi } from "../../api/notificationApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {getAccessToken, getAccessTokenSilence} from "../../auth/auth";
import { ProjectState } from "../../projects/projectsSlice";
import { SERVICE_CMS_ID } from "../../utils/Constants";
import TreeUtils from "../../utils/TreeUtils";
import { CMSState, setCurrentSection, setTypesDics } from "../cmsSlice";
import { ContentPickerItem } from "./ContentPickerItem";

export interface ContentItemType {
  type: string;
  content: string;
  options?: string[];
}

export interface MapKeyList {
  key: string;
  index: number;
}

export interface ContentPickerPopupProps {
  onUpdateComp(mapKey: MapKeyList, content: string, type: string): void;
  description: string;
  onClose(): void;
  readonly?: boolean;
  mapKey?: MapKeyList;
  noClient?: boolean;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
  width: "80vw",
};

export const ContentPickerPopup: React.FC<ContentPickerPopupProps> = ({
  // single,
  onClose,
  // location,
  onUpdateComp,
  description,
  readonly,
  mapKey,
  noClient,
}) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [contentItems, setContentItems] = React.useState<ContentItemType[]>([]);
  // const [contentCompIndex, setContentCompIndex] = React.useState<MapKeyList>();
  const [categoryAndSubExists, setCategoryAndSubExists] = React.useState(false);
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  const projectsState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const msalInstance = useMsal();
  const dispatch = useAppDispatch();

  const getByCategoryAndSubCategory = async (cmsKey: string) => {
    const accessToken = await getAccessToken(msalInstance);
    if (accessToken) {
      const dic = cmsState.cmsItems // TreeUtils.extractDicFromCms(cmsState.pages);
      const category = get(get(dic, "39", ""), "value", "").toLowerCase();
      const subCategory = get(get(dic, "40", ""), "value", "").toLowerCase();
      const businessName = get(dic, "42", "");
      const foundedYear = get(dic, "41", "");
      const country = get(get(dic, "73", ""), "value", "");
      if (subCategory !== "" && category !== "") {
        setCategoryAndSubExists(true);
        const response = await cmsApi.getCMSContentByCategory(
          cmsKey,
          category,
          subCategory,
          accessToken
        );
        const expertList =
          response && response.contents.length > 0 ? response.contents : [];
        return expertList.map((r) => {
          let s = r;
          if (businessName !== "") {
            s = s.replace(/{{businessName}}/g, businessName);
          }
          if (foundedYear !== "") {
            s = s.replace(/{{foundingYear}}/g, foundedYear);
            s = s.replace(
              /{{currentYear-foundingYear}}/g,
              `${2022 - parseInt(foundedYear, 10)}`
            );
            s = s.replace(
              /{{variable}}/g,
              `${2022 - parseInt(foundedYear, 10)}`
            );
          }
          if (country !== "") {
            s = s.replace(/{{country}}/g, country);
          }
          return s;
        });
      }
      return [];
    }
    return [];
  };

  const updateActivities = async ( stage: string) => {
    if (projectsState.activeProject) {
      const token = await getAccessTokenSilence(msalInstance)
      if (token) {
        notificationApi.addUserActivite(`Content wiazrd ${stage} - ${description}`, projectsState.activeProject.id, token.accessToken) 
      }
    }
  }

  const getNewSuggestionFromAi = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token && mapKey) {
      updateActivities("refresh")
      const aiItemList = await adminApi.getAllAiItems(token.accessToken);
      const aiItemsForCMS = aiItemList.items.filter(
        (item) =>
          item.cmsIds.length > 0 &&
          item.cmsIds.indexOf(mapKey.key) > -1 &&
          item.tokens.length > 0
      );
      if (aiItemsForCMS.length === 1) {
        const dic = cmsState.cmsItems //TreeUtils.extractDicFromCms(cmsState.pages);
        const category = get(get(dic, "39", ""), "value", "");
        const subCategory = get(get(dic, "40", ""), "value", "");
        const businessName = get(dic, "42", "");
        const foundedYear = get(dic, "41", "");
        const searchTokens = get(dic, "730", "");
        const usedAnswers: { [id: string]: string } = {
          SUB_CATEGORY_NAME: subCategory,
          COMPANY_NAME: businessName,
          ESTABLISH_AT: foundedYear,
          CATEGORY_NAME: category,
          SERVICE_NAME: description,
          SEO_KEYWORDS: searchTokens
        };
        const aiResponse = await adminApi.processAiTemaplate(
          cmsState.project ? cmsState.project.id :'',
          { id: aiItemsForCMS[0].id, data: usedAnswers, cmsIds: [] },
          token.accessToken
        )
        const data = (aiResponse??[]).join(" ").trim();
        return data;
      } else {
        return "";
      }
    }
    return "";
  };

  useAsyncEffect(async () => {
    if (mapKey && cmsState.cmsValuesByTypes) {
      updateActivities("open")
      const list: ContentItemType[] = [];
      const valueFromBrief = noClient
        ? ""
        : TreeUtils.htmlToText(get(cmsState.cmsValuesByTypes.brief, mapKey.key,""));

      const valueFromExpert = get(
        cmsState.cmsValuesByTypes.experts,
        mapKey.key,
        ""
      );

      let valueFromDesigner = TreeUtils.htmlToText(
        get(cmsState.cmsValuesByTypes.expert, mapKey.key, "")
      );

      if (mapKey.key === SERVICE_CMS_ID) {
        valueFromDesigner = get(
          valueFromDesigner[mapKey.index],
          "description",
          ""
        );
      }

      list.push({
        type: "client",
        content: valueFromBrief ? valueFromBrief : "",
      });
      list.push({ type: "designer", content: valueFromDesigner });
      list.push({
        type: "expert",
        content: valueFromExpert,
        options: await getByCategoryAndSubCategory(mapKey.key),
      });
      const valueFromAi = get(cmsState.cmsValuesByTypes.ai, mapKey.key);
      const aiValue =
        valueFromAi && mapKey.index < valueFromAi.length
          ? valueFromAi[mapKey.index]
          : "";
      list.push({ type: "ai", content: aiValue });
      setContentItems(list);
      setShow(true);
    }
  }, [cmsState.cmsValuesByTypes]);

  const onContentPicked = (content: string, type: string) => {
    if (mapKey) {
      updateActivities(`pick(${type})`)
      if (type === "expert" && cmsState.cmsValuesByTypes) {
        let updateDic = cloneDeep(cmsState.cmsValuesByTypes);
        if (!get(updateDic, "experts")) {
          updateDic = { ...updateDic, experts: {} };
        }
        updateDic.experts[mapKey.key] = content;
        dispatch(setTypesDics(updateDic));
      } else if (type === "ai" && cmsState.cmsValuesByTypes) {
        const updateDic = cloneDeep(cmsState.cmsValuesByTypes);
        if (updateDic) {
          if (!has(updateDic, 'ai')) {
            updateDic["ai"]={}
          }
          if (!has(updateDic.ai, mapKey.key)) {
            updateDic.ai[mapKey.key]=['']
          }
          updateDic.ai[mapKey.key][mapKey.index] = content;
          dispatch(setTypesDics(updateDic));
        }
      }
      onUpdateComp(mapKey, content, type);
      onClose();
    }
  };

  const onGoToCategorySet = () => {
    dispatch(setCurrentSection(0));
    onClose();
  };

  return (
    <>
      {" "}
      {show && (
        <Modal open={show} onClose={onClose}>
          <Box sx={style}>
            <Box
              sx={{
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                display: "flex",
                alignItems: "center",
                height: "70px",
                backgroundImage:
                  "linear-gradient(148deg, #fb8332 -39%, #f8c332 102%)",
              }}
            >
              {" "}
              <Box>
                <Typography
                  sx={{
                    padding: "0px 66px",
                    fontFamily: "Nunito",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Content options : {description}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{ paddingRight: "35px", cursor: "pointer" }}
                onClick={onClose}
              >
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.676 1.919a1.145 1.145 0 0 0 0-1.596 1.146 1.146 0 0 0-1.595 0l-9.575 9.59L1.914.322a1.126 1.126 0 0 0-1.58 0 1.117 1.117 0 0 0 0 1.596l9.576 9.573-9.575 9.59a1.117 1.117 0 0 0 0 1.595c.431.43 1.15.43 1.58 0l9.59-9.59 9.576 9.59c.446.43 1.149.43 1.595 0 .432-.447.432-1.165 0-1.596l-9.575-9.589 9.575-9.573z"
                    fill="#000"
                    fillRule="nonzero"
                    opacity=".648"
                  />
                </svg>
              </Box>
            </Box>
            <Box
              sx={{
                // height: "70vh",
                // minHeight: "70vh",
                width: "100%",
                bgcolor: "#f5f8f7",
                paddingTop: "40px",
                paddingBottom: "30px",
              }}
            >
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  padding: "0px 20px",
                }}
              >
                {contentItems.map((ci, idx) => (
                  <Grid item xs={3} key={`ci-${idx}`}>
                    <ContentPickerItem
                      contentItem={ci}
                      onContentPicked={onContentPicked}
                      readonly={readonly}
                      description={description}
                      categoryAndSubExists={categoryAndSubExists}
                      onGoToCategorySet={onGoToCategorySet}
                      onGetAiSuggest={getNewSuggestionFromAi}
                      updateActivities={updateActivities}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
