import { useMsal } from "@azure/msal-react";
import {
  loginRequest,
  loginRequestForCollaborators,
  signUpRequestForCollaborators,
  signUpRequestForDesigners,
} from "../authConfig";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { useSearchParams } from "react-router-dom";

export const LoginPage = () => {
  const { instance } = useMsal();
  const [searchParams] = useSearchParams();

  useAsyncEffect(async () => {
    if (document.location.href.indexOf("/designer_signup") >= 0) {
      let referral = undefined;
      if (searchParams) {
        referral = searchParams.get("referral");
      }
      instance.loginRedirect(
        referral
          ? {
              ...signUpRequestForDesigners,
              extraQueryParameters: {
                Referral: referral ?? "",
              },
            }
          : signUpRequestForDesigners
      );
    } else if (
      document.location.href.indexOf("questionnaire/collaborator") >= 0 ||
      document.location.href.indexOf("brief/collaborator") >= 0 ||
      document.location.href.indexOf("cms/collaborator") >= 0 ||
      document.location.href.indexOf("project/collaborator") >= 0 ||
      document.location.href.indexOf("collaborator/storage_files") >= 0 ||
      document.location.href.indexOf("collaborator/tasks") >= 0
    ) {
      let email = undefined;
      let designer = undefined;
      let forceLogin = false;
      let forceSignUp = false;
      let code = undefined;
      let referral = undefined;

      if (searchParams) {
        email = searchParams.get("email");
        forceLogin = !!searchParams.get("forceLogin");
        designer = searchParams.get("designer");
        referral = searchParams.get("referral");
        forceSignUp = !!searchParams.get("forceSignUp");
        code = searchParams.get("code");
      }
      const alreadyLynxiUser = localStorage.getItem("lynxiUser");
      if ((alreadyLynxiUser || forceLogin) && !forceSignUp) {
        const url = `${document.location.href}&forceSignUp=true`.replace(
          "&forceLogin=true",
          ""
        );
        instance.loginRedirect(
          email
            ? {
                ...loginRequestForCollaborators,
                loginHint: email,
                extraQueryParameters: {
                  InvitationCode: code ?? "",
                  Referral: referral ?? "",
                  Designer: designer ?? "",
                  CustomUrl: escape(url),
                },
              }
            : loginRequestForCollaborators
        );
      } else {
        const url = `${document.location.href}&forceLogin=true`.replace(
          "&forceSignUp=true",
          ""
        );
        instance.loginRedirect(
          email
            ? {
                ...signUpRequestForCollaborators,
                extraQueryParameters: {
                  InvitationCode: code ?? "",
                  CustomUrl: escape(url),
                  Referral: referral ?? "",
                  Designer: designer ?? "",
                },
                redirectStartPage: document.location.href
                  .replace("&forceLogin=true", "")
                  .replace("&forceSignUp=true", ""),
              }
            : signUpRequestForCollaborators
        );
      }
    } else {
      instance.loginRedirect(loginRequest);
    }
  }, []);

  return <></>;
};
