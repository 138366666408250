import React, { FC } from "react";
import Fab from "@mui/material/Fab";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { HelpToolTipOnHover } from "../HelpToolTipOnHover";

export interface FileUploadButtonProps {
  onFileSelect(files: File[]): void;
}

const FileUploadButton: FC<FileUploadButtonProps> = ({ onFileSelect }) => {
  const handleFileChange = (event: any) => {
    const files = event.target.files;
    onFileSelect(files);
  };

  return (
    <div>
      <input
        type="file"
        id="file-upload-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
      />
      <label htmlFor="file-upload-input">
        <HelpToolTipOnHover
          position="top"
          size={250}
          message={"Upload files"}
          actionNode={
            <Fab
              component="span"
              className={"uploadbutton"}
              size="small"
              style={{ transform: "scale(0.8)" }}
            >
              <AddPhotoAlternateIcon fontSize="medium" />
            </Fab>
          }
        />
      </label>
    </div>
  );
};

export default FileUploadButton;
