import axios from "axios";
import { CollaborationTypeEnum } from "../utils/Constants";
import ApiUtils from "./ApiUtils";
import { UserSiteImage } from "./cmsApi";
import { SubscriptionInfo } from "./paymentApi";

export interface UserSettings {
        displayName?: string
        notificationsEmail?: string
        address?: string
        additionalData: string
}

export interface AcccountUpdateRequest {
  name?: string
  accountId: string
  logoUrl?: string
  additionalData: string
  editorSettings: string
  useAsLogo?: boolean
}

export interface AccountMember {
  id: string
  name: string
  email: string
  role: CollaborationTypeEnum
}

export interface AccountInvite {
  created: string
  email: string
}

export interface LynxiAccountInfo {
  name?: string
  logoUrl?: string
  role: CollaborationTypeEnum
  members: AccountMember[]
  invitations: AccountInvite[]
  id: string,
  additionalData?: string
  editorSettings: string
  subscriptions: SubscriptionInfo[]
  created?:string
}

export interface AddMemeberInfo {
  email: string
}

export interface AccountResponse {
  accounts: LynxiAccountInfo[]
}

export interface AddMembersResponse {
  membersAssociatedWithOtherAccount: string[]
}

export interface UserFoundInfo {
  displayName?: string
  found: boolean  
  id?: string
  name?: string
}

export interface ParamData {
  name: string
  value: string
}

export interface UploadInvoiceLinkResponse {
    uploadLink: string
    url: string
}

export interface InvoiceData {
  name?:string
  url: string
  date: string
  id: string  
}

export interface GetInvoicesResponse {
  invoices : InvoiceData[]
}


export interface UsersListResponse {
  users: AccountMember[]
}

class BackofficeApi {


  getParamFromServer = async (param: string, token: string): Promise <ParamData> => {
    const response = await axios.get(`${ApiUtils.serverDomain}/admin/api/metadata?key=${param}`,ApiUtils.Get(token) )
    return response.data
  }


  getUserSettings = async (
    token: string
  ): Promise<UserSettings> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/users`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  updateUserSettings = async (
    userSettings: UserSettings,
    token: string
  ): Promise<string> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/backoffice/api/users`,userSettings, 
      ApiUtils.Put(token)
    );
    return response.data;
  };

  updateAccountSettings = async (
    accountSettings: AcccountUpdateRequest,
    token: string
  ): Promise<string> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/backoffice/api/accounts`,accountSettings, 
      ApiUtils.Put(token)
    );
    return response.data;
  };


  getAccountImages = async (
    accountId: string,
    token: string
  ): Promise<UserSiteImage[]> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/images/account/${accountId}/images`,
      ApiUtils.Get(token)
    );
    if (response.status === 200) {
      const res: string[] = response.data;
      return res.map((src, idx) => ({
        url: src,
        imageId: `${idx}`,
        description: "",
      }));
    }
    return [];
  };

  getProjectImages = async (
    projectId: string,
    token: string
  ): Promise<UserSiteImage[]> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/images/project/${projectId}/images`,
      ApiUtils.Get(token)
    );
    if (response.status === 200) {
      const res: string[] = response.data;
      return res.map((src, idx) => ({
        url: src,
        imageId: `${idx}`,
        description: "",
      }));
    }
    return [];
  };

  getAccount = async (
    token: string
  ): Promise<AccountResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/accounts`,
      ApiUtils.Get(token)
    );
    return response.data
  };

  addTeamMemberToAccount = async (
    accountId: string,
    members: AddMemeberInfo[],
    token: string
  ): Promise<AddMembersResponse> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/backoffice/api/accounts/team/add`,
      {accountId: accountId, members: members},
      ApiUtils.Put(token)
    );
    return response.data
  };

  sendTeamMemberReminder= async (
    accountId: string,
    memberEmail: string,
    token: string
  ): Promise<AddMembersResponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/backoffice/api/accounts/team/reminder`,
      {accountId: accountId, email: memberEmail},
      ApiUtils.Put(token)
    );
    return response.data
  };

  isUserExists = async (
    email: string,
    token: string
  ): Promise<UserFoundInfo> => {
    const params = {
      email: email,
    }
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/users/find/email`,
      ApiUtils.GetWithParams(token, params)
    );
    return response.data
  };

  deleteInvitation = async (
    email: string,
    briefId: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/backoffice/api/invitations/delete`,
      { email: email, briefId: briefId},
      ApiUtils.Post(token)
    );
    return response.data
  };


  getProjectImagesZipLink = async (
    projectId: string,
    token: string
  ): Promise<string> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/storage/project/${projectId}/download`,
      ApiUtils.Post(token)
    );
    return response.data
  };


  getAllUsersList = async (
    token: string
  ): Promise<UsersListResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/users/all`,
      ApiUtils.Get(token)
    );
    return response.data
  };
 
  getInvoiceUploadLink = async (
    accountId: string,
    token: string
  ): Promise<UploadInvoiceLinkResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/paypal/account/${accountId}/invoice/link`,
      ApiUtils.Get(token)
    );
    return response.data
  }

  uploadInvoiceToAccount = async (
    accountId: string,
    name: string,
    date: string,
    url: string,
    token: string
  ): Promise<string> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/backoffice/api/paypal/account/${accountId}/invoice`, {
        name: name,
        date: date,
        url: url
      },
      ApiUtils.Post(token)
    );
    return response.data
  }

  getAccountInfoFormPayPalCustomId = async (
    customId: string,
    token: string
  ): Promise<LynxiAccountInfo> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/paypal/custom_id/${customId}/account`,
      ApiUtils.Get(token)
    );
    return response.data
  }

  getAccountInvoices = async (
    accountId: string,
    token: string
  ): Promise<GetInvoicesResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/Paypal/account/${accountId}/invoices
      `,
      ApiUtils.Get(token)
    );
    return response.data
  }


}

export const backofficeApi = new BackofficeApi();
