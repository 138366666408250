import axios from "axios";
import { AiDataItem } from "../admin/aiSlice";
import ApiUtils from "./ApiUtils";

export interface AiDataItemsList {
  items: AiDataItem[];
}

export interface AIAddItemRequest {
  name: string;
  businessCategory: string;
  parameters: string;
  cmsIds: string[];
  tokens: string[];
  text: string;
}

export interface ProcessAiRequest {
  id: string;
  data: { [id: string]: string | string[] };
  limit?: boolean;
  cmsIds: string[];
}

export interface ProcessAiTestRequest {
  data: { [id: string]: string };
  text: string;
  parameters: string;
}

export interface ProcessChatRequest {
  content: string;
  temperature?: number;
  topP?: number;
  completionChoices?: number;
  maxTokens?: number;
}

export interface SiteAnalyzerResponse {
  logo?: string;
  about?: string[];
  contact?: string[];
}

export interface SiteSiteHtmlResponse {
  html: string;
  innerText: string;
}

class AdminApi {
  getAllAiItems = async (token: string): Promise<AiDataItemsList> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/neurons`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  updateAIData = async (
    aiItem: AiDataItem,
    token: string
  ): Promise<AiDataItemsList> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/neurons`,
      aiItem,
      ApiUtils.Put(token)
    );
    return response.data;
  };

  addNewDataItem = async (
    aIAddItemRequest: AIAddItemRequest,
    token: string
  ): Promise<AiDataItemsList> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/neurons`,
      aIAddItemRequest,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  getDataItemByid = async (id: string, token: string): Promise<AiDataItem> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/neurons/${id}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  processAiTemaplate = async (
    projectId: string,
    data: ProcessAiRequest,
    token: string
  ): Promise<string[] | undefined> => {
    let isBlocked = false;
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/briefs/api/neurons/${projectId}/process`,
        data,
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 429) {
        isBlocked = true;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
    if (isBlocked) {
      throw new Error("Blocked");
    }
  };

  processChat = async (
    data: ProcessChatRequest,
    token: string
  ): Promise<string[]> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/neurons/process/chat`,
      data,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  processAiText = async (
    data: ProcessAiTestRequest,
    token: string
  ): Promise<string[]> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/neurons/process/advanced`,
      data,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  siteAnalyzerRequest = async (
    url: string,
    token: string
  ): Promise<SiteAnalyzerResponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/analyzer/api/website`,
      url,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  getSiteHtml = async (
    url: string,
    token: string
  ): Promise<SiteSiteHtmlResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/analyzer/api/website?url=${encodeURIComponent(
        url
      )}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };
}

export const adminApi = new AdminApi();
