import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import * as React from "react";
import { MobileView } from "react-device-detect";
import useAsyncEffect from "use-async-effect";
import { useAppDispatch } from "../app/hooks";
import { ReactComponent as MobileLogoSvg } from "../assets/mobileLogo.svg";
import { setHideHeader } from "../projects/projectsSlice";


export interface MobileViewMessageProp {
  withLogout?: boolean
}

export const MobileViewMessage: React.FC<MobileViewMessageProp> = ({withLogout}) => {
  const instance = useMsal()
  const dispatch = useAppDispatch()
  useAsyncEffect( async() => {
    dispatch(setHideHeader(true))
  }, [])


  const checkLogout = () => {
    if (withLogout) {
      instance.instance.logout()
    } 
  }

  return (
    <MobileView>
      <Box
        sx={{
          display: "flex",
          background: "#f4f8f7",
          height: "100vh",
          flexDirection: "column",
          justifyContent:'space-between',
          maxWidth: '100vw'
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ margin: "20px" }}>
            <MobileLogoSvg onClick={ () => checkLogout()}/>
          </Box>
          <Box>
            <img
              className="mobileTopImage"
              src="/images/mobile/bitmap/bitmap.webp"
              srcSet="/images/mobile/bitmap/bitmap@2x.webp 2x,
                          /images/mobile/bitmap/bitmap@3x.webp 3x"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", textAlign:'center', flexDirection:'column', padding: '0 10px'}}>
          <Box sx={{fontFamily: 'Nunito', fontSize: '36px',fontweight: 'bold'}}>
             Welcome to Lynxi!
          </Box>
          <Box sx={{fontFamily: 'Nunito', fontSize: '21px',fontweight: 'normal'}}>
            For best expirience, please try 
            us on your desktop computer
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifySelf: "flex-end", marginBottom: '40px' }}>
          <img
            className="mobileBottomImage"
            src="/images/mobile/group/group.webp"
            srcSet="/images/mobile/group/group@2x.webp 2x,
                          /images/mobile/group/group@3x.webp 3x"
          />
        </Box>
      </Box>
    </MobileView>
  );
};
