import { Box, Fab } from "@mui/material";
import React, { FC } from "react";
import { InputEmailField } from "../../../basic/InputEmailField";
import { ReactComponent as InvitationIconSVG } from "../../../assets/project/invitation.svg";
import { BlackMessageDialog } from "../../../Massages/BlackMessageDialog";
import AddIcon from "@mui/icons-material/Add";
import { backofficeApi, UserFoundInfo } from "../../../api/backofficeApi";
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "../../../app/hooks";
import { updateForceRender } from "../../cmsSlice";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { CollaborationTypeEnum } from "../../../utils/Constants";
import { BasicPageBlocker } from "../../../basic/BasicPageBlocker";
import { getAccessTokenSilence } from "../../../auth/auth";

interface MemberAddPopupProps {
  onClose(): void;
  accountId: string;
}

export interface InviteInfo {
  name: string;
  email: string;
  isExist: boolean
}

export const MemberAddPopup: FC<MemberAddPopupProps> = ({
  onClose,
  accountId,
}) => {
  const [invites, setInvites] = React.useState<InviteInfo[]>([
    { name: "", email: "", isExist: false },
  ]);
  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);

  const msalInstance = useMsal();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const addMoreRows = () => {
    setInvites([...invites, { name: "", email: "", isExist: false }]);
  };

  const inviteEnabled = () => {
    return invites.filter((l) => l.email !== "" && !l.isExist).length === invites.length;
  };

  // const setName = (value: string, index: number) => {
  //   const array = [...invites];
  //   array[index].name = value;
  //   setInvites(array);
  // };

  const setEmail =  async (value: string, index: number) => {
    const array = [...invites];
    array[index].email = value;
    array[index].isExist = false
    setInvites(array);
  };

  const checkIfAlreadyDesigner = async (token: string) => {
      const allPromises :Promise<UserFoundInfo>[]= invites.map((r) => backofficeApi.isUserExists(r.email, token))
      const allResponse = await Promise.all(allPromises);
      const alreadyExistsMembers: string[] = []
      allResponse.map((res: UserFoundInfo, idx: number) => {
        if (res.found) {
          alreadyExistsMembers.push(invites[idx].email)
        }
      });
      return alreadyExistsMembers
}

  const sendToEnvites = async () => {
    const token = await getAccessTokenSilence(msalInstance)
    if (token) {
    const emailIssues = await checkIfAlreadyDesigner(token.accessToken)
      if (emailIssues.length> 0) {
        const inviteCopy = [...invites]
        setInvites( inviteCopy.map( invite => ({...invite, isExist: emailIssues.indexOf(invite.email)>=0})))
      } else {
        setOnProcessing(true)
        await backofficeApi.addTeamMemberToAccount(
          accountId,
          invites.map((r) => ({ email: r.email, role: CollaborationTypeEnum.CoOwner })),
          token.accessToken
        );
        setOnProcessing(false)
        dispatch(updateForceRender());
        onClose();
      }
    }
  };

  const deleteRow = (index: number) => {
    const array = [...invites]; // make a separate copy of the array
    array.splice(index, 1);
    setInvites(array);
  };

  return (
    <BlackMessageDialog
      onClose={() => onClose()}
      open={true}
      icon={<InvitationIconSVG />}
      hideDialogClose={true}
    >
      <Box sx={{ dispaly: "flex", flexDirection: "column" , position:'relative'}}>
        <BasicPageBlocker show={onProcessing} />   

        <Box
          sx={{
            fontSize: "14px",
            color: "#fff",
            marginBottom: "10px",
            fontWeight: 600,
          }}
        >
          {t("settings.account.add_popup.title")}
        </Box>
        <Box sx={{ fontSize: "14px", color: "#fff", marginBottom: "20px" }}>
          {t("settings.account.add_popup.sub_title")}
        </Box>
        {invites.map((invite, idx) => (
          <Box
            key={`invite-${idx}`}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "98%",
              marginBottom: "10px",
            }}
          >
            <Box sx={{ width: "90%" }}>
              <InputEmailField
                handleChange={(val) => setEmail(val, idx)}
                defaultValue={invite.email}
                placeholder={"Email"}
                isFullWidth={true}
                required={false}
              />
              {invite.isExist && (
                <span style={{color:'red'}}>User already has an account</span>
              )}
            </Box>
            <Box sx={{ width: "50px", textAlign:'center' }}>
              {idx > 0 && (
                <Fab
                  size="small"
                  sx={{
                    transform: "scale(0.6)",
                    backgroundImage:
                      "linear-gradient(143deg, #3023ae -60%, #c86dd7 102%)",
                    color: "#fff",
                    "&:hover": { color: "#000" },
                  }}
                  onClick={() => deleteRow(idx)}
                >
                  <CloseIcon fontSize="medium" />
                </Fab>
              )}
            </Box>
          </Box>
        ))}
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", marginTop: "5px", alignItems:'center' }}
        >
         <Box sx={{color:'#fff', fontSize: '12px'}}>Add co-worker{' '}</Box><Fab
            size="small"
            sx={{
              transform: "scale(0.6)",
              backgroundImage:
                "linear-gradient(143deg, #3023ae -60%, #c86dd7 102%)",
              color: "#fff",
              "&:hover": { color: "#000" },
            }}
            onClick={() => addMoreRows()}
          >
            <AddIcon fontSize="small" />
          </Fab>
        </Box>
        <Box sx={{ display: "flex", flexGrow: 1 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            color: "#fff",
            marginTop: "80px",
            fontSize: "16px",
          }}
        >
          <Box onClick={onClose} sx={{ cursor: "pointer" }}>
            Cancel
          </Box>
          <Box
            onClick={() => (inviteEnabled() ? sendToEnvites() : {})}
            sx={{
              cursor: "pointer",
              fontWeight: 600,
              color: inviteEnabled() ? "#fff" : "rgba(255,255,255,0.5)",
            }}
          >
            {t("settings.account.add_popup.action")}
          </Box>
        </Box>
      </Box>
    </BlackMessageDialog>
  );
};
