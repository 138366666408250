import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { FC } from "react";
import { Route, Routes, useParams, useSearchParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { projectsApi } from "../api/projectsApi";
import { getAccessToken } from "../auth/auth";
import { BriefsRouter } from "../Briefs/BriefsRouter";
import { Project, ProjectState, setActiveProject, setShowAccountPopup, setShowBrandingDialog } from "./projectsSlice";
import { ProjectView } from "./ProjectView/ProjectView";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { Backdrop, CircularProgress } from "@mui/material";
import ChoosePathPage from "./ProjectForm/ChoosePathPage";
import { SelectPathMessagePopup } from "../Massages/SelectPathMessagePopup";
import { ReactComponent as BrandingSvg } from "../assets/project/branding.svg";



const LazyCMSPdfViewer = React.lazy(
  () => import("../cms/PDFViewer/CMSPdfViewer")
);

const LazyCMSManager = React.lazy(() => import("../cms/CMSManager"));
const LazyProjectDetails = React.lazy(
  () => import("./ProjectForm/ProjectSettings")
);
const LazyImageCommentViewers = React.lazy(
  () => import("../cms/components/ImageCommentViewer/ImageCommentViewer")
);
const LazyPostRouter = React.lazy(() => import("../Social/Posts/PostRouter"));

export interface ProjectContextType {
  project: Project
}
          
export const ProjectContext = React.createContext<ProjectContextType | undefined>(undefined)

export const ProjectsRouter: FC = () => {
  const params = useParams();
  const msalInstance = useMsal();
  const dispatch = useAppDispatch();
  const [searchParam,setSearchParams] = useSearchParams();

  const projectsState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const loadProjectData = async () => {
    if (params.projectId) {
      const token = await getAccessToken(msalInstance);
      if (token) {
        const projectInfo = await projectsApi.getUserProjectById(
          params.projectId,
          token
        );
        dispatch(setActiveProject(projectInfo));
      }
    }
  };

  useAsyncEffect(async () => {
    loadProjectData();
  }, []);

  useAsyncEffect(async () => {
    if (searchParam && searchParam.get("update")) {
      searchParam.delete("update");
      setSearchParams(searchParam);
      dispatch(setActiveProject(undefined));
      loadProjectData();
    }
  }, [searchParam]);

  const onOpenBranding = () => {  
    localStorage.setItem("showBranding", "true");
    dispatch(setShowAccountPopup(true));
    dispatch(setShowBrandingDialog(false));
  }

  const onIgnoreBranding = () => {
    localStorage.setItem("showBranding", "true");
    dispatch(setShowBrandingDialog(false));
  }

  return (
    <>
      {projectsState.activeProject ? (
         <ProjectContext.Provider value={{project: projectsState.activeProject}}>
          {projectsState.showFillBrandingDialog && (
            <SelectPathMessagePopup
              open={true}
              title={"Create a branded experience"}
              message={"Personalize Lynxi with your business info and logo before sharing with your clients."}
              onLeftBtn={() => onIgnoreBranding()}
              onRightBtn={() => onOpenBranding()}
              onClose={() => onIgnoreBranding()}
              leftAction="Proceed without it"
              rightAction={"Add your brand"}
              disableAutoClose={true}
              extraWidth="500px"
              icon={<BrandingSvg />}
            />
      )}
        <Routes>
          <Route path="/details" element={<ProjectView />} />
          <Route path="/select_path" element={<ChoosePathPage project={projectsState.activeProject} />} />
          
          <Route
            path="/questionnaire*"
            element={<BriefsRouter project={projectsState.activeProject} />}
          />
          <Route
            path="/brief*"
            element={<BriefsRouter project={projectsState.activeProject} />}
          />
          <Route
            path="/collaborator/storage_files"
            element={<ProjectView />}
          />
          <Route
            path="/cms/:cmsId/imageComments"
            element={<LazyImageCommentViewers />}
          />
          <Route
            path="/cms/:metadataId/*"
            element={<LazyCMSManager project={projectsState.activeProject} />}
          />
          <Route
              path="/websiteBuilder/:metadataId/*"
              element={<LazyCMSManager project={projectsState.activeProject} webSiteMode={true}/>}
            />
          <Route path="/cms/pdf/:cmsId" element={<LazyCMSPdfViewer />} />
          <Route
            path="/posts/*"
            element={<LazyPostRouter project={projectsState.activeProject} />}
          />

          <Route path="/:mode" element={<LazyProjectDetails />} />
        </Routes>
        </ProjectContext.Provider>
      ) : (
        <Backdrop sx={{ color: "#fff", zIndex: 5000 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
