import { SilentRequest } from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import {
  getParamsByEnvType,
  loginRequestForCollaborators,
  signUpRequestForCollaborators,
  signUpRequestForDesigners,
  silentRequest,
} from "../authConfig";

export const getAccessToken = async (msalInstance: IMsalContext) => {
  if (msalInstance.accounts.length > 0) {
    const request = {
      scopes: [getParamsByEnvType("scope")],
      account: msalInstance.accounts[0],
      authority: getSilentRequestType(msalInstance).authority
    };
    const response = await msalInstance.instance
      .acquireTokenSilent(request)
      .catch(async (error) => {
        console.log(error);
        console.log(
          "silent token acquisition fails. acquiring token using popup"
        );

        await msalInstance.instance.logout();

        // fallback to interaction when silent call fails
        msalInstance.instance
          .acquireTokenRedirect(request)
          .then((tokenResponse) => {
            return tokenResponse;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    if (response) {
      return response.accessToken;
    } else {
      const response = await msalInstance.instance.acquireTokenPopup(request);
      if (response) {
        return response.accessToken;
      }
    }
  }
  return null;
};

export const getSilentRequestType = (msalInstance: IMsalContext): SilentRequest => {
  if (msalInstance.accounts.length > 0) {
    const account = msalInstance.accounts[0]
    const authority = account?.idTokenClaims?.tfp;
    if (authority) {
      switch (authority) {
        case "B2C_1_signup":
          return signUpRequestForDesigners
        case "B2C_1_Collaborator_sign_in":
          return loginRequestForCollaborators
        case "B2C_1_Collaborator_signup":
        case "B2C_1_Collaborator_sign_up":
          return signUpRequestForCollaborators
        default: {
          return silentRequest;
        }
      }
    } 
  }
  return silentRequest;
}



export const getAccessTokenSilence = async (
  msalInstance: IMsalContext,
) => {
  try {
    return await msalInstance.instance.acquireTokenSilent(
      getSilentRequestType(msalInstance)
    );
  } catch(e) {
    return null
  }
};
