import Box from "@mui/material/Box";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { CMSState, setCurrentSection } from "../cmsSlice";
import { ReactComponent as CommentSvg } from "../../assets/project/comment.svg";
import Badge from "@mui/material/Badge";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import IconButton from "@mui/material/IconButton";
import TreeUtils from "../../utils/TreeUtils";
import useAsyncEffect from "use-async-effect";
import { useSearchParams } from "react-router-dom";
import { CommentData } from "../../api/cmsApi";
import { get, orderBy, uniqBy } from "lodash";

export interface CmsCommnetsNavigatorProps {
  isDesignReviewComments?: boolean;
  isClient?: boolean
  noCommentsHelper?: string
}

export const CmsCommnetsNavigator: FC<CmsCommnetsNavigatorProps> = ({
  isDesignReviewComments,
  isClient,
  noCommentsHelper
}) => {
  const cmsData: CMSState = useAppSelector((state: RootState) => state.cmsData);
  const [commentIdx, setCommentIdx] = React.useState<number>(-1);
  const [sectionComps, setSectionComp] = React.useState<string[][]>([]);

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useAsyncEffect(async () => {
    if (cmsData.pages.length > 0 && sectionComps.length === 0) {
      setSectionComp(TreeUtils.getCompSection(cmsData.pages));
    }
  }, [cmsData.pages]);

  useAsyncEffect(async () => {
    if (sectionComps.length > 0) {
      if (searchParams) {
        const gotoComment = searchParams.get("comment");
        if (gotoComment) {
          const unReadComments = getUnreadComments()
          if (unReadComments.length > 0) {
            getCommentSection(getUnreadComments()[0].name);
          }
          setCommentIdx(0);
        }
      }
    }
  }, [sectionComps]);

  const getUnreadComments = () : CommentData[] => {
    if (isDesignReviewComments)
      return cmsData.comments.filter(
          (r) => !r.viewedAt && r.source === (isClient ?'Designer' :'Collaborator')
        )
      else {
        const list = cmsData.comments.filter(
          (r) => !r.viewedAt && r.source === (isClient ?'Designer' :'Collaborator')
        ).map( comment => {
          const index = comment.name.indexOf('-#')
          return { ...comment, countIndex: index === -1? comment.name: comment.name.substring(0,index), isLast: index === -1 ? 0:1}
        })
        return orderBy(
          uniqBy(
          list,
          "countIndex"
        ), ['isLast'],['asc'])
     }
  };

  const getCommentInReview = (comment: CommentData) => {
    const commentOj = JSON.parse(comment.comment);
    const commentId = get(get(commentOj, "info"), "id");
    if (commentId) {
      setTimeout(() => {
        const element = document.getElementById(commentId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          element.click();
        }
      }, 700);
    }
  };

  const getCommentSection = (compKey: string, autoOpen?: boolean) => {
    let sectionId = -1;
    const singleIdx =
      compKey.indexOf("__") === -1
        ? compKey
        : compKey.substring(0, compKey.indexOf("__"));
    sectionComps.forEach((r, index) => {
      if (r.indexOf(singleIdx) > -1) {
        sectionId = index;
      }
    });
    if (sectionId > -1) {
      dispatch(setCurrentSection(sectionId));
      setTimeout(() => {
        const element = document.getElementById(`comment-${singleIdx}`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          if (autoOpen) {
            const btn = document.getElementById(`comment-${compKey}-btn`);
            if (btn) {
              btn.click();
            }
          }
        }
      }, 700);
    }
  };

  const goNext = () => {
    // setCommentIdx(commentIdx + 1);
    // getCommentSection(getUnreadComments()[commentIdx + 1].name);
    setCommentIdx(0);
    isDesignReviewComments
      ? getCommentInReview(getUnreadComments()[0])
      : getCommentSection(getUnreadComments()[0].name, true);
  };

  // const goPrev = () => {
  //   setCommentIdx(commentIdx - 1);
  //   getCommentSection(getUnreadComments()[commentIdx - 1].name);
  // };

  const checkIfShowNext = () => {
    const unreadCount = getUnreadComments().length;
    return (
      commentIdx < getUnreadComments().length - 1 ||
      (unreadCount === 1 && commentIdx === -1)
    );
  };

  const gotoFirstComment = () => {
    setCommentIdx(0);
    isDesignReviewComments
      ? getCommentInReview(getUnreadComments()[0])
      : getCommentSection(getUnreadComments()[0].name, true);
  };

  return (
    <>
      {getUnreadComments().length > 0 ? (
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "70px",
                  height: "6px",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => gotoFirstComment()}
              >
                <Badge badgeContent={getUnreadComments().length} color="error">
                  <CommentSvg />
                </Badge>
              </Box>
            </Box>
            <Box>
              <IconButton
                disabled={!checkIfShowNext()}
                onClick={() => goNext()}
              >
                <NavigateNextIcon sx={{ cursor: "pointer" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ): noCommentsHelper? <Box sx={{textAlign:'center', fontWeight:'bold'}}>{noCommentsHelper}</Box>: null}
    </>
  );
};
